import axios from 'axios';
import { IAddUpswotModel, IGetUpswotModel } from 'entities/Upswot/Upswot.models';

const basePath = '/upswot';
const transparencyPath = '/transparency';

export const upswotTransport = {
  getByUser: ({ userId }: IGetUpswotModel) => axios.get(`${basePath}/${userId}`).then(r => r.data),
  add: ({ userId }: IAddUpswotModel) => axios.post(`${basePath}/${userId}`).then(r => r.data),
  getTransparency: (userId: string) => axios.get(`${transparencyPath}/${userId}`).then(r => r.data)
};
