export interface IPortfolioCashflowItem {
  title: string;
  endValue?: number | string | JSX.Element;
}

export interface IPortfolioItem {
  className: string;
  title: string;
  middleValue: string | JSX.Element;
  endValue: string | JSX.Element;
}

export enum EInvestOptions {
  Whole = 'whole',
  Partial = 'partial'
}
