import React from 'react';
import { Col, Row } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { withRouter, RouteComponentProps } from 'react-router';
import i18n from 'i18n';

interface IComponentProps {
  keyText?: string;
  valueText?: string;
  entityId?: string;
  backButton: boolean;
}

type AllProps = IComponentProps & RouteComponentProps;

class TopNavInfoBarComponent extends React.Component<AllProps> {
  render() {
    const { keyText, valueText, entityId, backButton } = this.props;

    return (
      <Row className="mb-10 simple-text" gutter={12}>
        <Col xxl={6} xl={6} xs={{ span: 12, order: 1 }} className="mb-5">
          {backButton && (
            <div onClick={this.goBack} className="pointer">
              <LeftOutlined className="blue-back-button" />
              <span className="color-blue ff-medium">{i18n.t<string>('back')}</span>
            </div>
          )}
        </Col>

        <Col xxl={12} xl={{ span: 12, order: 2 }} xs={{ span: 24, order: 3 }} className="mb-5 text-center">
          {keyText && valueText && (
            <>
              {keyText}:<span className="ff-medium color-black"> {valueText}</span>
            </>
          )}
        </Col>

        <Col xxl={6} xl={{ span: 6, order: 3 }} xs={{ span: 12, order: 2 }} className="mb-5 text-right">
          {entityId && (
            <>
              Loan ID: <span className="ff-medium color-black">{entityId}</span>
            </>
          )}
        </Col>
      </Row>
    );
  }
  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };
}

export const TopNavInfoBar = withRouter(TopNavInfoBarComponent);
