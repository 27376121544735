import React from 'react';
import { Col, Row } from 'antd';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { communicationDashboard, IDashboardConnectedProps } from 'entities/Dashboard/Dashboard.communications';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { DiversificationScoreCard } from 'entities/Dashboard/components/DiversificationScoreCard';
import { PortfolioHealthSection } from 'entities/Dashboard/components/PortfolioHealthSection';
import { PerformanceSection } from 'entities/Dashboard/components/PerformanceSection';
import { GeneralSection } from 'entities/Dashboard/components/GeneralSection';
import { PortfolioAverages } from 'entities/Dashboard/components/PortfolioAverages';

type AllPropsProps = IDashboardConnectedProps & IUsersConnectedProps;

class DashboardComponent extends React.Component<AllPropsProps> {
  componentDidMount() {
    const { userCurrent, addDashboardModel } = this.props;
    const userId = userCurrent.data?.id;

    if (userId) {
      addDashboardModel(userId);
    }
  }

  render() {
    const { dashboardModel } = this.props;
    const { data: dashboardData, loading } = dashboardModel;

    if (loading || !dashboardData) {
      return <LoadingSpinner />;
    }

    const { portfolio } = dashboardData;

    return (
      <div className="content-layout mt-6">
        <Row justify="space-between" gutter={[24, 24]}>
          <Col xs={24} xl={15}>
            <GeneralSection dashboardData={dashboardData} />
            <PerformanceSection dashboardData={dashboardData} />
            <PortfolioAverages />
          </Col>
          <Col xs={24} xl={8}>
            <DiversificationScoreCard />
            <PortfolioHealthSection portfolio={portfolio} />
          </Col>
        </Row>
      </div>
    );
  }
}

export const Dashboard = communicationUser.injector(communicationDashboard.injector(DashboardComponent));
