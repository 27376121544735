import React from 'react';
import { Pagination, Table } from 'antd';
import { ColumnProps, TablePaginationConfig } from 'antd/es/table';
import i18n from 'i18n';
import moment from 'moment';
import numeral from 'numeral';
import { SorterResult } from 'antd/lib/table/interface';
import { EDateTimeFormats, ENumberFormats } from 'common/consts';
import { ICollectionRequestParams } from 'common/models/request.model';
import { ITransactionsModel, ITransactionsCollection } from 'entities/Transactions/Transactions.models';
import { OrderDirect } from 'entities/LoanApp/LoanApp.const';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';

interface IComponentProps {
  data: ITransactionsCollection | null;
  getTransactionsCollection: (params: ICollectionRequestParams) => void;
  loading?: boolean;
}

interface IComponentState {
  sort: string[];
}

type AllProps = IComponentProps;

// TODO Think about refactoring all tables and make common component
class TransactionsTableComponent extends React.Component<AllProps, IComponentState> {
  state: IComponentState = {
    sort: ['']
  };

  render() {
    const { loading, data } = this.props;
    const pageNumber = data?.page || 1;
    const total = data?.total || 0;
    const transactionsData = data?.data || [];

    return (
      <>
        <Table
          rowKey={(record: ITransactionsModel, index: number | undefined) => `${record.date}${index}`}
          dataSource={transactionsData}
          columns={transactionsTableColumns}
          className="custom-table transactions table-cols-4"
          pagination={false}
          loading={loading}
          onChange={this.onTableChange}
        />
        <Pagination size="small" defaultCurrent={1} current={pageNumber} total={total} onChange={this.onPageChange} />
      </>
    );
  }

  onTableChange = (
    pagination: TablePaginationConfig,
    filters: Partial<Record<keyof ITransactionsModel, string[]>>,
    sorter: SorterResult<ITransactionsModel> | SorterResult<ITransactionsModel>[]
  ) => {
    const { getTransactionsCollection } = this.props;

    if (!Array.isArray(sorter) && sorter.order) {
      const sort = [`${sorter.field},${OrderDirect[sorter.order]}`];
      this.setState({ sort });

      getTransactionsCollection({ sort });
      return;
    }
    getTransactionsCollection({});
  };

  onPageChange = (page: number) => {
    const { getTransactionsCollection } = this.props;
    const { sort } = this.state;

    getTransactionsCollection({ page, sort });
  };
}

const transactionsTableColumns: ColumnProps<ITransactionsModel>[] = [
  {
    title: i18n.t<string>('transactionsTable.type'),
    dataIndex: 'type',
    sorter: true,
    render: text => <span className="medium-dark-text fs-xs">{i18n.t<string>(`transactionsTable.${text}`)}</span>
  },
  {
    title: i18n.t<string>('transactionsTable.loan'),
    dataIndex: 'name',
    sorter: true,
    render: text => <span className="medium-dark-text fs-xs">{text ? text : '--'}</span>
  },
  {
    title: i18n.t<string>('transactionsTable.date'),
    dataIndex: 'date',
    sorter: true,
    render: text => (
      <span className="medium-dark-text fs-xs">{moment(text).format(EDateTimeFormats.FullDayShortMonthFullYear)}</span>
    )
  },
  {
    title: i18n.t<string>('transactionsTable.transactionAmount'),
    dataIndex: 'amount',
    sorter: true,
    render: text => (
      <span className="fs-xs">
        <AccountCurrency /> <span className="medium-dark-text">{numeral(text).format(ENumberFormats.Real)}</span>
      </span>
    )
  }
];

export const TransactionsTable = TransactionsTableComponent;
