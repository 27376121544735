import React, { RefObject } from 'react';
import { Button, Form, Input, Row, Col, FormInstance } from 'antd';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import IdentityDocuments from 'entities/KYCProvider/components/IdentityDocuments';
import { EDecoratorNames, EDocTypes, ENewDecoratorNames } from 'entities/KYCProvider/KYC.const';
import { IShareholder, IUploadFileModel } from 'entities/KYCProvider/KYCProvider.models';

interface IComponentState {
  shareholders: IShareholder[];
  showForm: boolean;
  newDocType: EDocTypes;
}

interface IComponentProps {
  form: RefObject<FormInstance<any>>;
  docType: EDocTypes | null;
  disabled: boolean;
  initialValue?: IShareholder[];
  required?: boolean;
}

interface IAntdFormControl {
  onChange?: (shareholders: IShareholder[]) => void;
  value?: IUploadFileModel;
}

type AllProps = IComponentProps & IAntdFormControl;

class ShareholdersForm extends React.Component<AllProps, IComponentState> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      shareholders: [],
      showForm: false,
      newDocType: EDocTypes.IdentityCard
    };
  }

  componentDidMount() {
    const { initialValue } = this.props;

    if (initialValue) {
      this.setState({ shareholders: initialValue });
    }
  }

  render() {
    const { form, disabled, required = true } = this.props;
    const { shareholders, showForm, newDocType } = this.state;

    return (
      <div className="shareholders-step">
        {showForm && (
          <div className="mb-7">
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  colon={false}
                  label={i18n.t<string>('KYCStepper.currentIdDoc.firstName')}
                  name="newFirstName"
                  rules={[{ required, message: i18n.t<string>('pleaseEnterValue') }]}
                >
                  <Input className="form-input" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  colon={false}
                  label={i18n.t<string>('KYCStepper.currentIdDoc.lastName')}
                  name="newLastName"
                  rules={[{ required, message: i18n.t<string>('pleaseEnterValue') }]}
                >
                  <Input className="form-input" />
                </Form.Item>
              </Col>
            </Row>
            <p>
              <Trans i18nKey="KYCStepper.shareholders.documentInfo">
                A current identity document (double-sided identity card or passport)
                <br /> for the natural person representing the company holding the payment account.
              </Trans>
            </p>
            <IdentityDocuments
              form={form}
              options={[EDocTypes.IdentityCard, EDocTypes.Passport]}
              docType={newDocType}
              changeDocType={this.setNewDoctype}
              disabled={disabled}
              required={required}
              idCardFieldNames={[ENewDecoratorNames.NewIdentityCardSideA, ENewDecoratorNames.NewDrivingLicenceSideB]}
              passportFieldName={ENewDecoratorNames.NewPassport}
              drivingLicenceFieldNames={[ENewDecoratorNames.NewDrivingLicenceSideA, ENewDecoratorNames.NewDrivingLicenceSideB]}
            />
            <Button type="primary" htmlType="button" className="form-submit-button mr-4" onClick={this.onHolderSave}>
              {i18n.t<string>('KYCStepper.shareholders.saveHolder')}
            </Button>
            <Button htmlType="button" className="form-secondary-button" onClick={this.onCancel}>
              {i18n.t<string>('form.cancel')}
            </Button>
          </div>
        )}
        {!showForm && (
          <>
            {shareholders.map((item, index) => (
              <div className="shareholders-step__list-item" key={index}>
                {item.errors && <div className="color-red">{item.errors.map(it => i18n.t(`errors:${it}`)).join(', ')}</div>}
                <Row justify="space-between" align="middle" className="nowrap">
                  <div>
                    <div className="shareholders-step__shareholder-name">
                      {item.firstName ? `${item.firstName} ${item.lastName}` : 'Me'}
                    </div>
                    <div>
                      <span className="ff-semiBold">{i18n.t<string>(`KYCStepper.currentIdDoc.${item.document.type}`)}: </span>
                      <span>{item.document.names.join(', ')}</span>
                    </div>
                  </div>
                  <div className="shareholders-step__delete-button pointer" onClick={() => this.onRemoveItem(index)}>
                    {!disabled && <span>✕</span>}
                  </div>
                </Row>
              </div>
            ))}
            {!shareholders.length && (
              <Button
                htmlType="button"
                className="shareholders-step__add-btn"
                onClick={this.addShareholderByDefault}
                disabled={shareholders.length >= 4}
              >
                {i18n.t<string>('KYCStepper.shareholders.addMeAsShareholder')}
              </Button>
            )}
            <Button
              id="shareholders"
              htmlType="button"
              className="shareholders-step__add-btn"
              onClick={this.onAddOneMore}
              disabled={shareholders.length >= 4 || disabled}
            >
              {i18n.t<string>('KYCStepper.shareholders.addOneMore')}
            </Button>
          </>
        )}
      </div>
    );
  }

  addShareholderByDefault = () => {
    const { form, docType, onChange } = this.props;
    const { shareholders } = this.state;
    const { validateFields } = form.current!;

    validateFields(['firstName', 'lastName', ...Object.values(EDecoratorNames)]).then(values => {
      const newShareholder: IShareholder = {
        firstName: values.firstName,
        lastName: values.lastName,
        document: {
          type: docType as EDocTypes,
          names: Object.values(values as IUploadFileModel)
            .filter(item => item && item.name)
            .map(item => item.name),
          fileIds: Object.values(values as IUploadFileModel)
            .filter(item => item && item.id)
            .map(item => item.id)
        }
      };

      this.setState({ showForm: false, shareholders: [...shareholders, newShareholder] });

      if (onChange) {
        onChange([...shareholders, newShareholder]);
      }
    });
  };

  setNewDoctype = (newDocType: EDocTypes) => {
    this.setState({ newDocType });
  };

  onHolderSave = () => {
    const { form, onChange } = this.props;
    const { shareholders, newDocType } = this.state;
    const { validateFields } = form.current!;
    const fieldNames = ['newFirstName', 'newLastName', ...Object.values(ENewDecoratorNames)];

    validateFields(fieldNames).then(values => {
      const newShareholder: IShareholder = {
        firstName: values.newFirstName,
        lastName: values.newLastName,
        document: {
          type: newDocType as EDocTypes,
          names: Object.values(values as IUploadFileModel)
            .filter(item => item && item.name)
            .map(item => item.name),
          fileIds: Object.values(values as IUploadFileModel)
            .filter(item => item && item.id)
            .map(item => item.id)
        }
      };

      this.setState({ showForm: false, newDocType: EDocTypes.IdentityCard, shareholders: [...shareholders, newShareholder] });

      if (onChange) {
        onChange([...shareholders, newShareholder]);
      }

      form.current!.resetFields(fieldNames);
    });
  };

  onCancel = () => {
    const { form } = this.props;
    this.setState({ showForm: false, newDocType: EDocTypes.IdentityCard });
    form.current!.resetFields(['newFirstName', 'newLastName', ...Object.values(ENewDecoratorNames)]);
  };

  onRemoveItem = (index: number) => {
    const { onChange } = this.props;
    const { shareholders } = this.state;
    shareholders.splice(index, index ? index : 1);
    this.setState({ shareholders: [...shareholders] });

    if (onChange) {
      onChange([...shareholders]);
    }
  };

  onAddOneMore = () => {
    this.setState({ showForm: true });
  };
}

export default ShareholdersForm;
