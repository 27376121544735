import axios from 'axios';
import { ICompanyModel } from 'entities/Onboarding/OnboardingSteps.models';
import { IAddNotesParams } from 'entities/Companies/Companies.models';

const basePath = '/companies';

export const companiesTransport = {
  addNotes: (params: IAddNotesParams): Promise<ICompanyModel> =>
    axios.put(`${basePath}/notes/${params.companyId}`, params).then(r => r.data)
};
