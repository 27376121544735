import axios from 'axios';
import { IIbanModel, INewIbanRequestModel, IOutflowTransferModel } from 'entities/Wallet/Wallet.model';

const basePath = '/payments';

export const paymentsTransport = {
  getIbanModel: (userId: string): Promise<IIbanModel> => axios.get(`${basePath}/${userId}/iban`).then(r => r.data),
  addNewIban: (params: INewIbanRequestModel): Promise<any> =>
    axios.post(`${basePath}/${params.userId}/register-iban-non-sepa`, params).then(r => r.data),
  addOutflowTransfer: (params: IOutflowTransferModel): Promise<any> =>
    axios.post(`${basePath}/${params.userId}/money-out`, params)
};
