import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { DataCardSmall } from 'common/components/Card/DataCardSmall';
import { ILoanAppCollectionData } from 'entities/LoanApp/LoanApp.models';
import { ApplicationCard } from 'entities/LoanApp/components/ApplicationCard';
import { ELoanAppStatus } from 'entities/LoanApp/LoanApp.const';

interface IComponentProps {
  loans: ILoanAppCollectionData[];
}

export const BorrowerLoansApplications: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { loans } = props;
  const [isVisibleRejected, showRejected] = useState<boolean>(true);
  const [filteredLoans, setFilteredLoans] = useState<ILoanAppCollectionData[]>();

  useEffect(() => {
    setFilteredLoans(loans.filter(item => (isVisibleRejected ? item : item.status !== ELoanAppStatus.Declined)));
  }, [loans, isVisibleRejected]);

  return (
    <div>
      <div className="d-flex d-flex_justify_between">
        <DataCardSmall className="pb-6" header={t('borrowerLoans.subHeader.app')} />

        <Checkbox className="form-checkbox" checked={isVisibleRejected} onChange={() => showRejected(value => !value)}>
          {t('borrowerLoans.applications.rejected')}
        </Checkbox>
      </div>

      {filteredLoans?.map(loan => (
        <ApplicationCard loan={loan} key={loan.id} />
      ))}
    </div>
  );
};
