import React, { useEffect } from 'react';
import i18n from 'i18n';
import { DescriptionItem } from 'common/components/Description/DescriptionItem';
import { Description } from 'common/components/Description/Description';
import { communicationPayments, IPaymentsConnectedProps } from 'entities/Wallet/Wallet.communication';

interface IComponentProps {
  userId?: string;
}

type AllProps = IPaymentsConnectedProps & IComponentProps;

export const BankDetailsComponent: React.FC<AllProps> = props => {
  const { userId, getPaymentsIban, paymentsIban } = props;
  const { data: ibanData } = paymentsIban;

  const bankDetails = [
    { title: i18n.t('profileBankDetailsHolder'), value: ibanData?.holder },
    { title: i18n.t('profileBankDetailsIBAN'), value: ibanData?.iban },
    { title: i18n.t('profileBankDetailsBIC'), value: ibanData?.swift }
  ];

  useEffect(() => {
    if (userId) {
      getPaymentsIban(userId);
    }
  }, [userId]);

  if (bankDetails.every(item => !item.value)) {
    return null;
  }

  return (
    <Description header={i18n.t('profileBankDetails')}>
      {bankDetails.map(item => item.value && <DescriptionItem key={item.title} title={item.title} endValue={item.value} />)}
    </Description>
  );
};

export const BankDetails = communicationPayments.injector(BankDetailsComponent);
