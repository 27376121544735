import React from 'react';
import { RouteComponentProps } from 'react-router';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';
import { EAdminRoutes, ESpecialistRoutes } from 'common/models/routes.model';
import { COLLECTION_LIMIT_DEFAULT } from 'common/consts';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { UsersTable } from 'entities/User/components/UsersTable';
import { UsersFilterSection } from 'entities/User/components/UsersFilterSection';
import { EUserRoles, IGetUserCollectionParams } from 'entities/User/User.models';

type AllProps = IUsersConnectedProps & RouteComponentProps;

class UsersListComponent extends React.Component<AllProps> {
  componentDidMount(): void {
    const { getUserCollection, match } = this.props;
    let role = '';

    switch (match.path) {
      case ESpecialistRoutes.Clients:
        role = EUserRoles.Borrower;
        break;
      case ESpecialistRoutes.Investors:
        role = EUserRoles.Investor;
        break;
      case EAdminRoutes.Managers:
        role = EUserRoles.Specialist;
        break;
      default:
        return;
    }

    const params: IGetUserCollectionParams = { roles: [role], offset: 0, limit: COLLECTION_LIMIT_DEFAULT };

    getUserCollection(params);
  }

  render() {
    const { userCollection, userCurrent } = this.props;
    const { data: users, loading } = userCollection;
    const { data: currentUser } = userCurrent;
    const role = currentUser?.role;
    const specOrAdmin = role === EUserRoles.Specialist || role === EUserRoles.Admin;

    return (
      specOrAdmin && (
        <LayoutBasic className="blue-layout pt-10 pb-10">
          <UsersFilterSection applyParams={this.applyParams} role={role} />
          {users && <UsersTable loading={loading} data={users} role={role} applyParams={this.applyParams} />}
        </LayoutBasic>
      )
    );
  }

  applyParams = (params: IGetUserCollectionParams) => {
    const { getUserCollection, userCollection } = this.props;
    const newParams: IGetUserCollectionParams = { ...(userCollection.params || { limit: COLLECTION_LIMIT_DEFAULT, offset: 0 }) };

    getUserCollection({ ...newParams, ...params });
  };
}

export const UsersList = communicationUser.injector(UsersListComponent);
