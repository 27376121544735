import React from 'react';
import i18n from 'i18n';
import { Button, Col, Row } from 'antd';
import { Redirect } from 'react-router';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';
import { ECommonRoutes } from 'common/models/routes.model';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';

type AllProps = IAuthConnectedProps & IUsersConnectedProps;

class AuthErrorComponent extends React.Component<AllProps> {
  render() {
    const { authModel, userRegistration } = this.props;
    const { params } = authModel;
    const { loading } = userRegistration;
    const email = params && params.email;

    if (!email) {
      return <Redirect to="/" />;
    }

    return (
      <LayoutBasic className="blue-layout">
        <Row justify="center">
          <Col xxl={5} xl={7} lg={9} md={10} sm={16} xs={22} className="form-card mt-8 mb-8 p-8">
            <div className="mb-6 fs-sm ff-semiBold color-black">{i18n.t<string>('authErrorFormTitle')}</div>
            <span className="fs-sm color-gray">{i18n.t<string>('authErrorFormText')}</span>
            <Row justify="center">
              <Col>
                <Button type="primary" size="large" className="mb-6 mt-6" loading={loading} onClick={this.resendLink}>
                  {i18n.t<string>('authErrorFormButton')}
                </Button>
                <div className="text-center">
                  <a href={ECommonRoutes.LogIn} className="mb-3 fs-xs color-blue">
                    {i18n.t<string>('authErrorFormLinkToLogin')}
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </LayoutBasic>
    );
  }

  resendLink = () => {
    const { authModel, resendUserRegistration } = this.props;
    const { params } = authModel;
    const email = (params && params.email) || '';

    resendUserRegistration({ email });
  };
}

export const AuthError = communicationAuth.injector(communicationUser.injector(AuthErrorComponent));
