import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import i18n from 'i18n';
import { Button, Col, Form, Input, Row, Select, FormInstance } from 'antd';
import { mapBEErrorsToFields } from 'common/helpers/errors.helper';
import { EErrorStatus } from 'common/models/errors.model';
import { accountTypes, bankCountries, EWalletTabs, holderCountries } from 'entities/Wallet/Wallet.const';
import { communicationPayments, IPaymentsConnectedProps } from 'entities/Wallet/Wallet.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { UploadFileForm } from 'entities/Wallet/components/UploadFileForm';

interface IComponentState {
  fileContent: string | ArrayBuffer | null;
  fileName: string;
}

interface IComponentProps {
  edit?: boolean;
  header: JSX.Element;
}

type AllProps = IComponentProps & IPaymentsConnectedProps & IUsersConnectedProps & RouteComponentProps;

class DepositInfoProvidingFormComponent extends React.Component<AllProps, IComponentState> {
  state = {
    fileContent: '',
    fileName: ''
  };

  formRef = React.createRef<FormInstance>();

  componentDidUpdate() {
    const { paymentsNewIban } = this.props;
    const { errors } = paymentsNewIban;
    const status = errors?.status;
    const data = errors?.data.errors;

    if (status === EErrorStatus.Validation) {
      mapBEErrorsToFields(this.formRef.current!, data);
    }
  }

  render() {
    const { paymentsNewIban, edit, header } = this.props;
    const { loading } = paymentsNewIban;
    const initialValues = {
      accountType: accountTypes[0],
      holderCountry: holderCountries[0],
      bankCountry: bankCountries[0]
    };

    const renderSelectOption = (item: any) => (
      <Select.Option key={item} value={item}>
        {item}
      </Select.Option>
    );

    return (
      <>
        <Col xs={24} md={24} lg={16}>
          {header}
        </Col>
        <Row justify="start" className="w-100">
          <Col xs={24} md={24} lg={16} xl={12}>
            <Form
              ref={this.formRef}
              layout="vertical"
              hideRequiredMark={true}
              className="withdrawal-form"
              initialValues={initialValues}
              onFinish={this.onSubmit}
              onFinishFailed={this.onFinishFailed}
            >
              <Row gutter={32}>
                <Col xs={24} sm={8} md={6} lg={7} xl={7}>
                  <Form.Item
                    label={i18n.t<string>('withdrawalTab.accountType')}
                    name="accountType"
                    rules={[
                      {
                        required: true,
                        message: i18n.t<string>('pleaseSelectOption')
                      }
                    ]}
                  >
                    <Select disabled>
                      {accountTypes.map(item => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Col span={24}>
                <Form.Item
                  label={i18n.t<string>('withdrawalTab.holderName')}
                  name="holderName"
                  rules={[
                    {
                      required: true,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <Input placeholder={i18n.t('pleaseEnterValue')} disabled={loading} />
                </Form.Item>
              </Col>

              <Row gutter={32}>
                <Col xs={24} sm={8} md={6} lg={7} xl={7}>
                  <Form.Item
                    label={i18n.t<string>('withdrawalTab.holderCountry')}
                    name="holderCountry"
                    rules={[
                      {
                        required: true,
                        message: i18n.t<string>('pleaseSelectOption')
                      }
                    ]}
                  >
                    <Select placeholder={i18n.t<string>('selectPlaceholder')} disabled={loading}>
                      {holderCountries.map(renderSelectOption)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={16} md={18} lg={17} xl={17}>
                  <Form.Item
                    label={i18n.t<string>('withdrawalTab.accountNumber')}
                    name="accountNumber"
                    rules={[
                      {
                        required: true,
                        message: i18n.t<string>('pleaseEnterValue')
                      }
                    ]}
                  >
                    <Input placeholder={i18n.t('pleaseEnterValue')} disabled={loading} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                <Col xs={24} sm={8} md={6} lg={7} xl={7}>
                  <Form.Item
                    label={i18n.t<string>('withdrawalTab.bankCountry')}
                    name="bankCountry"
                    rules={[
                      {
                        required: true,
                        message: i18n.t<string>('pleaseSelectOption')
                      }
                    ]}
                  >
                    <Select placeholder={i18n.t<string>('selectPlaceholder')} disabled={loading}>
                      {bankCountries.map(renderSelectOption)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={16} md={18} lg={17} xl={17}>
                  <Form.Item
                    label={i18n.t<string>('withdrawalTab.bicCode')}
                    name="bicCode"
                    rules={[
                      {
                        required: true,
                        message: i18n.t<string>('pleaseEnterValue')
                      }
                    ]}
                  >
                    <Input placeholder={i18n.t('pleaseEnterValue')} disabled={loading} />
                  </Form.Item>
                </Col>
              </Row>

              <UploadFileForm form={this.formRef.current!} setFileContent={this.setFileContent} />

              <Form.Item>
                <Button className="submit-button" type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  {i18n.t<string>('form.submit')}
                </Button>
                {edit && (
                  <Button
                    className="ml-6 submit-button button-secondary"
                    type="default"
                    onClick={this.cancelEditing}
                    disabled={loading}
                  >
                    {i18n.t<string>('cancel')}
                  </Button>
                )}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </>
    );
  }

  setFileContent = (fileContent: string | ArrayBuffer | null, fileName: string) => {
    this.setState({ fileContent, fileName });
  };

  onSubmit = () => {
    const { addPaymentsNewIban, userCurrent } = this.props;
    const { validateFields } = this.formRef.current!;
    const userId = userCurrent.data?.id;

    validateFields().then(values => {
      if (userId) {
        const { fileContent, fileName } = this.state;
        const formData = { proofOfIbanBase64: fileContent.split('base64,')[1], proofOfIbanFilename: fileName, ...values };

        addPaymentsNewIban({ userId, ...formData });
      }
    });
  };

  onFinishFailed = ({ errorFields }: any) => {
    this.formRef.current!.scrollToField(errorFields[0].name);
  };

  cancelEditing = () => {
    const { history } = this.props;

    history.push({ search: `?tab=${EWalletTabs.Withdrawal}` });
  };
}

export const DepositInfoProvidingForm = communicationUser.injector(
  communicationPayments.injector(withRouter(DepositInfoProvidingFormComponent))
);
