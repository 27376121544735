import React from 'react';
import moment from 'moment';
import { EDateTimeFormats } from 'common/consts';

interface IComponentProps {
  date: string;
  value: JSX.Element[];
  author?: string;
  className?: string;
}

export const LoanHistoryItem: React.FC<IComponentProps> = props => {
  const { date, value, author = 'no author', className = 'history-item' } = props;
  return (
    <div className={className}>
      <div className="history-item__date">{moment(date).format(EDateTimeFormats.DefaultDateTime)}</div>
      <div className="history-item__value">{value}</div>
      <div className="history-item__author">{author}</div>
    </div>
  );
};
