import React from 'react';
import { Col, Row } from 'antd';

interface IComponenttProps {
  field?: string;
  value?: string;
}

export const IbanHolderInfoItem = (props: IComponenttProps) => {
  const { field, value } = props;

  return (
    <Row gutter={{ xs: 12, lg: 24 }} className="mb-4 fs-xxs color-grayDarkest">
      <Col xs={24} sm={12} md={6} lg={10} xl={7} xxl={6} className="ff-semiBold">
        {field}
      </Col>
      <Col xs={24} sm={12} md={18} lg={14} xl={17} xxl={18}>
        {value}
      </Col>
    </Row>
  );
};
