import {
  actionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  getSuccessType,
  StoreBranch
} from '@axmit/redux-communications';
import { call, put, takeEvery } from 'redux-saga/effects';
import { clearCreds, saveCreds } from '@axmit/axios-patch-jwt';
import { push } from 'connected-react-router';
import { EErrorStatus } from 'common/models/errors.model';
import { ECommonRoutes } from 'common/models/routes.model';
import { requestUser } from 'common/helpers/requestUser.helper';
import { showPostFailErrors } from 'common/helpers/errors.helper';
import { ITokenModel, IAuthModel } from 'entities/Auth/Auth.models';
import { authTransport } from 'entities/Auth/Auth.transport';
import { clearLoanAppModel } from 'entities/LoanApp/LoanApp.communication';

const namespace = 'auth';

export interface IAuthStoreProps {
  model: StoreBranch<ITokenModel>;
}

export interface IAuthConnectedProps {
  authModel: StoreBranch<ITokenModel, IAuthModel, any>;
  authUser: StoreBranch<IAuthModel>;
  initAuthModel(): void;
  addAuthModel(params: IAuthModel): void;
  deleteAuthModel(): void;
}

const modelApiProvider = [
  new APIProvider(actionsTypes.init, authTransport.initAuth, {
    postSuccessHook: function*() {
      yield requestUser();
    }
  }),
  new APIProvider(actionsTypes.add, authTransport.addAuthModel, {
    postSuccessHook: function*(response: ITokenModel) {
      const userId = response && response.access && response.access.userId;

      if (userId) {
        yield getAuthUser(userId);
        yield put(push(ECommonRoutes.Main));
      }
    },
    postFailHook: function*(response: any) {
      const status = response && response.status;

      showPostFailErrors(response);

      if (status === EErrorStatus.NotAcceptable) {
        yield put(push(ECommonRoutes.AuthError));
      }
    }
  }),
  new APIProvider(actionsTypes.delete, authTransport.deleteAuthModel, {
    postSuccessHook: function*() {
      yield clearAuth();
    }
  })
];

const branches = [new Branch('model', modelApiProvider, new StoreBranch<ITokenModel>(null, null, null, true))];

const strategy = new BaseStrategy({
  namespace,
  branches
});

function* loginSaga() {
  const loginSuccessType = getSuccessType(namespace, 'model', actionsTypes.add);
  yield takeEvery(loginSuccessType, function*(action: any) {
    yield call(saveCreds, action.payload);
  });
}

function* registrationSaga() {
  const registrationSuccessType = getSuccessType(namespace, 'registration', 'confirm');

  yield takeEvery(registrationSuccessType, function*(action: any) {
    const loginSuccessType = getSuccessType(namespace, 'model', actionsTypes.add);

    yield put({ type: loginSuccessType, payload: action.payload });
  });
}

export function* getAuthUser(userId: string) {
  yield put({ type: getStartType('user', 'current', actionsTypes.get), payload: userId });
}

export function* clearAuth() {
  yield clearAuthModel();
  yield clearUserRegistration();
  yield clearUserCurrent();
  yield clearUserPasswordRestore();
  yield clearLoanAppModel();
  yield call(clearCreds);
}

function* clearAuthModel() {
  yield put({ type: getStartType(namespace, 'model', 'clear') });
}

function* clearUserRegistration() {
  yield put({ type: getStartType('user', 'registration', 'clear') });
}

function* clearUserCurrent() {
  yield put({ type: getStartType('user', 'current', 'clear') });
}

function* clearUserPasswordRestore() {
  yield put({ type: getStartType('user', 'passwordRestore', 'clear') });
}

export const communicationAuth = buildCommunication<IAuthConnectedProps>(strategy);

communicationAuth.sagas.push(loginSaga(), registrationSaga());
