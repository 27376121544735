import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InfoSectionItem } from 'common/components/InfoSection/InfoSectionItem';
import { communicationUpswot, IUpswotConnectedProps } from 'entities/Upswot/Upswot.communication';

interface IComponentProps {
  userId: string;
}

type AllProps = IUpswotConnectedProps & IComponentProps;

function UserUpswotLinkComponent(props: AllProps) {
  const { userId, upswotModel } = props;
  const { loading, data: upswotData } = upswotModel;
  const { t } = useTranslation();

  useEffect(() => {
    const { getUpswotModel } = props;

    userId && getUpswotModel({ userId });
  }, [userId]);

  const upswotLink = upswotData?.url ? (
    <Link target="_blank" to={{ pathname: upswotData.url }}>
      Upswot
    </Link>
  ) : (
    '-'
  );

  return (
    <InfoSectionItem
      title={
        <div>
          {t('companyDetails.links')}: {loading ? <Spin size="small" spinning={loading} /> : upswotLink}
        </div>
      }
    />
  );
}

export const UserUpswotLink = React.memo(communicationUpswot.injector(UserUpswotLinkComponent));
