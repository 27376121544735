import { isString } from 'lodash';
import { phoneCodes } from 'common/components/Form/PhoneInput/phoneInput.const';

function extractNumber(value: string | null): string | undefined {
  if (!value) {
    return undefined;
  }

  if (isString(value)) {
    return value.replace(/[^0-9]/g, '');
  }

  return value;
}

function extractPhoneParts(value?: string) {
  if (!value) {
    return { countryCode: undefined, localPhoneNumber: undefined };
  }

  const fullPhoneNumber = phoneCodes.find(item => value.includes(`+${item.code}`));

  if (!fullPhoneNumber) {
    return { countryCode: undefined, localPhoneNumber: undefined };
  }

  return {
    countryCode: fullPhoneNumber.code,
    localPhoneNumber: value.replace(new RegExp(`^\\+${fullPhoneNumber.code}`), '')
  };
}

export const PhoneInputHelper = {
  extractPhoneParts,
  extractNumber
};
