import React from 'react';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';

type AllProps = IUsersConnectedProps;

class SignUpConfirmComponent extends React.PureComponent<AllProps> {
  componentDidMount() {
    const { confirmUserRegistration } = this.props;
    const token = window.location.search.split('token=')[1];
    confirmUserRegistration({ token });
  }

  render() {
    return <LoadingSpinner />;
  }
}

export const SignUpConfirmPage = communicationUser.injector(SignUpConfirmComponent);
