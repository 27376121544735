import React from 'react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { getCreds } from '@axmit/axios-patch-jwt';
import { ECommonRoutes } from 'common/models/routes.model';
import { SignUp } from 'entities/Auth/components/SignUp';
import { Login } from 'entities/Auth/components/Login';
import { RestorePassword } from 'entities/Auth/components/RestorePassword';

class PublicLayoutComponent extends React.PureComponent<RouteComponentProps> {
  async componentDidMount() {
    const creds = await getCreds();

    if (creds.access) {
      this.props.history.push(ECommonRoutes.Main);
    }
  }

  public render() {
    return (
      <Switch>
        <Route path={ECommonRoutes.RestorePass} component={RestorePassword} exact />
        <Route path={ECommonRoutes.SignUp} key={ECommonRoutes.SignUp} component={SignUp} exact />,
        <Route path={ECommonRoutes.LogIn} key={ECommonRoutes.LogIn} component={Login} exact />
        <Route path="*" component={Login} />
      </Switch>
    );
  }
}

export const PublicLayout = withRouter(PublicLayoutComponent);
