import React, { useEffect } from 'react';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { Progress, Row } from 'antd';
import { MIDFUNDER_EMAIL } from 'common/config';
import ProgressIcon from 'app/assets/icons/ProgresIcon.svg';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';
import { IPaymentScheduleCollection } from 'entities/PaymentSchedules/PaymentSchedules.models';
import { RepaymentsTable } from 'entities/LoanApp/components/RepaymentsTable';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { LoadingApplicationInfo } from 'entities/LoanApp/components/LoadingApplicationInfo';

interface IComponentProps {
  loanId: string;
  repaymentAmount?: number;
  userRole?: string;
  paymentScheduleData?: IPaymentScheduleCollection | null;
}

type AllType = ILoanAppConnectedProps & IComponentProps;

const LoanInfoDefaultedComponent: React.FC<AllType> = props => {
  const { loanId, repaymentAmount, userRole, paymentScheduleData, getLoanAppRepaymentDetails, loanAppRepaymentDetails } = props;
  const paymentData = paymentScheduleData?.data;
  const { data: repaymentDetailsData } = loanAppRepaymentDetails;

  const progressValue = () => {
    const paymentData = paymentScheduleData?.data;

    let progress = 0;
    if (paymentData && repaymentAmount) {
      const paidAmount = paymentData
        ?.filter(item => item.isPaid)
        ?.reduce((acc, it) => (it.totalPayment ? acc + it.totalPayment : acc), 0);

      progress = (paidAmount * 100) / repaymentAmount;
      return progress;
    }
    return progress;
  };

  useEffect(() => {
    getLoanAppRepaymentDetails(loanId);
  }, []);

  if (!repaymentDetailsData) {
    return <LoadingApplicationInfo />;
  }

  return (
    <>
      <div className="fs-xxlg mb-4">
        <span>{i18n.t<string>('loanInfoYouPaid')}</span> {''}
        <span className="ff-bold">
          {repaymentDetailsData?.paidTotal} <AccountCurrency />
        </span>
      </div>

      <div className="ff-bold fs-xs">
        <Trans i18nKey="loanInfoDefaltedOptions" values={{ email: MIDFUNDER_EMAIL }}>
          For all questions regarding the loan, please contact our support at
          <span className="color-primary">{MIDFUNDER_EMAIL}</span>
        </Trans>
      </div>

      <>
        <p className="active-info-block__title">{i18n.t<string>('loanInfoYourLoanRepaymentProgress')}</p>
        <Progress
          className="active-info-block__progress-bar"
          percent={progressValue()}
          status="exception"
          strokeColor="#794dd3"
          showInfo={false}
          strokeLinecap="square"
        />
        <Row align="top" justify="space-between">
          <p className="active-info-block__subtitle">{i18n.t<string>('loanInfoRepaymentProgressDescription.2')}</p>
          <img src={ProgressIcon} alt="progress" />
        </Row>
      </>

      <RepaymentsTable userRole={userRole} scheduleData={paymentData} isDefaultedLoan />
    </>
  );
};

export const LoanInfoDefaulted = communicationLoanApp.injector(LoanInfoDefaultedComponent);
