import React from 'react';

interface IComponentProps {
  header?: string;
  subheader?: string | JSX.Element;
  rightSlot?: string | JSX.Element;
  leftSlot?: string | JSX.Element;
  image?: string;
  imageAlt?: string;
  className?: string;
  classNameSubHead?: string;
}

export const InfoCard: React.FC<IComponentProps> = props => {
  const { header, subheader, rightSlot, leftSlot, image, imageAlt = '', className = '', classNameSubHead = '' } = props;
  return (
    <div className={`info-card ${className}`}>
      <div className="info-card__content">
        {image && <img src={image} alt={imageAlt} />}

        <div>
          {header && <div className="info-card__header">{header}</div>}
          {subheader && <div className={`info-card__subheader ${classNameSubHead}`}>{subheader}</div>}
          {leftSlot && <div>{leftSlot}</div>}
        </div>
      </div>

      {rightSlot && <div>{rightSlot}</div>}
    </div>
  );
};
