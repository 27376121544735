import React from 'react';
import i18n from 'i18n';
import numeral from 'numeral';
import { InfoBlock } from 'common/components/InfoSection/InfoBlock';
import { ENumberFormats } from 'common/consts';
import InfoIcon from 'app/assets/icons/InfoIcon.svg';
import { DashboardInfoCard } from 'entities/Dashboard/components/DashboardInfoCard';
import { IDashboardModel } from 'entities/Dashboard/Dashboard.model';
import { PerformanceChart } from 'entities/Dashboard/components/PerformanceChart';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

interface IComponentProps {
  dashboardData: IDashboardModel;
}

type AllProps = IComponentProps & ICurrencyConnectedProps;

class PerformanceSectionComponent extends React.Component<AllProps> {
  render() {
    const { dashboardData, currencyModel } = this.props;
    const { data: currency } = currencyModel;
    const { portfolio } = dashboardData;
    const { defaulted, active } = portfolio;
    const defaultedTotal = defaulted?.total || 0;
    const activeLoansTotal = active?.total || 0;
    const expTotalRepayments = numeral(defaultedTotal + activeLoansTotal).format(ENumberFormats.Real);

    const { realised, pending, defaultedExp } = this.getChardData(dashboardData);

    return (
      <DashboardInfoCard
        title={
          <>
            <span className="mr-3">{i18n.t<string>('dashboard.performance')}</span>
            <img src={InfoIcon} alt="Info Icon" />
          </>
        }
      >
        {currency && (
          <InfoBlock
            title={i18n.t('dashboard.expTotalRepayments')}
            infoValue={{ partOne: expTotalRepayments, partTwo: currency }}
            valueSize="middle"
          />
        )}
        <PerformanceChart chartData={{ realised, pending, defaultedExp }} />
      </DashboardInfoCard>
    );
  }

  getChardData = (dashboardData: IDashboardModel) => {
    const { cashflow, portfolio } = dashboardData;

    return {
      pending: Number(portfolio.active?.outstanding) || 0,
      realised: Number(cashflow.totalPayments) || 0,
      defaultedExp: Number(portfolio.defaulted?.outstanding) || 0
    };
  };
}

export const PerformanceSection = communicationCurrency.injector(PerformanceSectionComponent);
