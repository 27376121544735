import React from 'react';
import { Row } from 'antd';
import i18n from 'i18n';
import { InfoBlock } from 'common/components/InfoSection/InfoBlock';
import InfoIcon from 'app/assets/icons/InfoIcon.svg';
import { DashboardInfoCard } from 'entities/Dashboard/components/DashboardInfoCard';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

type AllProps = ICurrencyConnectedProps;

const PortfolioAveragesComponent: React.FC<AllProps> = props => {
  const { currencyModel } = props;
  const { data: currency } = currencyModel;

  return (
    <DashboardInfoCard
      title={
        <>
          <span className="mr-3">{i18n.t<string>('dashboard.portfolioAverages')}</span>
          <img src={InfoIcon} alt="Info Icon" />
        </>
      }
    >
      <Row justify="space-between">
        <InfoBlock title={i18n.t('loanProps.interestRate')} infoValue={{ partOne: '-', partTwo: '%' }} valueSize="middle" />
        <InfoBlock
          title={i18n.t('dashboard.currentYield')}
          infoValue={{ partOne: '-', partTwo: i18n.t('months') }}
          valueSize="middle"
        />
        {currency && (
          <InfoBlock
            title={i18n.t('dashboard.initialYield')}
            infoValue={{ partOne: '-', partTwo: currency }}
            valueSize="middle"
          />
        )}
        {currency && (
          <InfoBlock
            title={i18n.t('dashboard.currentProfit')}
            infoValue={{ partOne: '-', partTwo: currency }}
            valueSize="middle"
          />
        )}
      </Row>
    </DashboardInfoCard>
  );
};

export const PortfolioAverages = React.memo(communicationCurrency.injector(PortfolioAveragesComponent));
