import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { ECommonRoutes, EInvestorRoutes, ESpecialistRoutes } from 'common/models/routes.model';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EUserStatus } from 'entities/User/User.models';
import { StepperLayout } from 'entities/Onboarding/components/StepperLayout';
import { Dashboard } from 'entities/Dashboard/components/Dashboard';
import { LoanMarketplace } from 'entities/User/components/Investor/LoanMarketplace';
import { Investments } from 'entities/User/components/Investor/Investments';
import { InvestPage } from 'entities/User/components/Investor/InvestPage';
import { LoanAppPageForInvestor } from 'entities/User/components/Investor/LoanAppPageForInvestor';
import { Transactions } from 'entities/Transactions/components/Transactions';
import { Wallet } from 'entities/Wallet/components/Wallet';
import { KYCForm } from 'entities/KYCProvider/components/KYCForm';

type AllProps = IUsersConnectedProps & RouteComponentProps;

const InvestorRoutesComponent: React.FC<AllProps> = props => {
  const { userCurrent, location } = props;
  const { status } = userCurrent.data || {};
  const history = useHistory();

  useEffect(() => {
    const path =
      location.pathname !== ECommonRoutes.Main && location.pathname !== ECommonRoutes.Onboarding
        ? location
        : EInvestorRoutes.Dashboard;

    if (status === EUserStatus.Onboarding || status === EUserStatus.Unconfirmed) {
      history.replace(ECommonRoutes.Onboarding);
    }

    if (status !== EUserStatus.Onboarding && status !== EUserStatus.Unconfirmed) {
      history.replace(path);
    }
  }, []);

  if (!status) {
    return null;
  }

  return (
    <Switch>
      <Route path={ECommonRoutes.Onboarding} component={StepperLayout} exact key={ECommonRoutes.Onboarding} />,
      <Route path={EInvestorRoutes.KYC} component={KYCForm} exact key={EInvestorRoutes.KYC} />,
      <Route path={EInvestorRoutes.Dashboard} component={Dashboard} exact key={EInvestorRoutes.Dashboard} />,
      <Route path={EInvestorRoutes.Marketplace} component={LoanMarketplace} exact key={EInvestorRoutes.Marketplace} />,
      <Route path={EInvestorRoutes.Investments} component={Investments} exact key={EInvestorRoutes.Investments} />,
      <Route path={EInvestorRoutes.InvestPageItem} component={InvestPage} key={EInvestorRoutes.InvestPageItem} />,
      <Route path={ESpecialistRoutes.LoanItem} component={LoanAppPageForInvestor} key={ESpecialistRoutes.LoanItem} />,
      <Route path={ECommonRoutes.Transactions} component={Transactions} exact key={ECommonRoutes.Transactions} />,
      <Route path={ECommonRoutes.Wallet} component={Wallet} key={ECommonRoutes.Wallet} />,
      <Route path={ECommonRoutes.Main} component={Dashboard} key={ECommonRoutes.Main} />
    </Switch>
  );
};

export const InvestorRoutes = communicationUser.injector(withRouter(InvestorRoutesComponent));
