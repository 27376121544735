import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ZefixData } from 'common/components/ZefixData';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';

interface IComponentProps {
  userId: string;
}

type AllProps = IOnboardingConnectedProps & IComponentProps;

const ZeflixInfoComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { onboardingZefixModel, userId } = props;
  const { data: zefix, loading } = onboardingZefixModel;

  useEffect(() => {
    const { getByUserOnboardingZefixModel, clearOnboardingZefixModel } = props;

    userId && getByUserOnboardingZefixModel(userId);

    return () => {
      clearOnboardingZefixModel();
    };
  }, [userId]);

  return (
    <>
      <h3>{t<string>('zefixCompanyDetails')}</h3>
      <ZefixData loading={loading} data={zefix} onboarding={false} />
    </>
  );
};

export const ZeflixInfo = communicationOnboarding.injector(ZeflixInfoComponent);
