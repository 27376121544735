import React from 'react';
import { Row } from 'antd';
import i18n from 'i18n';
import { InfoBlock } from 'common/components/InfoSection/InfoBlock';
import { DashboardInfoCard } from 'entities/Dashboard/components/DashboardInfoCard';
import { IDashboardModel } from 'entities/Dashboard/Dashboard.model';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

interface IComponentProps {
  dashboardData: IDashboardModel;
}

type AllProps = IComponentProps & ICurrencyConnectedProps;

const GeneralSectionComponent: React.FC<AllProps> = props => {
  const { dashboardData, currencyModel } = props;
  const { data: currency } = currencyModel;
  const { activeLoans, cashflow } = dashboardData;
  const { receivedInterest } = cashflow;

  return (
    <DashboardInfoCard title={i18n.t('dashboard.general')}>
      <Row justify="space-between">
        <InfoBlock title={i18n.t('dashboard.loans')} infoValue={{ partOne: activeLoans, partTwo: '' }} />
        <InfoBlock title={i18n.t('dashboard.currentYield')} infoValue={{ partOne: '-', partTwo: '%' }} />
        <InfoBlock title={i18n.t('dashboard.initialYield')} infoValue={{ partOne: '-', partTwo: '%' }} />
        {currency && (
          <InfoBlock title={i18n.t('dashboard.currentProfit')} infoValue={{ partOne: receivedInterest, partTwo: currency }} />
        )}
        {currency && <InfoBlock title={i18n.t('dashboard.totalInvested')} infoValue={{ partOne: '-', partTwo: currency }} />}
      </Row>
    </DashboardInfoCard>
  );
};

export const GeneralSection = React.memo(communicationCurrency.injector(GeneralSectionComponent));
