import { Trans, useTranslation } from 'react-i18next';
import React from 'react';
import { Button } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';
import { ECommonRoutes } from 'common/models/routes.model';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EUserRoles } from 'entities/User/User.models';
import { AddKYCCard } from 'entities/LoanApp/components/AddKYCCard';

type AllProps = IUsersConnectedProps;

const WaitingScreenComponent: React.FC<AllProps> = props => {
  const { userCurrent } = props;
  const role = userCurrent.data?.role;
  const { t } = useTranslation();

  let footer;

  switch (role) {
    case EUserRoles.Borrower: {
      footer = (
        <Button className="form-secondary-button" onClick={() => window.location.replace(ECommonRoutes.Main)}>
          {t('borrowerLoans.KYC.btn')}
        </Button>
      );
      break;
    }
    case EUserRoles.Investor: {
      footer = <AddKYCCard />;
      break;
    }
    default:
      footer = null;
  }

  return (
    <div className="text-center">
      <ClockCircleOutlined className="circle-icon mb-5" />

      <div className="kyc-step-header__text pb-11">
        <Trans i18nKey="bankAccountVerification">
          Our managers are reviewing your application. Most likely it will take about 4 hours.
          <br />
          We will send you the result via email soon.
        </Trans>
      </div>

      {footer}
    </div>
  );
};

export const WaitingScreen = communicationUser.injector(WaitingScreenComponent);
