import React from 'react';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import i18n from 'i18n';
import { communicationLoanDocuments, ILoanDocumentsConnectedProps } from 'entities/LoanDocuments/LoanDocuments.communication';

interface IComponentProps {
  loanId: string;
}

type AllProps = ILoanDocumentsConnectedProps & IComponentProps;

const LoanAdditionalInfoFormComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { loanDocumentsModel } = props;
  const { loading } = loanDocumentsModel;

  const onFinish = (values: any) => {
    const { addLoanDocumentsModel, loanId } = props;

    addLoanDocumentsModel({ loanApplicationId: loanId, ...values });
  };

  return (
    <div>
      <Form onFinish={onFinish} hideRequiredMark={true} layout="vertical" disabled={loading}>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: i18n.t<string>('pleaseEnterValue')
            }
          ]}
        >
          <Input.TextArea rows={4} maxLength={255} />
        </Form.Item>

        <Form.List name="documents" initialValue={['']}>
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <div className="d-flex " key={field.key}>
                  <div className="d-flex__item">
                    <Form.Item
                      className="mb-0"
                      rules={[
                        {
                          required: true,
                          message: i18n.t<string>('pleaseEnterValue')
                        }
                      ]}
                      label={index === 0 ? t('loanProps.addInfoForm.docName') : ' '}
                      {...field}
                    >
                      <Input />
                    </Form.Item>
                  </div>

                  {fields.length > 1 && (
                    <div className="mt-9">
                      <Button type="link" className="color-red mb-2" size="large" onClick={() => remove(field.name)}>
                        <CloseCircleFilled className="fs-md va_middle" />
                      </Button>
                    </div>
                  )}
                </div>
              ))}

              <Form.ErrorList errors={errors} className="mt-4 mb-2" />

              <Form.Item>
                <Button type="link" onClick={() => add()} className="pl-0 mt-3">
                  <PlusCircleFilled />
                  {t('loanProps.addInfoForm.addOneMore')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <div className="d-flex d-flex_justify_center">
            <Button type="primary" className="form-submit-button" htmlType="submit" loading={loading}>
              {t('loanProps.addInfoForm.submit')}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};
export const LoanAdditionalInfoForm = communicationLoanDocuments.injector(LoanAdditionalInfoFormComponent);
