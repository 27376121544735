export const accountTypes = ['IBAN', 'BBAN/RIB', 'Other'];

// TODO Keep in mind that we will need different sets for different roles
export const holderCountries = ['CH', 'UK'];
export const bankCountries = ['CH', 'UK'];

export enum EWalletTabs {
  Deposit = 'deposit',
  Transfers = 'transfers',
  Withdrawal = 'withdrawal'
}

export enum EIbanStatuses {
  NA = 'N/A',
  Verification = 'verification',
  Enabled = 'enabled',
  Disabled = 'disabled'
}
