import React from 'react';
import { Button, Col, Input, Row } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { debounce } from 'lodash';
import i18n from 'i18n';
import { EAdminRoutes } from 'common/models/routes.model';
import { EUserRoles, IGetUserCollectionParams } from 'entities/User/User.models';

interface IComponentProps {
  applyParams: (params: IGetUserCollectionParams) => void;
  role?: string;
}

type AllProps = IComponentProps & RouteComponentProps;

class UsersFilterSectionComponent extends React.Component<AllProps> {
  private debouncedFilter: any;

  constructor(props: AllProps) {
    super(props);

    this.debouncedFilter = debounce(this.onFiltersChange, 500);
  }

  render() {
    const { role } = this.props;
    return (
      <div className="filter-section">
        <Row justify="space-between" align="bottom" gutter={32}>
          <Col xs={24} md={12} xl={8}>
            <div className="mb-4 fs-sm color-gray">{i18n.t<string>('usersTable.searchByName')}</div>
            <Input
              onChange={e => this.debouncedFilter(e.target.value)}
              placeholder={i18n.t('usersTable.searchByName')}
              allowClear
            />
          </Col>
          {role === EUserRoles.Admin && (
            <Col>
              <Button type="primary" className="submit-button" onClick={this.goToCreateManager}>
                {i18n.t<string>('addNewManager')}
              </Button>
            </Col>
          )}
        </Row>
      </div>
    );
  }

  onFiltersChange = (name: string) => {
    const { applyParams } = this.props;

    applyParams({ name: name.trim(), offset: 0 });
  };

  goToCreateManager = () => {
    const { history } = this.props;

    history.push(EAdminRoutes.AddManager);
  };
}

export const UsersFilterSection = withRouter(UsersFilterSectionComponent);
