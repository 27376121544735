import React from 'react';
import i18n from 'i18n';
import { IbanHolderInfoItem } from 'entities/Wallet/components/IbanHolderInfoItem';

interface IComponentsProps {
  holderName?: string;
  iban?: string;
  swift?: string;
}

export const IbanHolderInfo = (props: IComponentsProps) => {
  const { holderName, iban, swift } = props;

  return (
    <>
      <IbanHolderInfoItem field={i18n.t('withdrawalTab.holderName')} value={holderName} />
      <IbanHolderInfoItem field={i18n.t('withdrawalTab.accountNumber')} value={iban} />
      <IbanHolderInfoItem field={i18n.t('withdrawalTab.bicCode')} value={swift} />
    </>
  );
};
