import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { EDateTimeFormats } from 'common/consts';
import { AmountHelper } from 'common/helpers/amount.helper';
import { IPaymentItem } from 'entities/LoanApp/LoanApp.models';
import { getNextPaymentData } from 'entities/LoanApp/LoanApp.helpers';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

interface IComponentProps {
  paymentData: IPaymentItem[];
}

type AllProps = IComponentProps & ICurrencyConnectedProps;

const LoansCardDebtErrorComponent: React.FC<AllProps> = props => {
  const { paymentData, currencyModel } = props;
  const { data: currency } = currencyModel;
  const { t } = useTranslation();

  const payData = getNextPaymentData(paymentData);

  if (!payData) {
    return null;
  }

  const paymentDay = moment(payData.date);
  const formattedPaymentDay = moment(paymentDay).format(EDateTimeFormats.DefaultFullDate);

  return (
    <div className="loan-progress-card__warning">
      {t('borrowerLoans.active.payWarning', {
        total: AmountHelper.getCurrency(payData.totalPayment, currency),
        date: formattedPaymentDay
      })}
    </div>
  );
};

export const LoansCardDebtError = communicationCurrency.injector(LoansCardDebtErrorComponent);
