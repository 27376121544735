import React from 'react';
import { Row, Col, Button } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import i18n from 'i18n';
import { InfoBlock } from 'common/components/InfoSection/InfoBlock';
import { RiskOverview } from 'common/components/RiskOverview';
import { EInvestorRoutes } from 'common/models/routes.model';
import { RestyledTopNavBar } from 'common/components/RestyledTopNavBar';
import EmptyStar from 'app/assets/icons/EmptyStar.svg';
import FilledStar from 'app/assets/icons/FilledStar.svg';
import Statistic from 'app/assets/icons/StatisticIcon.svg';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { ILoanAppBookmarksConnectedProps, communicationLoanAppBookmarks } from 'entities/Bookmarks/Bookmarks.communication';
import { getFormattedSum } from 'entities/LoanApp/LoanApp.helpers';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';

type AllProps = ILoanAppConnectedProps & RouteComponentProps & ILoanAppBookmarksConnectedProps;

class CommonLoanInfoComponent extends React.Component<AllProps> {
  render() {
    const { loanAppModel } = this.props;
    const { data, loading } = loanAppModel;

    if (!data || loading) {
      return null;
    }

    const {
      loanAmount,
      youWillGet,
      duration,
      monthlyInstallments,
      purpose,
      purposeDescription,
      yourProfit,
      interestRate,
      ownRiskClass,
      crifRiskClass,
      inBookmarks,
      id,
      name
    } = data;

    return (
      <div className="common-loan-info">
        <RestyledTopNavBar entityId={id} entityName={name} />
        <Row align="stretch" gutter={24} className="mb-6">
          <Col xl={18}>
            <Row align="top" className="mb-6">
              <Col xl={6} md={{ span: 12, order: 1 }} sm={24} xs={24}>
                <InfoBlock
                  title={i18n.t('loanProps.loanAmount')}
                  infoValue={{ partOne: getFormattedSum(loanAmount), partTwo: <AccountCurrency /> }}
                />
              </Col>
              <Col xl={6} md={{ span: 12, order: 2 }} sm={24} xs={24}>
                <InfoBlock title={i18n.t('loanProps.duration')} infoValue={{ partOne: duration, partTwo: i18n.t('months') }} />
              </Col>
              <Col xl={6} md={{ span: 12, order: 3 }} sm={24} xs={24}>
                <InfoBlock
                  title={i18n.t('loanProps.loanPurpose')}
                  infoValue={{
                    partOne: undefined,
                    partTwo: purposeDescription ? `${purpose} (${purposeDescription})` : purpose
                  }}
                />
              </Col>
              <Col xl={6} md={{ span: 12, order: 4 }} sm={24} xs={24}>
                <InfoBlock
                  title={i18n.t('loanInfoRiskOverview')}
                  children={<RiskOverview ownRisk={ownRiskClass} crif={crifRiskClass} />}
                />
              </Col>
              <Col xl={6} md={{ span: 12, order: 5 }} sm={24} xs={24}>
                <InfoBlock
                  title={i18n.t('loanProps.youWillGet')}
                  infoValue={{ partOne: getFormattedSum(youWillGet), partTwo: <AccountCurrency /> }}
                  valueSize="middle"
                />
              </Col>
              <Col xl={6} md={{ span: 12, order: 6 }} sm={24} xs={24}>
                <InfoBlock
                  title={i18n.t('loanProps.monthlyInstallments')}
                  infoValue={{ partOne: getFormattedSum(monthlyInstallments), partTwo: <AccountCurrency /> }}
                  valueSize="middle"
                />
              </Col>
              <Col xl={6} md={{ span: 12, order: 7 }} sm={24} xs={24}>
                <InfoBlock
                  title={i18n.t('loanProps.yourProfit')}
                  infoValue={{ partOne: getFormattedSum(yourProfit), partTwo: <AccountCurrency /> }}
                  valueSize="middle"
                />
              </Col>
              <Col xl={6} md={{ span: 12, order: 8 }} sm={24} xs={24}>
                <InfoBlock
                  title={i18n.t('loanProps.interestRate')}
                  infoValue={{ partOne: interestRate, partTwo: '%' }}
                  valueSize="middle"
                />
              </Col>
            </Row>
          </Col>
          <Col xl={6}>
            <Button className="form-submit-button" onClick={this.goToInvestPage} loading={loading} disabled={loading}>
              {i18n.t<string>('invest')}
            </Button>
            {inBookmarks ? (
              <img className="loan-info-icon" src={FilledStar} alt="BookmarkOn" onClick={this.deleteFromBookmarks} />
            ) : (
              <img className="loan-info-icon" src={EmptyStar} alt="BookmarkOff" onClick={this.addToBookmarks} />
            )}
            <img className="loan-info-icon--inactive" src={Statistic} alt="Statistic" />
          </Col>
        </Row>
      </div>
    );
  }

  goToInvestPage = () => {
    const { loanAppModel, history } = this.props;
    const { data } = loanAppModel;

    // @ts-ignore
    history.push({ pathname: `${EInvestorRoutes.InvestPage}/${data?.id}`, state: { ...(history.location.state || {}) } });
  };

  addToBookmarks = () => {
    const { loanAppModel, addLoanAppBookmarksModel } = this.props;
    const loanApp = loanAppModel.data;
    const id = loanApp?.id;

    if (id) {
      addLoanAppBookmarksModel(id);
    }
  };

  deleteFromBookmarks = () => {
    const { loanAppModel, deleteLoanAppBookmarksModel } = this.props;
    const loanApp = loanAppModel.data;
    const id = loanApp?.id;

    if (id) {
      deleteLoanAppBookmarksModel(id);
    }
  };
}

export const CommonLoanInfo = communicationLoanApp.injector(
  communicationLoanAppBookmarks.injector(withRouter(CommonLoanInfoComponent))
);
