import React from 'react';
import { Row } from 'antd';
import i18n from 'i18n';
import { PerformanceChartInfoItem } from 'entities/Dashboard/components/PerformanceChartInfoItem';

interface IComponentProps {
  chartData: { realised: number; pending: number; defaultedExp: number };
}

class PerformanceChartComponent extends React.Component<IComponentProps> {
  render() {
    const { chartData } = this.props;
    const { realised, pending, defaultedExp } = chartData;

    const total = realised + pending + defaultedExp;

    const realisedWidth = this.getPercent(realised, total);
    const pendingWidth = this.getPercent(pending, total);
    const defaultWidth = this.getPercent(defaultedExp, total);

    return (
      <>
        <Row className="performance-chart">
          {!!realisedWidth && (
            <div
              className=" performance-chart__section performance-chart__section--realised"
              style={{ width: `${realisedWidth}%` }}
            />
          )}
          {!!pendingWidth && (
            <div
              className=" performance-chart__section performance-chart__section--pending"
              style={{ width: `${pendingWidth}%` }}
            />
          )}
          {!!defaultWidth && (
            <div
              className=" performance-chart__section performance-chart__section--default"
              style={{ width: `${defaultWidth}%` }}
            />
          )}
        </Row>
        <Row align="middle" justify="space-between" className="mt-6">
          <PerformanceChartInfoItem
            title={i18n.t('dashboard.realised')}
            percent={realisedWidth}
            value={realised || '-'}
            type="realised"
          />
          <PerformanceChartInfoItem
            title={i18n.t('dashboard.pending')}
            percent={pendingWidth}
            value={pending || '-'}
            type="pending"
          />
          <PerformanceChartInfoItem
            title={i18n.t('dashboard.defaulted')}
            percent={defaultWidth}
            value={defaultedExp || '-'}
            type="default"
          />
        </Row>
      </>
    );
  }

  getPercent = (value: number, valueBy: number) => {
    return (value * 100) / valueBy;
  };
}

export const PerformanceChart = PerformanceChartComponent;
