import { EOnboardingStepStatus, IOnboarding, IOnboardingStepsModel } from 'entities/Onboarding/OnboardingSteps.models';
import { EOnboardingSteps } from 'entities/Onboarding/OnboardingSteps.const';

export const getZefixUrl = (lang?: string, ehraid?: number) => {
  const zefixMainUrl = 'https://www.zefix.ch';

  return lang && ehraid ? `${zefixMainUrl}/${lang}/search/entity/list/firm/${ehraid}` : zefixMainUrl;
};

export const isStepUnfinished = (step: EOnboardingStepStatus): boolean => {
  return step === EOnboardingStepStatus.New || step === EOnboardingStepStatus.InProgress;
};

export const getOnboardingStep = (
  onboardingStepModelData: IOnboardingStepsModel | null,
  onboardingModelData: IOnboarding | null
) => {
  if (!onboardingStepModelData?.step) {
    if (isStepUnfinished(onboardingModelData?.company_step as EOnboardingStepStatus)) {
      return EOnboardingSteps.CompanyStep;
    }
    if (isStepUnfinished(onboardingModelData?.loan_step as EOnboardingStepStatus)) {
      return EOnboardingSteps.LoanStep;
    }
    if (isStepUnfinished(onboardingModelData?.kyc_step as EOnboardingStepStatus)) {
      return EOnboardingSteps.KYCStep;
    }
  }

  return onboardingStepModelData?.step;
};
