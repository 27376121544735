import i18n from 'i18n';
import { message, FormInstance } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { EErrorStatus, IValidationErrorData } from 'common/models/errors.model';
import { IShareholder } from 'entities/KYCProvider/KYCProvider.models';

export const translateValidationError = (data: IValidationErrorData) => {
  Object.keys(data).map(
    item =>
      (data[item] = data[item].map((it: string) => {
        const errMessage = it.replace(/[^a-zA-Z0-9 ]/g, '');
        return i18n.t(`errors:${errMessage}`);
      }))
  );
};

export const showPostFailErrors = (response: any) => {
  if (response.status === EErrorStatus.Validation) {
    return;
  }
  const errorText = response && response.data.message.split('error.')[1];

  if (response && !response.data.message.startsWith('error')) {
    message.error(response.data.message);
    return;
  }

  message.error(i18n.t<string>(`errors:${errorText}`));
};

export const mapShareholderErrors = (shareholders: IShareholder[], errItems: any) => {
  return shareholders.map((item, index) =>
    Object.keys(errItems).map((it: any) =>
      `${index}` === it ? (item.errors = Object.values(errItems[it].meta.shareholder)) : undefined
    )
  );
};

export const mapBEErrorsToFields = (form: FormInstance, errors: any) => {
  Object.entries(form.getFieldsValue(true)).forEach(([key]) => {
    const hasError = errors && errors[key];

    form.setFields([{ name: key, errors: hasError ? errors[key] : undefined }]);
  });
};

export const validateNameForNumbers = (_: RuleObject, value: string) => {
  const hasNumber = /\d/;

  if (hasNumber.test(value)) {
    return Promise.reject(`${i18n.t('errors:nameContainNumbers')}`);
  }
  return Promise.resolve();
};
