import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { DataCardSmall } from 'common/components/Card/DataCardSmall';
import { ILoanAppCollectionData } from 'entities/LoanApp/LoanApp.models';
import { LoansCard } from 'entities/LoanApp/components/LoansCard';

interface IComponentProps {
  loans: ILoanAppCollectionData[];
}

export const BorrowerLoansActive: React.FC<IComponentProps> = props => {
  const { t } = useTranslation();
  const { loans } = props;
  const [isVisibleCompleted, showCompleted] = useState<boolean>(false);
  const [filteredLoans, setFilteredLoans] = useState<ILoanAppCollectionData[]>();

  useEffect(() => {
    setFilteredLoans(loans.filter(item => (isVisibleCompleted ? item : item.status !== 'completed')));
  }, [loans, isVisibleCompleted]);

  return (
    <div>
      <div className="d-flex d-flex_justify_between">
        <DataCardSmall className="pb-6" header={t('borrowerLoans.subHeader.active')} />

        <Checkbox className="form-checkbox" checked={isVisibleCompleted} onChange={() => showCompleted(value => !value)}>
          {t('borrowerLoans.active.completed')}
        </Checkbox>
      </div>

      {filteredLoans?.map(loan => (
        <LoansCard loan={loan} key={loan.id} />
      ))}
    </div>
  );
};
