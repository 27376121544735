import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import i18n from 'i18n';
import { Button } from 'antd';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';
import { RiskOverview } from 'common/components/RiskOverview';
import { Description } from 'common/components/Description/Description';
import { LoanHistory } from 'common/components/History/LoanHistory';
import { CustomCollapse } from 'common/components/CustomCollapse';
import { FinancialDetails } from 'common/components/FinancialDetails';
import { TopNavInfoBar } from 'common/components/TopNavInfoBar';
import { PersonalDetails } from 'common/components/PersonalDetails';
import { CompanyDetailsDescriptionCard } from 'common/components/CompanyDetailsDescriptionCard';
import { RiskOverviewBlock } from 'common/components/RiskOverviewBlock';
import { Popup } from 'common/components/Popup';
import { LoanDetails } from 'entities/LoanApp/components/LoanDetails';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { ELoanAppItemStatus, ELoanAppStatus } from 'entities/LoanApp/LoanApp.const';
import { RepaymentsTable } from 'entities/LoanApp/components/RepaymentsTable';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { LoanActionsForm } from 'entities/LoanApp/components/LoanActionsForm';
import { communicationLoanAppAudit, ILoanAppAuditConnectedProps } from 'entities/LoanAppAudit/LoanAppAudit.communication';
import { LoadingLoanAppPage } from 'entities/LoanApp/components/LoadingLoanAppPage';
import { EUserRoles } from 'entities/User/User.models';
import { AttachedDocs } from 'entities/LoanApp/components/AttachedDocs';
import { AdditionalDocs } from 'entities/LoanDocuments/components/AdditionalDocs';
import { DefaultDetails } from 'entities/LoanApp/components/DefaultDetails';

interface IComponentState {
  isConfirmationVisible: boolean;
}

type AllProps = ILoanAppConnectedProps &
  ILoanAppAuditConnectedProps &
  IOnboardingConnectedProps &
  IUsersConnectedProps &
  RouteComponentProps<{ id: string }>;

class LoanAppPageComponent extends React.Component<AllProps, IComponentState> {
  state = {
    isConfirmationVisible: false
  };

  componentDidMount(): void {
    const {
      getLoanAppModel,
      getLoanAppAuditCollection,
      getLoanAppPaymentSchedule,
      getLoanAppRepaymentDetails,
      match
    } = this.props;
    const id = match.params.id;

    getLoanAppModel({
      id
    });

    getLoanAppAuditCollection({ id });
    getLoanAppPaymentSchedule(id);
    getLoanAppRepaymentDetails(id);
  }

  render() {
    const {
      loanAppModel,
      loanAppPaymentSchedule,
      userModel,
      userCurrent,
      loanAppAuditCollection,
      loanAppRepaymentDetails
    } = this.props;
    const { isConfirmationVisible } = this.state;
    const { loading: userLoading } = userModel;
    const { data: loanAppData, loading: loanAppLoading } = loanAppModel;
    const { data: paymentScheduleData, loading: paymentScheduleLoading } = loanAppPaymentSchedule;
    const { data: auditDataCollection, loading: auditLoading } = loanAppAuditCollection;
    const { data: repaymentDetailsData } = loanAppRepaymentDetails;
    const { data: currentUser } = userCurrent;
    const currentUserId = currentUser?.id;
    const currentUserRole = currentUser?.role;
    const loading = userLoading || loanAppLoading || paymentScheduleLoading;

    if (loading || !loanAppData || !repaymentDetailsData) {
      return <LoadingLoanAppPage />;
    }

    const { status, crifRiskClass, specialistId, company, name, ownRiskClass, user: requestedUser, comment } = loanAppData;

    // @ts-ignore Need BE fix
    const { profile, kyc, currency } = requestedUser;
    const files = kyc?.kycSteps;

    const isLoanAssigned = !!specialistId;
    const assignToMe = isLoanAssigned && specialistId === currentUserId;
    const assignText = assignToMe ? i18n.t('loanAssignedOnYou') : i18n.t('loanAssignedOnOther');
    const unassignedText = isLoanAssigned ? assignText : i18n.t('loanUnassigned');

    const showHistory = auditDataCollection && auditDataCollection.count > 0 && !auditLoading;
    const showAdditionalDetails = status !== ELoanAppStatus.Verification && status !== ELoanAppStatus.AdditionalInfoRequired;
    const showMetrics = currentUserRole === EUserRoles.Specialist && showAdditionalDetails;

    let statusForSpecialist = '';
    switch (status) {
      case ELoanAppStatus.Verification:
        statusForSpecialist = assignToMe
          ? i18n.t(`specLoanStatus.${ELoanAppItemStatus.RateRequest}`)
          : i18n.t(`specLoanStatus.${ELoanAppItemStatus.New}`);
        break;
      case ELoanAppStatus.AdditionalInfoRequired:
        statusForSpecialist = i18n.t(`specLoanStatus.${ELoanAppItemStatus.InfoIsRequested}`);
        break;
      case ELoanAppStatus.ReadyForSign:
        statusForSpecialist = i18n.t(`specLoanStatus.${ELoanAppItemStatus.WaitingForSigning}`);
        break;
      case ELoanAppStatus.LookingForInvestor:
        statusForSpecialist = i18n.t(`specLoanStatus.${ELoanAppItemStatus.Pool}`);
        break;
      case ELoanAppStatus.Active:
        statusForSpecialist = i18n.t(`specLoanStatus.${ELoanAppItemStatus.Funded}`);
        break;
      case ELoanAppStatus.Completed:
        statusForSpecialist = i18n.t(`specLoanStatus.${ELoanAppItemStatus.Completed}`);
        break;
      case ELoanAppStatus.Defaulted:
        statusForSpecialist = i18n.t(`specLoanStatus.${ELoanAppItemStatus.Defaulted}`);
        break;
      case ELoanAppStatus.Declined:
        statusForSpecialist = i18n.t(`specLoanStatus.${ELoanAppItemStatus.Declined}`);
        break;
      default:
        break;
    }

    let appInfoPageBody: JSX.Element = <div></div>;
    switch (status) {
      case ELoanAppStatus.Verification:
        if (!isLoanAssigned) {
          appInfoPageBody = (
            <Description
              header={i18n.t('loanActions')}
              footer={
                <Button className="submit-button" type="primary" onClick={this.assignToMe}>
                  {i18n.t<string>('assignToMe')}
                </Button>
              }
            />
          );
        }

        if (isLoanAssigned && assignToMe) {
          appInfoPageBody = <Description header={i18n.t<string>('loanActions')} footer={<LoanActionsForm />} />;
        }
        break;
      case ELoanAppStatus.ReadyForSign:
      case ELoanAppStatus.LookingForInvestor:
        break;
      case ELoanAppStatus.Active:
        appInfoPageBody = (
          <>
            {paymentScheduleData && !!paymentScheduleData.data.length && (
              <RepaymentsTable scheduleData={paymentScheduleData.data} currency={currency} />
            )}
            <RiskOverview crif={crifRiskClass} ownRisk={ownRiskClass} />
          </>
        );
        break;
      default:
        break;
    }

    return (
      <LayoutBasic className="pt-10 pb-10 blue-layout">
        <TopNavInfoBar
          backButton
          keyText={i18n.t('loanProps.status')}
          valueText={`${statusForSpecialist}, ${unassignedText}`}
          entityId={name}
        />
        <LayoutBasic>
          {appInfoPageBody}
          {showHistory && auditDataCollection && status !== ELoanAppStatus.Declined && (
            <CustomCollapse openedLabel={i18n.t('hideLoanHistory')} closedLabel={i18n.t('showLoanHistory')}>
              <LoanHistory history={auditDataCollection.data} />
            </CustomCollapse>
          )}

          <LoanDetails currency={currency} />

          {status === ELoanAppStatus.Active && (
            <Description
              header={i18n.t('loanActions')}
              footer={
                <Button
                  className="submit-button"
                  type="primary"
                  onClick={() => this.setState({ isConfirmationVisible: true })}
                  danger
                >
                  {i18n.t<string>('loanProps.defaultTheLoan')}
                </Button>
              }
            />
          )}

          {status === ELoanAppStatus.Defaulted && <DefaultDetails currency={currency} />}

          {status === ELoanAppStatus.Declined && (
            <Description header={i18n.t('declineInfo')}>
              <div className="application-info__comment mt-6 mb-0">{comment}</div>
            </Description>
          )}

          {(status === ELoanAppStatus.ReadyForSign || status === ELoanAppStatus.LookingForInvestor) && (
            <RiskOverviewBlock crif={crifRiskClass} ownRisk={ownRiskClass} />
          )}
          {showMetrics && <LoanDetails currency={currency} header={i18n.t('loanMetricsForInvestor')} metricsForInvestor />}
          <CompanyDetailsDescriptionCard
            userId={loanAppData.user.id}
            company={company}
            userRole={EUserRoles.Specialist}
            companyClickable
            reloadCompany={() => {
              const { getLoanAppModel, match } = this.props;
              const id = match.params.id;

              getLoanAppModel({
                id
              });
            }}
          />
          {profile && <PersonalDetails profile={profile} email={requestedUser.email} currency={currency} />}
          <AdditionalDocs loanId={loanAppData.id} cardView />
          {files && <AttachedDocs files={files} />}
          {showAdditionalDetails && <FinancialDetails />}

          <Popup
            className="loan-app-page__default-modal"
            title={i18n.t<string>('loanProps.areYouSure')}
            visible={isConfirmationVisible}
            onCancel={() => this.setState({ isConfirmationVisible: false })}
          >
            <Button className="submit-button" onClick={() => this.setState({ isConfirmationVisible: false })}>
              {i18n.t<string>('no')}
            </Button>
            <Button className="submit-button" type="primary" onClick={this.defaultTheLoan}>
              {i18n.t<string>('yes')}
            </Button>
          </Popup>
        </LayoutBasic>
      </LayoutBasic>
    );
  }

  assignToMe = () => {
    const {
      loanAppModel: { data: loanApp },
      userCurrent: { data: user },
      updateLoanAppModel
    } = this.props;

    if (loanApp && user) {
      loanApp.specialistId = user.id;
      updateLoanAppModel(loanApp);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  defaultTheLoan = () => {
    const { defaultLoanAppModel, match } = this.props;
    const id = match.params.id;

    this.setState({ isConfirmationVisible: false });
    defaultLoanAppModel(id);
  };
}

export const LoanAppPage = communicationLoanAppAudit.injector(
  communicationUser.injector(communicationLoanApp.injector(communicationOnboarding.injector(withRouter(LoanAppPageComponent))))
);
