import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import i18n from 'i18n';
import { Row, Col } from 'antd';
import { compose } from 'redux';
import { CompanyDetailsDescription } from 'common/components/CompanyDetailsDescription';
import { FinancialDetails } from 'common/components/FinancialDetails';
import { CustomCollapse } from 'common/components/CustomCollapse';
import { IModelRequestParams } from 'common/models/request.model';
import { queriesForRequests } from 'common/consts';
import { EInvestorRoutes } from 'common/models/routes.model';
import { InfoSection } from 'common/components/InfoSection/InfoSection';
import { InfoSectionItem } from 'common/components/InfoSection/InfoSectionItem';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { ELoanAppStatus } from 'entities/LoanApp/LoanApp.const';
import { CommonLoanInfo } from 'entities/User/components/Investor/CommonLoanInfo';
import { ActiveLoanInfo } from 'entities/User/components/Investor/ActiveLoanAppInfo';
import { RepaymentsTable } from 'entities/LoanApp/components/RepaymentsTable';
import { LoadingPageForInvestor } from 'entities/User/components/Investor/LoadingLoanAppPageForInvestor';
import { EUserRoles } from 'entities/User/User.models';
import { getLoanDetails } from 'entities/LoanApp/LoanApp.helpers';

type AllProps = ILoanAppConnectedProps & RouteComponentProps<{ id: string }>;

class LoanAppPageForInvestorComponent extends React.Component<AllProps> {
  async componentDidMount() {
    const { getLoanAppModel, match, location, getLoanAppPaymentSchedule } = this.props;
    const { id: loanId } = match.params;

    const payloadForModel: IModelRequestParams = {
      id: loanId
    };

    const paymentParams = new URLSearchParams(location.search);
    const event = paymentParams.get('event');

    if (event === queriesForRequests.docuSignComplete) {
      payloadForModel.event = event;
    }

    getLoanAppModel(payloadForModel);
    getLoanAppPaymentSchedule(loanId);
  }

  render() {
    const { loanAppModel, loanAppInvestors, loanAppPaymentSchedule } = this.props;
    const { data: loanAppData, loading: loanAppLoading } = loanAppModel;
    const { data: paymentScheduleData, loading: paymentScheduleLoading } = loanAppPaymentSchedule;
    const { loading: investorsLoading } = loanAppInvestors;
    const loading = loanAppLoading || paymentScheduleLoading || investorsLoading;

    if (loading || !loanAppData) {
      return <LoadingPageForInvestor />;
    }

    const { company, status } = loanAppData;
    const loanDetailsItems = getLoanDetails(loanAppData, EUserRoles.Investor, true);

    switch (status) {
      case ELoanAppStatus.Active:
        return (
          <div>
            <Row gutter={24} justify="center" className="content-layout">
              <Col xs={24} xl={16}>
                <ActiveLoanInfo />
                {paymentScheduleData && (
                  <RepaymentsTable scheduleData={paymentScheduleData.data} userRole={EUserRoles.Investor} />
                )}

                <CustomCollapse openedLabel={i18n.t('appInfoHide')} closedLabel={i18n.t('appInfoSeeMore')}>
                  <CompanyDetailsDescription
                    company={company}
                    userRole={EUserRoles.Investor}
                    reloadCompany={this.reloadCompany}
                  />

                  <InfoSection header={i18n.t('loanDetails')}>
                    {loanDetailsItems.map(({ title, value, formatter }, index) => (
                      <InfoSectionItem key={index} title={title} value={formatter(value)} />
                    ))}
                  </InfoSection>

                  <FinancialDetails />
                </CustomCollapse>
              </Col>
            </Row>
          </div>
        );
      case ELoanAppStatus.LookingForInvestor:
        return (
          <div>
            <CommonLoanInfo />
            <Row gutter={{ lg: 80, xl: 140 }} justify="start" className="content-layout mr-0 ml-0">
              <Col xs={24} lg={12} xl={10}>
                <CompanyDetailsDescription company={company} userRole={EUserRoles.Investor} reloadCompany={this.reloadCompany} />
              </Col>
              <Col xs={24} lg={12} xl={10}>
                <FinancialDetails />
              </Col>
            </Row>
          </div>
        );
      default:
        return <Redirect to={EInvestorRoutes.Marketplace} />;
    }
  }

  reloadCompany = () => {
    const { getLoanAppModel, match } = this.props;
    const id = match.params.id;

    getLoanAppModel({
      id
    });
  };
}

const containers = compose(communicationLoanApp.injector, withRouter);

export const LoanAppPageForInvestor = containers(LoanAppPageForInvestorComponent);
