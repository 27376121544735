import React from 'react';
import i18n from 'i18n';
import numeral from 'numeral';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { EDateTimeFormats, ENumberFormats } from 'common/consts';
import { AmountHelper } from 'common/helpers/amount.helper';
import { IPaymentItem } from 'entities/LoanApp/LoanApp.models';
import { ReminderFee } from 'entities/LoanApp/components/ReminderFee';
import { EPaymentTypes } from 'entities/LoanApp/LoanApp.const';
import { getNextPaymentData } from 'entities/LoanApp/LoanApp.helpers';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

interface IComponentProps {
  scheduleData?: IPaymentItem[];
  userRole?: string;
}

type AllProps = ICurrencyConnectedProps & IComponentProps;

class RepaymentInfoComponent extends React.Component<AllProps> {
  render() {
    const { scheduleData, currencyModel } = this.props;
    const { data: currency } = currencyModel;

    if (!scheduleData?.length) {
      return <div></div>;
    }

    const payData = getNextPaymentData(scheduleData);

    if (!payData) {
      return i18n.t('nextPaymentMessageAllPaymentsCompleted');
    }

    const paymentDay = moment(payData.date);
    const formattedPaymentDay = moment(paymentDay).format(EDateTimeFormats.DefaultFullDate);
    const nextPaymentAmount = AmountHelper.getCurrency(payData.totalPayment, currency);

    const penaltyAmount = scheduleData
      ?.filter(item => item.type === EPaymentTypes.Penalty && !item.isPaid)
      ?.reduce((acc, it) => (it.totalPayment ? acc + it.totalPayment : acc), 0);

    const differenceBetweenDates = moment(new Date().toDateString()).diff(paymentDay, 'days');

    return (
      <div className="repayment-info">
        <Trans i18nKey="nextPaymentMessage" values={{ payment: nextPaymentAmount, date: formattedPaymentDay }}>
          You need to pay
          <span className="ff-bold">{nextPaymentAmount}</span>
          until
          <span className="ff-bold">{formattedPaymentDay}</span>
        </Trans>
        <br />
        {differenceBetweenDates > 0 && (
          <>
            <span className="color-red">
              {' '}
              {i18n.t<string>('paymentOverdue', { count: differenceBetweenDates })}
            </span>
            {!!penaltyAmount && <ReminderFee penaltyAmount={numeral(penaltyAmount).format(ENumberFormats.Real)} />}
          </>
        )}
      </div>
    );
  }
}

export const RepaymentInfo = communicationCurrency.injector(RepaymentInfoComponent);
