import React from 'react';
import i18n from 'i18n';
import { IDescriptionItem } from 'common/models/common.model';
import { Description } from 'common/components/Description/Description';
import { DescriptionItem } from 'common/components/Description/DescriptionItem';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';

type AllProps = ILoanAppConnectedProps;

class FinancialDetailsComponent extends React.Component<AllProps> {
  render() {
    const { loanAppModel } = this.props;
    const { data: loanApp } = loanAppModel;

    if (!loanApp) {
      return null;
    }

    const { equityRatio, interestCoverageRatio, debtRatio, returnOnInvestment, operationEfficiency, revenueGrowth } = loanApp;
    const descriptionItems: IDescriptionItem[] = [
      {
        title: i18n.t('loanProps.equityRatio'),
        value: equityRatio,
        formatter: value => (value ? `${value} %` : '--')
      },
      {
        title: i18n.t('loanProps.interestCoverageRatio'),
        value: interestCoverageRatio,
        formatter: value => `${value || '--'}`
      },
      {
        title: i18n.t('loanProps.debtRatio'),
        value: debtRatio,
        formatter: value => `${value || '--'}`
      },
      {
        title: i18n.t('loanProps.returnOnInvestment'),
        value: returnOnInvestment,
        formatter: value => `${value || '--'}`
      },
      {
        title: i18n.t('loanProps.operationEfficiency'),
        value: operationEfficiency,
        formatter: value => `${value || '--'}`
      },
      {
        title: i18n.t('loanProps.revenueGrowth'),
        value: revenueGrowth,
        formatter: value => (value ? `${value} %` : '--')
      }
    ];

    return (
      <Description header={i18n.t('loanProps.financialDetails')}>
        {descriptionItems.map(({ title, value, formatter }, index) => (
          <DescriptionItem key={index} title={title} endValue={formatter(value)} />
        ))}
      </Description>
    );
  }
}

export const FinancialDetails = communicationLoanApp.injector(FinancialDetailsComponent);
