import React from 'react';
import i18n from 'i18n';
import { InfoBlock } from 'common/components/InfoSection/InfoBlock';
import InfoIcon from 'app/assets/icons/InfoIcon.svg';
import { DashboardInfoCard } from 'entities/Dashboard/components/DashboardInfoCard';
import { PortfolioHealthItem } from 'entities/Dashboard/components/PortfolioHealthItem';
import { IPortfolioModel } from 'entities/Dashboard/Dashboard.model';

interface IComponentProps {
  portfolio: IPortfolioModel;
}

export const PortfolioHealthSection = (props: IComponentProps) => {
  const { portfolio } = props;
  const { current, late, defaulted } = portfolio;

  return (
    <DashboardInfoCard
      title={
        <>
          <span className="mr-3">{i18n.t<string>('dashboard.portfolioHealth')}</span>
          <img src={InfoIcon} alt="Info Icon" />
        </>
      }
    >
      <InfoBlock title={i18n.t('dashboard.consistPayments')}>
        <PortfolioHealthItem percent="-" sum={current?.total} tooltip={i18n.t('dashboard.everythingGoesWell')} />
      </InfoBlock>
      <InfoBlock title={i18n.t('dashboard.latePaymentsTotal')}>
        <PortfolioHealthItem percent="-" sum={late?.total} />
      </InfoBlock>
      <InfoBlock title={i18n.t('dashboard.defaulted')}>
        <PortfolioHealthItem percent="-" sum={defaulted?.total} />
      </InfoBlock>
    </DashboardInfoCard>
  );
};
