import React from 'react';
import { Card, Col, Row } from 'antd';
import i18n from 'i18n';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';
import { TopNavInfoBar } from 'common/components/TopNavInfoBar';
import { NewManagerCreationForm } from 'entities/User/components/Admin/NewManagerCreationForm';

export class AddNewManagerPage extends React.Component {
  render() {
    return (
      <LayoutBasic className="blue-layout pt-10 pb-10">
        <TopNavInfoBar backButton />

        <Row justify="center">
          <Col xl={16} lg={18} md={24}>
            <Card title={i18n.t<string>('addNewManager')} className="custom-card">
              <NewManagerCreationForm />
            </Card>
          </Col>
        </Row>
      </LayoutBasic>
    );
  }
}
