import '@axmit/mdf-api';
import { IFormattedPagination, ISort } from 'common/models/request.model';

export interface IUserSignUpModel extends Components.Schemas.RegisterDto {}
export interface IUserModel extends Components.Schemas.UserDto {}
export interface IUserFileModel extends Components.Schemas.KycStepDto {}
export interface IUserCollection extends Components.Schemas.UserCollectionDto {}
export interface IUpdateUserModel extends Components.Schemas.UpdateUserDto {
  id: string;
}
export interface IUserProfile extends Components.Schemas.ProfileDto {}
export interface IRestorePasswordModel extends Components.Schemas.PasswordForgotDto {}
export interface INewPasswordModel extends Components.Schemas.PasswordRecoveryDto {}
export interface IConfirmationCode extends Components.Schemas.ConfirmDto {}
export interface IResendRequestModel extends Components.Schemas.EmailDto {}
export interface IBalanceModel extends Components.Schemas.BalanceDto {}
export interface IDepositInfoModel extends Components.Schemas.DetailsForDepositDto {}
export interface INewManagerRequestModel extends Components.Schemas.CreateSpecialistUserDto {}
export interface INewManagerResponseModel extends Components.Schemas.RegisterDto {
  confirmed: boolean;
}
// TODO: need BE types
export interface IGetUserCollectionParams extends Partial<IFormattedPagination>, Partial<ISort> {
  roles?: string[];
  name?: string;
}

export enum EUserRoles {
  Borrower = 'borrower',
  Investor = 'investor',
  Specialist = 'specialist',
  Admin = 'admin'
}

export enum EUserTypes {
  Individual = 'individual',
  CompanyRepresentative = 'company_representative'
}

export enum EUserStatus {
  New = 'new',
  Onboarding = 'onboarding',
  Unconfirmed = 'unconfirmed',
  Confirmed = 'confirmed',
  Blocked = 'blocked'
}
