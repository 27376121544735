import React from 'react';
import { Row, Col, Skeleton, Tooltip } from 'antd';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIcon from 'app/assets/icons/CopyIcon.svg';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';

type AllProps = IUsersConnectedProps;

class DepositComponent extends React.Component<AllProps> {
  state = {
    IBANTooltipVisible: false,
    RCTooltipVisible: false
  };
  componentDidMount() {
    const { getUserDepositInfo, userCurrent } = this.props;
    const { data: userData } = userCurrent;

    if (userData?.id) {
      getUserDepositInfo(userData.id);
    }
  }

  render() {
    const { userDepositInfo } = this.props;
    const IBAN = userDepositInfo.data?.iban;
    const reference = userDepositInfo.data?.reference;

    return (
      <div>
        <div className="mb-7 fs-sm color-grayDarkest">
          <Trans i18nKey="useIBANAndReference">
            Please use the <span className="ff-semiBold">IBAN</span> and
            <span className="ff-semiBold">Reference code</span> below to deposit the required amount to your wallet.
          </Trans>
        </div>
        <Row gutter={24}>
          <Col md={24} xl={12}>
            <div className="mb-4 fs-xs">{i18n.t<string>('IBAN')}</div>
            <div className="deposit-info-field">
              {IBAN ? (
                <>
                  <span>{IBAN}</span>
                  <CopyToClipboard text={IBAN} onCopy={() => this.showOrHideTooltip('IBANTooltipVisible')}>
                    <Tooltip title={i18n.t<string>('IBANCopied')} visible={this.state.IBANTooltipVisible}>
                      <img src={CopyIcon} alt="Copy Icon" className="pointer" />
                    </Tooltip>
                  </CopyToClipboard>
                </>
              ) : (
                <Skeleton active paragraph={{ rows: 1 }} title={false} />
              )}
            </div>
          </Col>
          <Col md={24} xl={12}>
            <div className="mb-4 fs-xs">{i18n.t<string>('referenceCode')}</div>
            <div className="deposit-info-field">
              {reference ? (
                <>
                  <span>{reference}</span>
                  <CopyToClipboard text={reference} onCopy={() => this.showOrHideTooltip('RCTooltipVisible')}>
                    <Tooltip title={i18n.t<string>('referenceCodeCopied')} visible={this.state.RCTooltipVisible}>
                      <img src={CopyIcon} alt="Copy Icon" className="pointer" />
                    </Tooltip>
                  </CopyToClipboard>
                </>
              ) : (
                <Skeleton active paragraph={{ rows: 1 }} title={false} />
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  showOrHideTooltip = (tooltip: string) => {
    this.setState({ [tooltip]: true });
    setTimeout(() => this.setState({ [tooltip]: false }), 1000);
  };
}

export const Deposit = communicationUser.injector(DepositComponent);
