import React from 'react';
import { Button, Col, Row } from 'antd';
import i18n from 'i18n';
import { useHistory } from 'react-router';
import { InfoBlock } from 'common/components/InfoSection/InfoBlock';
import { RiskOverview } from 'common/components/RiskOverview';
import { DataCardBase } from 'common/components/Card/DataCardBase';
import { EBorrowerRoutes } from 'common/models/routes.model';
import { getFormattedSum, getMarkupForSumWithAccountCurrency } from 'entities/LoanApp/LoanApp.helpers';
import { ILoanAppModel } from 'entities/LoanApp/LoanApp.models';
import { ApplicationConditions } from 'entities/LoanApp/components/ApplicationConditions';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';

interface IComponentProps {
  onSign?: () => void;
  docuSignLoading?: boolean;
  appModel: ILoanAppModel;
  isKYCSubmitted: boolean;
}

export const LoanInfoReadyForSign: React.FC<IComponentProps> = props => {
  const { appModel, onSign, docuSignLoading, isKYCSubmitted } = props;
  const { push } = useHistory();
  const {
    loanTotalCost,
    originationExpense,
    interestExpense,
    repaymentAmount,
    monthlyPayment,
    ownRiskClass,
    crifRiskClass,
    amount,
    duration,
    currency,
    purpose,
    purposeDescription,
    documentationFee
  } = appModel;

  return (
    <>
      <h3 className="application-info__header">
        {i18n.t<string>('loanInfoHereIsYour')}
        <br />
        <span className="ff-bold">{i18n.t<string>('loanInfoLoanConditions')}</span>
      </h3>
      <Row>
        <Col lg={6} md={24}>
          <InfoBlock
            title={i18n.t('loanProps.loanTotalCost')}
            infoValue={{ partOne: getFormattedSum(loanTotalCost), partTwo: <AccountCurrency /> }}
          />
          <InfoBlock
            title={i18n.t('loanProps.originationExpense')}
            infoValue={{ partOne: getFormattedSum(originationExpense), partTwo: <AccountCurrency /> }}
            valueSize="small"
          />
          <InfoBlock
            title={i18n.t('loanProps.interestExpense')}
            infoValue={{ partOne: getFormattedSum(interestExpense), partTwo: <AccountCurrency /> }}
            valueSize="small"
          />
        </Col>
        <Col lg={6} md={24}>
          <InfoBlock
            title={i18n.t('loanProps.repaymentAmount')}
            infoValue={{ partOne: getFormattedSum(repaymentAmount), partTwo: <AccountCurrency /> }}
          />
          <InfoBlock
            title={i18n.t('loanProps.monthlyPayment')}
            infoValue={{ partOne: getFormattedSum(monthlyPayment), partTwo: <AccountCurrency /> }}
            valueSize="small"
          >
            <Row className="info-block__value info-block__value--small">
              {getMarkupForSumWithAccountCurrency(monthlyPayment)} x{' '}
              <span className="info-block__value--bold">{duration}&nbsp;</span>
              <span>{i18n.t<string>('months')}</span>
            </Row>
          </InfoBlock>
          <InfoBlock
            title={i18n.t('loanProps.documentationFee')}
            infoValue={{ partOne: getFormattedSum(documentationFee), partTwo: <AccountCurrency /> }}
            valueSize="small"
          />
        </Col>
        <Col lg={6} md={24}>
          <InfoBlock
            title={i18n.t('loanInfoRiskOverview')}
            children={<RiskOverview ownRisk={ownRiskClass} crif={crifRiskClass} />}
          />
        </Col>
      </Row>
      {isKYCSubmitted ? (
        <Button className="form-submit-button" onClick={onSign} loading={docuSignLoading}>
          {i18n.t<string>('loanInfoStatusDocuSignButton')}
        </Button>
      ) : (
        <>
          <DataCardBase className="mt-5 mb-5" description={i18n.t<string>('loanInfoStatusAddKYCReason')} />

          <Button
            className="form-submit-button mb-4"
            onClick={() => {
              push(EBorrowerRoutes.KYC);
            }}
          >
            {i18n.t<string>('loanInfoStatusAddKYCButton')}
          </Button>
        </>
      )}
      <h3 className="application-info__header">
        {i18n.t<string>('loanInfoHereIsYour')}
        <br />
        <span className="ff-bold">{i18n.t<string>('loanInfoApplicationConditions')}</span>
      </h3>
      <ApplicationConditions
        amount={amount}
        duration={duration}
        purpose={purpose}
        purposeDescription={purposeDescription}
        editable
        currency={currency}
      />
    </>
  );
};
