import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataCardSmall } from 'common/components/Card/DataCardSmall';
import { ILoanAppCollectionData } from 'entities/LoanApp/LoanApp.models';
import { LoansCard } from 'entities/LoanApp/components/LoansCard';

interface IComponentProps {
  loans: ILoanAppCollectionData[];
}

export const BorrowerLoansDefaulted: React.FC<IComponentProps> = ({ loans }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="d-flex d-flex_justify_between">
        <DataCardSmall className="pb-6" header={t('borrowerLoans.subHeader.defaulted')} />
      </div>

      {loans?.map(loan => (
        <LoansCard loan={loan} key={loan.id} />
      ))}
    </div>
  );
};
