import React from 'react';
import Modal, { ModalProps } from 'antd/es/modal';
import CrossIcon from 'app/assets/icons/CrossIcon.svg';

interface IComponentProps {
  maxWidth?: string;
  children: React.ReactNode;
}

type AllProps = IComponentProps & ModalProps;

export const Popup: React.FC<AllProps> = props => {
  const { footer = [], className = '', children, maxWidth = '840px', ...rest } = props;
  return (
    <Modal
      className={`popup-modal ${className}`}
      width="100%"
      style={{ maxWidth: maxWidth }}
      footer={footer}
      {...rest}
      closeIcon={<img src={CrossIcon} alt="XCross Icon" />}
    >
      {children}
    </Modal>
  );
};
