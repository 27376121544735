import { COLLECTION_LIMIT_DEFAULT } from 'common/consts';
import { IFormattedPagination } from 'common/models/request.model';

export const getFormattedPagination = (page: number, pageSize: number): IFormattedPagination => {
  const newParams: { limit: number; offset: number } = { limit: COLLECTION_LIMIT_DEFAULT, offset: 0 };

  if (pageSize) {
    newParams.limit = pageSize;
  }

  if (page && pageSize) {
    newParams.offset = (page - 1) * pageSize;
  }

  return newParams;
};
