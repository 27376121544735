import React from 'react';

interface IComponentProps {
  children: React.ReactNode;
  side: string;
}

export const BackLayout: React.FC<IComponentProps> = props => {
  const { children, side } = props;
  return <div className={`back-layout back-layout--${side}`}>{children}</div>;
};
