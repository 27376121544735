import React from 'react';

interface IComponentProps {
  width?: string;
  height?: string;
  className?: string;
  children: React.ReactNode;
}

export const RiskClass: React.FC<IComponentProps> = props => {
  const { children, className = '', width, height } = props;
  // TODO this will be improved further
  return (
    <div className={`risk-class ${className}`} style={{ width: width, height: height }}>
      {children}
    </div>
  );
};
