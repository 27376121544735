import React from 'react';
import i18n from 'i18n';
import moment from 'moment';
import { EDateTimeFormats } from 'common/consts';
import { EUserTypes, EUserStatus, IUserModel } from 'entities/User/User.models';

const usersTableName = 'usersTable.name';
const profileFirstName = 'profile.firstName';
const usersTableRegistered = 'usersTable.registered';

const renderUsersTableName = (text: string, record: IUserModel) => (
  <span className="medium-dark-text fs-xs">
    {record.profile ? `${record.profile.firstName} ${record.profile.lastName}` : `${record.email}`}
  </span>
);

const renderUsersTableRegistered = (text: string) => (
  <span className="fs-xs">
    <span className="medium-dark-text">{moment(text).format(EDateTimeFormats.FullDayShortMonthFullYear)}</span>
    <span> at {moment(text).format(EDateTimeFormats.DefaultTime)}</span>
  </span>
);

const renderKYCColumn = (status: string) => {
  const newStatus = status === EUserStatus.Confirmed ? 'Yes' : 'No';
  const filteredStatus = status === EUserStatus.Blocked ? '-' : newStatus;

  return <span className="medium-dark-text">{filteredStatus}</span>;
};

const renderOnboardingColumn = (status: string) => {
  const newStatus = status === EUserStatus.Onboarding || status === EUserStatus.New ? 'No' : 'Yes';
  const filteredStatus = status === EUserStatus.Blocked ? '-' : newStatus;

  return <span className="medium-dark-text">{filteredStatus}</span>;
};

export const investorsColumns = [
  {
    title: i18n.t(usersTableName),
    dataIndex: profileFirstName,
    sorter: true,
    render: renderUsersTableName
  },
  {
    title: i18n.t('usersTable.onboarding'),
    dataIndex: 'status',
    sorter: true,
    key: 'onboarding',
    render: renderOnboardingColumn
  },
  {
    title: i18n.t('usersTable.kyc'),
    dataIndex: 'status',
    key: 'kyc',
    sorter: true,
    render: renderKYCColumn
  },
  {
    title: i18n.t('usersTable.account'),
    dataIndex: 'type',
    render: (text: string) => {
      let label;
      switch (text) {
        case EUserTypes.CompanyRepresentative:
          label = 'Company Representative';
          break;
        case EUserTypes.Individual:
          label = 'Individual';
          break;
        default:
          label = '- -';
          break;
      }
      return <span className="medium-dark-text fs-xs">{label}</span>;
    }
  },
  {
    title: i18n.t(usersTableRegistered),
    dataIndex: 'createdAt',
    sorter: true,
    render: renderUsersTableRegistered
  }
];

export const borrowerColumns = [
  {
    title: i18n.t(usersTableName),
    dataIndex: profileFirstName,
    sorter: true,
    render: renderUsersTableName
  },
  {
    title: i18n.t('usersTable.onboarding'),
    dataIndex: 'status',
    key: 'onboarding',
    sorter: true,
    render: renderOnboardingColumn
  },
  {
    title: i18n.t('usersTable.kyc'),
    dataIndex: 'status',
    key: 'kyc',
    sorter: true,
    render: renderKYCColumn
  },
  {
    title: i18n.t(usersTableRegistered),
    dataIndex: 'createdAt',
    sorter: true,
    render: renderUsersTableRegistered
  }
];

export const managersColumns = [
  {
    title: i18n.t(usersTableName),
    dataIndex: profileFirstName,
    sorter: true,
    render: renderUsersTableName
  },
  {
    title: i18n.t('usersTable.email'),
    dataIndex: 'email',
    sorter: true,
    render: (text: string) => <span className="medium-dark-text">{text}</span>
  },
  {
    title: i18n.t(usersTableRegistered),
    dataIndex: 'createdAt',
    sorter: true,
    render: renderUsersTableRegistered
  }
];
