import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { EDateTimeFormats } from 'common/consts';
import { AmountHelper } from 'common/helpers/amount.helper';
import { EBorrowerRoutes } from 'common/models/routes.model';
import { ILoanAppCollectionData, IPaymentItem } from 'entities/LoanApp/LoanApp.models';
import { LoansCardProgress } from 'entities/LoanApp/components/LoansCardProgress';
import { LoansCardDebt } from 'entities/LoanApp/components/LoansCardDebt';
import { getNextPaymentData } from 'entities/LoanApp/LoanApp.helpers';
import { LoansCardDebtError } from 'entities/LoanApp/components/LoansCardDebtError';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';
import { ELoanAppStatus } from 'entities/LoanApp/LoanApp.const';
import { paymentSchedulesTransport } from 'entities/PaymentSchedules/PaymentSchedules.transport';

interface IComponentProps {
  loan: ILoanAppCollectionData;
}

type AllProps = IComponentProps & ICurrencyConnectedProps;

const LoansCardComponent: React.FC<AllProps> = props => {
  const { loan, currencyModel } = props;
  const { data: currency } = currencyModel;
  const { updatedAt, repaymentAmount, status } = loan;
  const [paymentData, setPaymentData] = useState<IPaymentItem[]>();
  const isCompleted = status === ELoanAppStatus.Completed;
  const isDefaulted = status === ELoanAppStatus.Defaulted;

  useEffect(() => {
    paymentSchedulesTransport.getPaymentSchedule(loan.id).then(result => setPaymentData(result?.data));
  }, []);

  const payData = getNextPaymentData(paymentData);
  const paymentDay = payData ? moment(payData.date) : undefined;
  const differenceBetweenDates = paymentDay ? moment(new Date().toDateString()).diff(paymentDay, 'days') : undefined;
  const debtError = !isDefaulted && differenceBetweenDates && differenceBetweenDates > 0;
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <div className={`loan-progress-card ${debtError ? 'loan-progress-card_error' : ''}`}>
      <div className="d-flex d-flex_justify_between gap_lg">
        <div className="d-flex d-flex_wrap gap_lg d-flex_align_center">
          <div className="loan-progress-card__date">{moment(updatedAt).format(EDateTimeFormats.DefaultDateTime)}</div>
          <div className="loan-progress-card__sum">{AmountHelper.getCurrency(repaymentAmount, currency)}</div>
        </div>

        <div className="d-flex d-flex_wrap gap_lg d-flex_align_center d-flex_justify_end">
          {paymentData && !isDefaulted && <LoansCardDebt paymentData={paymentData} />}
          {isDefaulted && (
            <div className="ff-semiBold">
              <Trans i18nKey="borrowerLoans.applications.loanIsDefaulted">
                Loan is <span className="color-red">defaulted.</span>
              </Trans>
            </div>
          )}

          <Button
            className="form-secondary-button"
            onClick={() => {
              push(`${EBorrowerRoutes.Loans}/${loan.id}`);
            }}
          >
            {t('borrowerLoans.applications.btnDetails')}
          </Button>
        </div>
      </div>

      {!isCompleted && !isDefaulted && <LoansCardProgress loan={loan} paymentData={paymentData} debtError={debtError} />}
      {isCompleted && <p className="active-info-block__subtitle">{t<string>('borrowerLoans.active.done')}</p>}

      {!!debtError && paymentData && <LoansCardDebtError paymentData={paymentData} />}
    </div>
  );
};

export const LoansCard = communicationCurrency.injector(LoansCardComponent);
