import axios from 'axios';
import { IFileModel } from 'entities/LoanApp/LoanApp.models';

export const downloadFile = (file: Pick<IFileModel, 'id' | 'name'>) => {
  const { id, name } = file;

  axios({
    url: `/files/${id}`,
    method: 'GET',
    responseType: 'blob'
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
