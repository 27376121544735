import React from 'react';
import { Row, Col } from 'antd';
import i18n from 'i18n';
import { InfoBlock } from 'common/components/InfoSection/InfoBlock';
import { getFormattedSum } from 'entities/LoanApp/LoanApp.helpers';
import { LoanInfoEdit } from 'entities/LoanApp/components/LoanInfoEdit';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';

interface IComponentProps {
  amount: number;
  duration: number;
  purpose: string;
  purposeDescription: string | undefined;
  currency: string;
  editable?: boolean;
}

export const ApplicationConditions: React.FC<IComponentProps> = props => {
  const { amount, duration, purpose, purposeDescription, editable, currency } = props;
  return (
    <div>
      <Row>
        <Col lg={7} md={24}>
          <InfoBlock
            title={i18n.t('loanProps.disbursementAmount')}
            infoValue={{ partOne: getFormattedSum(amount), partTwo: currency || <AccountCurrency /> }}
          />
        </Col>
        <Col lg={5} md={24}>
          <InfoBlock title={i18n.t('loanProps.duration')} infoValue={{ partOne: duration, partTwo: i18n.t('months') }} />
        </Col>
        <Col lg={6} md={24}>
          <InfoBlock
            title={i18n.t('step2LoanPurpose')}
            infoValue={{
              partOne: undefined,
              partTwo: purposeDescription ? `${purpose} (${purposeDescription})` : purpose
            }}
          />
        </Col>
      </Row>
      {editable && <LoanInfoEdit />}
    </div>
  );
};
