import { EAdminRoutes, EBorrowerRoutes, ECommonRoutes, EInvestorRoutes, ESpecialistRoutes } from 'common/models/routes.model';

export interface IMenuItem {
  label: string;
  route: string;
  disabled?: boolean;
}

export const specialistMenu: IMenuItem[] = [
  { label: 'headerMenuSpecialist.loans', route: ESpecialistRoutes.Loans },
  { label: 'headerMenuSpecialist.investors', route: ESpecialistRoutes.Investors },
  { label: 'headerMenuSpecialist.clients', route: ESpecialistRoutes.Clients },
  { label: 'headerMenuSpecialist.transactions', route: ECommonRoutes.Transactions, disabled: true }
];

export const adminMenu: IMenuItem[] = [{ label: 'headerMenuSpecialist.managers', route: EAdminRoutes.Managers }];

export const investorMenu: IMenuItem[] = [
  { label: 'headerMenuInvestor.dashboard', route: EInvestorRoutes.Dashboard },
  { label: 'headerMenuInvestor.myInvestments', route: EInvestorRoutes.Investments },
  { label: 'headerMenuInvestor.loanMarketplace', route: EInvestorRoutes.Marketplace }
];

export const borrowerMenu: IMenuItem[] = [
  { label: 'headerMenuBorrower.performance', route: EBorrowerRoutes.Performance },
  { label: 'headerMenuBorrower.myLoans', route: EBorrowerRoutes.Loans }
];
