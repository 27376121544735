import React from 'react';
import { Col, Row } from 'antd';
import i18n from 'i18n';
import FilledStar from 'app/assets/icons/FilledStar.svg';
import EmptyStar from 'app/assets/icons/EmptyStar.svg';
import { ELoanListTabs } from 'entities/LoanApp/LoanApp.const';

interface IComponentProps {
  tab: ELoanListTabs;
  changeTab: (value: ELoanListTabs) => void;
}

type AllProps = IComponentProps;

export class MarketPlaceNavigation extends React.Component<AllProps> {
  render() {
    const { tab, changeTab } = this.props;

    return (
      <Row align="middle" gutter={10} className="marketplace__content-header">
        <Col>
          <Row
            align="middle"
            justify="center"
            className={`marketplace__nav-button ${tab === ELoanListTabs.Marketplace ? 'marketplace__nav-button--active' : ''}`}
            onClick={() => changeTab(ELoanListTabs.Marketplace)}
          >
            {i18n.t<string>('headerMenuInvestor.marketplace')}
          </Row>
        </Col>
        <Col>
          <Row
            align="middle"
            justify="center"
            className={`marketplace__nav-button ${tab === ELoanListTabs.Bookmarks ? 'marketplace__nav-button--active' : ''}`}
            onClick={() => changeTab(ELoanListTabs.Bookmarks)}
          >
            <img src={tab === ELoanListTabs.Bookmarks ? FilledStar : EmptyStar} alt="Empty star" className="mr-3" width={16} />
            <span>{i18n.t<string>('headerMenuInvestor.bookmarks')}</span>
          </Row>
        </Col>
      </Row>
    );
  }
}
