import React from 'react';

interface IComponentProps {
  children: any;
  title?: string;
}

export const FormCard: React.FC<IComponentProps> = props => {
  const { title, children } = props;
  return (
    <div className="form-card">
      {title && <p className="form-card__title">{title}</p>}
      {children}
    </div>
  );
};
