export const allDocsExts = { exts: 'image/jpeg,image/png,application/pdf', sign: 'JPEG, PNG, PDF' };
export const imagesExts = { exts: 'image/jpeg,image/png', sign: 'JPEG, PNG' };

export const numbers = {
  loan: { amountMin: 10, amountMax: 2500000, durationMin: 6, durationMax: 60 }
};

export enum ENumberFormats {
  Decimal = '0,0',
  Real = '0,0.00'
}

export enum EDateTimeFormats {
  DefaultFullDate = 'DD MMMM YYYY',
  DefaultDateTime = 'D MMM HH:mm',
  FullDayShortMonthFullYear = 'DD MMM YYYY',
  DateForRequests = 'YYYY-MM-DD',
  DefaultTime = 'HH:mm',
  DateWithSeparator = 'DD/MM/YYYY'
}

export const queriesForRequests = {
  filterStatusEqual: 'status||$eq||',
  filterSpecIdEqual: 'specialist||$eq||',
  filterSpecIdNull: 'specialist||$isnull',
  filterCompanyNameContains: 'company.name||$contL||',
  filterUserNameContains: 'profile.firstName||$contL||',
  filterUserRoleEqual: 'role||$eq||',
  filterDateOfUpdating: 'updatedAt||$between||',
  filterLoanAmountBetween: 'loanAmount||$between||',
  filterLoanAmountGreater: 'loanAmount||$gte||',
  filterLoanAmountLower: 'loanAmount||$lte||',
  filterDuration: 'duration||$between||',
  filterInterest: 'interestRate||$between||',
  filterOwnRisk: 'ownRiskClass||$between||',
  filterIndustryContains: 'company.industry||$contL||',
  filterInBookmarks: 'inBookmarks||eq||true',
  filterHasInvestedEqual: 'hasInvested||$eq||',
  joinCompany: 'company',
  joinCompanies: 'companies',
  joinCompaniesLoanApp: 'companies.loanApplications',
  kyc: ['kyc'],
  kycSteps: ['kyc.kycSteps'],
  kycStepsFile: ['kyc.kycSteps.file'],
  docuSignComplete: 'signing_complete',
  specialist: 'specialist',
  history: 'loanApplicationDiffs',
  orUserFirstNameContain: 'profile.firstName||$contL||',
  orUserLastNameContain: 'profile.lastName||$contL||',
  orUserEmailContain: 'email||$contL||'
};

export enum ECompanyCountries {
  CH = 'CH',
  UK = 'UK',
  Other = 'Other'
}

export const companyCountryOptions = [
  { key: ECompanyCountries.CH, item: 'Switzerland', currency: 'CHF' },
  { key: ECompanyCountries.UK, item: 'UK', currency: 'GBP' },
  { key: ECompanyCountries.Other, item: 'Other', currency: undefined }
];

export const defaultCurrency: string = 'CHF';

export const COLLECTION_LIMIT_DEFAULT = 10;
