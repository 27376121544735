import React, { useEffect } from 'react';
import i18n from 'i18n';
import { Button, Form, Input } from 'antd';
import { Redirect } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { FormFooter } from 'common/components/Form/FormFooter';
import { AuthWidget } from 'common/components/AuthWidget';
import { mapBEErrorsToFields } from 'common/helpers/errors.helper';
import { ECommonRoutes } from 'common/models/routes.model';
import { useQuery } from 'common/hooks/Routes.hooks';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';

type AllProps = IUsersConnectedProps;

const NewPasswordFormComponent: React.FC<AllProps> = props => {
  const query = useQuery();
  const code = query.get('code');
  const [form] = useForm();
  const { userPasswordRestore } = props;
  const { loading, errors } = userPasswordRestore;
  const dataErrors = errors?.data.errors;

  useEffect(() => {
    mapBEErrorsToFields(form, dataErrors);
  }, [dataErrors]);

  const updatePassword = (values: any) => {
    const { updateUserPasswordRestore } = props;

    updateUserPasswordRestore({ ...values, code });
  };

  if (!code) {
    return <Redirect to={ECommonRoutes.LogIn} />;
  }

  return (
    <AuthWidget title={i18n.t('form.createPassTitle')}>
      <Form form={form} onFinish={updatePassword} className="mt-7">
        <Form.Item
          label={i18n.t<string>('form.newPassword')}
          required={false}
          name="password"
          rules={[{ required: true, message: `${i18n.t('form.passwordError')}` }]}
        >
          <Input type="password" placeholder={`${i18n.t('form.newPassword')}`} disabled={loading} className="form-input" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="large" htmlType="submit" loading={loading} className="form-submit-button mt-4 mb-5">
            {i18n.t<string>('form.submit')}
          </Button>
        </Form.Item>
      </Form>
      <FormFooter />
    </AuthWidget>
  );
};

export const CreatePassword = communicationUser.injector(NewPasswordFormComponent);
