import axios from 'axios';
import { objectToQuery } from 'common/helpers/request.helper';
import { ICollectionRequestParams } from 'common/models/request.model';
import { ITransactionsCollection } from 'entities/Transactions/Transactions.models';

const basePath = '/transactions';

export const transactionsTransport = {
  getTransactionsCollection: ({ limit = 10, ...params }: ICollectionRequestParams): Promise<ITransactionsCollection> =>
    axios.get(`${basePath}${objectToQuery({ limit, ...params })}`).then(r => r.data)
};
