import React from 'react';
import { Col, Row } from 'antd';
import { FormCard } from 'common/components/Form/FormCard';
import { KYCStepper } from 'entities/KYCProvider/components/KYCStepper';

export const KYCForm: React.FC = () => {
  return (
    <div className="onboarding-stepper">
      <Row gutter={32}>
        <Col sm={{ span: 16, offset: 4 }} xs={24}>
          <FormCard>
            <KYCStepper />
          </FormCard>
        </Col>
      </Row>
    </div>
  );
};
