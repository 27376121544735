import React, { SyntheticEvent } from 'react';
import i18n from 'i18n';
import { RcFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { Form, Upload, FormInstance } from 'antd';
import { checkFileSize, getBase64 } from 'common/helpers/fileLoader.helper';
import { FILES_SIZE_LIMIT_MAX } from 'common/config';
import { allDocsExts } from 'common/consts';
import { UploadField } from 'entities/Wallet/components/UploadField';

interface IComponentState {
  fileName?: string;
  fileList?: RcFile[];
}

interface IComponentProps {
  form: FormInstance;
  setFileContent: (fileContent: string | ArrayBuffer | null, fileName: string) => void;
}

class UploadFileFormComponent extends React.Component<IComponentProps, IComponentState> {
  state = {
    fileName: ''
  };
  render() {
    const { fileName } = this.state;

    return (
      <Form.Item
        label={i18n.t<string>('withdrawalTab.documentUpload')}
        name="proofOfIban"
        rules={[
          {
            required: true,
            message: i18n.t<string>('pleaseUploadFile')
          },
          {
            validator: this.validateFile
          }
        ]}
      >
        <Upload
          type="drag"
          accept={allDocsExts.exts}
          listType="text"
          beforeUpload={this.beforeUpload}
          showUploadList={false}
          className={`custom-uploader ${fileName ? 'loaded' : ''}`}
        >
          <UploadField fileName={fileName} removeFile={this.removeFile} />
        </Upload>
      </Form.Item>
    );
  }

  validateFile = (rule: any, value: any) => {
    if (!value?.file) {
      return Promise.resolve();
    }
    if (!checkFileSize(value.file)) {
      this.setState({ fileName: '' });
      return Promise.reject(
        `${i18n.t('errors:onlyImagesAndPdf')} ${i18n.t('errors:sizeMustBeLessThan', { size: FILES_SIZE_LIMIT_MAX })}`
      );
    }

    return Promise.resolve();
  };

  removeFile = (event: SyntheticEvent) => {
    event.stopPropagation();
    const { form } = this.props;
    const { resetFields } = form;

    resetFields(['proofOfIban']);
    this.setState({ fileName: '' });
  };

  normFile = (e: UploadChangeParam) => {
    if (Array.isArray(e)) {
      return e;
    }

    if (e.fileList.length > 1) {
      e.fileList.shift();
    }

    return e && e.fileList;
  };

  beforeUpload = (file: RcFile) => {
    const { setFileContent } = this.props;

    if (checkFileSize(file)) {
      getBase64(file, fileContent => {
        setFileContent(fileContent, file.name);
      });

      this.setState({ fileName: file.name });
    }

    return false;
  };
}

export const UploadFileForm = UploadFileFormComponent;
