import React from 'react';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { getFormattedDate } from 'common/helpers/date.helper';
import WaitingPay from 'app/assets/icons/WaitingPay.svg';
import IsPaid from 'app/assets/icons/IsPaid.svg';
import CurrentPay from 'app/assets/icons/CurrentPay.svg';
import { IPaymentItem } from 'entities/LoanApp/LoanApp.models';
import { getMarkupForSumWithAccountCurrency } from 'entities/LoanApp/LoanApp.helpers';
import { EUserRoles } from 'entities/User/User.models';
import { EPaymentTypes } from 'entities/LoanApp/LoanApp.const';

interface IComponentProps {
  userRole?: string;
  currency?: string;
  scheduleData?: IPaymentItem[];
  isDefaultedLoan?: boolean;
}

type AllProps = IComponentProps;

class RepaymentsTableComponent extends React.Component<AllProps> {
  render() {
    const { userRole, currency } = this.props;
    const { scheduleData } = this.props;

    const columns: ColumnProps<IPaymentItem>[] = [
      {
        title: i18n.t<string>('repaymentSchedule.date'),
        dataIndex: 'date',
        render: (text: string, record: IPaymentItem) => (
          <span className={this.getClassName(record, userRole)}>{getFormattedDate(record.date)}</span>
        )
      },
      {
        title: i18n.t<string>('repaymentSchedule.principal'),
        dataIndex: 'principal',
        render: (text: string, record: IPaymentItem) => (
          <span className={this.getClassName(record, userRole)}>
            {getMarkupForSumWithAccountCurrency(record.principal, undefined, currency)}
          </span>
        )
      },
      {
        title: i18n.t<string>('repaymentSchedule.interest'),
        dataIndex: 'interest',
        render: (text: string, record: IPaymentItem) => (
          <span className={this.getClassName(record, userRole)}>
            {getMarkupForSumWithAccountCurrency(record.interest, undefined, currency)}
          </span>
        )
      },
      {
        title: i18n.t<string>('repaymentSchedule.repaymentFee'),
        dataIndex: 'managementFee',
        render: (text: string, record: IPaymentItem) => (
          <span className={this.getClassName(record, userRole)}>
            {getMarkupForSumWithAccountCurrency(record.managementFee, undefined, currency)}
          </span>
        )
      },
      {
        title: i18n.t<string>('repaymentSchedule.monthlyInstallments'),
        dataIndex: 'monthlyInstallments',
        render: (value: string | number) => getMarkupForSumWithAccountCurrency(value, undefined, currency),
        hidden: userRole !== EUserRoles.Investor
      },
      {
        title: i18n.t<string>('repaymentSchedule.total'),
        dataIndex: 'totalPayment',
        render: (text: string, record: IPaymentItem) => (
          <span className={this.getClassName(record, userRole)}>
            {getMarkupForSumWithAccountCurrency(record.totalPayment, undefined, currency)}
          </span>
        ),
        hidden: userRole !== EUserRoles.Borrower
      },
      {
        title: i18n.t<string>('repaymentSchedule.paid'),
        dataIndex: 'isPaid',
        render: (value: boolean, record: IPaymentItem) => this.getPaidIcon(value, record)
      }
    ].filter(column => !column.hidden);

    const sortedByDate = scheduleData
      ?.filter(item => item.type === EPaymentTypes.Debt)
      .sort((a: IPaymentItem, b: IPaymentItem) => new Date(a.date).getTime() - new Date(b.date).getTime());

    return (
      <Table
        rowKey="id"
        columns={columns}
        dataSource={sortedByDate}
        className="custom-table schedule"
        pagination={{ total: scheduleData?.length, size: 'small' }}
      />
    );
  }

  getPaidIcon = (value: boolean, record: IPaymentItem) => {
    const { userRole, isDefaultedLoan } = this.props;
    const paymentDay = moment(record.date);
    const differenceBetweenDates = moment(new Date().toDateString()).diff(paymentDay, 'days');

    if (differenceBetweenDates > 0) {
      return value ? (
        <img src={IsPaid} alt="Is Paid" />
      ) : isDefaultedLoan ? (
        <img src={CurrentPay} alt="Current Pay" />
      ) : (
        <span className="color-add-2">
          <Trans i18nKey="delayMessage" values={{ days: differenceBetweenDates }}>
            Delay <span className="ff-semiBold"></span> day(s)
          </Trans>
        </span>
      );
    }

    if (differenceBetweenDates === 0) {
      return value ? <img src={IsPaid} alt="Is Paid" /> : <img src={CurrentPay} alt="Current Pay" />;
    }

    if (this.isPaymentNextForBorrower(record, userRole)) {
      return <img src={CurrentPay} alt="Current Pay" />;
    }

    return <img src={WaitingPay} alt="Waiting Pay" />;
  };

  isPaymentNextForBorrower(record: IPaymentItem, userRole?: string) {
    const { scheduleData } = this.props;
    const nextPayment = scheduleData
      ?.filter(item => new Date(item.date).getTime() > new Date().getTime())
      .sort((a: IPaymentItem, b: IPaymentItem) => new Date(a.date).getTime() - new Date(b.date).getTime())[0];

    return userRole === EUserRoles.Borrower && record.date === nextPayment?.date;
  }

  getClassName = (record: IPaymentItem, userRole?: string) => {
    let className: string = '';

    if (this.isPaymentNextForBorrower(record, userRole)) {
      className = 'current-payment';
    }

    if (record.isPaid && userRole === EUserRoles.Borrower) {
      className = 'paid-payment';
    }

    return className;
  };
}

export const RepaymentsTable = RepaymentsTableComponent;
