import React, { useEffect, useState } from 'react';
import i18n from 'i18n';
import { RouteComponentProps } from 'react-router';
import { Col, Row } from 'antd';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EUserTypes, EUserRoles } from 'entities/User/User.models';
import { StepperForBorrower } from 'entities/Onboarding/components/StepperForBorrower';
import { StepperForInvestor } from 'entities/Onboarding/components/StepperForInvestor';
import { OnboardingProgressBar } from 'entities/Onboarding/components/OnboardingProgressBar';
import {
  progressBarForBorrower,
  progressBarForCorpInvestor,
  progressBarForIndInvestor
} from 'entities/Onboarding/OnboardingSteps.const';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { OnboardingStub } from 'entities/Onboarding/components/OnboardingStub';

type AllProps = IUsersConnectedProps & RouteComponentProps & IOnboardingConnectedProps;

const StepperLayoutComponent: React.FC<AllProps> = props => {
  const [investorType, setInvestorType] = useState<EUserTypes>(EUserTypes.Individual);
  const { userCurrent } = props;
  const { data: user } = userCurrent;
  const { id: userId } = user || {};

  useEffect(() => {
    const { getOnboardingModel } = props;

    userId && getOnboardingModel(userId);
  }, [userId]);

  const defineStepper = () => {
    const role = userCurrent.data?.role;

    if (role === EUserRoles.Borrower) {
      return <StepperForBorrower />;
    }
    if (role === EUserRoles.Investor) {
      return <StepperForInvestor onChangeInvestorType={value => setInvestorType(value)} investorType={investorType} />;
    }
    return null;
  };

  const getProgressBarData = () => {
    const role = userCurrent.data?.role;
    const type = userCurrent.data?.type;
    const investor = type || investorType;

    if (role === EUserRoles.Borrower) {
      return progressBarForBorrower;
    }

    if (role === EUserRoles.Investor && investor === EUserTypes.Individual) {
      return progressBarForIndInvestor;
    }

    if (role === EUserRoles.Investor && investor === EUserTypes.CompanyRepresentative) {
      return progressBarForCorpInvestor;
    }
    return [];
  };

  return (
    <>
      <div className="onboarding-stepper">
        <Row gutter={32}>
          <Col span={8}>
            <h2 className="onboarding-stepper__header">{i18n.t<string>('stepperHeaderTitle')}</h2>
            <OnboardingProgressBar steps={getProgressBarData()} />
          </Col>
          <Col span={16}>{defineStepper()}</Col>
        </Row>
      </div>

      <OnboardingStub />
    </>
  );
};

export const StepperLayout = communicationOnboarding.injector(communicationUser.injector(StepperLayoutComponent));
