import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { queriesForRequests } from 'common/consts';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { IUserCollection } from 'entities/User/User.models';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';

interface IComponentProps {
  signersCollection: IUserCollection | null;
  onSuccess: () => void;
}

type AllProps = IComponentProps & ILoanAppConnectedProps & IUsersConnectedProps & RouteComponentProps<{ id: string }>;

const SyncWithDocuSignComponent: React.FC<AllProps> = props => {
  const { signersCollection } = props;
  const { data: singers } = signersCollection || {};
  const [count, setCount] = useState(0);

  useEffect(() => {
    const { syncLoanAppDocuSign, onSuccess, userCurrent, match, location, history } = props;
    const { data: userData } = userCurrent;
    const investorId = userData?.id;

    const params = new URLSearchParams(location.search);
    const event = params.get('event');
    const { id } = match.params;

    let timerId: NodeJS.Timer;

    if (singers?.length && event === queriesForRequests.docuSignComplete) {
      const signedByMe = singers.some(item => item.id === investorId);

      // count > 2 - Update within 30 seconds
      if (signedByMe || count > 2) {
        history.push(location.pathname);
      } else {
        timerId = setInterval(() => {
          setCount(count + 1);
          syncLoanAppDocuSign({ loanId: id, onSuccess });
        }, 10000);
      }
    }

    return () => clearInterval(timerId);
  }, [singers]);

  return null;
};

export const SyncWithDocuSign = React.memo(
  communicationLoanApp.injector(communicationUser.injector(withRouter(SyncWithDocuSignComponent)))
);
