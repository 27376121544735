import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { Row } from 'antd';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { MIDFUNDER_EMAIL } from 'common/config';
import { DepositInfoProvidingForm } from 'entities/Wallet/components/DepositInfoProvidingForm';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { communicationPayments, IPaymentsConnectedProps } from 'entities/Wallet/Wallet.communication';
import { TransferForm } from 'entities/Wallet/components/TransferForm';
import { EIbanStatuses } from 'entities/Wallet/Wallet.const';

type AllProps = IPaymentsConnectedProps & IUsersConnectedProps & RouteComponentProps;

class WithdrawalTabComponent extends React.Component<AllProps> {
  componentDidMount() {
    const { getPaymentsIban, userCurrent } = this.props;
    const userId = userCurrent.data?.id;

    if (userId) {
      getPaymentsIban(userId);
    }
  }

  render() {
    const { paymentsIban } = this.props;
    const { data: iban, loading } = paymentsIban;
    const isEdit = new URL(window.location.href).searchParams.has('edit');

    if (loading) {
      return <LoadingSpinner />;
    }

    if (!iban || isEdit) {
      return (
        <DepositInfoProvidingForm
          edit={isEdit}
          header={<div className="mb-9 simple-text color-grayDarkest">{i18n.t<string>('withdrawalTab.title')}</div>}
        />
      );
    }

    switch (iban.status) {
      case EIbanStatuses.Verification:
        return (
          <Row justify="center">
            <div className="simple-text color-grayDarkest">{i18n.t<string>('IBANVerificationMessage')}</div>
          </Row>
        );
      case EIbanStatuses.Enabled:
        return <TransferForm />;
      case EIbanStatuses.Disabled:
      case EIbanStatuses.NA:
      default:
        return (
          <DepositInfoProvidingForm
            header={
              <div className="mb-9 simple-text color-grayDarkest">
                <div className="mb-7 item-title">{i18n.t<string>('smthWentWrong')}</div>
                <Trans i18nKey="smthWrongContactUs">
                  We were unable to verify your details. Check the accuracy of the entered information, or contact us via email
                  <a href={`mailto:${MIDFUNDER_EMAIL}`} target="_blank" rel="noopener noreferrer">
                    {MIDFUNDER_EMAIL}
                  </a>
                </Trans>
              </div>
            }
          />
        );
    }
  }
}

export const WithdrawalTab = communicationPayments.injector(communicationUser.injector(withRouter(WithdrawalTabComponent)));
