export enum EErrorStatus {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  NotAcceptable = 406,
  Conflict = 409,
  Validation = 422,
  InternalServerError = 500
}

export interface IValidationErrorData {
  [key: string]: string[];
}
