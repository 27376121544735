import React from 'react';
import { Menu } from 'antd';
import * as H from 'history';
import { useTranslation } from 'react-i18next';
import { IMenuItem } from 'common/components/Header/Header.const';

interface IComponentProps {
  items: IMenuItem[];
  path: string;
  history: H.History;
}

export const MenuWithItems: React.FC<IComponentProps> = props => {
  const { items, history, path } = props;
  const { t } = useTranslation();

  return (
    <Menu
      mode="horizontal"
      selectedKeys={[path]}
      className="header-menu"
      items={items.map(({ label, route, disabled }) => ({
        key: route,
        label: t(label),
        onClick: () => history.push(route),
        disabled
      }))}
    />
  );
};
