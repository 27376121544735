import React from 'react';
import i18n from 'i18n';
import numeral from 'numeral';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Col, Row } from 'antd';
import { debounce } from 'lodash';
import { compose } from 'redux';
import { ColumnType } from 'antd/lib/table/interface';
import { RiskClass } from 'common/components/RiskClass';
import { COLLECTION_LIMIT_DEFAULT, ENumberFormats } from 'common/consts';
import { getValueFromUrl } from 'common/helpers/common.helper';
import FilledStar from 'app/assets/icons/FilledStar.svg';
import EmptyStar from 'app/assets/icons/EmptyStar.svg';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { communicationLoanAppBookmarks, ILoanAppBookmarksConnectedProps } from 'entities/Bookmarks/Bookmarks.communication';
import { IGetLoanAppCollectionParams, ILoanAppModel } from 'entities/LoanApp/LoanApp.models';
import { ELoanListTabs } from 'entities/LoanApp/LoanApp.const';
import { MarketPlaceNavigation } from 'entities/User/components/Investor/MarketPlaceNavigation';
import { MarketPlaceFilterSection } from 'entities/User/components/Investor/MarketPlaceFilterSection';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';
import { LoansTableRest } from 'entities/LoanApp/components/LoansTableRest';

type AllProps = ILoanAppConnectedProps & IUsersConnectedProps & ILoanAppBookmarksConnectedProps & RouteComponentProps;

interface IComponentState {
  filter: IGetLoanAppCollectionParams;
  tab: ELoanListTabs;
}

const defaultFilters: IGetLoanAppCollectionParams = {
  limit: COLLECTION_LIMIT_DEFAULT,
  offset: 0,
  status: 'lookingForInvestor',
  amountFrom: undefined,
  amountTo: undefined,
  durationFrom: undefined,
  durationTo: undefined,
  interestRateFrom: undefined,
  interestRateTo: undefined,
  ownRiskClassTo: undefined,
  ownRiskClassFrom: undefined,
  industry: undefined
};

class LoanMarketplaceComponent extends React.PureComponent<AllProps, IComponentState> {
  state: IComponentState = {
    filter: defaultFilters,
    tab: ELoanListTabs.Marketplace
  };

  loansTableColumnsMarketplace: ColumnType<ILoanAppModel>[] = [
    {
      title: i18n.t<string>('tableColumns.amount'),
      dataIndex: 'loanAmount',
      key: 'amount',
      sorter: true,
      render: text => (
        <span>
          <span className="table-value--bold">{numeral(text).format(ENumberFormats.Real)}</span> <AccountCurrency />
        </span>
      )
    },
    {
      title: i18n.t<string>('tableColumns.industry'),
      dataIndex: 'company.industry',
      render: text => <span className="table-value--bold">{i18n.t<string>(text)}</span>
    },
    {
      title: i18n.t<string>('tableColumns.score'),
      dataIndex: 'ownRiskClass',
      render: text => <RiskClass className={`risk-class--${text || '--'}`}>{text || '--'}</RiskClass>
    },
    {
      title: i18n.t<string>('tableColumns.interestRate'),
      dataIndex: 'investorInterestRate',
      key: 'interestRate',
      sorter: true,
      render: text =>
        text && (
          <span>
            <span className="table-value--bold">{text}</span> %
          </span>
        )
    },
    {
      title: i18n.t<string>('tableColumns.duration'),
      dataIndex: 'duration',
      sorter: true,
      render: text => (
        <span>
          <span className="table-value--bold">{text}</span> Months
        </span>
      )
    },
    {
      title: i18n.t<string>('tableColumns.timeLeft'),
      dataIndex: 'timeLeft',
      render: text => (
        <span>
          <span className="table-value--bold">{text || '--'}</span> Day(s)
        </span>
      )
    },
    {
      title: '',
      dataIndex: '',
      render: (record: ILoanAppModel) => (
        <img
          src={record.inBookmarks ? FilledStar : EmptyStar}
          alt="Bookmark mark"
          onClick={e => (record.inBookmarks ? this.deleteFromBookmarks(e, record.id) : this.addToBookmarks(e, record.id))}
          style={{ visibility: record.inBookmarks ? 'visible' : 'hidden' }}
        />
      )
    }
  ];

  private debouncedGetLoanAppCollection = debounce(this.props.getLoanAppCollection, 500);

  componentDidMount(): void {
    const tab = getValueFromUrl('tab') as ELoanListTabs;

    this.changeTab(tab);
  }

  render() {
    const { loanAppCollection, getLoanAppCollection } = this.props;
    const { tab, filter } = this.state;
    const loanList = loanAppCollection?.data;
    const { loading } = loanAppCollection;

    return (
      <Row>
        <Col span={7}>
          <MarketPlaceNavigation tab={tab} changeTab={this.changeTab} />
          <MarketPlaceFilterSection resetFilters={this.resetFilters} updateFilter={this.setFilter} />
        </Col>

        <Col span={17}>
          <LoansTableRest
            data={loanList}
            reloadCollection={params => {
              this.setState({ filter: params });
              getLoanAppCollection(params);
            }}
            loading={loading}
            filter={filter}
            tabKey={tab}
            columns={this.loansTableColumnsMarketplace}
            className="custom-table marketplace-table"
          />
        </Col>
      </Row>
    );
  }

  changeTab = (value: ELoanListTabs) => {
    const { history } = this.props;
    let filter = defaultFilters;
    let tab: ELoanListTabs;

    switch (value) {
      case ELoanListTabs.Marketplace:
        tab = value;
        break;
      case ELoanListTabs.Bookmarks:
        filter = { ...filter, inBookmarks: true };
        tab = value;
        break;
      default:
        tab = ELoanListTabs.Marketplace;
        break;
    }

    history.push({ search: `tab=${tab}` });

    this.setState({ tab, filter }, () => {
      this.debouncedGetLoanAppCollection(this.state.filter);
    });
  };

  setFilter = (newFilter: Partial<IGetLoanAppCollectionParams>) => {
    const { filter } = this.state;

    this.setState({ filter: { ...filter, ...newFilter } }, () => {
      this.debouncedGetLoanAppCollection(this.state.filter);
    });
  };

  resetFilters = () => {
    this.debouncedGetLoanAppCollection(defaultFilters);
    this.setState({ filter: defaultFilters });
  };

  addToBookmarks = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    const { addLoanAppBookmarksModel } = this.props;
    addLoanAppBookmarksModel(id);
  };

  deleteFromBookmarks = (e: React.MouseEvent<HTMLImageElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    const { deleteLoanAppBookmarksModel } = this.props;
    deleteLoanAppBookmarksModel(id);
  };
}

const containers = compose(
  communicationUser.injector,
  communicationLoanApp.injector,
  communicationLoanAppBookmarks.injector,
  withRouter
);

export const LoanMarketplace = containers(LoanMarketplaceComponent);
