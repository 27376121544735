import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import i18n from 'i18n';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import { EInvestorRoutes, ESpecialistRoutes } from 'common/models/routes.model';
import { ELoanListTabs } from 'entities/LoanApp/LoanApp.const';
import { getLocationData } from 'entities/LoanApp/LoanApp.helpers';

interface IComponentProps {
  entityId: string;
  entityName: string;
}

interface IState {
  from: string;
}

type AllProps = IComponentProps & RouteComponentProps<{}, {}, IState>;

class RestyledTopNavBarComponent extends React.Component<AllProps> {
  render() {
    const { entityId, entityName, history } = this.props;

    const routesData = [
      this.getFirstLocation(),
      {
        path: `${ESpecialistRoutes.Loans}/${entityId}`,
        breadcrumbName: entityName
      }
    ];

    if (history.location.pathname.includes(EInvestorRoutes.InvestPage)) {
      routesData.push({
        path: `${EInvestorRoutes.InvestPage}/${entityId}`,
        breadcrumbName: i18n.t('headerMenuInvestor.investmentDetails')
      });
    }

    return (
      <Breadcrumb
        itemRender={this.renderBreadcrumbs}
        routes={routesData}
        className="top-nav-bar"
        separator="•"
        params={history}
      />
    );
  }

  renderBreadcrumbs(route: Route, params: any, routes: Array<Route>) {
    const last = routes.indexOf(route) === routes.length - 1;

    const { pathname, search } = getLocationData(route.path);

    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={{ pathname, search, state: { ...params.location.state } }}>{route.breadcrumbName}</Link>
    );
  }

  getFirstLocation = () => {
    const { history } = this.props;

    let prevLocation: Route = {
      path: `${EInvestorRoutes.Marketplace}?tab=${ELoanListTabs.Marketplace}`,
      breadcrumbName: i18n.t('headerMenuInvestor.marketplace')
    };

    if (history.location.state) {
      const prevPath = history.location.state.from;

      if (prevPath === ELoanListTabs.Bookmarks) {
        prevLocation = {
          path: `${EInvestorRoutes.Marketplace}?tab=${ELoanListTabs.Bookmarks}`,
          breadcrumbName: i18n.t('headerMenuInvestor.bookmarks')
        };
      }
    }

    return prevLocation;
  };
}

export const RestyledTopNavBar = withRouter(RestyledTopNavBarComponent);
