import React from 'react';
import { Row } from 'antd';

interface IComponentProps {
  title: string;
  infoValue?: { partOne: string | number | undefined; partTwo: string | undefined | JSX.Element };
  valueSize?: 'big' | 'small' | 'middle';
  children?: React.ReactNode;
}

export const InfoBlock: React.FC<IComponentProps> = props => {
  const { title, infoValue, valueSize = 'big', children } = props;
  return (
    <div className="info-block">
      <h3 className="info-block__title">{title}</h3>
      {children || (
        <Row className={`info-block__value info-block__value--${valueSize}`}>
          {infoValue?.partOne && <span className="info-block__value--bold">{infoValue.partOne}&nbsp;</span>}
          {infoValue?.partTwo && <span>{infoValue.partTwo}</span>}
        </Row>
      )}
    </div>
  );
};
