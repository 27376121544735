import React from 'react';
import i18n from 'i18n';
import { Button, Col, Form, Input, InputNumber, Radio, Row, Select, FormInstance } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { mapBEErrorsToFields } from 'common/helpers/errors.helper';
import { EErrorStatus } from 'common/models/errors.model';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { ELoanAppStatus, EScores, ownRisks } from 'entities/LoanApp/LoanApp.const';
import { getScores } from 'entities/LoanApp/LoanApp.helpers';
import { LoanAdditionalInfoForm } from 'entities/LoanApp/components/LoanAdditionalInfoForm';
import { LenderField } from 'entities/LoanApp/components/LenderField';

type AllProps = ILoanAppConnectedProps;

interface IComponentState {
  radioValue: number;
  selectedRisk: string;
  required: boolean;
}

class LoanActionsFormComponent extends React.Component<AllProps, IComponentState> {
  state = {
    radioValue: 1,
    selectedRisk: '',
    required: true
  };

  formRef = React.createRef<FormInstance>();

  componentDidUpdate() {
    const { loanAppModel } = this.props;
    const { errors } = loanAppModel;
    const status = errors?.status;
    const data = errors?.data.errors;

    if (status === EErrorStatus.Validation) {
      mapBEErrorsToFields(this.formRef.current!, data);
    }
  }

  render() {
    const { radioValue, selectedRisk, required } = this.state;
    const { loanAppModel } = this.props;
    const { data: loan } = loanAppModel;

    return (
      <div className="loan-actions-form">
        <div className="mb-6 simple-text color-black">{i18n.t<string>('loanProps.infoReview')}</div>
        <Radio.Group onChange={this.onRadioChange} defaultValue={1} className="radio-section">
          <Radio value={1}>{i18n.t<string>('loanProps.appReadyToBeSigned')}</Radio>
          <Radio value={2}>{i18n.t<string>('loanProps.additionalInfoIsRequired')}</Radio>
        </Radio.Group>

        {radioValue === 1 && (
          <Form ref={this.formRef} layout="vertical" onFinish={this.onSubmit} onFinishFailed={this.onFinishFailed}>
            <div className="mb-8 simple-text color-black">{i18n.t<string>('loanProps.riskOverview')}</div>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={i18n.t<string>('loanProps.interestRate')}
                  name="interestRate"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: `${i18n.t('valueMustBeNumberGreater')} 0`
                    },
                    {
                      required,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <InputNumber formatter={value => `% ${value}`} placeholder={i18n.t('pleaseEnterValue')} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={i18n.t<string>('loanProps.originationFee')}
                  name="originationFee"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: `${i18n.t('valueMustBeNumberGreater')} 0`
                    },
                    {
                      required,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <InputNumber formatter={value => `% ${value}`} placeholder={i18n.t('pleaseEnterValue')} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={i18n.t<string>('loanProps.repaymentFee')}
                  name="repaymentFee"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: `${i18n.t('valueMustBeNumberGreater')} 0`
                    },
                    {
                      required,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <InputNumber formatter={value => `% ${value}`} placeholder={i18n.t('pleaseEnterValue')} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={i18n.t<string>('loanProps.documentationFee')}
                  name="documentationFee"
                  initialValue={loan?.documentationFee}
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: `${i18n.t('valueMustBeNumberGreater')} 0`
                    },
                    {
                      required,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <InputNumber
                    formatter={value => `${value} ${loan?.currency}`}
                    parser={value => value?.replace(`${loan?.currency}`, '') || ''}
                    placeholder={i18n.t('pleaseEnterValue')}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className="company-details tooltip">
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Row justify="start" className="mb-2">
                    <Col md={12} xs={12}>
                      {i18n.t<string>('loanProps.probabilityOfDefault')}:
                    </Col>
                    <span>{getScores(EScores.Probability, selectedRisk)}</span>
                  </Row>
                  <Row justify="start" className="mb-2">
                    <Col md={12} xs={12}>
                      {i18n.t<string>('loanProps.avgInterestRate')}:
                    </Col>
                    <span>{getScores(EScores.Rate, selectedRisk)}</span>
                  </Row>
                  <Row justify="start" className="mb-2">
                    <Col md={12} xs={12}>
                      {i18n.t<string>('loanProps.targetReturn')}:
                    </Col>
                    <span>{getScores(EScores.Target, selectedRisk)}</span>
                  </Row>
                </Col>
                <Col xs={24} md={12}>
                  <Row gutter={24}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={i18n.t<string>('loanProps.ownRiskClass')}
                        name="ownRiskClass"
                        rules={[
                          {
                            required,
                            message: i18n.t<string>('pleaseSelectOption')
                          }
                        ]}
                      >
                        <Select
                          placeholder={i18n.t<string>('selectPlaceholder')}
                          onChange={value => this.setState({ selectedRisk: value })}
                        >
                          {ownRisks.map(item => (
                            <Select.Option key={item.key} value={item.value}>
                              {item.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={i18n.t<string>('loanProps.crifRiskClass')}
                        name="crifRiskClass"
                        rules={[
                          {
                            required,
                            message: i18n.t<string>('pleaseEnterValue')
                          }
                        ]}
                      >
                        <Input placeholder={i18n.t('pleaseEnterValue')} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div className="mt-6 mb-8 simple-text color-black">{i18n.t<string>('loanProps.financialDetails')}</div>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={i18n.t<string>('loanProps.equityRatio')}
                  name="equityRatio"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: `${i18n.t<string>('valueMustBeNumberGreater')} 0`
                    },
                    {
                      required,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <InputNumber formatter={value => `% ${value}`} placeholder={i18n.t<string>('pleaseEnterValue')} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={i18n.t<string>('loanProps.returnOnInvestment')}
                  name="returnOnInvestment"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: `${i18n.t('valueMustBeNumberGreater')} 0`
                    },
                    {
                      required,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <InputNumber placeholder={i18n.t('pleaseEnterValue')} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={i18n.t<string>('loanProps.interestCoverageRatio')}
                  name="interestCoverageRatio"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: `${i18n.t('valueMustBeNumberGreater')} 0`
                    },
                    {
                      required,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <InputNumber placeholder={i18n.t('pleaseEnterValue')} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={i18n.t<string>('loanProps.operationEfficiency')}
                  name="operationEfficiency"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: `${i18n.t('valueMustBeNumberGreater')} 0`
                    },
                    {
                      required,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <InputNumber placeholder={i18n.t('pleaseEnterValue')} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={i18n.t<string>('loanProps.debtRatio')}
                  name="debtRatio"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: `${i18n.t('valueMustBeNumberGreater')} 0`
                    },
                    {
                      required,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <InputNumber formatter={value => `% ${value}`} placeholder={i18n.t('pleaseEnterValue')} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={i18n.t<string>('loanProps.revenueGrowth')}
                  name="revenueGrowth"
                  rules={[
                    {
                      type: 'number',
                      min: 0,
                      message: `${i18n.t('valueMustBeNumberGreater')} 0`
                    },
                    {
                      required,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <InputNumber formatter={value => `% ${value}`} placeholder={i18n.t('pleaseEnterValue')} />
                </Form.Item>
              </Col>
            </Row>

            <div className="mt-6 mb-8 simple-text color-black">{i18n.t<string>('loanProps.lenderData')}</div>
            <Row>
              <Col xs={24}>
                <LenderField required={required} currency={loan?.currency} />
              </Col>
            </Row>

            <Form.Item className="m-0">
              <Row justify="space-around" gutter={[16, 16]}>
                <Button className="submit-button" type="primary" htmlType="submit" disabled={!required}>
                  {i18n.t<string>('requestForSigning')}
                </Button>

                <Button className="submit-button" type="primary" danger onClick={() => this.setState({ required: false })}>
                  {i18n.t<string>('loanProps.declineApplication')}
                </Button>
              </Row>
            </Form.Item>

            {!required && (
              <>
                <Form.Item
                  name="comment"
                  className="m-0 mt-12"
                  rules={[
                    {
                      required: true,
                      message: i18n.t<string>('pleaseEnterValue')
                    }
                  ]}
                >
                  <Row>
                    <Input.TextArea
                      className="loan-actions-form__comment"
                      placeholder={i18n.t<string>('loanProps.commentPlaceholder')}
                      rows={5}
                    />
                  </Row>
                </Form.Item>

                <Form.Item className="m-0 mt-8">
                  <Row justify="space-around" gutter={[16, 16]}>
                    <Button className="submit-button" type="primary" danger onClick={this.onDecline}>
                      {i18n.t<string>('loanProps.decline')}
                    </Button>

                    <Button className="submit-button" onClick={() => this.setState({ required: true })}>
                      {i18n.t<string>('cancel')}
                    </Button>
                  </Row>
                </Form.Item>
              </>
            )}
          </Form>
        )}
        {radioValue === 2 && loan && <LoanAdditionalInfoForm loanId={loan.id} />}
      </div>
    );
  }

  onRadioChange = (e: RadioChangeEvent) => {
    this.setState({ radioValue: e.target.value });
  };

  onSubmit = () => {
    const { updateLoanAppModel, loanAppModel } = this.props;
    const { validateFields } = this.formRef.current!;

    validateFields().then(values => {
      if (loanAppModel.data) {
        const status = ELoanAppStatus.ReadyForSign;
        const { amount, duration, purpose, specialistId, id } = loanAppModel.data;
        const data = { id, amount, duration, purpose, specialistId, status, ...values };

        updateLoanAppModel(data);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  };

  onDecline = () => {
    const { loanAppModel, declineLoanAppModel } = this.props;
    const { data: loanAppData } = loanAppModel;
    const { validateFields } = this.formRef.current!;

    validateFields(['comment']).then(values => {
      if (loanAppData) {
        const { comment } = values;
        const { id } = loanAppData;

        declineLoanAppModel({ id, comment });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  };

  onFinishFailed = ({ errorFields }: any) => {
    this.formRef.current!.scrollToField(errorFields[0].name);
  };
}

export const LoanActionsForm = communicationLoanApp.injector(LoanActionsFormComponent);
