import React from 'react';
import { Button } from 'antd';
import i18n from 'i18n';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EUserRoles } from 'entities/User/User.models';

export interface IComponentProps {
  incompletedKYC?: boolean;
  secondaryBtnMethod: () => void;
  formUpdated: boolean;
}

type AllProps = IUsersConnectedProps & IComponentProps;

const KYCStepFooterButtonsComponent = (props: AllProps) => {
  const { incompletedKYC, secondaryBtnMethod, userCurrent, formUpdated } = props;
  const { data: userCurrentData, loading: userCurrentLoading } = userCurrent;
  const role = userCurrentData?.role;

  if (userCurrentLoading) {
    return null;
  }

  return (
    <div>
      <Button type="primary" htmlType="submit" className="form-submit-button" disabled={!incompletedKYC && !formUpdated}>
        {incompletedKYC ? i18n.t<string>('KYCStepper.submitButton') : i18n.t<string>('KYCStepper.finishButton')}
      </Button>

      {role === EUserRoles.Borrower && (
        <Button type="default" className="form-secondary-button ml-4" onClick={secondaryBtnMethod}>
          {incompletedKYC ? i18n.t<string>('KYCStepper.saveAndCompleteButton') : i18n.t<string>('KYCStepper.goBackButton')}
        </Button>
      )}
    </div>
  );
};

export const KYCStepFooterButtons = communicationUser.injector(KYCStepFooterButtonsComponent);
