import axios from 'axios';
import {
  ILoanDocRequest,
  ILoanDocRequestAddParams,
  ILoanDocRequestUpdateParams
} from 'entities/LoanDocuments/LoanDocuments.models';

const basePath = '/loan-application-documents-requests';

export const loanDocumentsTransport = {
  getDocumentRequest: (id: string): Promise<ILoanDocRequest> => axios.get(`${basePath}/${id}`).then(r => r.data),
  updateDocumentRequest: (params: ILoanDocRequestUpdateParams): Promise<ILoanDocRequest> =>
    axios.put(`${basePath}/${params.requestId}`, params).then(r => r.data),
  addDocumentRequest: (params: ILoanDocRequestAddParams): Promise<ILoanDocRequest> =>
    axios.post(`${basePath}`, params).then(r => r.data)
};
