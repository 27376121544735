import React from 'react';

interface IComponentProps {
  header: string | JSX.Element;
  footer?: JSX.Element;
  className?: string;
  classNameBody?: string;
  children?: React.ReactNode;
}

export const Description: React.FC<IComponentProps> = props => {
  const { header, footer, className = '', classNameBody = '', children } = props;
  return (
    <div className={`${className} bordered-card mb-6`}>
      <div className="description__header">{header}</div>
      <div className={`description__body ${classNameBody}`}>{children}</div>
      {footer && <div className="description__footer">{footer}</div>}
    </div>
  );
};
