import React, { ErrorInfo } from 'react';
import i18n from 'i18n';
import { Button, Row, Typography } from 'antd';
import * as Sentry from '@sentry/browser';

interface IComponentProps {
  children: React.ReactNode;
}

interface IComponentState {
  hasError: boolean;
  eventId: string;
}

type AllProps = IComponentProps;

export class UIErrorHandler extends React.Component<AllProps, IComponentState> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: AllProps) {
    super(props);
    this.state = { hasError: false, eventId: '' };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (!error) {
      return;
    }

    Sentry.withScope(scope => {
      const eventId = Sentry.captureException(error);

      // @ts-ignore
      scope.setExtras(errorInfo);
      this.setState({ eventId });
    });
  }

  render() {
    const { hasError } = this.state;
    return !hasError ? (
      this.props.children
    ) : (
      <>
        <Row justify="center">
          <Typography.Title level={4} type="danger" className="error-message p-4 mt-6">
            {i18n.t<string>('errors:anyUiError')}
          </Typography.Title>
        </Row>

        <Row justify="center">
          <Button type="primary" onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>
            Report feedback
          </Button>
        </Row>
      </>
    );
  }
}
