import React from 'react';
import { Collapse, Form, FormInstance } from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { WarningOutlined } from '@ant-design/icons';
import i18n from 'i18n';
import { RouteComponentProps, withRouter } from 'react-router';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { ECommonRoutes } from 'common/models/routes.model';
import { ReactComponent as Arrow } from 'app/assets/icons/Collapse-arrow.svg';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EUserTypes, EUserRoles, EUserStatus } from 'entities/User/User.models';
import {
  EDocTypes,
  EKycStatus,
  EKycStatusLabel,
  EKycSteps,
  KYCConfigForBorrower,
  KYCConfigForFundRepresentativeInvestor
} from 'entities/KYCProvider/KYC.const';
import { communicationKyc, IKycConnectedProps } from 'entities/KYCProvider/KYCProvider.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { PersonalInformationStep } from 'entities/KYCProvider/components/PersonalInformationStep';
import CurrentIDStep from 'entities/KYCProvider/components/CurrentIDStep';
import { MultipleFileUploaderStep } from 'entities/KYCProvider/components/MultipleFileUploaderStep';
import ShareholdersStep from 'entities/KYCProvider/components/ShareholdersStep';
import { KYCStepFooterButtons } from 'entities/KYCProvider/components/KYCStepFooterButtons';
import { IKYCStepItem } from 'entities/KYCProvider/KYCProvider.models';
import { getKYCData } from 'entities/KYCProvider/helpers/KYCProvider.helper';
import { WaitingScreen } from 'entities/KYCProvider/components/WaitingScreen';
import { EOnboardingSteps } from 'entities/Onboarding/OnboardingSteps.const';
import { AdditionalDocumentsUploader } from 'entities/KYCProvider/components/AdditionalDocumentsUploader';

const { Panel } = Collapse;

interface IComponentState {
  required: boolean;
  docType: EDocTypes | null;
  formUpdated: boolean;
  skipLoading: boolean;
}

type AllProps = IUsersConnectedProps & IKycConnectedProps & IAuthConnectedProps & IOnboardingConnectedProps & RouteComponentProps;

class KYCStepperComponent extends React.Component<AllProps> {
  state: IComponentState = {
    required: true,
    docType: null,
    formUpdated: false,
    skipLoading: false
  };

  formRef = React.createRef<FormInstance>();

  componentDidMount() {
    const { getKycStatus, userCurrent } = this.props;
    const userId = userCurrent.data?.id;

    if (userId) {
      getKycStatus(userId);
    }

    window.addEventListener('beforeunload', this.onUnload);
  }

  componentDidUpdate(prevProps: Readonly<AllProps>) {
    const { getKycStatus, userCurrent } = this.props;
    const { userCurrent: prevUser } = prevProps;

    if (userCurrent.data?.id && userCurrent.data?.id !== prevUser.data?.id) {
      getKycStatus(userCurrent.data.id);
    }
  }

  componentWillUnmount() {
    this.removeBeforeunloadEventListener();
  }

  render() {
    const { kycStatus, kycSubmit, kycAssignData, userCurrent, location, history } = this.props;
    const { formUpdated, skipLoading } = this.state;
    const { data: kycData } = kycStatus;
    const { data: userData } = userCurrent;
    const { loading: kycSubmitLoading } = kycSubmit;
    const { loading: kycAssignLoading } = kycAssignData;

    if (!kycData || kycSubmitLoading || kycAssignLoading || !userData || skipLoading) {
      return <LoadingSpinner />;
    }

    if (userData.status === EUserStatus.Confirmed) {
      history.replace(ECommonRoutes.Main);
    }

    const { status } = kycData;
    const { role } = userData;
    const incompletedKYC = status === EKycStatus.PersonalInfoRequired || status === EKycStatus.Skipped;

    if (status === EKycStatus.WaitingForApproval) {
      return <WaitingScreen />;
    }

    if (
      role === EUserRoles.Investor &&
      location.pathname === ECommonRoutes.Onboarding &&
      (status === EKycStatus.AvailableForEditing || status === EKycStatus.Declined)
    ) {
      return <WaitingScreen />;
    }

    return (
      <Form
        ref={this.formRef}
        onFinish={this.onFinish}
        layout="vertical"
        hideRequiredMark={true}
        scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        }}
        onValuesChange={this.onValuesChange}
      >
        {this.getStepToShow()}

        <KYCStepFooterButtons
          incompletedKYC={incompletedKYC}
          secondaryBtnMethod={incompletedKYC ? this.onSave : this.backToAccount}
          formUpdated={formUpdated}
        />
      </Form>
    );
  }

  onUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };

  removeBeforeunloadEventListener = () => window.removeEventListener('beforeunload', this.onUnload);

  getIdDocsOptions = (stepType: EKycSteps) => {
    const { userCurrent, kycStatus } = this.props;
    const role = userCurrent.data?.role;
    const type = userCurrent.data?.type;

    switch (role) {
      case EUserRoles.Investor:
        if (type === EUserTypes.Individual) {
          const options = [EDocTypes.IdentityCard, EDocTypes.Passport, EDocTypes.DrivingLicence];
          const selectedType = kycStatus.data?.steps.idPrimary?.data?.fileType;

          if (selectedType && stepType === EKycSteps.IdSecondary) {
            return options.filter(item => item !== selectedType);
          }

          return options;
        }
        if (type === EUserTypes.CompanyRepresentative) {
          return [EDocTypes.IdentityCard, EDocTypes.Passport];
        }

        return [] as EDocTypes[];
      case EUserRoles.Borrower:
      default:
        return [EDocTypes.IdentityCard, EDocTypes.Passport];
    }
  };

  getStepToShow = () => {
    const { kycStatus } = this.props;
    const { data: kycData } = kycStatus;
    const config = this.getStepperConfig();
    const incompletedKYC = kycData?.status === EKycStatus.PersonalInfoRequired || kycData?.status === EKycStatus.Skipped;

    if (!config || !kycData) {
      return null;
    }

    return (
      <>
        {!incompletedKYC && (
          <div className="d-flex d-flex_justify_end">
            <p className="form-card__title">
              KYC Status is: <span className={`kyc-status-${kycData.status}`}>{EKycStatusLabel[kycData.status]}</span>
            </p>
          </div>
        )}

        {config.map((item, index) => {
          if (incompletedKYC) {
            return (
              <div className="kyc-step-item" key={index}>
                <p className="form-card__title">{item.stepTitle}</p>
                {item.stepText && <div className="kyc-step-header__text">{item.stepText}</div>}
                {item.content}
              </div>
            );
          } else {
            return (
              <Collapse
                key={index}
                accordion
                ghost
                className="kyc-collapse"
                defaultActiveKey={
                  item.status === EKycStatus.Declined || item.status === EKycStatus.Pending || item.comment || item.error
                    ? index
                    : undefined
                }
                expandIcon={({ isActive }) => <Arrow className={`mt-3 ${isActive && 'rotate'}`} />}
              >
                <Panel
                  className="kyc-collapse-panel"
                  key={index}
                  forceRender
                  header={
                    <>
                      <div>
                        <p className="kyc-collapse-panel__title">{item.stepTitle}</p>
                        {(item.error || item.comment) && (
                          <div className="kyc-collapse-panel__message">
                            <WarningOutlined />
                            {item.comment ? <p>{item.comment}</p> : <p>{item.error}</p>}
                          </div>
                        )}
                      </div>
                      {index !== 0 ? (
                        <p className={`kyc-collapse-panel__status-${item.status}`}>
                          {!item.status
                            ? i18n.t<string>('borrowerLoans.KYC.documentStatus.declined')
                            : i18n.t<string>(`borrowerLoans.KYC.documentStatus.${item.status}`)}
                        </p>
                      ) : (
                        <p className={`kyc-collapse-panel__status-${item.status}`}></p>
                      )}
                    </>
                  }
                >
                  {item.stepText && <div className="kyc-step-header__text">{item.stepText}</div>}
                  {item.content}
                </Panel>
              </Collapse>
            );
          }
        })}

        {!incompletedKYC && (
          <div className="kyc-step-item">
            <p className="form-card__title">{i18n.t<string>('KYCStepper.other.title')}</p>
            <div className="kyc-step-header__text">{i18n.t<string>('KYCStepper.other.text')}</div>

            <Form.Item name="additionalDocuments">
              <AdditionalDocumentsUploader />
            </Form.Item>
          </div>
        )}
      </>
    );
  };

  getStepContent = (stepType: EKycSteps) => {
    const { required, docType } = this.state;

    switch (stepType) {
      case EKycSteps.PersonalInformation:
        return <PersonalInformationStep form={this.formRef} />;
      case EKycSteps.UBO:
        return <MultipleFileUploaderStep step={EKycSteps.LivenessCheck} fieldName={EDocTypes.UBO} required={required} />;
      case EKycSteps.Shareholders:
        return <ShareholdersStep form={this.formRef} docType={docType} required={required} />;
      case EKycSteps.IdPrimary:
      case EKycSteps.IdSecondary:
        return (
          <CurrentIDStep
            form={this.formRef}
            options={this.getIdDocsOptions(EKycSteps.IdPrimary)}
            setDocType={(docType: EDocTypes) => this.setState({ docType })}
            required={required}
          />
        );
      case EKycSteps.KBIS:
        return <MultipleFileUploaderStep step={EKycSteps.KBIS} fieldName={EDocTypes.KBIS} required={required} />;
      case EKycSteps.ArticlesOfAssociation:
        return (
          <MultipleFileUploaderStep
            step={EKycSteps.ArticlesOfAssociation}
            fieldName={EDocTypes.ArticlesOfAssociation}
            required={required}
          />
        );
      case EKycSteps.FundManagementRegulations:
        return (
          <MultipleFileUploaderStep
            step={EKycSteps.FundManagementRegulations}
            fieldName={EDocTypes.FundManagementRegulations}
            required={required}
          />
        );
      default:
        return null;
    }
  };

  createStepperConfigItem = (item: IKYCStepItem) => {
    const { kycStatus } = this.props;
    const { data: kycData } = kycStatus;

    return {
      ...item,
      content: this.getStepContent(item.stepType),
      // @ts-ignore
      status: kycData?.steps[item.stepType]?.status,
      // @ts-ignore
      error: kycData?.steps[item.stepType]?.errors?.[0],
      // @ts-ignore
      comment: kycData?.steps[item.stepType]?.comment
    };
  };

  getStepperConfig = () => {
    const { userCurrent } = this.props;
    const role = userCurrent.data?.role;
    const type = userCurrent.data?.type;

    switch (role) {
      case EUserRoles.Investor:
        if (type === EUserTypes.Individual) {
          return [];
        }
        if (type === EUserTypes.CompanyRepresentative) {
          return KYCConfigForFundRepresentativeInvestor.map(this.createStepperConfigItem);
        }
        return;
      case EUserRoles.Borrower:
      default:
        return KYCConfigForBorrower.map(this.createStepperConfigItem);
    }
  };

  backToAccount = () => {
    window.location.replace(ECommonRoutes.Main);
  };

  onValuesChange = () => {
    this.setState({ formUpdated: true });
  };

  onSave = () => {
    const { userCurrent, addKycAssignData, skipOnboardingModel } = this.props;
    const { docType } = this.state;
    const { validateFields, getFieldsValue } = this.formRef.current!;
    const role = userCurrent.data?.role;

    validateFields([
      'firstName',
      'lastName',
      'phone',
      'birthday',
      'countryNationality',
      'address',
      'city',
      'country',
      'zipCode',
      'companyDescription'
    ])
      .then(() => {
        const values = getFieldsValue();

        const items = getKYCData(role as EUserRoles, docType as EDocTypes, values);

        addKycAssignData({
          items,
          onSuccess: () => {
            this.removeBeforeunloadEventListener();
            this.setState({ skipLoading: true });
            skipOnboardingModel({
              step: EOnboardingSteps.KYCStep,
              onSuccess: () => window.location.replace(ECommonRoutes.Main),
              onFail: () => this.setState({ skipLoading: false })
            });
          }
        });
      })
      .catch((error: ValidateErrorEntity) => {
        this.formRef.current!.scrollToField(error.errorFields[0].name, {
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      });
  };

  onFinish = () => {
    const { kycStatus, userCurrent, addKycAssignData, addKycSubmit } = this.props;
    const { docType } = this.state;
    const { validateFields } = this.formRef.current!;
    const role = userCurrent.data?.role;

    validateFields().then(values => {
      let items;

      if (kycStatus.data?.status !== EKycStatus.AvailableForEditing) {
        items = getKYCData(role as EUserRoles, docType as EDocTypes, values);
      } else {
        items = getKYCData(role as EUserRoles, docType as EDocTypes, values).filter(
          item =>
            // @ts-ignore
            kycStatus.data?.steps?.[item.step]?.status !== EKycStatus.Approved || item.step === EKycSteps.PersonalInformation
        );
      }

      addKycAssignData({
        items,
        onSuccess: () => {
          addKycSubmit({
            onSuccess: () => this.removeBeforeunloadEventListener()
          });
        }
      });
    });
  };
}

export const KYCStepper = communicationAuth.injector(
  communicationUser.injector(communicationOnboarding.injector(communicationKyc.injector(withRouter(KYCStepperComponent))))
);
