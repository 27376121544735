export interface ILanguageItem {
  short: string;
  key: string;
  value: string;
}

export const languages: ILanguageItem[] = [
  { short: 'en', key: 'En', value: 'English' },
  { short: 'de', key: 'De', value: 'Deutsch' },
  { short: 'fr', key: 'Fr', value: 'French' },
  { short: 'it', key: 'It', value: 'Italian' }
];
