import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import i18n from 'i18n';
import { Button } from 'antd';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';
import { PersonalDetails } from 'common/components/PersonalDetails';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { firstLetterUpperCase, getUserName } from 'common/helpers/common.helper';
import { TopNavInfoBar } from 'common/components/TopNavInfoBar';
import { EUserRoles } from 'entities/User/User.models';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { UserLoans } from 'entities/User/components/Specialist/UserLoans';
import { ZeflixInfo } from 'entities/User/components/Specialist/ZeflixInfo';

type AllProps = RouteComponentProps<{ id: string }> & IUsersConnectedProps & IOnboardingConnectedProps;

class UserPageComponent extends React.Component<AllProps> {
  componentDidMount(): void {
    const { getUserModel, match } = this.props;
    const id = match.params.id;

    getUserModel({
      id
    });
  }

  componentWillUnmount() {
    const { clearUserModel } = this.props;

    clearUserModel();
  }

  render() {
    const { userModel } = this.props;

    if (!userModel || !userModel.data) {
      return <LoadingSpinner />;
    }
    const { data: user, loading } = userModel;
    // @ts-ignore
    const { profile, role, id, email, currency, isBlocked } = user;
    const roleValue = role === EUserRoles.Borrower ? i18n.t('client') : firstLetterUpperCase(role);

    const block = () => {
      const { blockUserModel } = this.props;

      blockUserModel(id);
    };

    const unblock = () => {
      const { unblockUserModel } = this.props;

      unblockUserModel(id);
    };

    return (
      <LayoutBasic className="pt-10 pb-10 blue-layout">
        <TopNavInfoBar backButton keyText={roleValue} valueText={getUserName(user)} />
        <LayoutBasic>
          {profile && (
            <PersonalDetails
              profile={profile}
              email={email}
              footer={
                <div className="gap_lg d-flex d-flex_direction_column">
                  <div>
                    <ZeflixInfo userId={id} />
                  </div>

                  {role === EUserRoles.Borrower && (
                    <div>
                      {isBlocked ? (
                        <Button size="large" className="form-secondary-button" onClick={unblock} loading={loading}>
                          {i18n.t<string>('form.unblock')}
                        </Button>
                      ) : (
                        <Button size="large" className="form-danger-button" onClick={block} loading={loading}>
                          {i18n.t<string>('form.block')}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              }
              currency={currency}
            />
          )}
        </LayoutBasic>

        {role === EUserRoles.Borrower && <UserLoans userId={id} />}
      </LayoutBasic>
    );
  }
}

export const UserPage = communicationOnboarding.injector(communicationUser.injector(withRouter(UserPageComponent)));
