import React from 'react';
import { downloadFile } from 'common/helpers/file.helper';
import Attach from 'app/assets/icons/Attach.svg';
import { IFileModel } from 'entities/LoanApp/LoanApp.models';

interface IComponentProps {
  title: string;
  files: Pick<IFileModel, 'id' | 'name'>[];
  className?: string;
}

export const Attachment: React.FC<IComponentProps> = props => {
  const { className = 'attachment', title, files } = props;

  return (
    <div className={className}>
      <div className="attachment__title">{title}</div>
      {files.map(file => (
        <div className="attachment__file" key={file.id}>
          <img src={Attach} alt="Attached file" className="mr-3" />
          <div
            onClick={() => {
              downloadFile(file);
            }}
          >
            {file.name}
          </div>
        </div>
      ))}
    </div>
  );
};
