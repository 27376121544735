import React, { RefObject } from 'react';
import { Form, FormInstance } from 'antd';
import i18n from 'i18n';
import { EDocTypes, EKycSteps } from 'entities/KYCProvider/KYC.const';
import { IKYCStatus } from 'entities/KYCProvider/KYCProvider.models';
import { communicationKyc, IKycConnectedProps } from 'entities/KYCProvider/KYCProvider.communication';
import { IFileModel } from 'entities/LoanApp/LoanApp.models';
import ShareholdersForm from 'entities/KYCProvider/components/ShareholdersForm';
import { setDisable } from 'entities/KYCProvider/helpers/KYCProvider.helper';

interface IComponentProps {
  form: RefObject<FormInstance<any>>;
  docType: EDocTypes | null;
  required?: boolean;
}

type AllProps = IComponentProps & IKycConnectedProps;

class ShareholdersStep extends React.Component<AllProps> {
  render() {
    const { form, docType, required = true, kycStatus } = this.props;
    const { data: kycStatusData } = kycStatus;
    const initialValue = this.getInitialValue(kycStatusData);
    const disabled = setDisable(kycStatusData, EKycSteps.Shareholders);

    return (
      <Form.Item
        name="shareholders"
        rules={[{ required, message: i18n.t<string>('KYCStepper.shareholders.shareholdersRequired') }]}
        initialValue={initialValue}
      >
        <ShareholdersForm form={form} docType={docType} disabled={disabled} initialValue={initialValue} required={required} />
      </Form.Item>
    );
  }

  getInitialValue = (kycStatusData: IKYCStatus | null) => {
    const data = kycStatusData?.steps.shareholders?.data;

    if (data) {
      return Object.values(data).map((item: any) => ({
        firstName: item.firstName,
        lastName: item.lastName,
        document: {
          type: item.fileType,
          names: item.files.map((item: IFileModel) => item.name),
          fileIds: item.files.map((item: IFileModel) => item.id)
        }
      }));
    }

    return undefined;
  };
}

export default communicationKyc.injector(ShareholdersStep);
