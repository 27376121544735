import React from 'react';
import { Form, Input } from 'antd';
import i18n from 'i18n';

interface IComponentProps {
  label?: string;
  fieldName: string;
  placeholder?: string;
  initialValue?: number;
  disabled?: boolean;
  maxValue?: number;
  minValue?: number;
  onChange?: () => void;
}

type AllProps = IComponentProps;

class NumericInputComponent extends React.Component<AllProps> {
  render() {
    const { label, placeholder, disabled, fieldName, maxValue, minValue, initialValue, onChange } = this.props;

    return (
      <Form.Item
        label={label && i18n.t<string>(label)}
        name={fieldName}
        initialValue={initialValue}
        rules={[
          {
            required: true,
            message: i18n.t<string>('pleaseEnterValue')
          },
          {
            validator: (rule, value) => this.numericValidator(rule, value, minValue, maxValue)
          }
        ]}
      >
        <Input
          type="number"
          placeholder={placeholder}
          disabled={disabled}
          className="form-input form-input-number"
          onChange={onChange}
        />
      </Form.Item>
    );
  }

  numericValidator = (rule: any, value: any, minValue?: number, maxValue?: number) => {
    const numericValue = parseInt(value);

    if (minValue && numericValue < minValue) {
      return Promise.reject(`${i18n.t('valueMustBeGreater')} ${minValue}!`);
    }

    if (maxValue && numericValue > maxValue) {
      return Promise.reject(`${i18n.t('valueMustBeLess')} ${maxValue}!`);
    }

    return Promise.resolve();
  };
}

export const NumericInput = NumericInputComponent;
