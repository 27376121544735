import React from 'react';
import { Col, Row } from 'antd';

interface IComponentProps {
  title: string | JSX.Element;
  value?: string | number | JSX.Element;
  content?: string | number | JSX.Element;
  className?: string;
}

export const InfoSectionItem: React.FC<IComponentProps> = props => {
  const { title, value, className, content } = props;

  return (
    <div className={`info-section-item ${className}`}>
      <Row justify="space-between" align="middle" gutter={24}>
        {title && <Col className="info-section-item__title">{title}</Col>}
        {value && <Col className="info-section-item__value">{value}</Col>}
      </Row>

      {content && <div>{content}</div>}
    </div>
  );
};
