import React from 'react';

interface IComponentProps {
  title: string | JSX.Element;
  endValue?: string | number | JSX.Element;
  middleValue?: string | number | JSX.Element;
  className?: string;
}

export const DescriptionItem: React.FC<IComponentProps> = props => {
  const { title, endValue, middleValue, className = '' } = props;

  return (
    <div className={`description__item ${className}`}>
      <div className="description__item__label">{title}</div>
      {middleValue && <div className="description__item__value-middle">{middleValue}</div>}
      <div className={middleValue ? 'description__item__value-end' : 'description__item__value'}>{endValue}</div>
    </div>
  );
};
