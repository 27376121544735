import React from 'react';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useParams } from 'react-router';
import { DataCard } from 'common/components/Card/DataCard';
import { EBorrowerRoutes } from 'common/models/routes.model';
import { ELoanAppStatus } from 'entities/LoanApp/LoanApp.const';
import { AdditionalInfoRequest } from 'entities/LoanApp/components/AdditionalInfoRequest';
import { RepaymentInfo } from 'entities/LoanApp/components/RepaymentInfo';
import { IPaymentItem } from 'entities/LoanApp/LoanApp.models';
import { DefaultInfo } from 'entities/LoanApp/components/DefaultInfo';

interface IComponentProps {
  status: string;
  docuSignLoading: boolean;
  userRole?: string;
  scheduleData?: IPaymentItem[];
  comment?: string;
}

export const LoanStatus: React.FC<IComponentProps> = (props: IComponentProps) => {
  const { status, userRole, scheduleData, comment } = props;
  const { id } = useParams<{ id: string }>();

  let statusCard;

  switch (status) {
    case ELoanAppStatus.Verification:
      statusCard = (
        <DataCard
          mainClassName="borderless"
          header={i18n.t('loanInfoStatusVerification')}
          subheader={i18n.t('loanInfoStatusVerificationSubheader')}
        />
      );
      break;
    case ELoanAppStatus.AdditionalInfoRequired:
      statusCard = <AdditionalInfoRequest />;
      break;
    case ELoanAppStatus.ReadyForSign:
      statusCard = (
        <DataCard
          mainClassName="borderless"
          header={i18n.t('loanInfoStatusReadyForSign')}
          subheader={i18n.t('loanInfoStatusReadyForSignSubheader')}
          description={i18n.t('loanInfoStatusReadyForSignDescription')}
        />
      );
      break;
    case ELoanAppStatus.LookingForInvestor:
      statusCard = (
        <DataCard
          mainClassName="borderless"
          header={i18n.t('loanInfoStatusLookingForInvestor')}
          subheader={i18n.t('loanInfoStatusLookingForInvestorSubheader')}
        />
      );
      break;
    case ELoanAppStatus.Active:
      statusCard = (
        <DataCard
          mainClassName="borderless"
          header={i18n.t('loanInfoStatusActive')}
          subheader={<RepaymentInfo scheduleData={scheduleData} userRole={userRole} />}
          description={
            <Trans i18nKey="loanInfoStatusActiveDescription">
              The funds will be debited automatically. <br /> Please make sure there is enough funds on your account balance.
            </Trans>
          }
        />
      );
      break;
    case ELoanAppStatus.Completed:
      statusCard = (
        <DataCard
          mainClassName="borderless"
          header={i18n.t('loanInfoStatusActive')}
          subheader={<RepaymentInfo scheduleData={scheduleData} userRole={userRole} />}
        />
      );
      break;
    case ELoanAppStatus.Declined:
      statusCard = (
        <DataCard
          header={i18n.t('loanInfoStatusDeclined')}
          subheader={i18n.t('loanInfoStatusDeclinedSubheader')}
          description={<div className="application-info__comment">{comment}</div>}
        />
      );
      break;
    case ELoanAppStatus.Defaulted:
      statusCard = (
        <DataCard
          header={
            <Trans i18nKey="loanInfoStatusDefaulted">
              Your loan is <span className="color-red">defaulted.</span>
            </Trans>
          }
          subheader={<DefaultInfo />}
        />
      );
      break;
    default:
      break;
  }

  if (status === ELoanAppStatus.AdditionalInfoRequired) {
    return <Redirect to={EBorrowerRoutes.LoanAddDocs.replace(':id', id)} />;
  }

  return <div>{statusCard}</div>;
};
