import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { InfoSectionItem } from 'common/components/InfoSection/InfoSectionItem';
import { Popup } from 'common/components/Popup';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';
import { communicationCompanies, ICompaniesConnectedProps } from 'entities/Companies/Companies.communication';

interface IComponentProps {
  editable?: boolean;
  companyId: string;
  notes?: string;
  reloadCompany?: () => void;
}

type AllProps = IComponentProps & ICurrencyConnectedProps & ICompaniesConnectedProps;

const CompanyDetailsDescriptionNotesComponent: React.FC<AllProps> = props => {
  const { editable, notes, companiesNotes } = props;
  const { t } = useTranslation();
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const onFinish = async (values: any) => {
    const { addCompaniesNotes, companyId, reloadCompany } = props;

    await addCompaniesNotes({ ...values, companyId });
    reloadCompany && reloadCompany();
    setShowPopup(false);
  };

  return (
    <>
      <Popup
        visible={showPopup}
        onCancel={() => {
          setShowPopup(false);
        }}
        destroyOnClose
      >
        <Form onFinish={onFinish} hideRequiredMark={true} layout="vertical" initialValues={{ notes }}>
          <Form.Item name="notes">
            <Input.TextArea rows={5} maxLength={255} />
          </Form.Item>

          <div className="gap_md d-flex">
            <Button
              className="form-secondary-button"
              onClick={() => {
                setShowPopup(false);
              }}
              loading={companiesNotes.loading}
            >
              {t('form.cancel')}
            </Button>

            <Button type="primary" className="form-submit-button" htmlType="submit" loading={companiesNotes.loading}>
              {t('form.submit')}
            </Button>
          </div>
        </Form>
      </Popup>

      <InfoSectionItem
        title={i18n.t('companyDetails.notes')}
        content={
          editable ? (
            <div>
              <div className="mb-5">{notes}</div>

              <Button
                className="mb-6 form-secondary-button"
                onClick={() => {
                  setShowPopup(true);
                }}
              >
                {i18n.t<string>('companyDetails.btnEditNotes')}
              </Button>
            </div>
          ) : (
            <div>{notes || '-'}</div>
          )
        }
      />
    </>
  );
};

export const CompanyDetailsDescriptionNotes = communicationCompanies.injector(
  communicationCurrency.injector(CompanyDetailsDescriptionNotesComponent)
);
