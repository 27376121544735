import React, { PropsWithChildren } from 'react';
import { Collapse } from 'antd';
import Minus from 'app/assets/icons/MinusIcon.svg';
import Plus from 'app/assets/icons/PlusIcon.svg';

const { Panel } = Collapse;

interface IComponentProps {
  openedLabel: string;
  closedLabel: string;
}

interface IComponentState {
  open: boolean;
}

type AllProps = PropsWithChildren<IComponentProps>;

export class CustomCollapse extends React.Component<AllProps, IComponentState> {
  state = {
    open: false
  };

  render() {
    const { openedLabel, closedLabel } = this.props;
    const { open } = this.state;
    return (
      <Collapse
        className="custom-collapse"
        bordered={false}
        onChange={this.onChange}
        expandIcon={() => (open ? <img src={Minus} alt="close-info" /> : <img src={Plus} alt="open-info" />)}
      >
        <Panel
          header={
            open ? (
              <div className="application-info__icon-text">{openedLabel}</div>
            ) : (
              <div className="application-info__icon-text">{closedLabel}</div>
            )
          }
          key="1"
        >
          {this.props.children}
        </Panel>
      </Collapse>
    );
  }

  onChange = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };
}
