import axios, { AxiosResponse } from 'axios';
import { objectToQuery } from 'common/helpers/request.helper';
import { IModelRequestParams } from 'common/models/request.model';
import {
  IUserSignUpModel,
  IUserModel,
  IConfirmationCode,
  INewPasswordModel,
  IResendRequestModel,
  IRestorePasswordModel,
  IUpdateUserModel,
  IBalanceModel,
  INewManagerRequestModel,
  INewManagerResponseModel,
  IUserCollection
} from 'entities/User/User.models';

const userPath = '/users';
const basePath = '/auth';

export const userTransport = {
  addRegistration: (params: IUserSignUpModel): Promise<IUserModel> =>
    axios.post(`${basePath}/register`, params).then(r => r.data),
  confirmRegistration: (params: IConfirmationCode): Promise<AxiosResponse> => {
    const { token } = params;
    return axios.post(`${basePath}/confirm`, { token });
  },
  resendRegistration: (params: IResendRequestModel): Promise<AxiosResponse> =>
    axios.post(`${basePath}/send-confirm-link`, params),
  restorePassword: (params: IRestorePasswordModel): Promise<AxiosResponse> => axios.post('/restore-password', params),
  updatePassword: (params: INewPasswordModel): Promise<AxiosResponse> => axios.put(`/restore-password`, params),
  getUserCurrent: (id: string): Promise<IUserModel> => axios.get(`${userPath}/${id}`).then(r => r.data),
  getUserModel: ({ id, ...rest }: IModelRequestParams): Promise<IUserModel> =>
    axios.get(`${userPath}/${id}${objectToQuery({ ...rest })}`).then(r => r.data),
  getUserCollection: (params: string): Promise<IUserCollection> =>
    axios.get(`${userPath}${objectToQuery(params)}`).then(r => r.data),
  updateUserCurrent: ({ id, ...rest }: IUpdateUserModel): Promise<IUserModel> =>
    axios.put(`${userPath}/${id}`, { ...rest }).then(r => r.data),
  updateUserPassword: (
    id: string,
    data: { password: string; oldPassword: string },
    successHook: () => void,
    failHook: (err: any) => void
  ) => {
    return axios
      .put(`/users/${id}`, data)
      .then(successHook)
      .catch(failHook);
  },
  getBalance: (id: string): Promise<IBalanceModel> => axios.get(`${userPath}/${id}/balance`).then(r => r.data),
  getDepositInfo: (id: string): Promise<IBalanceModel> => axios.get(`${userPath}/${id}/details-for-deposit`).then(r => r.data),
  block: (userId: string): Promise<IUserModel> => axios.post(`${userPath}/block/${userId}`).then(r => r.data),
  unblock: (userId: string): Promise<IUserModel> => axios.post(`${userPath}/unblock/${userId}`).then(r => r.data),
  addManager: (params: INewManagerRequestModel): Promise<INewManagerResponseModel> =>
    axios.post(`${userPath}/specialist`, params).then(r => r.data)
};
