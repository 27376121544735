import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { Button, Checkbox, Form, Input, FormInstance } from 'antd';
import { getLandingDomainName } from 'common/helpers/domainName.helper';
import { ECommonRoutes } from 'common/models/routes.model';
import { AuthWidget } from 'common/components/AuthWidget';
import { mapBEErrorsToFields } from 'common/helpers/errors.helper';
import { EErrorStatus } from 'common/models/errors.model';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EUserRoles } from 'entities/User/User.models';

type AllProps = IUsersConnectedProps & RouteComponentProps;

interface IComponentState {
  isAccepted: boolean;
}

class SignUpComponent extends React.Component<AllProps, IComponentState> {
  constructor(props: AllProps) {
    super(props);
    this.state = { isAccepted: true };
  }

  formRef = React.createRef<FormInstance>();

  componentDidUpdate() {
    const { userRegistration } = this.props;
    const { errors } = userRegistration;
    const status = errors?.status;
    const data = errors?.data.errors;

    if (status === EErrorStatus.Validation) {
      mapBEErrorsToFields(this.formRef.current!, data);
    }
  }

  render() {
    const { userRegistration } = this.props;
    const { loading } = userRegistration;
    const { isAccepted } = this.state;
    const initialValues = {
      email: '',
      password: '',
      subscribed: true,
      acceptance: true
    };
    // TODO: Remove hard code
    const tempUrl = 'https://midfunder.com';

    // eslint-disable-next-line
    const url = getLandingDomainName();

    return (
      <AuthWidget title={i18n.t('signUpWidget.join')}>
        <Form ref={this.formRef} initialValues={initialValues} layout="vertical" onFinish={this.signUp}>
          <Form.Item
            name="email"
            label={i18n.t<string>('form.emailTitle')}
            required={false}
            rules={[
              {
                type: 'email',
                message: `${i18n.t('form.emailValidationError')}`
              },
              { required: true, message: `${i18n.t('form.emailError')}` }
            ]}
          >
            <Input type="e-mail" placeholder={`${i18n.t('form.emailTitle')}`} disabled={loading} className="form-input" />
          </Form.Item>
          <Form.Item
            label={i18n.t<string>('form.passwordTitle')}
            required={false}
            name="password"
            rules={[
              { required: true, message: `${i18n.t('form.passwordError')}` },
              { min: 8, message: i18n.t('form.errorPasswordMin') },
              {
                pattern: new RegExp(/[A-Z]/g),
                message: i18n.t('form.errorPasswordUppercase')
              },
              {
                pattern: new RegExp(/[a-z]/g),
                message: i18n.t('form.errorPasswordLowercase')
              },
              {
                pattern: new RegExp(/[0-9]/g),
                message: i18n.t('form.errorPasswordNumbers')
              },
              {
                pattern: new RegExp(/(?=.*?[#?!@$%^&*-])/g),
                message: i18n.t('form.errorSpecialChar')
              }
            ]}
          >
            <Input type="password" placeholder={`${i18n.t('form.passwordTitle')}`} disabled={loading} className="form-input" />
          </Form.Item>
          <Form.Item className="m-0" name="subscribed" valuePropName="checked">
            <Checkbox className="form-checkbox" disabled={loading}>
              {i18n.t<string>('signUpWidget.signUpFormCheckboxInterested')}
            </Checkbox>
          </Form.Item>
          <Form.Item className="m-0" name="acceptance" valuePropName="checked">
            <Checkbox className="form-checkbox" disabled={loading} onChange={() => this.setState({ isAccepted: !isAccepted })}>
              <Trans i18nKey="signUpWidget.signUpFormCheckboxAccept">
                I accept the
                <a href={`${tempUrl}${ECommonRoutes.TermsAndConditions}`} target="_blank" rel="noopener noreferrer">
                  {' Terms and Conditions '}
                </a>
                and
                <a href={`${tempUrl}${ECommonRoutes.PrivacyPolicy}`} target="_blank" rel="noopener noreferrer">
                  {' Privacy Policy '}
                </a>
              </Trans>
            </Checkbox>
          </Form.Item>
          <Form.Item className="m-0 mt-8">
            <Button size="large" htmlType="submit" className="form-submit-button" loading={loading} disabled={!isAccepted}>
              {i18n.t<string>('signUpWidget.join')}
            </Button>
          </Form.Item>
        </Form>
      </AuthWidget>
    );
  }

  signUp = () => {
    const { addUserRegistration } = this.props;
    const { validateFields } = this.formRef.current!;
    const role = this.defineRole() || EUserRoles.Borrower;

    validateFields().then(values => {
      const { email, password, subscribed } = values;

      addUserRegistration({
        role,
        email,
        password,
        subscribed,
        type: 'company_representative'
      });
    });
  };

  defineRole = () => {
    const { location, history } = this.props;
    const role = new URLSearchParams(location.search).get('role');

    switch (role) {
      case EUserRoles.Investor:
      case EUserRoles.Borrower:
        return role;
      default:
        history.push(`${ECommonRoutes.SignUp}?role=${EUserRoles.Borrower}`);
        return;
    }
  };
}

export const SignUp = communicationUser.injector(withRouter(SignUpComponent));
