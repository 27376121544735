import React from 'react';
import { Form, Input } from 'antd';
import i18n from 'i18n';

interface IComponentProps {
  label: string;
  name: string;
  requiredError: string;
  validator?: (rule: any, value: string) => Promise<void>;
  passwordCheck?: boolean;
}

export const PasswordInput: React.FC<IComponentProps> = props => {
  const { label, name, requiredError, validator, passwordCheck } = props;

  return (
    <Form.Item
      label={label}
      name={name}
      rules={[
        { required: true, message: requiredError },
        passwordCheck
          ? {
              min: 8,
              message: i18n.t<string>('form.passwordValidationError')
            }
          : {},
        {
          validator
        }
      ]}
    >
      <Input.Password />
    </Form.Item>
  );
};
