import React, { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { EDateTimeFormats } from 'common/consts';
import { AmountHelper } from 'common/helpers/amount.helper';
import { EBorrowerRoutes } from 'common/models/routes.model';
import { ILoanAppCollectionData } from 'entities/LoanApp/LoanApp.models';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';
import { ELoanAppStatus } from 'entities/LoanApp/LoanApp.const';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';

interface IComponentProps {
  loan: ILoanAppCollectionData;
}

type AllProps = IComponentProps & ICurrencyConnectedProps & ILoanAppConnectedProps;

const ApplicationCardComponent: React.FC<AllProps> = props => {
  const { loan } = props;
  const { createdAt, amount, status, isSigning, currency } = loan;
  const { t } = useTranslation();
  const { push } = useHistory();

  useEffect(() => {
    const { syncLoanAppDocuSign } = props;

    if (isSigning) {
      syncLoanAppDocuSign({ loanId: loan.id });
    }
  }, []);

  return (
    <div className="loan-progress-card">
      <div className="d-flex d-flex_justify_between gap_lg">
        <div className="d-flex gap_lg d-flex_align_center">
          <div className="loan-progress-card__date">{moment(createdAt).format(EDateTimeFormats.DefaultDateTime)}</div>
          <div className="loan-progress-card__sum">
            <span className="color-primary">{t('borrowerLoans.applications.disbursement')}</span>
            {AmountHelper.getCurrency(amount, currency)}
          </div>
        </div>

        <div className="d-flex d-flex_align_center d-flex_justify_end gap_lg">
          {!isSigning && (
            <div>{t('borrowerLoans.applications.status', { status: t<string>(`loanProps.${status}`, status) })}</div>
          )}
          {isSigning && <div>{t('borrowerLoans.applications.docuSignWait')}</div>}

          <Button
            className="form-secondary-button"
            disabled={isSigning}
            onClick={() => {
              push(`${EBorrowerRoutes.Loans}/${loan.id}`);
            }}
          >
            {t('borrowerLoans.applications.btnDetails')}
          </Button>
        </div>
      </div>

      {status === ELoanAppStatus.AdditionalInfoRequired && (
        <div className="loan-progress-card__warning">{t('borrowerLoans.applications.errorNeedFiles')}</div>
      )}

      {status === ELoanAppStatus.ReadyForSign && (
        <div className="loan-progress-card__warning">{t('borrowerLoans.applications.errorNeedSign')}</div>
      )}
    </div>
  );
};

export const ApplicationCard = communicationLoanApp.injector(communicationCurrency.injector(ApplicationCardComponent));
