import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import i18n from 'i18n';
import { Button, Card } from 'antd';
import { InfoSectionItem } from 'common/components/InfoSection/InfoSectionItem';
import { queriesForRequests } from 'common/consts';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { getMarkupForSumWithAccountCurrency } from 'entities/LoanApp/LoanApp.helpers';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { PaymentSection } from 'entities/User/components/Investor/PaymentSection';

interface IComponentState {
  docuSignLoading: boolean;
}

type AllProps = IUsersConnectedProps & ILoanAppConnectedProps & RouteComponentProps;

class SignAndInvestWidgetComponent extends React.Component<AllProps, IComponentState> {
  state = {
    docuSignLoading: false
  };

  render() {
    const { docuSignLoading } = this.state;
    const { loanAppModel, userBalance, userCurrent, loanAppSign, loanAppSigners, loanAppInvestors, location } = this.props;
    const { errors } = loanAppSign;
    const { data: loanApp } = loanAppModel;
    const { data: userData } = userCurrent;
    const { data: balanceData } = userBalance;

    const { data: signersCollection } = loanAppSigners;
    const { data: investorsCollection } = loanAppInvestors;
    const investorId = userData?.id;
    const params = new URLSearchParams(location.search);
    const event = params.get('event');

    if (!loanApp || !balanceData) {
      return null;
    }

    const { loanAmount, duration, yourProfit } = loanApp;
    const { balance } = balanceData;
    const signedByMe = signersCollection?.data?.some(item => item.id === investorId);
    const investedByMe = investorsCollection?.data?.some(item => item.id === investorId);

    const descriptionItems = [
      {
        title: i18n.t('youInvest'),
        value: loanAmount,
        formatter: (value?: number | string) => getMarkupForSumWithAccountCurrency(value)
      },
      {
        title: i18n.t('loanProps.duration'),
        value: duration,
        formatter: (value?: number | string) => (
          <>
            {value} <span className="duration-string">Months</span>
          </>
        )
      },
      {
        title: i18n.t('loanProps.yourProfit'),
        value: yourProfit,
        formatter: (value?: number | string) => getMarkupForSumWithAccountCurrency(value)
      }
    ];

    return (
      <Card className="shadow-card side-invest-widget" bordered={false}>
        {descriptionItems.map(({ title, value, formatter }, index) => (
          <InfoSectionItem key={index} title={title} value={formatter(value)} />
        ))}

        {signedByMe ? (
          <div>
            {investedByMe ? (
              <div className="info-item">{i18n.t<string>('youAlreadyInvest')}</div>
            ) : (
              <PaymentSection balance={balance} />
            )}
          </div>
        ) : (
          <Button
            className="form-submit-button"
            onClick={this.onSign}
            loading={event === queriesForRequests.docuSignComplete || (docuSignLoading && !errors)}
            disabled={docuSignLoading && !errors}
          >
            {i18n.t<string>('signTheContract')}
          </Button>
        )}
      </Card>
    );
  }

  onSign = (): void => {
    const { getLoanAppSign, loanAppModel } = this.props;
    const { data } = loanAppModel;

    if (data) {
      this.setState({ docuSignLoading: true });
      getLoanAppSign(data.id);
    }
  };
}

const containers = compose(communicationLoanApp.injector, communicationUser.injector);

export const SignAndInvestWidget = withRouter(containers(SignAndInvestWidgetComponent));
