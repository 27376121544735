import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from 'i18n';
import { ECommonRoutes } from 'common/models/routes.model';
import { ReactComponent as Coins } from 'app/assets/icons/Coins.svg';
import { ReactComponent as CheckCircle } from 'app/assets/icons/CheckCircle.svg';
import { EWalletTabs } from 'entities/Wallet/Wallet.const';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';
import { AmountCard } from 'entities/LoanApp/components/AmountCard';

interface IComponentProps {
  repay: boolean;
  balance?: number;
  confirm: () => void;
  cancel: () => void;
}

type AllProps = ILoanAppConnectedProps & IComponentProps;

const EarlyRepaymentModalComponent: React.FC<AllProps> = props => {
  const { repay, balance, confirm, cancel, loanAppRepaymentDetails } = props;
  const { data } = loanAppRepaymentDetails;
  const [confirmPayment, setConfirmPayment] = useState<boolean>(false);
  const [unableToPay, setUnableToPay] = useState<boolean>(false);
  const [difference, setDifference] = useState<number | null>(null);
  // TODO: Remove hard code
  const tempUrl = 'https://midfunder.com';

  const checkRepayment = () => {
    if (data && balance) {
      const difference = balance - data.earlyRepaymentTotal;

      if (difference > 0) {
        setConfirmPayment(true);
      } else {
        setDifference(Number(Math.abs(difference).toFixed(2)));
        setUnableToPay(true);
      }
    }
  };

  const handleCancel = () => {
    setConfirmPayment(false);
    setUnableToPay(false);
    setDifference(null);
    cancel();
  };

  return (
    <Modal
      className="early-repayment-modal"
      title={
        confirmPayment
          ? i18n.t<string>('loanInfoEarlyRepayment.paymentConfirmation')
          : i18n.t<string>('loanInfoEarlyRepayment.earlyRepayment')
      }
      visible={repay && !!data}
      onCancel={cancel}
      footer={false}
      width={789}
    >
      {!confirmPayment ? (
        <>
          <div className="early-repayment-modal__header">
            <AmountCard
              icon={<Coins />}
              title={i18n.t<string>('loanInfoEarlyRepayment.loanRepaymentAmount')}
              amount={data?.repaymentAmount}
            />

            <AmountCard
              icon={<CheckCircle />}
              title={i18n.t<string>('loanInfoEarlyRepayment.paidAmount')}
              amount={data?.paidTotal}
            />
          </div>

          <div className="early-repayment-modal__body">
            <div className="early-repayment-modal__body_amount">
              <span>{i18n.t<string>('loanInfoEarlyRepayment.earlyRepaymentAmount')}</span>
              <span>
                {`${data?.earlyRepaymentTotal}`} {''}
                <AccountCurrency /> {''}
                (inc. fees)
              </span>
            </div>

            <a href={`${tempUrl}${ECommonRoutes.TermsAndConditions}`} target="_blank" rel="noopener noreferrer">
              {i18n.t<string>('loanInfoEarlyRepayment.termsOfEarlyRepayment')}
            </a>

            {unableToPay && (
              <>
                <div className="early-repayment-modal__body_warning">
                  <span>{i18n.t<string>('loanInfoEarlyRepayment.yourNeedToAddToYourWallet')}</span>
                  <span>
                    {difference} {''}
                    <AccountCurrency />
                  </span>
                </div>

                <div className="fs-xs">
                  <Trans i18nKey="depositOptions">
                    You can make a deposit using a <Link to="#">credit card</Link> or make a wire bank transfer on the
                    <Link to={`${ECommonRoutes.Wallet}?tab=${EWalletTabs.Deposit}`}> wallet</Link> page
                  </Trans>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="early-repayment-modal__body">
          <p className="early-repayment-modal__body_message">{i18n.t<string>('loanInfoEarlyRepayment.message')}</p>
        </div>
      )}

      <div className="early-repayment-modal__footer">
        <Button className={unableToPay ? 'form-submit-button' : 'form-secondary-button'} onClick={handleCancel}>
          {i18n.t<string>('loanInfoEarlyRepayment.cancel')}
        </Button>
        {!confirmPayment ? (
          !unableToPay ? (
            <Button className="form-submit-button" onClick={checkRepayment}>
              {i18n.t<string>('loanInfoEarlyRepayment.pay')}
            </Button>
          ) : null
        ) : (
          <Button className="form-submit-button" onClick={confirm}>
            {i18n.t<string>('loanInfoEarlyRepayment.yes')}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export const EarlyRepaymentModal = communicationLoanApp.injector(EarlyRepaymentModalComponent);
