import React from 'react';
import { Row, Tabs } from 'antd';
import i18n from 'i18n';
import numeral from 'numeral';
import { RouteComponentProps, withRouter } from 'react-router';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';
import { ENumberFormats } from 'common/consts';
import { getValueFromUrl } from 'common/helpers/common.helper';
import WalletBigIcon from 'app/assets/icons/WalletBigIcon.svg';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EWalletTabs } from 'entities/Wallet/Wallet.const';
import { Transactions } from 'entities/Transactions/components/Transactions';
import { WithdrawalTab } from 'entities/Wallet/components/WithdrawalTab';
import { Deposit } from 'entities/Wallet/components/Deposit';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';
import { EUserStatus } from 'entities/User/User.models';

type AllProps = IUsersConnectedProps & RouteComponentProps & ICurrencyConnectedProps;

class WalletComponent extends React.Component<AllProps> {
  componentDidMount() {
    const { getUserDepositInfo, userCurrent } = this.props;
    const { data: userData } = userCurrent;

    if (userData?.id) {
      getUserDepositInfo(userData.id);
    }
  }

  render() {
    const { userBalance, currencyModel, userCurrent } = this.props;
    const { status } = userCurrent.data || {};
    const { data: currency } = currencyModel;
    const balance = userBalance.data?.balance;
    const disabled = status === EUserStatus.Unconfirmed;

    const activeTab = getValueFromUrl('tab') || EWalletTabs.Withdrawal;

    return (
      <LayoutBasic className="blue-layout pt-10 pb-10">
        <div className={`bordered-card pt-7 pb-8' ${disabled ? 'no-click o-6' : ''}`}>
          <Row justify="space-between" className="pr-7 pl-7">
            <div className="item-title color-grayDarkest">
              <img src={WalletBigIcon} alt="Wallet icon" className="mr-3" />
              {i18n.t<string>('myWallet')}
            </div>
            <div className="item-title color-grayDarkest">
              <span>{i18n.t<string>('balance')}: </span>
              {currency ? (
                <span className="color-blue">
                  {numeral(balance).format(ENumberFormats.Real)} {currency}
                </span>
              ) : (
                '-'
              )}
            </div>
          </Row>

          <Tabs activeKey={activeTab} className="custom-tabs deposit-tabs" onChange={this.onSwitchTab}>
            <Tabs.TabPane tab={i18n.t<string>('deposit')} key={EWalletTabs.Deposit} disabled={disabled}>
              <Deposit />
            </Tabs.TabPane>
            <Tabs.TabPane tab={i18n.t<string>('transfers')} key={EWalletTabs.Transfers} disabled={disabled}>
              <Transactions />
            </Tabs.TabPane>
            <Tabs.TabPane tab={i18n.t<string>('withdrawal')} key={EWalletTabs.Withdrawal} disabled={disabled}>
              <WithdrawalTab />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </LayoutBasic>
    );
  }

  onSwitchTab = (activeKey: string) => {
    const { history } = this.props;

    history.push({ search: `?tab=${activeKey}` });
  };
}

export const Wallet = communicationCurrency.injector(communicationUser.injector(withRouter(WalletComponent)));
