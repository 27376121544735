import React from 'react';
import i18n from 'i18n';
import { Col, Row } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router';
import { CompanyDetailsDescription } from 'common/components/CompanyDetailsDescription';
import { CustomCollapse } from 'common/components/CustomCollapse';
import Fifth from 'app/assets/images/ApplicationInfo/AppInfoFifth.svg';
import Fourth from 'app/assets/images/ApplicationInfo/AppInfoFourth.svg';
import Third from 'app/assets/images/ApplicationInfo/AppInfoThird.svg';
import Second from 'app/assets/images/ApplicationInfo/AppInfoSecond.svg';
import First from 'app/assets/images/ApplicationInfo/AppInfoFirst.svg';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { LoanStatus } from 'entities/LoanApp/components/LoanStatus';
import { LoanDetails } from 'entities/LoanApp/components/LoanDetails';
import { ELoanAppStatus } from 'entities/LoanApp/LoanApp.const';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { LoadingApplicationInfo } from 'entities/LoanApp/components/LoadingApplicationInfo';
import { AttachedDocs } from 'entities/LoanApp/components/AttachedDocs';
import { LoanInfoVerification } from 'entities/LoanApp/components/LoanInfoVerification';
import { LoanInfoReadyForSign } from 'entities/LoanApp/components/LoanInfoReadyForSign';
import { LoanInfoActive } from 'entities/LoanApp/components/LoanInfoActive';
import { LoanInfoLookingForInvestor } from 'entities/LoanApp/components/LoanInfoLookingForInvestor';
import { LoanInfoDefaulted } from 'entities/LoanApp/components/LoanInfoDefaulted';
import { AdditionalDocs } from 'entities/LoanDocuments/components/AdditionalDocs';
import { EUserStatus } from 'entities/User/User.models';

interface IComponentState {
  docuSignLoading: boolean;
}

interface IComponentProps {
  loanId: string;
}

type AllProps = IOnboardingConnectedProps & ILoanAppConnectedProps & RouteComponentProps & IUsersConnectedProps & IComponentProps;

class BorrowerLoanComponent extends React.Component<AllProps, IComponentState> {
  state = {
    docuSignLoading: false
  };

  componentDidMount(): void {
    const { getLoanAppModel, loanId, getOnboardingCompanyCollection, getLoanAppPaymentSchedule } = this.props;

    getLoanAppModel({
      id: loanId
    });
    getLoanAppPaymentSchedule(loanId);
    getOnboardingCompanyCollection();
  }

  render() {
    const { loanId, loanAppModel, loanAppSign, userCurrent, onboardingCompanyCollection, loanAppPaymentSchedule } = this.props;
    const { docuSignLoading } = this.state;
    const { data: user, loading: userLoading } = userCurrent;
    const { data: appModel, loading: loanAppLoading } = loanAppModel; //
    const { data: companies, loading: companyLoading } = onboardingCompanyCollection;
    const companyModel = companies?.data && companies.data[0];
    const { data: paymentScheduleData } = loanAppPaymentSchedule; //
    const { errors: docuSignError } = loanAppSign;
    const loading = loanAppLoading || companyLoading || userLoading;

    if (loading || !appModel || !companyModel || !user) {
      return <LoadingApplicationInfo />;
    }

    const userRole = userCurrent.data?.role;
    const { status, repaymentAmount, comment } = appModel;
    const files = user.kyc?.kycSteps;

    let appInfoPageBody: JSX.Element;

    switch (status) {
      case ELoanAppStatus.Verification:
        appInfoPageBody = <LoanInfoVerification appModel={appModel} />;
        break;
      case ELoanAppStatus.AdditionalInfoRequired:
        appInfoPageBody = <LoanDetails editable />;
        break;
      case ELoanAppStatus.ReadyForSign:
        appInfoPageBody = (
          <LoanInfoReadyForSign
            isKYCSubmitted={user.status !== EUserStatus.Unconfirmed}
            appModel={appModel}
            onSign={this.onSign}
            docuSignLoading={docuSignLoading}
          />
        );
        break;
      case ELoanAppStatus.Declined:
      case ELoanAppStatus.LookingForInvestor:
        appInfoPageBody = <div></div>;
        break;
      case ELoanAppStatus.Active:
      case ELoanAppStatus.Completed:
        appInfoPageBody = (
          <LoanInfoActive
            loanId={loanId}
            status={status}
            repaymentAmount={repaymentAmount}
            userRole={userRole}
            paymentScheduleData={paymentScheduleData}
          />
        );
        break;
      case ELoanAppStatus.Defaulted:
        appInfoPageBody = (
          <LoanInfoDefaulted
            loanId={loanId}
            repaymentAmount={repaymentAmount}
            userRole={userRole}
            paymentScheduleData={paymentScheduleData}
          />
        );
        break;
      default:
        appInfoPageBody = <LoanDetails />;
        break;
    }

    return (
      <div className="application-info">
        <Row gutter={32}>
          <Col xs={{ span: 24, order: 2 }} lg={{ span: 14, order: 1 }} xl={13}>
            <LoanStatus
              status={status}
              docuSignLoading={docuSignLoading && !docuSignError}
              userRole={userRole}
              scheduleData={paymentScheduleData?.data}
              comment={comment}
            />
          </Col>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 10, order: 2 }} xl={11} className="info-image-container">
            {this.getHeaderImg(status)}
          </Col>
          <Col xs={{ span: 24, order: 3 }} lg={20}>
            {appInfoPageBody}
          </Col>
          <Col xs={{ span: 24, order: 4 }} lg={20}>
            <CustomCollapse openedLabel={i18n.t('appInfoHide')} closedLabel={i18n.t('appInfoSeeMore')}>
              {status === ELoanAppStatus.LookingForInvestor && <LoanInfoLookingForInvestor appModel={appModel} />}
              <CompanyDetailsDescription
                company={companyModel}
                splitData
                reloadCompany={() => {
                  const { getOnboardingCompanyCollection } = this.props;
                  getOnboardingCompanyCollection();
                }}
              />
              {loanAppModel.data && <AdditionalDocs loanId={loanAppModel.data.id} />}
              {files && !!files.length && <AttachedDocs files={files} />}
            </CustomCollapse>
          </Col>
        </Row>
      </div>
    );
  }

  getHeaderImg = (status: string) => {
    switch (status) {
      case ELoanAppStatus.Verification:
        return <img className="application-info__image" src={First} alt="First step" />;
      case ELoanAppStatus.AdditionalInfoRequired:
        return <img className="application-info__image" src={Second} alt="Second step" />;
      case ELoanAppStatus.ReadyForSign:
        return <img className="application-info__image" src={Third} alt="Third step" />;
      case ELoanAppStatus.LookingForInvestor:
      case ELoanAppStatus.Declined:
        return <img className="application-info__image" src={Fourth} alt="Fourth step" />;
      case ELoanAppStatus.Active:
      case ELoanAppStatus.Completed:
      case ELoanAppStatus.Defaulted:
        return <img className="application-info__image" src={Fifth} alt="Fifth step" style={{ maxWidth: '401px' }} />;
      default:
        return <div></div>;
    }
  };

  onSign = () => {
    const { addLoanAppSign, loanAppModel } = this.props;
    if (loanAppModel.data) {
      const { id } = loanAppModel.data;

      this.setState({ docuSignLoading: true });
      addLoanAppSign(id);
    }
  };
}

export const BorrowerLoan = communicationUser.injector(
  communicationOnboarding.injector(communicationLoanApp.injector(withRouter(BorrowerLoanComponent)))
);
