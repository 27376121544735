import React from 'react';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

type AllProps = ICurrencyConnectedProps;

const AccountCurrencyComponent: React.FC<AllProps> = props => {
  const { currencyModel } = props;
  const { data: currency } = currencyModel;

  if (!currency) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{currency}</>;
};

export const AccountCurrency = communicationCurrency.injector(AccountCurrencyComponent);
