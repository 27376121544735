import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import i18n from 'i18n';
import ArrowDown from 'app/assets/icons/ArrowDown.svg';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { IIndustryCollection, IIndustryModel } from 'entities/Onboarding//OnboardingSteps.models';

interface IComponentProps {
  disabled: boolean;
  onChange?: (value: string) => void;
}

type AllProps = IOnboardingConnectedProps & IComponentProps;

const IndustryFieldComponent: React.FC<AllProps> = props => {
  const { onboardingIndustryCollection, getOnboardingIndustryCollection, disabled, onChange } = props;
  const { data } = onboardingIndustryCollection;
  const [offset, setOffset] = useState<number>(1);
  const [options, setOptions] = useState<IIndustryModel[]>([]);
  const otherIndustryName = 'Other';

  useEffect(() => {
    getOnboardingIndustryCollection({}).then((response: IIndustryCollection) => {
      setOptions(response.data);
    });
  }, []);

  const onPopupScroll = (e: any) => {
    const { target } = e;

    if (data && options.length < data.total && Number(target.scrollTop) + Number(target.offsetHeight) === target.scrollHeight) {
      getOnboardingIndustryCollection({ offset: offset * 20 }).then((response: IIndustryCollection) => {
        setOffset(offset + 1);
        setOptions([...options, ...response.data]);
      });
    }
  };

  const sortOptions = (options: IIndustryModel[]) => {
    const otherIndustry = options.find(industry => industry.name === otherIndustryName);
    const sortedIndustries = options
      .filter(industry => industry.name !== otherIndustryName)
      .sort((prevIndustry, nextIndustry) => (prevIndustry.name > nextIndustry.name ? 1 : -1));
    return otherIndustry ? [...sortedIndustries, otherIndustry] : sortedIndustries;
  };

  return (
    <Form.Item
      label={i18n.t<string>('step1Industry')}
      name="industryId"
      rules={[
        {
          required: true,
          message: i18n.t<string>('pleaseSelectOption')
        }
      ]}
    >
      <Select
        className="form-select"
        placeholder={i18n.t<string>('selectPlaceholder')}
        suffixIcon={<img src={ArrowDown} alt="Arrow down" />}
        disabled={disabled}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
        onChange={(_, option: any) => onChange && onChange(option.children)}
        onPopupScroll={onPopupScroll}
      >
        {sortOptions(options).map((item: IIndustryModel) => (
          <Select.Option value={item.id} key={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export const IndustryField = communicationOnboarding.injector(IndustryFieldComponent);
