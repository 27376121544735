import React from 'react';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

interface IComponentProps {
  penaltyAmount: string;
}

type AllProps = IComponentProps & ICurrencyConnectedProps;

const ReminderFeeComponent: React.FC<AllProps> = props => {
  const { penaltyAmount, currencyModel } = props;
  const { data: currency } = currencyModel;

  return (
    <div className="reminder-fee-layout">
      <div className=" mb-3 sub-title">{i18n.t<string>('reminderFee')}</div>
      <div>
        <Trans i18nKey="penaltyMessage" values={{ penalty: penaltyAmount, currency }}>
          <span className="ff-semiBold">
            <span className="color-blue">{currency} </span>
            {penaltyAmount}
          </span>
          added to the late payment
        </Trans>
      </div>
    </div>
  );
};

export const ReminderFee = React.memo(communicationCurrency.injector(ReminderFeeComponent));
