import React, { ChangeEvent } from 'react';
import { Select, Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { isString } from 'lodash';
import { PhoneInputHelper } from 'common/components/Form/PhoneInput/PhoneInput.helper';
import { phoneCodes } from 'common/components/Form/PhoneInput/phoneInput.const';
import ArrowDown from 'app/assets/icons/ArrowDown.svg';
import { communicationKyc, IKycConnectedProps } from 'entities/KYCProvider/KYCProvider.communication';

interface IComponentProps {
  disableControls?: boolean;
  onChange?: (value?: string) => void;
  initialValue?: string;
}

interface IComponentState {
  countryCode?: number;
  localPhoneNumber?: string;
}

type AllProps = IKycConnectedProps & InputProps & IComponentProps;

// TODO Use translation
class PhoneInputComponent extends React.Component<AllProps, IComponentState> {
  state: IComponentState = {
    countryCode: 41,
    localPhoneNumber: undefined
  };

  componentDidMount() {
    const { initialValue } = this.props;

    const { countryCode, localPhoneNumber } = PhoneInputHelper.extractPhoneParts(`+${initialValue}`);

    if (countryCode && localPhoneNumber) {
      this.setState({ countryCode, localPhoneNumber });
    }
  }

  render() {
    const { disableControls } = this.props;
    const { countryCode, localPhoneNumber } = this.state;

    const prefixSelector = (
      <Select
        dropdownMatchSelectWidth={false}
        showSearch
        optionLabelProp="label"
        value={countryCode}
        filterOption={this.applyFilter}
        onChange={this.onCountryCodeChange}
        style={{ width: 100 }}
        disabled={disableControls}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
        suffixIcon={<img src={ArrowDown} alt="Arrow down" />}
        className="form-select"
      >
        {phoneCodes.map((item, index) => (
          <Select.Option key={index} value={item.code} label={`+${item.code}`}>
            {`+${item.code} (${item.country})`}
          </Select.Option>
        ))}
      </Select>
    );

    return (
      <Input
        onChange={this.onTextInputChange}
        addonBefore={prefixSelector}
        value={localPhoneNumber}
        disabled={disableControls}
        className="form-phone-input"
      />
    );
  }

  applyFilter = (input: string, option: any) => {
    const optionString = option.props.children;
    return isString(optionString) ? optionString.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false;
  };

  onCountryCodeChange = async (val: number) => {
    this.setState({ countryCode: val });
    this.updatePhoneNumber(val, this.state.localPhoneNumber);
  };

  onTextInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ localPhoneNumber: event.currentTarget.value });
    this.updatePhoneNumber(this.state.countryCode, PhoneInputHelper.extractNumber(event.currentTarget.value));
  };

  updatePhoneNumber = (countryCode?: number, localPhoneNumber?: string) => {
    const { onChange } = this.props;

    if (countryCode && localPhoneNumber) {
      onChange?.(`+${countryCode}${localPhoneNumber}`);
    }
  };
}

export const PhoneInput = communicationKyc.injector(PhoneInputComponent);
