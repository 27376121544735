import axios from 'axios';
import { IPaymentScheduleCollection, IPaymentScheduleLateInfo } from 'entities/PaymentSchedules/PaymentSchedules.models';

const basePath = '/payment-schedules';

export const paymentSchedulesTransport = {
  getPaymentSchedule: (loanId: string): Promise<IPaymentScheduleCollection> =>
    axios.get(`${basePath}/${loanId}`).then(r => r.data),
  getPaymentScheduleLateInfo: (userId: string): Promise<IPaymentScheduleLateInfo> =>
    axios.get(`${basePath}/late-payments/${userId}`).then(r => r.data),
  getPaymentScheduleLatest: (loanId: string): Promise<IPaymentScheduleCollection> =>
    axios.get(`${basePath}/${loanId}/late-payment`).then(r => r.data)
};
