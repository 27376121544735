import React from 'react';
import i18n from 'i18n';
import { TransactionsTable } from 'entities/Transactions/components/TransactionsTable';
import { communicationTransactions, ITransactionsConnectedProps } from 'entities/Transactions/Transactions.communication';

type AllProps = ITransactionsConnectedProps;

class TransactionsComponent extends React.Component<AllProps> {
  componentDidMount(): void {
    const { getTransactionsCollection } = this.props;
    getTransactionsCollection({});
  }

  render() {
    const { transactionsCollection, getTransactionsCollection } = this.props;
    const { loading, data: transactionList } = transactionsCollection;

    return (
      <>
        <div className="item-title text-center color-grayDarkest">{i18n.t<string>('headerMyTransactions')}</div>
        <TransactionsTable data={transactionList} getTransactionsCollection={getTransactionsCollection} loading={loading} />
      </>
    );
  }
}

export const Transactions = communicationTransactions.injector(TransactionsComponent);
