import React from 'react';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import { debounce } from 'lodash';
import i18n from 'i18n';
import moment from 'moment';
import { EDateTimeFormats } from 'common/consts';
import { EFilters } from 'entities/LoanApp/LoanApp.const';

interface IComponentProps {
  setFilters: (field: EFilters, value: string | string[]) => void;
  company: string | undefined;
  updateCompany: (company: string) => void;
}

type AllProps = IComponentProps;

class LoanAppFilterSectionComponent extends React.Component<AllProps> {
  private debouncedFilter: any;

  constructor(props: AllProps) {
    super(props);
    const { setFilters } = props;

    this.debouncedFilter = debounce(setFilters, 500);
  }

  render() {
    const { company } = this.props;

    return (
      <div className="filter-section">
        <Form>
          <Row gutter={32}>
            <Col xs={24} md={12} xl={8}>
              <div className="mb-4 fs-sm color-grayDark">{i18n.t<string>('tableColumns.company')}</div>
              <Input
                onChange={e => this.onFiltersChange(EFilters.Company, e.target.value)}
                placeholder={i18n.t('tableColumns.company')}
                value={company}
                allowClear
              />
            </Col>
            <Col xs={24} md={12} xl={8}>
              <div className="mb-4 fs-sm color-grayDark">{i18n.t<string>('tableColumns.statusChanged')}</div>
              <DatePicker.RangePicker
                placeholder={['From', 'To']}
                onChange={(dates, dateStrings) => {
                  this.onFiltersChange(EFilters.Date, dateStrings);
                }}
              />
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

  onFiltersChange = (field: EFilters, value: string | string[]) => {
    const { updateCompany } = this.props;

    if (field === EFilters.Date && Array.isArray(value)) {
      if (!value[1]) {
        this.debouncedFilter(field, [undefined, undefined]);
        return;
      }

      value[1] = moment(value[1])
        .add(1, 'days')
        .format(EDateTimeFormats.DateForRequests);
      this.debouncedFilter(field, value);
      return;
    }

    if (field === EFilters.Company) {
      updateCompany(value.toString());
      this.debouncedFilter(field, value);
      return;
    }

    this.debouncedFilter(field, value);
  };
}

export const LoanAppFilterSection = LoanAppFilterSectionComponent;
