import {
  actionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { AxiosResponse } from 'axios';
import { put, select } from '@redux-saga/core/effects';
import { IApplicationState } from 'app/store/reducers';
import { loanAppBookmarksTransport } from 'entities/Bookmarks/Bookmarks.transport';

const namespace = 'loanAppBookmarks';

export interface ILoanAppBookmarksConnectedProps {
  loanAppBookmarksModel: StoreBranch<any>;
  addLoanAppBookmarksModel(id: string): void;
  deleteLoanAppBookmarksModel(id: string): void;
}

const loanAppBookmarksModelApiProvider = [
  new APIProvider(actionsTypes.add, loanAppBookmarksTransport.addLoanAppBookmarksModel, {
    postSuccessHook: function*(response: AxiosResponse, payload: string) {
      yield updateCollection(response, payload);
    }
  }),
  new APIProvider(actionsTypes.delete, loanAppBookmarksTransport.deleteLoanAppBookmarksModel, {
    postSuccessHook: function*(response: AxiosResponse, payload: string) {
      yield updateCollection(response, payload);
    }
  })
];

function* updateCollection(response: AxiosResponse, payload: string): any {
  const loansCollectionParams = yield select((state: IApplicationState) => state.loanApp.collection.params);
  const filter = loansCollectionParams?.filter;
  const sort = loansCollectionParams?.sort;

  if (filter && sort) {
    yield put({
      type: getStartType('loanApp', 'collection', actionsTypes.get),
      payload: { filter: loansCollectionParams.filter, sort: loansCollectionParams.sort }
    });
  }

  yield put({
    type: getStartType('loanApp', 'model', actionsTypes.get),
    payload: {
      id: payload
    }
  });
}

const branches = [new Branch('model', loanAppBookmarksModelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationLoanAppBookmarks = buildCommunication<ILoanAppBookmarksConnectedProps>(strategy);
