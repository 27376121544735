import numeral from 'numeral';
import { ENumberFormats } from 'common/consts';

const getCurrency = (valueInit?: number | null, currency?: string | null): string => {
  const value = valueInit || 0;

  return `${numeral(value).format(ENumberFormats.Real)} ${currency || ''}`;
};

export const AmountHelper = {
  getCurrency
};
