import React, { RefObject } from 'react';
import i18n from 'i18n';
import { Col, DatePicker, Form, FormInstance, Input, Row, Select } from 'antd';
import moment from 'moment';
import { PhoneInput } from 'common/components/Form/PhoneInput/PhoneInput';
import { mapBEErrorsToFields, validateNameForNumbers } from 'common/helpers/errors.helper';
import { EErrorStatus } from 'common/models/errors.model';
import ArrowDown from 'app/assets/icons/ArrowDown.svg';
import CalendarIcon from 'app/assets/icons/CalendarIcon.svg';
import { nationalities } from 'entities/KYCProvider/KYC.const';
import { communicationKyc, IKycConnectedProps } from 'entities/KYCProvider/KYCProvider.communication';
import { IPersonalInformationModel } from 'entities/KYCProvider/KYCProvider.models';

interface IComponentProps {
  form: RefObject<FormInstance<any>>;
}

type AllProps = IKycConnectedProps & IComponentProps;

class PersonalInformationStepComponent extends React.Component<AllProps> {
  componentDidUpdate() {
    const { kycAssignData, form } = this.props;
    const { errors } = kycAssignData;
    const status = errors?.status;
    const data = errors?.data.errors.items[0].meta.personalInformation;

    if (status === EErrorStatus.Validation) {
      mapBEErrorsToFields(form.current!, data);
    }
  }

  render() {
    const { kycStatus } = this.props;
    const { data } = kycStatus;
    const initialValues: IPersonalInformationModel | undefined = data?.steps.personalInformation?.data?.meta?.personalInformation;

    return (
      <div className="personal-information">
        <Row gutter={24} justify="space-between">
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('KYCStepper.personalInfo.firstName')}
              name="firstName"
              initialValue={initialValues?.firstName}
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('pleaseEnterValue')
                },
                {
                  validator: validateNameForNumbers
                }
              ]}
            >
              <Input placeholder={i18n.t('pleaseEnterValue')} className="form-input" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('KYCStepper.personalInfo.lastName')}
              name="lastName"
              initialValue={initialValues?.lastName}
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('pleaseEnterValue')
                },
                {
                  validator: validateNameForNumbers
                }
              ]}
            >
              <Input placeholder={i18n.t('pleaseEnterValue')} className="form-input" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} justify="space-between">
          <Col xs={24} md={12}>
            <Form.Item
              className="personal-information__patronymic"
              normalize={value => (value === '' ? undefined : value)}
              label={i18n.t<string>('KYCStepper.personalInfo.middleName')}
              name="middleName"
              initialValue={initialValues?.middleName}
            >
              <Input placeholder={i18n.t('pleaseEnterValue')} className="form-input" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('KYCStepper.personalInfo.phoneNumber')}
              name="phone"
              initialValue={initialValues?.phone}
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('pleaseEnterValue')
                }
              ]}
            >
              <PhoneInput initialValue={initialValues?.phone} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} justify="space-between">
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('KYCStepper.personalInfo.birthday')}
              name="birthday"
              initialValue={initialValues?.birthday ? moment(initialValues.birthday) : undefined}
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('pleaseEnterValue')
                }
              ]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="dd / mm / yyyy"
                style={{ width: '100%' }}
                className="form-date-picker"
                suffixIcon={<img src={CalendarIcon} alt="Calendar icon" />}
                getPopupContainer={(triggerNode: Element) => triggerNode as HTMLElement}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('KYCStepper.personalInfo.countryNationality')}
              name="countryNationality"
              initialValue={initialValues?.countryNationality || 'CHE'}
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('pleaseSelectOption')
                }
              ]}
            >
              <Select
                showSearch
                placeholder={i18n.t<string>('selectPlaceholder')}
                getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
                suffixIcon={<img src={ArrowDown} alt="Arrow down" />}
                className="form-select"
                optionFilterProp="children"
              >
                {nationalities.map(item => (
                  <Select.Option key={item.code} value={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} justify="space-between">
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('KYCStepper.personalInfo.address')}
              name="address"
              initialValue={initialValues?.address}
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('pleaseEnterValue')
                }
              ]}
            >
              <Input placeholder={i18n.t('pleaseEnterValue')} className="form-input" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('KYCStepper.personalInfo.city')}
              name="city"
              initialValue={initialValues?.city}
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('pleaseEnterValue')
                }
              ]}
            >
              <Input placeholder={i18n.t('pleaseEnterValue')} className="form-input" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24} justify="space-between">
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('KYCStepper.personalInfo.country')}
              name="country"
              initialValue={initialValues?.country}
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('pleaseEnterValue')
                }
              ]}
            >
              <Select
                className="form-select"
                placeholder={i18n.t<string>('selectPlaceholder')}
                showSearch
                suffixIcon={<img src={ArrowDown} alt="Arrow down" />}
                optionFilterProp="children"
              >
                {nationalities.map((item, index) => (
                  <Select.Option key={index} value={item.code}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('KYCStepper.personalInfo.zipCode')}
              name="zipCode"
              initialValue={initialValues?.zipCode}
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('pleaseEnterValue')
                }
              ]}
            >
              <Input placeholder={i18n.t('pleaseEnterValue')} className="form-input" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Form.Item
              label={i18n.t<string>('KYCStepper.personalInfo.companyDescription')}
              name="companyDescription"
              initialValue={initialValues?.companyDescription}
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('pleaseEnterValue')
                }
              ]}
            >
              <Input.TextArea
                placeholder={i18n.t<string>('KYCStepper.personalInfo.companyDescriptionPlaceholder')}
                className="form-textarea"
                rows={5}
                maxLength={256}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
}

export const PersonalInformationStep = communicationKyc.injector(PersonalInformationStepComponent);
