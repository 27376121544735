import React, { useState } from 'react';
import { Button, Form } from 'antd';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { DataCardSmall } from 'common/components/Card/DataCardSmall';
import { MultipleFileUploaderCard } from 'entities/KYCProvider/components/MultipleFileUploaderCard';
import { IDocumentDto } from 'entities/LoanDocuments/LoanDocuments.models';
import { IUploadFileModel } from 'entities/KYCProvider/KYCProvider.models';

interface IComponentProps {
  docs: IDocumentDto[];
  loadingRequest: boolean;
  onSubmit: (data: { [key: string]: IUploadFileModel[] }) => void;
}

const UploadDocsComponent: React.FC<IComponentProps> = props => {
  const { docs, onSubmit, loadingRequest } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  if (!docs?.length) {
    return null;
  }

  return (
    <Form onFinish={onSubmit} layout="vertical" disabled={loadingRequest}>
      {docs.map(({ id, name, files }) => (
        <React.Fragment key={id}>
          <DataCardSmall header={name.toString()} className="mb-6" />

          <Form.Item name={id} rules={[{ required: true, message: i18n.t<string>('form.fileIsRequired') }]}>
            <MultipleFileUploaderCard setLoading={setLoading} value={files} loadingRequest={loadingRequest} />
          </Form.Item>
        </React.Fragment>
      ))}

      <Button type="primary" htmlType="submit" className="form-submit-button" loading={loadingRequest} disabled={loading}>
        {t('form.submit')}
      </Button>
    </Form>
  );
};

export const UploadDocs = UploadDocsComponent;
