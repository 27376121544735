import React from 'react';
import { Button, Col, Form, Input, Row, FormInstance } from 'antd';
import i18n from 'i18n';
import { mapBEErrorsToFields } from 'common/helpers/errors.helper';
import { EErrorStatus } from 'common/models/errors.model';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';

type AllProps = IUsersConnectedProps;

class NewManagerCreationFormComponent extends React.Component<AllProps> {
  formRef = React.createRef<FormInstance>();

  componentDidUpdate() {
    const { userManager } = this.props;
    const { errors } = userManager;
    const status = errors?.status;
    const data = errors?.data.errors;

    if (status === EErrorStatus.Validation) {
      mapBEErrorsToFields(this.formRef.current!, data);
    }
  }

  render() {
    const { userManager } = this.props;
    const { loading } = userManager;

    return (
      <Form
        ref={this.formRef}
        layout="vertical"
        className="mt-8"
        hideRequiredMark={true}
        onFinish={this.onSubmit}
        onFinishFailed={this.onFinishFailed}
      >
        <Row gutter={28}>
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('profileDetailsFirstName')}
              name="firstName"
              rules={[{ required: true, message: `${i18n.t('pleaseEnterValue')}` }]}
            >
              <Input placeholder={`${i18n.t('profileDetailsFirstName')}`} disabled={loading} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('profileDetailsLastName')}
              name="lastName"
              rules={[{ required: true, message: `${i18n.t('pleaseEnterValue')}` }]}
            >
              <Input placeholder={`${i18n.t('profileDetailsLastName')}`} disabled={loading} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={28}>
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('form.emailTitle')}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: `${i18n.t('form.emailValidationError')}`
                },
                { required: true, message: `${i18n.t('pleaseEnterValue')}` }
              ]}
            >
              <Input placeholder={`${i18n.t('form.emailTitle')}`} disabled={loading} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label={i18n.t<string>('form.passwordTitle')}
              name="password"
              rules={[{ required: true, message: `${i18n.t('pleaseEnterValue')}` }]}
            >
              <Input placeholder={`${i18n.t('form.passwordTitle')}`} disabled={loading} />
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" className="submit-button" htmlType="submit" loading={loading} disabled={loading}>
          {i18n.t<string>('Add')}
        </Button>
      </Form>
    );
  }

  onSubmit = () => {
    const { addUserManager } = this.props;
    const { validateFields } = this.formRef.current!;

    validateFields().then(values => {
      const { email, password, firstName, lastName } = values;
      addUserManager({ email, password, profile: { firstName, lastName } });
    });
  };

  onFinishFailed = ({ errorFields }: any) => {
    this.formRef.current!.scrollToField(errorFields[0].name);
  };
}

export const NewManagerCreationForm = communicationUser.injector(NewManagerCreationFormComponent);
