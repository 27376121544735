import React from 'react';
import { Redirect } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { EAdminRoutes, ECommonRoutes, ESpecialistRoutes } from 'common/models/routes.model';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { BorrowerRoutes } from 'common/components/Routes/BorrowerRoutes';
import { InvestorRoutes } from 'common/components/Routes/InvestorRoutes';
import { SpecialistLayout } from 'common/components/Layouts/SpecialistLayout';
import { AdminLayout } from 'common/components/Layouts/AdminLayout';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EUserRoles } from 'entities/User/User.models';
import { LoanListForManager } from 'entities/LoanApp/components/LoanListForManager';
import { LoanAppPage } from 'entities/LoanApp/components/LoanAppPage';
import { UsersList } from 'entities/User/components/UsersList';
import { UserPage } from 'entities/User/components/Specialist/UserPage';
import { SpecialistTransactions } from 'entities/Transactions/SpecialistTransactions';
import { AddNewManagerPage } from 'entities/User/components/Admin/AddNewManagerPage';
import { Profile } from 'entities/User/components/Profile';

type AllProps = IAuthConnectedProps & IUsersConnectedProps;

class PrivateLayoutComponent extends React.Component<AllProps> {
  render() {
    const { authModel, userCurrent } = this.props;
    const { data: authData, loading: authLoading } = authModel;
    const { data: userData } = userCurrent;
    const userRole = userCurrent.data?.role;
    const loading = authLoading;

    if (loading && !userData) {
      return <LoadingSpinner />;
    }

    const authorized = authData?.access;

    if (!authorized && !loading) {
      return <Redirect to={ECommonRoutes.LogIn} />;
    }

    if (!authorized && loading) {
      return null;
    }

    return (
      <Switch>
        <Route path={ECommonRoutes.Profile} component={Profile} exact />
        {userRole === EUserRoles.Borrower && <Route component={BorrowerRoutes} key="BorrowerRoutes" />}
        {userRole === EUserRoles.Investor && <Route component={InvestorRoutes} key="IvestorRoutes" />}
        {userRole === EUserRoles.Specialist && [
          <Route path={ESpecialistRoutes.Loans} component={LoanListForManager} exact key={ESpecialistRoutes.Loans} />,
          <Route path={ESpecialistRoutes.LoanItem} component={LoanAppPage} key={ESpecialistRoutes.LoanItem} />,
          <Route path={ESpecialistRoutes.Investors} component={UsersList} exact key={ESpecialistRoutes.Investors} />,
          <Route path={ESpecialistRoutes.Clients} component={UsersList} exact key={ESpecialistRoutes.Clients} />,
          <Route path={ESpecialistRoutes.UserItem} component={UserPage} exact key={ESpecialistRoutes.UserItem} />,
          <Route path={ECommonRoutes.Transactions} component={SpecialistTransactions} exact key={ECommonRoutes.Transactions} />,
          <Route path={ECommonRoutes.Main} component={SpecialistLayout} key={ECommonRoutes.Main} />
        ]}
        {userRole === EUserRoles.Admin && [
          <Route path={EAdminRoutes.Managers} component={UsersList} exact key={EAdminRoutes.Managers} />,
          <Route path={EAdminRoutes.AddManager} component={AddNewManagerPage} exact key={EAdminRoutes.AddManager} />,
          <Route path={ECommonRoutes.Main} component={AdminLayout} key={ECommonRoutes.Main} />
        ]}
      </Switch>
    );
  }
}

export const PrivateLayout = communicationAuth.injector(communicationUser.injector(PrivateLayoutComponent));
