import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EBorrowerRoutes, EInvestorRoutes } from 'common/models/routes.model';
import { EKycStatus } from 'entities/KYCProvider/KYC.const';
import { EUserRoles } from 'entities/User/User.models';

interface IComponentProps {
  role?: string;
  status?: EKycStatus;
}

export const AddKYCCardRightSlot: React.FC<IComponentProps> = props => {
  const { role, status } = props;
  const { push } = useHistory();
  const { t } = useTranslation();

  if (!status || !role) {
    return null;
  }

  if (status === EKycStatus.Skipped) {
    return (
      <Button
        className="form-secondary-button"
        onClick={() => {
          push(EBorrowerRoutes.KYC);
        }}
      >
        {t('borrowerLoans.KYC.submit')}
      </Button>
    );
  }

  if (status === EKycStatus.AvailableForEditing) {
    return (
      <Button
        className="form-secondary-button"
        onClick={() => {
          if (role === EUserRoles.Borrower) {
            push(EBorrowerRoutes.KYC);
          }

          if (role === EUserRoles.Investor) {
            push(EInvestorRoutes.KYC);
          }
        }}
      >
        {t('borrowerLoans.KYC.btnFix')}
      </Button>
    );
  }

  return null;
};
