import React, { useEffect } from 'react';
import i18n from 'i18n';
import { Col, Row } from 'antd';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import { DataCard } from 'common/components/Card/DataCard';
import { UploadDocs } from 'common/components/UploadDocs';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { EBorrowerRoutes } from 'common/models/routes.model';
import GroupSearch from 'app/assets/images/GroupSearch.svg';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { communicationLoanDocuments, ILoanDocumentsConnectedProps } from 'entities/LoanDocuments/LoanDocuments.communication';
import { IUploadFileModel } from 'entities/KYCProvider/KYCProvider.models';
import { IUpdateDocumentDto } from 'entities/LoanDocuments/LoanDocuments.models';
import { ELoanAppStatus } from 'entities/LoanApp/LoanApp.const';

type AllProps = ILoanAppConnectedProps & ILoanDocumentsConnectedProps;

const BorrowerLoanAddDocsComponent: React.FC<AllProps> = props => {
  const { loanDocumentsModel, loanDocumentsCollection, loanAppModel } = props;
  const { data: documents, loading: loadingDocuments } = loanDocumentsCollection;
  const { loading: loadingDocument } = loanDocumentsModel;
  const { data: loanModel, loading: loanLoading } = loanAppModel;

  const { id: loanId } = useParams<{ id?: string }>();

  useEffect(() => {
    const { getLoanAppModel } = props;

    loanId && getLoanAppModel({ id: loanId });
  }, []);

  useEffect(() => {
    const { getLoanDocumentsCollection } = props;
    // If model uploaded and id same as in url
    // case: user redirect directly to this page and loan in not "add more doc" status
    const statusChecked = loanModel && loanModel.id === loanId && loanModel.status === ELoanAppStatus.AdditionalInfoRequired;

    if (statusChecked && loanAppModel) {
      loanId && getLoanDocumentsCollection(loanId);
    }
  }, [loanModel]);

  const onSubmit = (data: { [key: string]: IUploadFileModel[] }, requestId: string) => {
    const { updateLoanDocumentsModel } = props;

    const docs: IUpdateDocumentDto[] = [];

    // tslint:disable-next-line:forin
    for (const loanIds in data) {
      docs.push({ id: loanIds, fileIds: data[loanIds].map(item => item.id) });
    }

    loanId && updateLoanDocumentsModel({ loanId, documents: docs, requestId });
  };

  if (loanId && loanModel && loanModel.status !== ELoanAppStatus.AdditionalInfoRequired) {
    return <Redirect to={EBorrowerRoutes.Loan.replace(':id', loanId)} />;
  }

  return (
    <div className="application-info application-info_with-bg">
      <Row gutter={32}>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 14, order: 1 }} xl={13}>
          {documents
            ?.filter(({ status }) => status !== 'completed')
            .map(doc => (
              <React.Fragment key={doc.id}>
                <DataCard
                  mainClassName="mb-11"
                  header={i18n.t('loanProps.additionalDocumentsForm.header')}
                  description={doc.description}
                />

                {doc?.documents && (
                  <UploadDocs
                    loadingRequest={loadingDocument || loadingDocuments || loanLoading}
                    docs={doc.documents}
                    onSubmit={data => {
                      onSubmit(data, doc.id);
                    }}
                  />
                )}
              </React.Fragment>
            ))}

          {!documents && (loadingDocuments || loadingDocument || loanLoading) && (
            <>
              <DataCard mainClassName="mb-11" header={i18n.t('loanProps.additionalDocumentsForm.header')} />

              <LoadingSpinner />
            </>
          )}
        </Col>

        <Col xs={{ span: 24, order: 1 }} lg={{ span: 10, order: 2 }} xl={11} className="info-image-container">
          <img src={GroupSearch} alt="search" />
        </Col>
      </Row>
    </div>
  );
};

export const BorrowerLoanAddDocs = communicationLoanDocuments.injector(
  communicationLoanApp.injector(BorrowerLoanAddDocsComponent)
);
