import React from 'react';
import i18n from 'i18n';
import { Description } from 'common/components/Description/Description';
import { LoanHistoryItem } from 'common/components/History/LoanHistoryItem';
import { LoanHistoryMessage } from 'common/components/History/LoanHistoryMessage';
import { getUserName } from 'common/helpers/common.helper';
import { ILoanAppAuditModel } from 'entities/LoanAppAudit/LoanAppAudit.models';
import { IUserModel } from 'entities/User/User.models';
import { IDiff, ILoanAppDiffDto, IUserDiff } from 'entities/LoanApp/LoanApp.models';

interface IComponentProps {
  history: ILoanAppAuditModel[];
}

export const LoanHistory: React.FC<IComponentProps> = props => {
  const { history } = props;
  const getHistoryMessageComponent = (diff: IDiff | IUserDiff, name: string, key: string): JSX.Element => {
    const nameTranslation = i18n.t(`loanProps.${name}`);
    // TODO History translation
    switch (name) {
      case 'specialist':
        const newSpecNameLabel = getUserName(diff.__new as IUserModel);
        const oldSpecNameLabel = getUserName(diff.__old as IUserModel);

        return (
          <LoanHistoryMessage
            key={`${nameTranslation}-${key}`}
            label={nameTranslation}
            newValue={newSpecNameLabel}
            oldValue={diff.__old ? oldSpecNameLabel : undefined}
          />
        );
      case 'status':
        return (
          <div className="history-item__message history-item__primary" key={`status-${key}`}>
            {`${nameTranslation} Is Changed From ${i18n.t(`loanProps.${diff.__old.toString()}`)} To ${i18n.t(
              `loanProps.${diff.__new.toString()}`
            )}`}
          </div>
        );
      default:
        return (
          <LoanHistoryMessage
            key={`${nameTranslation}-${key}`}
            label={nameTranslation}
            newValue={diff.__new.toString()}
            oldValue={diff.__old ? diff.__old.toString() : undefined}
          />
        );
    }
  };
  return (
    <Description header={i18n.t('loanHistory')}>
      {history.map(item => {
        if (!item) {
          return null;
        }

        const { diff, id, createdAt, author } = item;

        if (!diff) {
          return null;
        }

        const diffKeys = Object.keys(diff);
        const statusIndex = diffKeys.indexOf('status');

        if (statusIndex > 0) {
          // Status should be always first
          diffKeys.splice(statusIndex, 1);
          diffKeys.unshift('status');
        }

        const authorLabel = getUserName(author);

        return (
          <LoanHistoryItem
            date={createdAt}
            value={diffKeys.map(diffKey => getHistoryMessageComponent(diff[diffKey as keyof ILoanAppDiffDto]!, diffKey, id))}
            author={authorLabel}
            key={`${id}-`}
          />
        );
      })}
    </Description>
  );
};
