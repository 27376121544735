import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Button, Col, Form, Input, Row, FormInstance } from 'antd';
import i18n from 'i18n';
import { Popup } from 'common/components/Popup';
import { mapBEErrorsToFields } from 'common/helpers/errors.helper';
import { EErrorStatus } from 'common/models/errors.model';
import { communicationPayments, IPaymentsConnectedProps } from 'entities/Wallet/Wallet.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EWalletTabs } from 'entities/Wallet/Wallet.const';
import { IbanHolderInfo } from 'entities/Wallet/components/IbanHolderInfo';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

interface IComponentState {
  showPopup: boolean;
}

type AllProps = IPaymentsConnectedProps & IUsersConnectedProps & ICurrencyConnectedProps & RouteComponentProps;

class TransferFormComponent extends React.Component<AllProps, IComponentState> {
  state = {
    showPopup: false
  };

  formRef = React.createRef<FormInstance>();

  componentDidUpdate() {
    const { paymentsOutflowTransfer } = this.props;
    const { errors } = paymentsOutflowTransfer;
    const status = errors?.status;
    const data = errors?.data.errors;

    if (status === EErrorStatus.Validation) {
      mapBEErrorsToFields(this.formRef.current!, data);
    }
  }

  render() {
    const { showPopup } = this.state;
    const { paymentsOutflowTransfer, paymentsIban, currencyModel } = this.props;
    const { data: currency } = currencyModel;
    const { data: ibanData } = paymentsIban;
    const { loading } = paymentsOutflowTransfer;

    const holderName = ibanData?.holder;
    const iban = ibanData?.iban;
    const swift = ibanData?.swift;

    return (
      <Row gutter={{ lg: 24, xl: 48 }}>
        <Col xs={24} md={24} lg={12}>
          <Form ref={this.formRef} onFinish={this.onSubmit} hideRequiredMark={true} onFinishFailed={this.onFinishFailed}>
            <Form.Item
              label={i18n.t<string>('withdrawalTab.withdrawalAmount')}
              name="totalAmount"
              rules={[{ validator: this.validateAmount }]}
            >
              <Input placeholder={currency || undefined} />
            </Form.Item>
            <Form.Item>
              <Button className="submit-button" type="primary" htmlType="submit" loading={loading} disabled={loading}>
                {i18n.t<string>('withdrawalTab.makeTransfer')}
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} md={24} lg={12}>
          <IbanHolderInfo holderName={holderName} iban={iban} swift={swift} />
          <Row justify="end">
            <div onClick={this.togglePopup} className="fs-xs text-underline color-blue pointer">
              {i18n.t<string>('withdrawalTab.changeWithdrawalDetails')}
            </div>
          </Row>
        </Col>
        <Popup
          title={i18n.t<string>('ibanWillBeReset')}
          visible={showPopup}
          onCancel={this.togglePopup}
          closable={false}
          maxWidth="540px"
        >
          <Row gutter={24}>
            <Col xs={24} md={12}>
              <Button className="mb-7 submit-button" type="primary" onClick={this.goToIbanEditForm}>
                {i18n.t<string>('continue')}
              </Button>
            </Col>
            <Col xs={24} md={12}>
              <Button className="mb-7 submit-button button-secondary" type="default" onClick={this.togglePopup}>
                {i18n.t<string>('cancel')}
              </Button>
            </Col>
          </Row>
        </Popup>
      </Row>
    );
  }

  onSubmit = () => {
    const { addPaymentsOutflowTransfer, paymentsIban, userCurrent } = this.props;
    const { validateFields } = this.formRef.current!;
    const userId = userCurrent.data?.id;
    const iban = paymentsIban.data;

    validateFields().then((values: any) => {
      const { totalAmount } = values;

      if (iban && userId) {
        addPaymentsOutflowTransfer({ userId, ibanId: iban.id, totalAmount: Number(totalAmount) });
      }
    });
  };

  onFinishFailed = ({ errorFields }: any) => {
    this.formRef.current!.scrollToField(errorFields[0].name);
  };

  validateAmount = (rule: any, value: any) => {
    const { userBalance } = this.props;
    const balance = userBalance.data?.balance;

    switch (true) {
      case !value:
        return Promise.reject(i18n.t('pleaseEnterValue'));
      case isNaN(value):
        return Promise.reject(i18n.t('enterOnlyNumbers'));
      case value <= 0:
        return Promise.reject(`${i18n.t('valueMustBeGreater')} 0`);
      case balance && value > balance:
        return Promise.reject(i18n.t('sumLessThanBalance'));
      default:
        return Promise.resolve();
    }
  };

  goToIbanEditForm = () => {
    const { history } = this.props;

    history.push({ search: `?tab=${EWalletTabs.Withdrawal}&edit` });
  };

  togglePopup = () => {
    this.setState(prevState => ({ showPopup: !prevState.showPopup }));
  };
}

export const TransferForm = communicationCurrency.injector(
  communicationUser.injector(communicationPayments.injector(withRouter(TransferFormComponent)))
);
