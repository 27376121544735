import React from 'react';
import { Col, Row } from 'antd';
import i18n from 'i18n';
import { InfoBlock } from 'common/components/InfoSection/InfoBlock';
import { RiskOverview } from 'common/components/RiskOverview';
import { getFormattedSum } from 'entities/LoanApp/LoanApp.helpers';
import { ILoanAppModel } from 'entities/LoanApp/LoanApp.models';

interface IComponentProps {
  appModel: ILoanAppModel;
}

export const LoanInfoLookingForInvestor: React.FC<IComponentProps> = props => {
  const { appModel } = props;
  const {
    loanTotalCost,
    originationExpense,
    interestExpense,
    repaymentAmount,
    monthlyPayment,
    ownRiskClass,
    crifRiskClass,
    currency,
    amount
  } = appModel;
  return (
    <>
      <h3 className="application-info__header">
        {i18n.t<string>('loanInfoHereIsYour')}
        <br />
        <span className="ff-bold">{i18n.t<string>('loanInfoLoanConditions')}</span>
      </h3>
      <Row>
        <Col lg={6} md={24}>
          <InfoBlock
            title={i18n.t('loanProps.loanTotalCost')}
            infoValue={{ partOne: getFormattedSum(loanTotalCost), partTwo: currency }}
          />
          <InfoBlock
            title={i18n.t('loanProps.originationExpense')}
            infoValue={{ partOne: getFormattedSum(originationExpense), partTwo: currency }}
            valueSize="small"
          />
          <InfoBlock
            title={i18n.t('loanProps.interestExpense')}
            infoValue={{ partOne: getFormattedSum(interestExpense), partTwo: currency }}
            valueSize="small"
          />
        </Col>
        <Col lg={6} md={24}>
          <InfoBlock
            title={i18n.t('loanProps.repaymentAmount')}
            infoValue={{ partOne: getFormattedSum(repaymentAmount), partTwo: currency }}
          />
          <InfoBlock
            title={i18n.t('loanProps.monthlyPayment')}
            infoValue={{ partOne: getFormattedSum(monthlyPayment), partTwo: currency }}
            valueSize="small"
          />
        </Col>
        <Col lg={6} md={24}>
          <InfoBlock
            title={i18n.t('loanProps.disbursementAmount')}
            infoValue={{ partOne: getFormattedSum(amount), partTwo: currency }}
          />
        </Col>
        <Col lg={6} md={24}>
          <InfoBlock
            title={i18n.t('loanInfoRiskOverview')}
            children={<RiskOverview ownRisk={ownRiskClass} crif={crifRiskClass} />}
          />
        </Col>
      </Row>
    </>
  );
};
