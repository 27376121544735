import React from 'react';
import i18n from 'i18n';
import { Form } from 'antd';
import { FILES_SIZE_LIMIT_MAX } from 'common/config';
import { allDocsExts } from 'common/consts';
import { MultipleFileUploader } from 'entities/KYCProvider/components/MultipleFileUploader';
import { EDocTypes, EKycSteps } from 'entities/KYCProvider/KYC.const';
import { communicationKyc, IKycConnectedProps } from 'entities/KYCProvider/KYCProvider.communication';
import { IFileModel } from 'entities/LoanApp/LoanApp.models';
import { setDisable } from 'entities/KYCProvider/helpers/KYCProvider.helper';

interface IComponentProps {
  step: EKycSteps;
  fieldName: EDocTypes;
  fileConstraints?: string;
  formLabel?: string;
  required?: boolean;
}

type AllProps = IComponentProps & IKycConnectedProps;

class MultipleFileUploaderStepComponent extends React.Component<AllProps> {
  render() {
    const {
      fieldName,
      fileConstraints = i18n.t('KYCStepper.fileConstraints', { fileSize: FILES_SIZE_LIMIT_MAX, fileExts: allDocsExts.sign }),
      step,
      required = true,
      kycStatus
    } = this.props;
    const { data } = kycStatus;
    // @ts-ignore
    const initialValue = data?.steps[step]?.data?.files.map((file: IFileModel) => ({ id: file.id, name: file.name }));
    const disabled = setDisable(data, step);

    return (
      <>
        <div className="mb-6 color-grayDark">{fileConstraints}</div>
        <Form.Item
          name={`${fieldName}`}
          rules={[{ required, message: i18n.t<string>('form.fileIsRequired') }]}
          initialValue={initialValue}
        >
          <MultipleFileUploader step={step} initialValue={initialValue} disabled={disabled} />
        </Form.Item>
      </>
    );
  }
}

export const MultipleFileUploaderStep = communicationKyc.injector(MultipleFileUploaderStepComponent);
