import React from 'react';
import i18n from 'i18n';
import { Button, Row } from 'antd';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { ECommonRoutes } from 'common/models/routes.model';
import { ENumberFormats } from 'common/consts';
import { CARD_PAYMENT_LIMIT_MAX, CARD_PAYMENT_LIMIT_MIN } from 'common/config';
import { InfoSectionItem } from 'common/components/InfoSection/InfoSectionItem';
import GreenMark from 'app/assets/icons/GreenMark.svg';
import PaleRedExclamation from 'app/assets/icons/PaleRedExclamation.svg';
import { getMarkupForSumWithAccountCurrency } from 'entities/LoanApp/LoanApp.helpers';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { ELoanAppPaymentType } from 'entities/LoanApp/LoanApp.const';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

interface IComponentProps {
  balance: number;
}

interface IComponentState {
  investLoading: boolean;
}

type AllProps = IComponentProps & ILoanAppConnectedProps & ICurrencyConnectedProps;

class PaymentSectionComponent extends React.Component<AllProps, IComponentState> {
  state = {
    investLoading: false
  };

  render() {
    const { loanAppModel, balance, loanAppInvest, currencyModel } = this.props;
    const { data: currency } = currencyModel;
    const { investLoading } = this.state;
    const { data: loanApp } = loanAppModel;
    const { errors } = loanAppInvest;
    const loanAmount = loanApp?.loanAmount;

    if (!loanAmount) {
      return null;
    }

    const enoughMoney = balance >= loanAmount;
    const difference = +(loanAmount - balance).toFixed(2);
    const amountItem = [
      {
        title: i18n.t('depositAmount'),
        value: difference,
        formatter: (value?: number | string) => getMarkupForSumWithAccountCurrency(value)
      }
    ];

    const inLimit = difference >= Number(CARD_PAYMENT_LIMIT_MIN) && difference <= Number(CARD_PAYMENT_LIMIT_MAX);

    return (
      <div className="mt-4">
        <div className="side-invest-widget_payment-title">{i18n.t<string>('payment')}</div>
        <Row justify="space-between" align="middle" className="balance-value">
          <span className="color-gray-text-2">{i18n.t<string>('wallet')}</span>
          <Row align="middle">
            {getMarkupForSumWithAccountCurrency(balance)}&nbsp;
            <img src={enoughMoney ? GreenMark : PaleRedExclamation} alt="Balance mark" />
          </Row>
        </Row>

        {enoughMoney ? (
          <Button
            type="primary"
            className="form-submit-button"
            onClick={() => this.onInvest(ELoanAppPaymentType.Wallet)}
            loading={investLoading && !errors}
            disabled={investLoading && !errors}
          >
            {enoughMoney ? i18n.t<string>('invest') : i18n.t<string>('depositAndInvest')}
          </Button>
        ) : (
          <>
            {inLimit && (
              <div className="info-item">
                <Trans i18nKey="youNeedToDeposit" values={{ sum: numeral(difference).format(ENumberFormats.Real), currency }}>
                  You need to deposit <span className="ff-semiBold color-black">{currency} </span> in your wallet.
                  <br />
                  Please, use a credit card or
                </Trans>
                <Link to={ECommonRoutes.Wallet} className="text-underline">
                  {i18n.t<string>('depositPage')}
                </Link>
              </div>
            )}

            {amountItem.map(({ title, value, formatter }, index) => (
              <InfoSectionItem key={index} title={title} value={formatter(value)} />
            ))}

            {!inLimit && (
              <div className="info-item">
                <Trans i18nKey="limitByCardIsExceeded" values={{ limit: CARD_PAYMENT_LIMIT_MAX, currency }}>
                  Credit card payment is limited by <span className="ff-semiBold color-black">{currency} </span>. Thus you can’t
                  do the investment right away. Please go to the
                  <a href={ECommonRoutes.Wallet} className="text-underline">
                    deposit page
                  </a>
                  to charge your balance.
                </Trans>
              </div>
            )}

            <Button
              className="form-submit-button"
              onClick={() => this.onInvest(ELoanAppPaymentType.Card, difference)}
              loading={investLoading && !errors}
              disabled={(investLoading && !errors) || !inLimit}
            >
              {i18n.t<string>('depositAndInvest')}
            </Button>
          </>
        )}
      </div>
    );
  }

  onInvest = (type: string, difference?: number) => {
    const { addLoanAppInvest, loanAppModel } = this.props;
    const { data: loanApp } = loanAppModel;
    const loanAmount = loanApp?.loanAmount;
    const id = loanApp?.id;

    this.setState({ investLoading: true });

    if (id) {
      if (loanAmount && type === ELoanAppPaymentType.Wallet) {
        addLoanAppInvest({ id, amount: loanAmount, from: ELoanAppPaymentType.Wallet });
      }
      if (difference && type === ELoanAppPaymentType.Card) {
        addLoanAppInvest({ id, amount: difference, from: ELoanAppPaymentType.Card });
      }
    }
  };
}

export const PaymentSection = communicationCurrency.injector(communicationLoanApp.injector(PaymentSectionComponent));
