import { actionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { message } from 'antd';
import i18n from 'i18n';
import { ICollectionRequestParams } from 'common/models/request.model';
import { transactionsTransport } from 'entities/Transactions/Transactions.transport';
import { ITransactionsCollection } from 'entities/Transactions/Transactions.models';

const namespace = 'transactions';

export interface ITransactionsConnectedProps {
  transactionsCollection: StoreBranch<ITransactionsCollection>;
  getTransactionsCollection(params: ICollectionRequestParams): void;
}

const transactionsCollectionApiProvider = [
  new APIProvider(actionsTypes.get, transactionsTransport.getTransactionsCollection, {
    preRequestDataMapper: function(
      response: ITransactionsCollection | null,
      payload: ICollectionRequestParams,
      branchState: StoreBranch<ITransactionsCollection, ICollectionRequestParams>
    ) {
      return branchState.data;
    },
    postFailHook: function() {
      message.error(i18n.t<string>('errors:userHaveNoBankAccount'));
    }
  })
];

const branches = [new Branch('collection', transactionsCollectionApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationTransactions = buildCommunication<ITransactionsConnectedProps>(strategy);
