import React from 'react';
import { Row, Col, Popover } from 'antd';
import i18n from 'i18n';
import { RiskClass } from 'common/components/RiskClass';
import Info from 'app/assets/icons/InfoIcon.svg';
import { getScores } from 'entities/LoanApp/LoanApp.helpers';
import { EScores } from 'entities/LoanApp/LoanApp.const';

interface IComponentProps {
  ownRisk?: string;
  crif?: string;
}

export const RiskOverview: React.FC<IComponentProps> = props => {
  const { crif = '--', ownRisk = '--' } = props;

  const popoverContent = (
    <>
      <Row justify="space-between">
        <Col span={15}>{i18n.t<string>('loanProps.avgInterestRate')}</Col>
        <Col span={9}>
          <span className="ff-bold">{getScores(EScores.Rate, ownRisk)}</span>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={15}>{i18n.t<string>('loanProps.targetReturn')}</Col>
        <Col span={9}>
          <span className="ff-bold">{getScores(EScores.Target, ownRisk)}</span>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={15}>{i18n.t<string>('loanProps.probabilityOfDefault')}</Col>
        <Col span={9}>
          <span className="ff-bold">{getScores(EScores.Probability, ownRisk)}</span>
        </Col>
      </Row>
    </>
  );

  return (
    <Row align="middle">
      <RiskClass className={`risk-class risk-class--${ownRisk || '--'}`}>{ownRisk || '--'}</RiskClass>
      CRIF -&nbsp;<span className="risk-class__crif">{crif || '--'}</span>
      <Popover placement="topLeft" content={popoverContent} overlayClassName="risk-class__popover">
        <img className="risk-class__info" src={Info} alt="Information" />
      </Popover>
    </Row>
  );
};
