import React from 'react';
import { Col } from 'antd';
import { InfoBlock } from 'common/components/InfoSection/InfoBlock';

interface IComponentProps {
  title: string;
}

export const DiversificationScoreItem: React.FC<IComponentProps> = props => {
  const { title } = props;

  return (
    <Col span={8}>
      <InfoBlock title={title}>
        <div className="score-item">
          <span className="score-item__divisor">-</span>
          <span className="score-item__slash"> / </span>
          <span className="score-item__dividend">10</span>
        </div>
      </InfoBlock>
    </Col>
  );
};
