import React from 'react';
import i18n from 'i18n';
import { Button, Col, Form, Row, FormInstance, message, Input } from 'antd';
import { Trans } from 'react-i18next';
import { NumericInput } from 'common/components/Form/NumericInput';
import { FormSelect } from 'common/components/Form/FormSelect';
import { numbers } from 'common/consts';
import { mapBEErrorsToFields } from 'common/helpers/errors.helper';
import { EErrorStatus } from 'common/models/errors.model';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import ImgLoanInfo from 'app/assets/images/LoanAppInfoByBorrower.svg';
import ImgNewLoan from 'app/assets/images/ApplicationInfo/AppInfoFirst.svg';
import { EOnboardingSteps, loanPurposeOptions } from 'entities/Onboarding/OnboardingSteps.const';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { LoanAppInfoForbidCreate } from 'entities/Onboarding/components/LoanAppInfoForbidCreate';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';
import { ILoanAppInfoByBorrowerInitialValues } from 'entities/Onboarding/OnboardingSteps.models';

interface IComponentProps {
  edit?: boolean;
  onboarding?: boolean;
  onSubmit?: () => void;
  layoutSm?: boolean;
  skipStep?: () => void;
}

interface IComponentState {
  otherOptionSelected: boolean;
  initialValues: ILoanAppInfoByBorrowerInitialValues | undefined;
}

type AllProps = IOnboardingConnectedProps & ILoanAppConnectedProps & IComponentProps & ICurrencyConnectedProps;

class LoanAppInfoByBorrowerComponent extends React.Component<AllProps, IComponentState> {
  constructor(props: AllProps) {
    super(props);
    this.state = {
      otherOptionSelected: false,
      initialValues: undefined
    };
  }
  formRef = React.createRef<FormInstance>();

  componentDidUpdate() {
    const { loanAppModel } = this.props;
    const { errors } = loanAppModel;
    const status = errors?.status;
    const data = errors?.data.errors;

    if (status === EErrorStatus.Validation) {
      mapBEErrorsToFields(this.formRef.current!, data);
    }
  }

  componentDidMount() {
    const { onboarding, getOnboardingCompanyCollection, getLoanAppCollection } = this.props;

    getOnboardingCompanyCollection();

    if (onboarding) {
      getLoanAppCollection({}).then(response => {
        const data = response.data[0];

        if (data) {
          const initialValues = {
            amount: data.amount.toString(),
            duration: data.duration.toString(),
            purpose: data.purpose,
            purposeDescription: data.purposeDescription
          };

          initialValues.purpose === i18n.t<string>('step2OtherOption')
            ? this.setState({ otherOptionSelected: true, initialValues })
            : this.setState({ initialValues });
        }
      });
    }
  }

  render() {
    const {
      loanAppModel,
      edit,
      onboarding,
      layoutSm,
      onboardingCompanyCollection,
      skipStep,
      currencyModel: { data: currency },
      loanAppCollection: { loading: loanAppCollectionLoading }
    } = this.props;
    const { otherOptionSelected, initialValues } = this.state;
    const { loading: loanAppLoading, data } = loanAppModel;
    const { loading: companyLoading } = onboardingCompanyCollection;
    let amount, purpose, duration;

    if (data && edit) {
      ({ amount, purpose, duration } = data);
    }

    if ((loanAppLoading && !data) || companyLoading || (onboarding && loanAppCollectionLoading)) {
      return <LoadingSpinner />;
    }

    if (onboardingCompanyCollection.data?.data[0]?.isEligibleForSubmit === false) {
      return (
        <LoanAppInfoForbidCreate
          loanRequirements={onboardingCompanyCollection.data?.data[0]?.loanRequirements}
          submit={skipStep}
        />
      );
    }

    return (
      <Form
        ref={this.formRef}
        initialValues={initialValues || { purpose: purpose }}
        hideRequiredMark={true}
        layout="vertical"
        onFinish={this.onSubmit}
      >
        <Row gutter={[24, 40]} align="stretch" justify="space-between">
          <Col xs={{ span: 24, order: 2 }} xl={14}>
            <p className="form-card__title">{i18n.t<string>('step2Header.borrower')}</p>
            <NumericInput
              label={i18n.t('step2LoanAmount', { currency })}
              fieldName="amount"
              initialValue={amount}
              placeholder="500,000"
              minValue={numbers.loan.amountMin}
              maxValue={numbers.loan.amountMax}
              disabled={loanAppLoading}
            />
            <NumericInput
              label="step2Duration"
              fieldName="duration"
              initialValue={duration}
              placeholder="12"
              minValue={numbers.loan.durationMin}
              maxValue={numbers.loan.durationMax}
              disabled={loanAppLoading}
            />
            <Form.Item
              label={i18n.t<string>('step2LoanPurpose')}
              name="purpose"
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('optionRequiredMessage')
                }
              ]}
            >
              <FormSelect
                placeholder="selectPlaceholder"
                data={loanPurposeOptions}
                disabled={loanAppLoading}
                onChange={(value: string) => {
                  value === i18n.t<string>('step2OtherOption') &&
                    !otherOptionSelected &&
                    this.setState({ otherOptionSelected: true });
                  value !== i18n.t<string>('step2OtherOption') &&
                    otherOptionSelected &&
                    this.setState({ otherOptionSelected: false });
                }}
              />
            </Form.Item>

            {otherOptionSelected && (
              <Form.Item label={i18n.t<string>('step2PleaseSpecify')} name="purposeDescription" rules={[{ required: true }]}>
                <Input type="text" className="form-input" />
              </Form.Item>
            )}

            <div className="loan-info-footer">
              <div className="d-flex d-flex_justify_between">
                <Form.Item className="submit-loan-info-btn mb-0">
                  <Button className="form-submit-button" type="primary" htmlType="submit" loading={loanAppLoading}>
                    {i18n.t<string>('form.submit')}
                  </Button>
                </Form.Item>

                {skipStep && (
                  <Button className="form-secondary-button" loading={loanAppLoading} onClick={skipStep}>
                    {i18n.t<string>('form.skip')}
                  </Button>
                )}
              </div>
            </div>
          </Col>

          <Col xs={{ span: 0, order: 1 }} xl={{ span: 9, order: 2 }}>
            <div className="loan-info-image-wrapper">
              {layoutSm ? <img src={ImgNewLoan} alt="New Loan" /> : <img src={ImgLoanInfo} alt="Loan Info" />}
            </div>
          </Col>
        </Row>

        {edit && (
          <p className="mt-5 mb-0">
            <Trans i18nKey="editLoanInfoPopupNote">
              <span className="ff-bold">Please note: </span>
              If you submit the changes, the status of your loan will change to &quot;Being verified&quot;
            </Trans>
          </p>
        )}
      </Form>
    );
  }

  onSubmit = () => {
    const {
      onboardingCompanyCollection,
      loanAppModel,
      addLoanAppModel,
      updateLoanAppModel,
      edit,
      onSubmit,
      onboarding,
      addLoanOnboardingModel,
      setOnboardingStepsModel
    } = this.props;
    const { validateFields } = this.formRef.current!;
    const { data } = onboardingCompanyCollection;
    const companyId = data && data.data[0].id;

    validateFields().then(values => {
      const { amount, duration, purpose, purposeDescription } = values;

      if (onboarding) {
        if (!companyId) {
          message.error(i18n.t<string>('step2LoanErrorNoCompany'));
          throw new Error('Can not find comapny. Please refresh the page');
        }

        addLoanOnboardingModel({
          companyId,
          amount: Number(amount),
          duration: Number(duration),
          purpose,
          purposeDescription,
          onSuccess: () => {
            setOnboardingStepsModel({ step: EOnboardingSteps.KYCStep });
          }
        });

        if (onSubmit) {
          onSubmit();
        }

        return;
      }

      if (companyId && !edit) {
        addLoanAppModel({ companyId, amount: Number(amount), duration: Number(duration), purpose, purposeDescription }).then(
          () => {
            if (onSubmit) {
              onSubmit();
            }
          }
        );

        return;
      }

      const { data: loanApp } = loanAppModel;

      if (edit && loanApp) {
        updateLoanAppModel({ id: loanApp.id, amount: Number(amount), duration: Number(duration), purpose });
      }

      if (onSubmit) {
        onSubmit();
      }
    });
  };
}

export const LoanAppInfoByBorrower = communicationCurrency.injector(
  communicationOnboarding.injector(communicationLoanApp.injector(LoanAppInfoByBorrowerComponent))
);
