import React from 'react';
import numeral from 'numeral';
import { ENumberFormats } from 'common/consts';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';
import { EUserRoles, EUserStatus } from 'entities/User/User.models';

interface IComponentProps {
  userId?: string;
}

type AllProps = IComponentProps & IUsersConnectedProps;

class BalanceBlockComponent extends React.Component<AllProps> {
  componentDidMount() {
    const { userCurrent, userId: id, getUserBalance } = this.props;
    const { data } = userCurrent;
    const { status } = data || {};

    if (id && status && status !== EUserStatus.Onboarding) {
      getUserBalance(id);
    }
  }

  render() {
    const { userBalance, userCurrent } = this.props;
    const { role, status } = userCurrent.data || {};
    const { data, errors, loading } = userBalance;
    const balanceValue = errors ? 0 : data?.balance;
    const unconfirmedInvestor = role === EUserRoles.Investor && status === EUserStatus.Unconfirmed;

    if (loading || status === EUserStatus.Onboarding || unconfirmedInvestor) {
      return null;
    }

    return (
      <div className="balance">
        <span className="balance__sum">{numeral(balanceValue).format(ENumberFormats.Real)}</span>
        <span className="balance__currency">
          {' '}
          <AccountCurrency />
        </span>
      </div>
    );
  }
}

export const BalanceBlock = React.memo(communicationUser.injector(BalanceBlockComponent));
