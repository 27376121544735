import React from 'react';
import i18n from 'i18n';
import { Progress, Row } from 'antd';
import ProgressIcon from 'app/assets/icons/ProgresIcon.svg';
import { ILoanAppCollectionData, IPaymentItem } from 'entities/LoanApp/LoanApp.models';

interface IComponentProps {
  loan: ILoanAppCollectionData;
  paymentData?: IPaymentItem[];
  debtError: number | boolean | undefined;
}

const LoansCardProgressComponent: React.FC<IComponentProps> = props => {
  const { loan, paymentData, debtError } = props;

  const progressValue = () => {
    const { repaymentAmount } = loan;

    let progress = 0;
    if (paymentData && repaymentAmount) {
      const paidAmount = paymentData
        ?.filter(item => item.isPaid)
        ?.reduce((acc, it) => (it.totalPayment ? acc + it.totalPayment : acc), 0);

      progress = (paidAmount * 100) / repaymentAmount;
      return progress;
    }
    return progress;
  };

  return (
    <>
      <p className="active-info-block__title pt-6">{i18n.t<string>('loanInfoYourLoanRepaymentProgress')}</p>
      <Progress
        className="active-info-block__progress-bar"
        percent={progressValue()}
        status="exception"
        strokeColor="#794dd3"
        showInfo={false}
        strokeLinecap="square"
      />
      <Row align="top" justify="space-between">
        <p className="active-info-block__subtitle">
          {debtError
            ? `${i18n.t<string>('loanInfoRepaymentProgressDescription.1')}`
            : `${i18n.t<string>('loanInfoRepaymentProgressDescription.0')} 👌`}
        </p>
        <img src={ProgressIcon} alt="progress" />
      </Row>
    </>
  );
};

export const LoansCardProgress = LoansCardProgressComponent;
