import React from 'react';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { Col, Row } from 'antd';
import ConfirmationImage from 'app/assets/images/ConfirmationImage.svg';

class EmailConfirmationComponent extends React.Component {
  render() {
    return (
      <Row justify="center" className="email-confirm-page">
        <Col xs={22} sm={18} md={14} lg={10} xl={8} xxl={6}>
          <p className="email-confirm-page__title">{i18n.t<string>('emailConfirmation')}</p>
          <p className="email-confirm-page__text">
            <Trans i18nKey="needToConfirm">
              You just need to check your account
              <br />
              and click to the confirmation link.
            </Trans>
          </p>
          <img src={ConfirmationImage} alt="Congratulations!" className="email-confirm-page__image" />
        </Col>
      </Row>
    );
  }
}

export const EmailConfirmation = EmailConfirmationComponent;
