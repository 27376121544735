import moment, { Moment } from 'moment';
import React from 'react';

export const getFormattedDate = (
  date: Moment | string,
  dayFormat: string = 'DD',
  monthFormat: string = 'MMM',
  yearFormat: string = 'YYYY'
): JSX.Element => {
  const day = moment(date).format(dayFormat);
  const month = moment(date).format(monthFormat);
  const year = moment(date).format(yearFormat);

  return (
    <span className="date-string">
      <span className="date-string__day">{day} </span>
      <span className="date-string__month">{month} </span>
      <span className="date-string__year">{year}</span>
    </span>
  );
};
