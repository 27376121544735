import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { EDateTimeFormats } from 'common/consts';
import { AmountHelper } from 'common/helpers/amount.helper';
import { IPaymentItem } from 'entities/LoanApp/LoanApp.models';
import { getNextPaymentData } from 'entities/LoanApp/LoanApp.helpers';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

interface IComponentProps {
  paymentData: IPaymentItem[];
}

type AllProps = IComponentProps & ICurrencyConnectedProps;

const LoansCardDebtComponent: React.FC<AllProps> = props => {
  const { paymentData, currencyModel } = props;
  const { data: currency } = currencyModel;

  const { t } = useTranslation();

  const payData = getNextPaymentData(paymentData);

  if (!payData) {
    return null;
  }

  const paymentDay = moment(payData.date);
  const formattedPaymentDay = moment(paymentDay).format(EDateTimeFormats.DefaultFullDate);
  const nextPaymentAmount = AmountHelper.getCurrency(payData.totalPayment, currency);

  return (
    <div className="loan-progress-card__sum">
      {t('nextPaymentMessage2', { payment: nextPaymentAmount, date: formattedPaymentDay })}
      {' 🗓'}
    </div>
  );
};

export const LoansCardDebt = communicationCurrency.injector(LoansCardDebtComponent);
