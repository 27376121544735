import {
  actionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import i18n from 'i18n';
import { message } from 'antd';
import { put } from '@redux-saga/core/effects';
import { getCurrentUserId } from 'common/helpers/requestUser.helper';
import { IRequestSuccess } from 'common/models/request.model';
import { IKYCAssignData, IKYCStatus } from 'entities/KYCProvider/KYCProvider.models';
import { kycTransport } from 'entities/KYCProvider/KYCProvider.transport';

const namespace = 'kyc';

export interface IKycConnectedProps {
  kycStatus: StoreBranch<IKYCStatus>;
  kycAssignData: StoreBranch<IKYCAssignData, IKYCAssignData, any>;
  kycSubmit: StoreBranch<any>;
  getKycStatus(id: string): void;
  addKycAssignData(params: IKYCAssignData): void;
  addKycSubmit(params?: IRequestSuccess): void;
}

function* getCurrentKycStatus(): any {
  const userId = yield getCurrentUserId();

  if (userId) {
    return yield put({ type: getStartType(namespace, 'status', actionsTypes.get), payload: userId });
  }
}

const statusApiProvider = [new APIProvider(actionsTypes.get, kycTransport.getKYCStatus)];

const assignDataApiPRovider = [
  new APIProvider(actionsTypes.add, kycTransport.assignTypeToFile, {
    postSuccessHook: function*(response: any, payload: any) {
      yield getCurrentKycStatus();

      if (payload.onSuccess) {
        payload.onSuccess();
      }
    }
  })
];

const submitApiProvider = [
  new APIProvider(actionsTypes.add, kycTransport.addKYCSubmit, {
    postSuccessHook: function*(response: any, payload: any) {
      yield getCurrentKycStatus();
      message.success(i18n.t<string>(i18n.t('form.dataSent')));

      if (payload.onSuccess) {
        payload.onSuccess();
      }
    },
    postFailHook: function() {
      message.error(i18n.t<string>('errors:internalServerError'));
    }
  })
];

const branches = [
  new Branch('status', statusApiProvider),
  new Branch('assignData', assignDataApiPRovider),
  new Branch('submit', submitApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationKyc = buildCommunication<IKycConnectedProps>(strategy);
