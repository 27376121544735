import React, { useEffect, useMemo, useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { ILoanAppCollectionData } from 'entities/LoanApp/LoanApp.models';
import { activeLoansStatuses, ELoanAppStatus, ELoanListTabs } from 'entities/LoanApp/LoanApp.const';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { LoansTableRest } from 'entities/LoanApp/components/LoansTableRest';

interface IComponentProps {
  userId: string;
}

type AllProps = IComponentProps & ILoanAppConnectedProps;

const UserLoansComponent: React.FC<AllProps> = props => {
  const { loanAppCollection } = props;
  const { data: loans, loading } = loanAppCollection;

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<ELoansTabs>(ELoansTabs.Application);

  useEffect(() => {
    const { getLoanAppCollection, clearLoanAppCollection, userId } = props;

    getLoanAppCollection({ userId, limit: 100 });

    return () => {
      clearLoanAppCollection();
    };
  }, []);

  const activeLoans = useMemo<ILoanAppCollectionData[] | undefined>(() => {
    return loans?.data.filter(item => activeLoansStatuses.includes(item.status as ELoanAppStatus));
  }, [loans]);

  const nonActiveLoans = useMemo<ILoanAppCollectionData[] | undefined>(() => {
    return loans?.data.filter(item => !activeLoansStatuses.includes(item.status as ELoanAppStatus));
  }, [loans]);

  return (
    <Tabs
      activeKey={activeTab}
      onChange={key => setActiveTab(key as ELoansTabs)}
      className="light-tabs light-tabs__header_white mt-11"
    >
      {Array.from(loansTabs).map(([key, label]) => (
        <Tabs.TabPane tab={t<string>(`tabName.${label}`)} key={key} className="mb-0">
          <LoansTableRest
            loading={loading}
            data={{
              data: (activeTab === ELoansTabs.Loans ? activeLoans : nonActiveLoans) || [],
              count: (activeTab === ELoansTabs.Loans ? activeLoans?.length : nonActiveLoans?.length) || 0,
              page: 0,
              pageCount: 0,
              total: 0
            }}
            reloadCollection={params => {
              const { getLoanAppCollection, userId } = props;

              getLoanAppCollection({ ...params, userId, limit: 100 });
            }}
            tabKey={ELoanListTabs.ClientInfo}
          />
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
};

export const UserLoans = communicationLoanApp.injector(UserLoansComponent);

enum ELoansTabs {
  Application = 'application',
  Loans = 'loans'
}

const loansTabs = new Map<ELoansTabs, string>([
  [ELoansTabs.Application, 'app'],
  [ELoansTabs.Loans, 'loans']
]);
