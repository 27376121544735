import moment from 'moment';
import i18n from 'i18n';
import { EDateTimeFormats } from 'common/consts';
import { IUserModel } from 'entities/User/User.models';

export const getUserName = (user?: IUserModel) => {
  if (!user) {
    return 'System';
  }

  const authorFirstName = user.profile?.firstName;
  return authorFirstName ? `${authorFirstName} ${user?.profile?.lastName}` : user?.email;
};

export const getPersonalInfo = (profile?: Components.Schemas.ProfileDto, email?: string, currency?: string) => {
  if (!profile) {
    return [];
  }
  const { firstName, lastName, address, country, city, phone, dob } = profile;

  return [
    { title: i18n.t('profileDetailsFirstName'), value: firstName },
    { title: i18n.t('profileDetailsLastName'), value: lastName },
    { title: i18n.t('profileDetailsEmail'), value: email },
    { title: i18n.t('profileDetailsCurrency'), value: currency },
    { title: i18n.t('profileDetailsDOB'), value: moment(dob).format(EDateTimeFormats.FullDayShortMonthFullYear) },
    { title: i18n.t('profileDetailsCountry'), value: country },
    { title: i18n.t('profileDetailsStateOfBirth'), value: city },
    { title: i18n.t('profileDetailsPhoneNumber'), value: phone },
    { title: i18n.t('profileDetailsAddress'), value: address }
  ];
};

export const firstLetterUpperCase = (value?: string): string => {
  return value ? value.replace(/^./, value[0].toUpperCase()) : '';
};

export const createPlainObject = (nested: object, path: string = ''): object =>
  Object.entries(nested).reduce((obj, [key, value]) => {
    const newPath = `${path}${path ? '.' : ''}${key}`;
    return Object.assign(obj, value instanceof Object ? createPlainObject(value, newPath) : { [newPath]: value });
  }, {});

export const getValueFromUrl = (searchParam: string) => new URL(window.location.href).searchParams.get(searchParam);
