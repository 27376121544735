import React from 'react';
import i18n from 'i18n';
import LightLogo from 'app/assets/icons/WhiteLogo.png';

export const OnboardingStub: React.FC = () => (
  <div className="onboarding-stub">
    <img className="onboarding-stub__logo" src={LightLogo} alt="Midfunder logo" />
    <p className="onboarding-stub__top-description">{i18n.t<string>('onboardingStubDescription.0')}</p>
    <p className="onboarding-stub__bottom-description">{i18n.t<string>('onboardingStubDescription.1')}</p>
  </div>
);
