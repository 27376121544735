import React from 'react';

interface IComponentProps {
  header?: string | JSX.Element;
  subheader?: string | JSX.Element;
  description?: string | JSX.Element;
  footer?: string | JSX.Element;
  mainClassName?: string;
  descriptionClassName?: string;
}

export const DataCard: React.FC<IComponentProps> = props => {
  const { header, subheader, description, footer, mainClassName = 'data-card', descriptionClassName = '' } = props;
  return (
    <div className={mainClassName}>
      {header && <div className="data-card__header">{header}</div>}
      {subheader && <div className="data-card__subheader">{subheader}</div>}
      {description && <div className={`data-card__description ${descriptionClassName}`}>{description}</div>}
      {footer && <div className="data-card__footer">{footer}</div>}
    </div>
  );
};
