import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import i18n from 'i18n';
import { Button, Form, Row, Col, Input, FormInstance } from 'antd';
import { FormFooter } from 'common/components/Form/FormFooter';
import { AuthWidget } from 'common/components/AuthWidget';
import { ECommonRoutes } from 'common/models/routes.model';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';

type AllProps = IAuthConnectedProps & RouteComponentProps;

class LoginComponent extends React.Component<AllProps> {
  formRef = React.createRef<FormInstance>();

  render() {
    const { authModel } = this.props;
    const { loading } = authModel;
    const initialValues = {
      email: '',
      password: ''
    };

    return (
      <AuthWidget title={i18n.t('headerLogInButton')}>
        <Form ref={this.formRef} layout="vertical" initialValues={initialValues} onFinish={this.login}>
          <Form.Item
            label={i18n.t<string>('form.emailTitle')}
            required={false}
            className="mt-8"
            name="email"
            rules={[
              {
                type: 'email',
                message: `${i18n.t('form.emailValidationError')}`
              },
              { required: true, message: `${i18n.t('form.emailError')}` }
            ]}
          >
            <Input type="e-mail" placeholder={`${i18n.t('form.emailTitle')}`} disabled={loading} className="form-input" />
          </Form.Item>
          <Form.Item
            label={i18n.t<string>('form.passwordTitle')}
            required={false}
            name="password"
            rules={[{ required: true, message: `${i18n.t('form.passwordError')}` }]}
          >
            <Input type="password" placeholder={`${i18n.t('form.passwordTitle')}`} disabled={loading} className="form-input" />
          </Form.Item>
          <Row align="middle" className="mt-8 mb-6" gutter={[24, 24]}>
            <Col>
              <Form.Item className="mb-0">
                <Button type="primary" size="large" htmlType="submit" loading={loading} className="form-submit-button">
                  {i18n.t<string>('headerLogInButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Link to={ECommonRoutes.RestorePass} className="fs-xs">
                {i18n.t<string>('forgotPass')}
              </Link>
            </Col>
          </Row>
        </Form>
        <FormFooter />
      </AuthWidget>
    );
  }

  login = () => {
    const { addAuthModel } = this.props;
    const { validateFields } = this.formRef.current!;

    validateFields().then(values => {
      addAuthModel({ ...values });
    });
  };
}

export const Login = communicationAuth.injector(withRouter(LoginComponent));
