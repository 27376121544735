import React from 'react';
import i18n from 'i18n';
import { ILoanAppModel } from 'entities/LoanApp/LoanApp.models';
import { ApplicationConditions } from 'entities/LoanApp/components/ApplicationConditions';

interface IComponentProps {
  appModel: ILoanAppModel;
}

export const LoanInfoVerification: React.FC<IComponentProps> = props => {
  const { appModel } = props;
  const { amount, duration, purpose, purposeDescription, currency } = appModel;

  return (
    <>
      <h3 className="application-info__header">
        {i18n.t<string>('loanInfoHereIsYour')}
        <br />
        <span className="ff-bold">{i18n.t<string>('loanInfoApplicationConditions')}</span>
      </h3>
      <ApplicationConditions
        amount={amount}
        duration={duration}
        purpose={purpose}
        purposeDescription={purposeDescription}
        currency={currency}
      />
    </>
  );
};
