import React from 'react';
import { Col, Row } from 'antd';
import { FormCard } from 'common/components/Form/FormCard';

interface IComponentProps {
  children: React.ReactNode;
  title?: string;
}

export const AuthWidget: React.FC<IComponentProps> = props => {
  const { children, title } = props;

  return (
    <Row justify="center">
      <Col xl={{ span: 9, offset: 12 }} xs={22}>
        <FormCard title={title}>{children}</FormCard>
      </Col>
    </Row>
  );
};
