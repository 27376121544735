import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { useHistory } from 'react-router';
import { DataCardSmall } from 'common/components/Card/DataCardSmall';
import { DataCardBase } from 'common/components/Card/DataCardBase';
import { EBorrowerRoutes } from 'common/models/routes.model';
import { LoanAppInfoByBorrower } from 'entities/Onboarding/components/LoanAppInfoByBorrower';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';

type AllProps = IOnboardingConnectedProps;

const LoansCreateComponent: React.FC<AllProps> = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="application-info">
      <Row gutter={32} className="mb-12">
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 14, order: 1 }} xl={17}>
          <DataCardSmall
            className="pb-11"
            description={
              <>
                <a href={EBorrowerRoutes.Loans}>{t('loansCreate.breadcrumbs.1')}</a>
                &nbsp;-&gt;&nbsp;
                {t('loansCreate.breadcrumbs.2')}
              </>
            }
          />

          <DataCardBase
            className="pb-11"
            header={
              <>
                <div className="ff-regular">{t('loansCreate.header.1')}</div>
                {t('loansCreate.header.2')}
              </>
            }
          />
        </Col>
      </Row>

      <LoanAppInfoByBorrower
        layoutSm
        onSubmit={() => {
          history.replace(EBorrowerRoutes.Loans);
        }}
      />
    </div>
  );
};

export const LoansCreate = communicationOnboarding.injector(LoansCreateComponent);
