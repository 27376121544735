import React from 'react';
import { Spin, Select, Form, message, FormInstance } from 'antd';
import { DefaultOptionType, SelectProps } from 'antd/lib/select';
import i18n from 'i18n';
import { debounce } from 'lodash';
import { LabeledValue } from 'antd/es/select';
import { ZefixData } from 'common/components/ZefixData';
import { companyCountryOptions, ECompanyCountries } from 'common/consts';
import { onboardingTransport } from 'entities/Onboarding/OnboardingSteps.transport';
import {
  FirmItem,
  FirmList,
  IBusinessInfoInitialValues,
  IFirmItemData,
  IZefixResponseCollection
} from 'entities/Onboarding/OnboardingSteps.models';

interface IComponentProps {
  disabled: boolean;
  className?: string;
  changeCompanyCountry?: (company: ECompanyCountries) => void;
  form?: FormInstance;
  otherOptionSelected?: boolean;
  initialValues: IBusinessInfoInitialValues | undefined;
}

interface IComponentState {
  firmListData?: IZefixResponseCollection[];
  firmItemData?: IFirmItemData;
  listLoading: boolean;
  itemLoading: boolean;
  companyCountry: 'CH' | 'UK' | 'Other' | undefined;
  currency: 'CHF' | 'GBP' | undefined;
}

type AllProps = IComponentProps & SelectProps;

class CompanyDetailsFormComponent extends React.Component<AllProps, IComponentState> {
  state = {
    firmListData: new Array<FirmList>(),
    firmItemData: new FirmItem(),
    listLoading: false,
    itemLoading: false,
    companyCountry: this.props.initialValues?.registrationCountry,
    currency: this.props.initialValues?.currency
  };

  private debouncedFindCompanies: any;

  constructor(props: IComponentProps) {
    super(props);
    this.debouncedFindCompanies = debounce(this.findCompanies, 500);
  }

  componentDidMount() {
    const { initialValues } = this.props;

    if (initialValues && initialValues.externalId) {
      this.getFirmItemById('', { key: initialValues.externalId.key } as any);
    }
  }

  render() {
    const { listLoading, itemLoading, firmListData, firmItemData, companyCountry, currency } = this.state;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { disabled, otherOptionSelected } = this.props;

    return (
      <>
        <Form.Item
          label={i18n.t<string>('step1CompanyCountry')}
          name="registrationCountry"
          rules={[
            {
              required: true,
              message: i18n.t<string>('step1CompanyCountryRequired')
            }
          ]}
        >
          <Select
            className="form-select"
            showArrow={false}
            placeholder={i18n.t<string>('selectCountryPlaceholder')}
            onSelect={(val: any) => this.selectCompanyCountry(val)}
          >
            {companyCountryOptions.map(option => (
              <Select.Option key={option.key} value={option.key}>
                {option.item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {!otherOptionSelected && (
          <>
            <Form.Item
              label={i18n.t<string>('step1Currency')}
              name="currency"
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('step1CurrencyRequired')
                }
              ]}
            >
              <Select
                className="form-select"
                showArrow={false}
                placeholder={i18n.t<string>('selectCurrencyPlaceholder')}
                onSelect={(val: any) => this.setState({ currency: val })}
              >
                {companyCountryOptions.map(
                  option =>
                    option.currency && (
                      <Select.Option key={option.key} value={option.currency}>
                        {option.currency}
                      </Select.Option>
                    )
                )}
              </Select>
            </Form.Item>

            {currency && <p className="company-details-form__currency__warning">{i18n.t<string>('step1CurrencyWarning')}</p>}

            <Form.Item
              label={i18n.t<string>('step1CompanyName')}
              name="externalId"
              rules={[
                {
                  required: true,
                  message: i18n.t<string>('step1CompanyNameRequired')
                }
              ]}
            >
              <Select
                showSearch
                showArrow={false}
                placeholder="Select company"
                notFoundContent={listLoading ? <Spin size="small" /> : <div>{i18n.t<string>('step1CompanyNameNoResults')}</div>}
                onSearch={val => this.debouncedFindCompanies(val)}
                onSelect={(val: any, option: DefaultOptionType) => this.getFirmItemById(val, option)}
                filterOption={false}
                disabled={disabled || !companyCountry}
                className="form-select"
                getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
                labelInValue
              >
                {firmListData.map(item => (
                  <Select.Option key={item.ehraid} value={item.ehraid}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <ZefixData loading={itemLoading} data={firmItemData} onboarding />
          </>
        )}
      </>
    );
  }

  resetAdditionalFields = () => {
    const { form } = this.props;

    form?.setFieldsValue({
      monthlyRevenue: null,
      industryId: null,
      annualProfits: null,
      state: null,
      additionalInfo: null
    });
  };

  selectCompanyCountry = (val: ECompanyCountries) => {
    const { form, changeCompanyCountry } = this.props;

    changeCompanyCountry && changeCompanyCountry(val);
    this.setState({ firmListData: new Array<FirmList>(), firmItemData: new FirmItem(), companyCountry: val });
    form?.setFieldsValue({ externalId: null });
    this.resetAdditionalFields();
  };

  findCompanies = (val: string) => {
    const { companyCountry } = this.state;
    const lang = i18n.languages[0];

    this.setState({ listLoading: true });

    if (!val.length) {
      return;
    }

    if (companyCountry === ECompanyCountries.CH) {
      onboardingTransport
        .getZefixCollection({ name: val, languageKey: lang, maxEntries: 20 })
        .then(res => {
          const data = res.map(item => ({ ehraid: item.ehraid, name: item.name }));

          this.setState({ firmListData: data, listLoading: false });
        })
        .catch(() => {
          message.error(i18n.t<string>('errors:serviceUnavailable'));
        });
    } else {
      onboardingTransport
        .getCompaniesHouseCollection({ query: val, itemsPerPage: 20 })
        .then(res => {
          const data = res.items
            .map(item => ({ ehraid: item.company_number || '', name: item.title }))
            .filter(item => !!item.ehraid);

          this.setState({ firmListData: data, listLoading: false });
        })
        .catch(() => {
          message.error(i18n.t<string>('errors:serviceUnavailable'));
        });
    }
  };

  getFirmItemById = (value: string | number | LabeledValue, option: DefaultOptionType) => {
    const { companyCountry } = this.state;

    if (option.key) {
      this.setState({ itemLoading: true });

      if (companyCountry === ECompanyCountries.CH) {
        onboardingTransport
          .getZefixModel(option.key)
          .then(res => {
            this.setState({ firmItemData: res, itemLoading: false });
          })
          .catch(() => {
            message.error(i18n.t<string>('errors:internalServerError'));
          });
      } else {
        onboardingTransport
          .getCompaniesHouseModel(option.key)
          .then(res => {
            this.setState({
              firmItemData: {
                name: res.company_name,
                ehraid: Number(res.company_number),
                legalForm: res.type.toUpperCase(),
                uid: res.company_number,
                address: {
                  street: res.registered_office_address.address_line_1,
                  town: res.registered_office_address.locality,
                  country: res.registered_office_address.country
                }
              },
              itemLoading: false
            });
          })
          .catch(() => {
            message.error(i18n.t<string>('errors:internalServerError'));
          });
      }
    }

    this.resetAdditionalFields();
  };

  getFirmAddress = () => {
    const { firmItemData } = this.state;

    return firmItemData.ehraid ? Object.values(firmItemData.address).join(', ') : '';
  };
}

export const CompanyDetailsForm = CompanyDetailsFormComponent;
