import React, { RefObject } from 'react';
import { Form, Radio, Row, Col, FormInstance } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import i18n from 'i18n';
import { imagesExts } from 'common/consts';
import { FILES_SIZE_LIMIT_MAX } from 'common/config';
import SingleFileUploader from 'entities/KYCProvider/components/SingleFileUploader';
import { EDecoratorNames, EDocTypes } from 'entities/KYCProvider/KYC.const';
import { IUploadFileModel } from 'entities/KYCProvider/KYCProvider.models';

interface IAntdFormControl {
  onChange?: (value?: { type: EDocTypes; fileIds: string[] }) => void;
  value?: IUploadFileModel;
}

interface IComponentProps {
  form: RefObject<FormInstance<any>>;
  options: EDocTypes[];
  docType: EDocTypes | undefined;
  changeDocType: (docType: EDocTypes) => void;
  disabled: boolean;
  initialValues?: IUploadFileModel[];
  required?: boolean;
  idCardFieldNames?: string[];
  passportFieldName?: string;
  drivingLicenceFieldNames?: string[];
}

type AllProps = IComponentProps & IAntdFormControl;

class IdentityDocuments extends React.Component<AllProps> {
  render() {
    const {
      options,
      docType,
      disabled,
      initialValues,
      required = true,
      idCardFieldNames = [EDecoratorNames.IdentityCardSideA, EDecoratorNames.IdentityCardSideB],
      passportFieldName = EDecoratorNames.Passport,
      drivingLicenceFieldNames = [EDecoratorNames.DrivingLicenceSideA, EDecoratorNames.DrivingLicenceSideB]
    } = this.props;
    const fileConstraints = i18n.t('KYCStepper.fileConstraints', { fileSize: FILES_SIZE_LIMIT_MAX, fileExts: imagesExts.sign });
    const formFileIsRequired = 'form.fileIsRequired';
    const KYCStepperUpload = 'KYCStepper.upload';

    return (
      <>
        <Radio.Group onChange={this.onChange} className="form-radio-inline mb-6" value={docType} disabled={disabled}>
          {options.map(item => (
            <Radio value={item} key={item}>
              {i18n.t<string>(`KYCStepper.currentIdDoc.${item}`)}
            </Radio>
          ))}
        </Radio.Group>
        <p className="file-constraints">{fileConstraints}</p>
        <Row justify="space-between" gutter={16} className="mb-7">
          {docType === EDocTypes.IdentityCard && (
            <>
              <Col md={12} xs={24}>
                <Form.Item
                  colon={false}
                  name={idCardFieldNames[0]}
                  rules={[{ required, message: i18n.t<string>(formFileIsRequired) }]}
                  initialValue={initialValues?.[0]}
                >
                  <SingleFileUploader
                    fieldId={idCardFieldNames[0]}
                    acceptedExts={imagesExts.exts}
                    placeholder={`${i18n.t(KYCStepperUpload)} ${i18n.t('KYCStepper.currentIdDoc.idCardSideA')}`}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  colon={false}
                  name={idCardFieldNames[1]}
                  rules={[{ required, message: i18n.t<string>(formFileIsRequired) }]}
                  initialValue={initialValues?.[1]}
                >
                  <SingleFileUploader
                    fieldId={idCardFieldNames[1]}
                    acceptedExts={imagesExts.exts}
                    placeholder={`${i18n.t(KYCStepperUpload)} ${i18n.t('KYCStepper.currentIdDoc.idCardSideB')}`}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          {docType === EDocTypes.Passport && (
            <Col md={12} xs={24}>
              <Form.Item
                colon={false}
                name={passportFieldName}
                rules={[{ required, message: i18n.t<string>(formFileIsRequired) }]}
                initialValue={initialValues?.[0]}
              >
                <SingleFileUploader
                  fieldId={passportFieldName}
                  placeholder={`${i18n.t(KYCStepperUpload)} ${i18n.t('KYCStepper.currentIdDoc.passport')}`}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
          )}
          {docType === EDocTypes.DrivingLicence && (
            <>
              <Col md={12} xs={24}>
                <Form.Item
                  colon={false}
                  name={drivingLicenceFieldNames[0]}
                  rules={[{ required, message: i18n.t<string>(formFileIsRequired) }]}
                  initialValue={initialValues?.[0]}
                >
                  <SingleFileUploader
                    fieldId={drivingLicenceFieldNames[0]}
                    acceptedExts={imagesExts.exts}
                    placeholder={`${i18n.t(KYCStepperUpload)} ${i18n.t('KYCStepper.currentIdDoc.drivingLicenceSideA')}`}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  colon={false}
                  name={drivingLicenceFieldNames[1]}
                  rules={[{ required, message: i18n.t<string>(formFileIsRequired) }]}
                  initialValue={initialValues?.[1]}
                >
                  <SingleFileUploader
                    fieldId={drivingLicenceFieldNames[1]}
                    acceptedExts={imagesExts.exts}
                    placeholder={`${i18n.t(KYCStepperUpload)} ${i18n.t('KYCStepper.currentIdDoc.drivingLicenceSideB')}`}
                    disabled={disabled}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </>
    );
  }

  onChange = (e: RadioChangeEvent) => {
    const {
      form,
      changeDocType,
      idCardFieldNames = [EDecoratorNames.IdentityCardSideA, EDecoratorNames.IdentityCardSideB],
      passportFieldName = EDecoratorNames.Passport,
      drivingLicenceFieldNames = [EDecoratorNames.DrivingLicenceSideA, EDecoratorNames.DrivingLicenceSideB]
    } = this.props;

    changeDocType(e.target.value);
    form.current!.resetFields([...idCardFieldNames, ...drivingLicenceFieldNames, passportFieldName]);
  };
}

export default IdentityDocuments;
