import React from 'react';
import i18n from 'i18n';
import { Row } from 'antd';
import InfoIcon from 'app/assets/icons/InfoIcon.svg';
import { DashboardInfoCard } from 'entities/Dashboard/components/DashboardInfoCard';
import { DiversificationScoreItem } from 'entities/Dashboard/components/DiversificationScoreItem';

export const DiversificationScoreCard = () => {
  return (
    <DashboardInfoCard
      title={
        <>
          <span className="mr-3">{i18n.t<string>('dashboard.diversificationScore')}</span>
          <img src={InfoIcon} alt="Info Icon" />
        </>
      }
    >
      <Row gutter={[0, 24]}>
        <DiversificationScoreItem title={i18n.t('loanInfoRiskClass')} />
        <DiversificationScoreItem title={i18n.t('loanProps.loanAmount')} />
        <DiversificationScoreItem title={i18n.t('dashboard.industries')} />
        <DiversificationScoreItem title={i18n.t('dashboard.loanDuration')} />
        <DiversificationScoreItem title={i18n.t('loanProps.interestRate')} />
      </Row>
    </DashboardInfoCard>
  );
};
