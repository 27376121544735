import React, { useEffect } from 'react';
import { Button, Col, Row, Spin } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { DataCardBase } from 'common/components/Card/DataCardBase';
import { DataCardSmall } from 'common/components/Card/DataCardSmall';
import { EBorrowerRoutes } from 'common/models/routes.model';
import MoneyFlower from 'app/assets/images/MoneyFlower.svg';
import { communicationUpswot, IUpswotConnectedProps } from 'entities/Upswot/Upswot.communication';

interface IComponentProps {
  userId: string;
}

type AllProps = IUpswotConnectedProps & IComponentProps;

function TransparencyScoreComponent(props: AllProps) {
  const { userId, upswotTransparency } = props;
  const { loading, data: transparency } = upswotTransparency;
  const { total } = transparency || {};
  const { t } = useTranslation();
  const { push } = useHistory();

  useEffect(() => {
    const { getUpswotTransparency } = props;

    getUpswotTransparency(userId);
  }, [userId]);

  useEffect(() => {
    return () => {
      const { clearUpswotTransparency } = props;

      clearUpswotTransparency();
    };
  }, []);

  const completed = total === MAX_SCORE;

  const styles = getContentByCount(total);

  return (
    <Row gutter={32}>
      <Col xs={{ span: 24, order: 2 }} lg={{ span: 14, order: 1 }} xl={13}>
        <DataCardBase className="pb-11" header={t('borrowerLoans.header')} description={t('borrowerLoans.description')} />

        {/* MDF-1634 */}
        <div style={{ display: 'none' }}>
          {loading || !transparency ? (
            <Spin spinning={loading} />
          ) : (
            <>
              <DataCardSmall
                className="pb-11"
                header={
                  <>
                    {t('borrowerLoans.score')}{' '}
                    <span className={styles.className}>
                      {/* TODO: Change after upswot is ready */}
                      {/* {total} / {MAX_SCORE} */}
                      {t('borrowerLoans.comingSoon')}
                    </span>
                  </>
                }
                // TODO: Change after upswot is ready
                // description={t(styles.textKey)}
              />

              {!completed && (
                <Button
                  className="form-secondary-button"
                  onClick={() => {
                    push(EBorrowerRoutes.Performance);
                  }}
                  disabled
                >
                  <ArrowUpOutlined />
                  {t('borrowerLoans.btnImprove')}
                </Button>
              )}
            </>
          )}
        </div>
      </Col>

      <Col xs={{ span: 24, order: 1 }} lg={{ span: 10, order: 2 }} xl={11} className="info-image-container">
        <img className="application-info__image" src={MoneyFlower} alt="First step" />
      </Col>
    </Row>
  );
}

export const TransparencyScore = communicationUpswot.injector(TransparencyScoreComponent);

const getContentByCount = (count: number = 0): { textKey: string; className: string } => {
  if (count < 3) {
    return { textKey: 'borrowerLoans.paperNotes1', className: 'color-red' };
  }

  if (count < 6) {
    return { textKey: 'borrowerLoans.paperNotes2', className: 'color-alert' };
  }

  return { textKey: 'borrowerLoans.paperNotes3', className: 'color-alert-green' };
};

const MAX_SCORE = 10;
