import React, { useEffect, useState } from 'react';
import i18n from 'i18n';
import { Form, Select } from 'antd';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EUserRoles, IUserModel } from 'entities/User/User.models';

interface IComponentProps {
  required: boolean;
  currency?: string;
}

type AllProps = IUsersConnectedProps & IComponentProps;

const LenderFieldComponent: React.FC<AllProps> = props => {
  const { required, currency, getUserCollection, userCollection } = props;
  const { data: users } = userCollection;
  const [options, setOptions] = useState<IUserModel[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const LENDERS_OFFSET = 20;

  const filterLenders = (options: IUserModel[]) => {
    return options.filter(option => option.profile && option.currency === currency && option.companies?.length);
  };

  const onPopupScroll = (e: any) => {
    const { target } = e;

    if (users && users.page < users.pageCount && Number(target.scrollTop) + Number(target.offsetHeight) === target.scrollHeight) {
      getUserCollection({ roles: [EUserRoles.Investor], offset: offset * LENDERS_OFFSET }).then(response => {
        const filterdLenders = filterLenders(response.data);
        setOffset(offset + 1);
        setOptions([...options, ...filterdLenders]);
      });
    }
  };

  const getLenders = (lenders: IUserModel[], offset: number) => {
    getUserCollection({ roles: [EUserRoles.Investor], offset: offset * LENDERS_OFFSET }).then(response => {
      const filterdLenders = filterLenders([...lenders, ...response.data]);
      setOffset(offset + 1);

      if (filterdLenders.length < LENDERS_OFFSET && response.page < response.pageCount) {
        getLenders(filterdLenders, offset + 1);
      } else {
        setOptions(filterdLenders);
      }
    });
  };

  useEffect(() => {
    getLenders(options, offset);
  }, []);

  return (
    <Form.Item
      label={i18n.t<string>('loanProps.chooseALender')}
      name="lenderId"
      rules={[
        {
          required,
          message: i18n.t<string>('pleaseSelectOption')
        }
      ]}
    >
      <Select
        placeholder={i18n.t<string>('selectPlaceholder')}
        onPopupScroll={onPopupScroll}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
      >
        {options.map(item => (
          <Select.Option key={item.id} value={item.id}>
            {`${item.profile?.firstName} ${item.profile?.lastName} | ${item.companies?.[0].name}`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export const LenderField = communicationUser.injector(LenderFieldComponent);
