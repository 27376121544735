import { actionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { ICollectionWithIdRequestParams } from 'common/models/request.model';
import { ILoanAppAuditCollection } from 'entities/LoanAppAudit/LoanAppAudit.models';
import { loanAppAuditTransport } from 'entities/LoanAppAudit/LoanAppAudit.transport';

const namespace = 'loanAppAudit';

export interface ILoanAppAuditConnectedProps {
  loanAppAuditCollection: StoreBranch<ILoanAppAuditCollection>;
  getLoanAppAuditCollection(params: ICollectionWithIdRequestParams): void;
}

const collectionApiProvider = [new APIProvider(actionsTypes.get, loanAppAuditTransport.getLoanAppAuditCollection)];

const branches = [new Branch('collection', collectionApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationLoanAppAudit = buildCommunication<ILoanAppAuditConnectedProps>(strategy);
