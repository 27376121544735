import React from 'react';
import { Row } from 'antd';
import { InfoBlock } from 'common/components/InfoSection/InfoBlock';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';

interface IComponentProps {
  title: string;
  percent: number;
  value: number | string;
  type?: string;
}

type AllProps = IComponentProps & ICurrencyConnectedProps;

const PerformanceChartInfoItemComponent: React.FC<AllProps> = props => {
  const { percent, value, type = 'default', title, currencyModel } = props;
  const { data: currency } = currencyModel;

  return (
    <Row>
      <div className={`performance-chart__info-item performance-chart__info-item--${type}`}>{percent.toFixed(0)}%</div>
      {currency && <InfoBlock title={title} infoValue={{ partOne: value, partTwo: currency }} valueSize="middle" />}
    </Row>
  );
};
export const PerformanceChartInfoItem = communicationCurrency.injector(PerformanceChartInfoItemComponent);
