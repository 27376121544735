import React from 'react';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Tabs } from 'antd';
import i18n from 'i18n';
import { SpecialistLayout } from 'common/components/Layouts/SpecialistLayout';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';
import { getValueFromUrl } from 'common/helpers/common.helper';
import { COLLECTION_LIMIT_DEFAULT } from 'common/consts';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EFilters, ELoanAppStatus, ELoanListTabs, specialistLoanListTabs } from 'entities/LoanApp/LoanApp.const';
import { LoanAppFilterSection } from 'entities/LoanApp/components/LoanAppFilterSection';
import { LoansTableRest } from 'entities/LoanApp/components/LoansTableRest';
import { IGetLoanAppCollectionParams } from 'entities/LoanApp/LoanApp.models';

type AllProps = ILoanAppConnectedProps & IUsersConnectedProps & RouteComponentProps;

interface IComponentState {
  filter: IGetLoanAppCollectionParams;
  company?: string;
}

class LoanListForManagerComponent extends React.Component<AllProps, IComponentState> {
  state: IComponentState = {
    filter: { limit: COLLECTION_LIMIT_DEFAULT, offset: 0 }
  };

  goBackListener: () => void;

  componentDidMount() {
    const { history } = this.props;
    const activeTab = getValueFromUrl('tab') || ELoanListTabs.New;

    this.goBackListener = history.listen((location, action) => {
      if (action === 'POP') {
        this.onSwitchTab(activeTab);
      }
    });

    this.onSwitchTab(activeTab);
  }

  componentWillUnmount() {
    this.goBackListener();
  }

  render() {
    const { loanAppCollection } = this.props;
    const { filter, company } = this.state;
    const { loading } = loanAppCollection;
    const loanList = loanAppCollection.data;

    const activeTab = getValueFromUrl('tab') || ELoanListTabs.New;

    return (
      <SpecialistLayout>
        <LayoutBasic className="blue-layout">
          <Tabs activeKey={activeTab} onChange={this.onSwitchTab} className="custom-tabs">
            {specialistLoanListTabs.map(item => (
              <Tabs.TabPane tab={i18n.t<string>(item.tab)} key={item.key}>
                <>
                  <LoanAppFilterSection
                    setFilters={this.setFilters}
                    company={company}
                    updateCompany={value => {
                      this.setState({ company: value });
                    }}
                  />
                  {loanList && (
                    <LoansTableRest
                      data={loanList}
                      reloadCollection={params => {
                        this.reloadWithParams(params);
                      }}
                      filter={filter}
                      className="custom-table"
                      loading={loading}
                      tabKey={item.key}
                    />
                  )}
                </>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </LayoutBasic>
      </SpecialistLayout>
    );
  }

  onSwitchTab = (activeTab: string): void => {
    const { userCurrent, history } = this.props;
    const specId = userCurrent.data?.id;
    const filter: IGetLoanAppCollectionParams = {
      specialistId: undefined,
      status: undefined,
      offset: 0,
      withSpecialist: undefined,
      updatedAtFrom: undefined,
      updatedAtTo: undefined,
      companyName: undefined
    };

    switch (activeTab) {
      case ELoanListTabs.New:
        filter.status = ELoanAppStatus.Verification;
        filter.withSpecialist = 'false';
        break;
      case ELoanListTabs.RateRequest:
        filter.status = ELoanAppStatus.Verification;
        filter.specialistId = specId;
        break;
      case ELoanListTabs.InfoRequested:
        filter.status = ELoanAppStatus.AdditionalInfoRequired;
        break;
      case ELoanListTabs.WaitingForSigning:
        filter.status = ELoanAppStatus.ReadyForSign;
        break;
      case ELoanListTabs.Pool:
        filter.status = ELoanAppStatus.LookingForInvestor;
        break;
      case ELoanListTabs.Funded:
        filter.status = ELoanAppStatus.Active;
        break;
      case ELoanListTabs.Declined:
        filter.status = ELoanAppStatus.Declined;
        break;
      case ELoanListTabs.Defaulted:
        filter.status = ELoanAppStatus.Defaulted;
        break;
      case ELoanListTabs.Completed:
        filter.status = ELoanAppStatus.Completed;
        break;
      default:
        break;
    }

    this.setState({ company: undefined });
    this.reloadWithParams(filter);
    history.push({ search: `?tab=${activeTab}` });
  };

  setFilters = (field: EFilters, value: string | string[]) => {
    switch (field) {
      case EFilters.Company:
        this.reloadWithParams({ companyName: value.toString() });
        break;
      case EFilters.Date:
        if (Array.isArray(value)) {
          this.reloadWithParams({ updatedAtFrom: value[0], updatedAtTo: value[1] });
          return;
        }
        break;
      default:
        break;
    }
  };

  reloadWithParams = (params: IGetLoanAppCollectionParams) => {
    const { getLoanAppCollection } = this.props;
    const { filter } = this.state;

    const newFilters: IGetLoanAppCollectionParams = { ...filter, ...params };
    this.setState({ filter: newFilters }, () => {
      getLoanAppCollection(newFilters);
    });
  };
}

const containers = compose(withRouter, communicationUser.injector, communicationLoanApp.injector);

export const LoanListForManager = containers(LoanListForManagerComponent);
