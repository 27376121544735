import { select } from '@redux-saga/core/effects';
import { IApplicationState } from 'app/store/reducers';
import { getAuthUser } from 'entities/Auth/Auth.communication';

export function* requestUser(): any {
  const authModelData = yield select((state: IApplicationState) => state.auth.model.data);
  const userId = authModelData?.access?.userId;

  if (userId) {
    yield getAuthUser(userId);
  }
}

export function* getCurrentUserId(): any {
  return yield select((state: IApplicationState) => state.user.current.data?.id);
}
