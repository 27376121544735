import axios, { AxiosResponse } from 'axios';
import { getCreds } from '@axmit/axios-patch-jwt';
import { ITokenModel, IAuthModel } from 'entities/Auth/Auth.models';

const basePath = '/auth';

export const authTransport = {
  initAuth: (): Promise<ITokenModel> => getCreds(),
  addAuthModel: (data: IAuthModel): Promise<ITokenModel> => axios.post(`${basePath}`, data).then(r => r.data),
  deleteAuthModel: (): Promise<AxiosResponse> => axios.delete(`${basePath}`)
};
