import React from 'react';
import { Button, ModalProps } from 'antd';
import Modal from 'antd/es/modal';
import { useTranslation } from 'react-i18next';

interface IComponentProps {
  header?: string | JSX.Element;
  desc?: string | JSX.Element;
}

type AllProps = ModalProps & IComponentProps;

const ForbidModalComponent: React.FC<AllProps> = ({ header, desc, onCancel, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Modal visible={false} footer={null} width={789} closable={false} {...rest} onCancel={onCancel}>
      {header && <div className="fs-lg lh-sm mb-7">{typeof header === 'string' ? t(header) : header}</div>}
      {desc && <div className="fs-xs mb-9">{typeof desc === 'string' ? t(desc) : desc}</div>}

      <div className="ta-c">
        <Button className="form-submit-button" onClick={onCancel}>
          {t('btnOkay')}
        </Button>
      </div>
    </Modal>
  );
};

export const ForbidModal = ForbidModalComponent;
