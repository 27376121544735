import '@axmit/mdf-api';
import { IRequestFail, IRequestSuccess } from 'common/models/request.model';
import { ILoanAppCollectionData } from 'entities/LoanApp/LoanApp.models';
import { EOnboardingSteps } from 'entities/Onboarding/OnboardingSteps.const';

export interface IZefixResponseCollection extends Omit<Components.Schemas.ZefixFirmListItem, 'ehraid'> {
  ehraid: string;
}
export interface IZefixModel extends Components.Schemas.ZefixFirm {}
export interface ICompanyRequestBody extends Components.Schemas.CreateCompanyDto {}
export interface ICompanyCreateParams extends Components.Schemas.CreateCompanyDto, IRequestSuccess {}
export interface ICompanyModel extends Components.Schemas.CompanyDto {
  loanApplications?: ILoanAppCollectionData[];
}
export interface ICompanyCollection extends Components.Schemas.CompanyCollectionDto {}

export class FirmList {
  ehraid: string;
  name: string;
  constructor() {
    this.ehraid = '';
    this.name = '';
  }
}

export class FirmItem {
  name: string;
  ehraid: number;
  legalForm: string;
  shabDate: string;
  uid: string;
  address: { street: string; houseNumber: string; town: string; country: string };
  constructor() {
    this.name = '';
    this.ehraid = 0;
    this.legalForm = '';
    this.uid = '';
    this.shabDate = '';
    this.address = {
      street: '',
      houseNumber: '',
      town: '',
      country: ''
    };
  }
}

export interface IZefixRequestBody {
  name: string;
  languageKey?: string;
  offset?: number;
  maxEntries?: number;
}

export interface IOnboardingStepperItem<T> {
  step: T;
  title: string;
  subTitle?: string;
}

export interface ICompanyHouseRequestBody extends Paths.CompaniesHouseControllerList.QueryParameters {}

export interface ICompanyHouseResponseItem {
  address: {
    address_line_1?: string;
    country?: string;
    locality?: string;
    postal_code?: string;
    premises?: string;
    region?: string;
  };
  company_number?: string;
  title: string;
}

export interface ICompanyHouseResponseCollection {
  items: ICompanyHouseResponseItem[];
  items_per_page: number;
  kind: string;
  page_number: number;
  start_index: number;
  total_results: number;
}

export interface ICompanyHouseResponseModel {
  accounts: any;
  can_file: boolean;
  company_name: string;
  company_number: string;
  company_status: string;
  confirmation_statement: any;
  date_of_creation: string;
  etag: string;
  has_charges: boolean;
  has_insolvency_history: boolean;
  has_super_secure_pscs: boolean;
  jurisdiction: string;
  links: any;
  registered_office_address: any;
  registered_office_is_in_dispute: boolean;
  sic_codes: string[];
  type: string;
  undeliverable_registered_office_address: boolean;
}

export interface IFirmItemData {
  name: string;
  ehraid: number;
  legalForm: string;
  uid: string;
  address: {
    street: string;
    houseNumber?: string;
    town: string;
    country: string;
  };
}

interface IIndustryItem {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  isBlocked: boolean;
}

export interface IIndustryCollection {
  data: IIndustryItem[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export interface IOnboarding extends Components.Schemas.OnboardingDto {}
export interface ISkipOnboardingParams extends Components.Schemas.SkipStepDto, IRequestSuccess, IRequestFail {}

export enum EOnboardingStepStatus {
  New = 'new',
  InProgress = 'in_progress',
  Skipped = 'skipped',
  Completed = 'completed'
}

export interface IOnboardingStepsModel {
  step: EOnboardingSteps;
  kycStep?:
    | 'personalInformation'
    | 'idPrimary'
    | 'idSecondary'
    | 'shareholders'
    | 'kbis'
    | 'livenessCheck'
    | 'articlesOfAssociation'
    | 'fundManagementRegulations';
}

export interface ILoanRequirements extends Components.Schemas.LoanRequirementsDto {}
export interface IIndustryCollectionParams extends Paths.IndustryControllerList.QueryParameters {}
export interface IIndustryModel extends Components.Schemas.IndustryDto {}

export interface IBusinessInfoInitialValues {
  registrationCountry: 'CH' | 'UK';
  currency: 'CHF' | 'GBP';
  externalId: {
    disabled: boolean | undefined;
    key: string;
    label: string;
    value: number;
  };
  companyId: string;
  monthlyRevenue: string;
  industryId: string;
  annualProfits: string;
  state:
    | 'Z\u00FCrich'
    | 'Bern / Berne'
    | 'Luzern'
    | 'Uri'
    | 'Schwyz'
    | 'Unterwalden'
    | 'Glarus'
    | 'Zug'
    | 'Freiburg / Fribourg'
    | 'Solothurn'
    | 'Basel'
    | 'Schaffhausen'
    | 'Appenzell'
    | 'Sankt Gallen'
    | 'Graub\u00FCnden'
    | 'Aargau'
    | 'Thurgau'
    | 'Ticino'
    | 'Vaud'
    | 'Valais / Wallis'
    | 'Neuch\u00E2tel'
    | 'Gen\u00E8ve'
    | 'Jura';
  additionalInfo: string[];
}

export interface ILoanAppInfoByBorrowerInitialValues {
  amount: string;
  duration: string;
  purpose: string;
  purposeDescription: string | undefined;
}
