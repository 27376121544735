import React, { ChangeEvent } from 'react';
import { Input, Row, Select, Slider } from 'antd';
import i18n from 'i18n';
import ArrowDown from 'app/assets/icons/ArrowDown.svg';
import {
  durationFilterValues,
  interestRateFilterValues,
  ratingMarks
} from 'entities/User/components/Investor/LoanMarketpalce.consts';
import { IGetLoanAppCollectionParams } from 'entities/LoanApp/LoanApp.models';

interface IComponentState {
  amountFrom: string;
  amountTo: string;
  durationFilter: string;
  interestFilter: string;
  ownRiskFilter: any;
  industryFilter: string;
}

interface IComponentProps {
  updateFilter: (filter: Partial<IGetLoanAppCollectionParams>) => void;
  resetFilters: () => void;
}

type AllProps = IComponentProps;

class MarketPlaceFilterSectionComponent extends React.Component<AllProps, IComponentState> {
  state: IComponentState = {
    amountFrom: '',
    amountTo: '',
    durationFilter: durationFilterValues[0].value,
    interestFilter: interestRateFilterValues[0].value,
    ownRiskFilter: [0, 100],
    industryFilter: ''
  };

  render() {
    const { amountFrom, amountTo, durationFilter, interestFilter, ownRiskFilter, industryFilter } = this.state;
    const renderSelectOption = (item: any, index: number) => (
      <Select.Option value={item.value} key={index}>
        {item.name}
      </Select.Option>
    );

    return (
      <div className="marketplace__filter-section">
        <Row align="middle" justify="space-between" className="marketplace__filter-section__title">
          <span>{i18n.t<string>('headerMenuInvestor.filters')}</span>
          <span className="marketplace__filter-section__title-btn" onClick={this.onFiltersClear}>
            {i18n.t<string>('headerMenuInvestor.clearFilters')}
          </span>
        </Row>
        <span className="form-label">{i18n.t<string>('loanProps.amount')}</span>
        <Input.Group compact>
          <Input
            value={amountFrom}
            className="form-input mb-8"
            addonBefore={<span>From</span>}
            style={{ width: '50%' }}
            onChange={this.onChangeAmountFrom}
          />
          <Input
            value={amountTo}
            className="form-input"
            addonBefore={<span>To</span>}
            style={{ width: '50%' }}
            onChange={this.onChangeAmountTo}
          />
        </Input.Group>

        <span className="form-label">{i18n.t<string>('loanProps.duration')}</span>
        <Select
          value={durationFilter}
          className="form-select mb-8"
          getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
          suffixIcon={<img src={ArrowDown} alt="Arrow down" />}
          onChange={this.onChangeDuration}
        >
          {durationFilterValues.map(renderSelectOption)}
        </Select>

        <span className="form-label">{i18n.t<string>('loanProps.interestRate')}</span>
        <Select
          value={interestFilter}
          className="form-select mb-8"
          getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
          suffixIcon={<img src={ArrowDown} alt="Arrow down" />}
          onChange={this.onChangeInterestRate}
        >
          {interestRateFilterValues.map(renderSelectOption)}
        </Select>

        <span className="form-label">{i18n.t<string>('tableColumns.score')}</span>
        <Slider
          marks={ratingMarks}
          step={25}
          value={ownRiskFilter}
          dots
          range
          className="filter-slider score-filter-slider mb-8"
          tipFormatter={null}
          onChange={this.onScoreFilterChange}
        />

        <span className="form-label">{i18n.t<string>('companyDetails.industry')}</span>
        <Input className="form-input" value={industryFilter} onChange={this.onChangeIndustry} />
      </div>
    );
  }

  onChangeAmountFrom = (e: ChangeEvent<HTMLInputElement>) => {
    const { updateFilter } = this.props;
    const { value } = e.target;
    const modifiedValue = value.replace(/[^0-9]/g, '');

    this.setState({ amountFrom: modifiedValue });
    updateFilter({ amountFrom: valuesToNumber(modifiedValue) });
  };

  onChangeAmountTo = (e: ChangeEvent<HTMLInputElement>) => {
    const { updateFilter } = this.props;
    const { value } = e.target;
    const modifiedValue = value.replace(/[^0-9]/g, '');

    this.setState({ amountTo: modifiedValue });
    updateFilter({ amountTo: valuesToNumber(modifiedValue) });
  };

  onChangeDuration = (value: string) => {
    const { updateFilter } = this.props;
    const [from, to] = value.split(',');

    this.setState({ durationFilter: value });
    updateFilter({ durationTo: valuesToNumber(to), durationFrom: valuesToNumber(from) });
  };

  onChangeInterestRate = (value: string) => {
    const { updateFilter } = this.props;
    const [from, to] = value.split(',');

    this.setState({ interestFilter: value });
    updateFilter({ interestRateTo: valuesToNumber(to), interestRateFrom: valuesToNumber(from) });
  };

  onScoreFilterChange = (value: any) => {
    const { updateFilter } = this.props;
    const [from, to] = value;

    this.setState({ ownRiskFilter: value });
    updateFilter({ ownRiskClassTo: to || undefined, ownRiskClassFrom: from || undefined });
  };

  onChangeIndustry = (e: ChangeEvent<HTMLInputElement>) => {
    const { updateFilter } = this.props;
    const { value } = e.target;

    this.setState({ industryFilter: value });

    updateFilter({ industry: value || undefined });
  };

  onFiltersClear = () => {
    const { resetFilters } = this.props;

    this.setState({
      amountFrom: '',
      amountTo: '',
      durationFilter: '',
      interestFilter: '',
      ownRiskFilter: [0, 100],
      industryFilter: ''
    });

    resetFilters();
  };
}

export const MarketPlaceFilterSection = MarketPlaceFilterSectionComponent;

const valuesToNumber = (value: string): number | undefined => {
  if (!value) {
    return undefined;
  }

  return isNaN(Number(value)) ? undefined : Number(value);
};
