import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { EBorrowerRoutes, ECommonRoutes } from 'common/models/routes.model';
import { StepperLayout } from 'entities/Onboarding/components/StepperLayout';
import { LoansCreate } from 'entities/LoanApp/components/LoansCreate';
import { BorrowerLoanPage } from 'entities/LoanApp/components/BorrowerLoanPage';
import { BorrowerLoans } from 'entities/LoanApp/components/BorrowerLoans';
import { Transactions } from 'entities/Transactions/components/Transactions';
import { Wallet } from 'entities/Wallet/components/Wallet';
import { BorrowerLoanAddDocs } from 'entities/LoanDocuments/components/BorrowerLoanAddDocs';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EUserStatus } from 'entities/User/User.models';
import { KYCForm } from 'entities/KYCProvider/components/KYCForm';
import { Performance } from 'entities/Upswot/components/Performance';

type AllProps = IUsersConnectedProps;

const BorrowerRoutesComponent: React.FC<AllProps> = props => {
  const { userCurrent } = props;
  const { status } = userCurrent.data || {};
  const history = useHistory();

  useEffect(() => {
    const isOnbordingRoute = history.location.pathname === ECommonRoutes.Onboarding;

    if (status === EUserStatus.Onboarding && !isOnbordingRoute) {
      history.replace(ECommonRoutes.Onboarding);
    }

    if (isOnbordingRoute && status !== EUserStatus.Onboarding) {
      history.replace(ECommonRoutes.Main);
    }
  }, []);

  if (!status) {
    return null;
  }

  return (
    <Switch>
      <Route path={ECommonRoutes.Onboarding} component={StepperLayout} exact key={ECommonRoutes.Onboarding} />,
      <Route path={EBorrowerRoutes.KYC} component={KYCForm} exact key={EBorrowerRoutes.CreateLoan} />,
      <Route path={EBorrowerRoutes.CreateLoan} component={LoansCreate} exact key={EBorrowerRoutes.CreateLoan} />,
      <Route path={EBorrowerRoutes.LoanAddDocs} component={BorrowerLoanAddDocs} exact key={EBorrowerRoutes.LoanAddDocs} />,
      <Route path={EBorrowerRoutes.Loan} component={BorrowerLoanPage} exact key={EBorrowerRoutes.Loan} />,
      <Route path={EBorrowerRoutes.Performance} component={Performance} exact key={EBorrowerRoutes.Performance} />,
      <Route path={EBorrowerRoutes.Loans} component={BorrowerLoans} exact key={EBorrowerRoutes.Loans} />,
      <Route path={ECommonRoutes.Transactions} component={Transactions} exact key={ECommonRoutes.Transactions} />,
      <Route path={ECommonRoutes.Wallet} component={Wallet} exact key={ECommonRoutes.Wallet} />,
      <Route path={ECommonRoutes.Main} component={Performance} key={ECommonRoutes.Main} />,
    </Switch>
  );
};

export const BorrowerRoutes = communicationUser.injector(BorrowerRoutesComponent);
