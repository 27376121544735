import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import i18n from 'i18n';
import ArrowDown from 'app/assets/icons/ArrowDown.svg';

interface IComponentProps extends SelectProps {
  placeholder?: string;
  data: string[];
  disabled?: boolean;
  needsToTranslate?: boolean;
  onChange?: (value: string) => void;
}

type AllProps = IComponentProps;

class FormSelectComponent extends React.Component<AllProps> {
  render() {
    const { placeholder = '', data, disabled = false, needsToTranslate = false, ...rest } = this.props;

    return (
      <Select
        placeholder={i18n.t<string>(placeholder)}
        suffixIcon={<img src={ArrowDown} alt="Arrow down" />}
        disabled={disabled}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode}
        className="form-select"
        {...rest}
      >
        {data.map((item, index) => (
          <Select.Option value={item} key={index}>
            {needsToTranslate ? i18n.t<string>(item) : item}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export const FormSelect = FormSelectComponent;
