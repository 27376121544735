import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18n';
import { Button, Col, Form, Input, Row, FormInstance } from 'antd';
import { FormFooter } from 'common/components/Form/FormFooter';
import { AuthWidget } from 'common/components/AuthWidget';
import { ECommonRoutes } from 'common/models/routes.model';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';

type AllProps = IUsersConnectedProps;

class RestorePasswordFormComponent extends React.Component<AllProps> {
  formRef = React.createRef<FormInstance>();

  render() {
    const { userPasswordRestore } = this.props;
    const { loading } = userPasswordRestore;

    return (
      <AuthWidget title={i18n.t('form.restorePassTitle')}>
        <Form className="mt-6" ref={this.formRef} layout="vertical" onFinish={this.restorePassword}>
          <Form.Item
            label={i18n.t<string>('form.emailTitle')}
            required={false}
            name="email"
            rules={[
              {
                type: 'email',
                message: `${i18n.t('form.emailValidationError')}`
              },
              { required: true, message: `${i18n.t('form.emailError')}` }
            ]}
          >
            <Input type="e-mail" placeholder={`${i18n.t('form.emailTitle')}`} disabled={loading} className="form-input" />
          </Form.Item>
          <Form.Item>
            <Row align="middle" className="mt-2 mb-2" gutter={[24, 24]}>
              <Col>
                <Form.Item className="mb-0">
                  <Button type="primary" size="large" htmlType="submit" loading={loading} className="form-submit-button">
                    {i18n.t<string>('form.restore')}
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Link to={ECommonRoutes.LogIn} className="fs-xs">
                  {i18n.t<string>('form.tryToLogin')}
                </Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>
        <FormFooter />
      </AuthWidget>
    );
  }

  restorePassword = () => {
    const { addUserPasswordRestore } = this.props;
    const { validateFields } = this.formRef.current!;

    validateFields().then(values => {
      addUserPasswordRestore({ ...values });
    });
  };
}

export const RestorePassword = communicationUser.injector(RestorePasswordFormComponent);
