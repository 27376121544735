import {
  actionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { message } from 'antd';
import { put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import i18n from 'i18n';
import { EErrorStatus } from 'common/models/errors.model';
import { getCurrentUserId } from 'common/helpers/requestUser.helper';
import { paymentsTransport } from 'entities/Wallet/Wallet.transport';
import { IIbanModel, IOutflowTransferModel, INewIbanRequestModel } from 'entities/Wallet/Wallet.model';
import { EWalletTabs } from 'entities/Wallet/Wallet.const';

const namespace = 'payments';

export interface IPaymentsConnectedProps {
  paymentsIban: StoreBranch<IIbanModel, any, any>;
  paymentsNewIban: StoreBranch<INewIbanRequestModel, any, any>;
  paymentsOutflowTransfer: StoreBranch<IOutflowTransferModel, any, any>;

  getPaymentsIban(userId: string): void;
  addPaymentsNewIban(params: INewIbanRequestModel): void;
  addPaymentsOutflowTransfer(params: IOutflowTransferModel): void;
}

const ibanModelApiProvider = [new APIProvider(actionsTypes.get, paymentsTransport.getIbanModel)];

const newIbanApiProvider = [
  new APIProvider(actionsTypes.add, paymentsTransport.addNewIban, {
    postSuccessHook: function*(): any {
      const userId = yield getCurrentUserId();

      message.success(i18n.t<string>('withdrawalTab.ibanSuccessfullyAdded'));
      yield put(push({ search: `?tab=${EWalletTabs.Withdrawal}` }));

      if (userId) {
        yield put({
          type: getStartType(namespace, 'iban', actionsTypes.get),
          payload: userId
        });
      }
    },
    postFailHook: function(response: any) {
      const status = response?.status;

      if (status !== EErrorStatus.Validation) {
        message.error(response.data?.message);
      }
    }
  })
];

const outflowTrasferApiProvider = [
  new APIProvider(actionsTypes.add, paymentsTransport.addOutflowTransfer, {
    postSuccessHook: function*(): any {
      const userId = yield getCurrentUserId();

      message.success(i18n.t<string>('transferSuccess'));

      if (userId) {
        yield put({
          type: getStartType('user', 'balance', actionsTypes.get),
          payload: userId
        });
      }
    },
    clearParams: true
  })
];

const branches = [
  new Branch('iban', ibanModelApiProvider),
  new Branch('newIban', newIbanApiProvider),
  new Branch('outflowTransfer', outflowTrasferApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationPayments = buildCommunication<IPaymentsConnectedProps>(strategy);
