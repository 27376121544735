import axios from 'axios';
import { objectToQuery } from 'common/helpers/request.helper';
import {
  IZefixModel,
  IZefixResponseCollection,
  IZefixRequestBody,
  ICompanyModel,
  ICompanyCollection,
  ICompanyHouseRequestBody,
  ICompanyHouseResponseCollection,
  ICompanyHouseResponseModel,
  IIndustryCollection,
  IOnboarding,
  ICompanyCreateParams,
  ISkipOnboardingParams,
  IIndustryCollectionParams
} from 'entities/Onboarding/OnboardingSteps.models';
import { ILoanAppCreateModel } from 'entities/LoanApp/LoanApp.models';

const basePath = '/onboarding';
const zefixPath = '/zefix';
const companiesPath = '/companies';
const companiesHousePath = '/companies-house';
const industriesPath = '/industries';

export const onboardingTransport = {
  getZefixModel: (id: number | string): Promise<IZefixModel> => axios.get(`${zefixPath}/${id}`).then(r => r.data),
  getByUserZefixModel: (userId: string): Promise<IZefixModel> => axios.get(`${zefixPath}/by-user/${userId}`).then(r => r.data),
  getZefixCollection: (params: IZefixRequestBody): Promise<IZefixResponseCollection[]> =>
    axios.get(`${zefixPath}/${objectToQuery(params)}`).then(r => r.data.data),
  getOnboarding: (userId: string): Promise<IOnboarding> => axios.get(`${basePath}/${userId}`).then(r => r.data),
  skipOnboarding: (params: ISkipOnboardingParams): Promise<IOnboarding> =>
    axios.post(`${basePath}/skip-step`, params).then(r => r.data),
  addOnboardingCompany: (params: ICompanyCreateParams): Promise<IOnboarding> =>
    axios.post(`${basePath}/company-step`, params).then(r => r.data),
  addOnboardingLoan: (params: ILoanAppCreateModel): Promise<IOnboarding> =>
    axios.post(`${basePath}/loan-step`, params).then(r => r.data),
  getCompanyModel: (id: string): Promise<ICompanyModel> => axios.get(`${companiesPath}/${id}`).then(r => r.data),
  getCompanyCollection: (): Promise<ICompanyCollection> => axios.get(`${companiesPath}`).then(r => r.data),
  getCompaniesHouseCollection: (params: ICompanyHouseRequestBody): Promise<ICompanyHouseResponseCollection> =>
    axios.get(`${companiesHousePath}/${objectToQuery(params)}`).then(r => r.data),
  getCompaniesHouseModel: (id: string): Promise<ICompanyHouseResponseModel> =>
    axios.get(`${companiesHousePath}/${id}`).then(r => r.data),
  getIndustryCollection: (params: IIndustryCollectionParams): Promise<IIndustryCollection> =>
    axios.get(`${industriesPath}`, { params }).then(r => r.data)
};
