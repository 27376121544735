export const objectToQuery = (obj: any) => {
  let params = '';
  if (obj && typeof obj === 'object') {
    const mappedParams = Object.keys(obj)
      .filter(k => {
        return !(obj[k] === '' || obj[k] === false || obj[k] === undefined);
      })
      .map(k => {
        if (obj[k] === true) {
          obj[k] = '1';
        } else if (Array.isArray(obj[k])) {
          return obj[k].map((i: any) => {
            if (i) {
              return `${encodeURIComponent(k)}=${encodeURIComponent(i)}`;
            }
            return '';
          });
        }
        return `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`;
      });
    const flatParams = Array.prototype.concat(...mappedParams);
    params = `?${flatParams.join('&')}`;
  } else if (obj !== undefined && obj !== null) {
    params = obj;
  }
  return params;
};
