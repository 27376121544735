import React from 'react';
import { Col, Row } from 'antd';

interface IComponentProps {
  className?: string;
  children: React.ReactNode;
}

export class LayoutBasic extends React.Component<IComponentProps> {
  render() {
    const { children, className } = this.props;
    return (
      <Row justify="center" align="middle" className={className}>
        <Col span={20}>{children}</Col>
      </Row>
    );
  }
}
