import React from 'react';

interface IComponentProps {
  children: React.ReactNode;
  header: string | JSX.Element;
  className?: string;
}

export const InfoSection: React.FC<IComponentProps> = props => {
  const { header, className, children } = props;
  return (
    <div className={`info-section ${className}`}>
      <h3 className="info-section__header">{header}</h3>
      {children}
    </div>
  );
};
