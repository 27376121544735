import axios from 'axios';
import moment from 'moment';
import { IKYCStatus, IKYCAssignData } from 'entities/KYCProvider/KYCProvider.models';

const basePath = '/kyc';

export const kycTransport = {
  getKYCStatus: (id: string): Promise<IKYCStatus> => axios.get(`${basePath}/${id}/status`).then(r => r.data),
  assignTypeToFile: (params: IKYCAssignData): Promise<IKYCAssignData> => {
    const mappedParams = JSON.parse(JSON.stringify(params));
    const dob = mappedParams.items[0].meta?.personalInformation?.birthday;

    if (dob) {
      mappedParams.items[0].meta.personalInformation.birthday = moment(dob).format('YYYY-MM-DD');
    }

    return axios.post(`${basePath}/assign`, mappedParams).then(r => r.data);
  },
  addKYCSubmit: (): Promise<any> => axios.post(`${basePath}/submit`).then(r => r.data)
};
