import React from 'react';
import { Row } from 'antd';
import { cutFileName } from 'common/helpers/fileLoader.helper';

interface IComponentProps {
  name: string;
  id: string;
  removeFile: (id?: string) => void;
  disabled: boolean;
}

export const LoadedFileField = (props: IComponentProps) => {
  const { name, id, removeFile, disabled } = props;

  return (
    <Row justify="space-between" align="middle" className="loaded-file-field">
      <Row align="middle">
        <span className="mr-2">✓</span>
        <span>{cutFileName(name)}</span>
      </Row>
      {!disabled && (
        <span onClick={() => removeFile(id)} className="pointer">
          ✕
        </span>
      )}
    </Row>
  );
};
