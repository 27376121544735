import React from 'react';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';
import { Transactions } from 'entities/Transactions/components/Transactions';

export const SpecialistTransactions = () => {
  return (
    // TODO: Incorrect values and currency
    <LayoutBasic className="blue-layout pt-10 pb-10">
      <Transactions />
    </LayoutBasic>
  );
};
