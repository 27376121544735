import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import i18n from 'i18n';
import { Button, Card, Form, Row, message, Col, FormInstance } from 'antd';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { DataCard } from 'common/components/Card/DataCard';
import { Popup } from 'common/components/Popup';
import { PasswordInput } from 'common/components/Form/PasswordInput';
import { PersonalDetails } from 'common/components/PersonalDetails';
import { mapBEErrorsToFields } from 'common/helpers/errors.helper';
import { BankDetails } from 'common/components/BankDetails';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { userTransport } from 'entities/User/User.transport';

interface IComponentState {
  showPopup: boolean;
  popupLoading: boolean;
}

type AllProps = IUsersConnectedProps & RouteComponentProps;

class ProfileComponent extends React.Component<AllProps, IComponentState> {
  state = { showPopup: false, popupLoading: false };

  formRef = React.createRef<FormInstance>();

  render() {
    const { userCurrent } = this.props;
    const { showPopup, popupLoading } = this.state;
    const { data: userData, loading } = userCurrent;

    if (!userData || loading) {
      return <LoadingSpinner />;
    }

    // @ts-ignore Need BE fix
    const { profile, currency } = userData;

    return (
      <LayoutBasic className="blue-layout">
        <Row justify="center" className="profile__title">
          {i18n.t<string>('headerProfile')}
        </Row>
        <LayoutBasic>
          <BankDetails userId={userData?.id} />

          {profile && <PersonalDetails profile={profile} email={userData.email} currency={currency} />}

          <Card className="bordered-card mb-6">
            <Row justify="center">
              <Button className="change-pass-button" type="primary" onClick={this.togglePopup}>
                {i18n.t<string>('changeMyPassword')}
              </Button>
            </Row>
            <DataCard
              mainClassName="change-password-card"
              descriptionClassName="change-password-card__description"
              header={i18n.t('profileChangeDetails')}
              description={i18n.t('profileChangeDetailsDescription')}
            />
          </Card>
          <Popup title={i18n.t<string>('changeYourPassword')} visible={showPopup} onCancel={this.togglePopup} maxWidth="540px">
            <Form ref={this.formRef} layout="vertical" onFinish={this.onSubmit}>
              <PasswordInput
                label={i18n.t<string>('form.oldPassword')}
                name="oldPassword"
                requiredError={i18n.t('errors:password.oldRequired')}
              />
              <PasswordInput
                label={i18n.t<string>('form.newPassword')}
                name="password"
                requiredError={i18n.t('errors:password.newRequired')}
                passwordCheck
              />
              <PasswordInput
                label={i18n.t<string>('form.repeatNewPassword')}
                name="passwordConfirm"
                requiredError={i18n.t('errors:password.confirmRequired')}
                validator={this.comparePassword}
              />
              <Row justify="space-between" gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item>
                    <Button className="mt-6 submit-button button-secondary" type="default" onClick={this.togglePopup}>
                      {i18n.t<string>('cancel')}
                    </Button>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <Form.Item>
                    <Button
                      className="mt-6 submit-button"
                      type="primary"
                      htmlType="submit"
                      loading={popupLoading}
                      disabled={popupLoading}
                    >
                      {i18n.t<string>('form.submit')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Popup>
        </LayoutBasic>
      </LayoutBasic>
    );
  }

  togglePopup = () => {
    this.setState(prevState => ({ showPopup: !prevState.showPopup }));
    this.formRef.current! && this.formRef.current!.resetFields();
  };

  comparePassword = (rule: any, value: string) => {
    if (value !== this.formRef.current!.getFieldValue('password')) {
      return Promise.reject(`${i18n.t('errors:password.match')}`);
    }
    return Promise.resolve();
  };

  onSubmit = () => {
    const { userCurrent } = this.props;
    const { data: user } = userCurrent;
    const { validateFields } = this.formRef.current!;

    validateFields().then(values => {
      if (user) {
        const { password, oldPassword } = values;
        this.setState({ popupLoading: true });

        userTransport.updateUserPassword(
          user.id,
          { password, oldPassword },
          () => {
            message.success(i18n.t<string>('passwordChanged'));
            this.togglePopup();
          },
          error => {
            if (error.status === 403 && error.data.message === 'error.userInvalidCredentials') {
              mapBEErrorsToFields(this.formRef.current!, error.data.errors);
              return;
            }

            if (error?.data?.errors) {
              mapBEErrorsToFields(this.formRef.current!, error.data.errors);
            }
          }
        );
        this.setState({ popupLoading: false });
      }
    });
  };
}

export const Profile = communicationUser.injector(withRouter(ProfileComponent));
