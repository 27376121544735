import { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import { FILES_SIZE_LIMIT_MAX } from 'common/config';

export function getBase64(file: File, callback: (param: string | ArrayBuffer | null) => void) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(file);
}

export const checkFileSize = (file: UploadFile) => file.size && file.size / 1024 / 1024 <= FILES_SIZE_LIMIT_MAX;

function b64toFile(b64Data: string, contentType: string = '', sliceSize: number = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  const fileExt = contentType.split('/')[1];
  const fileName = `selfie_${moment().format('DD-MM-YYYY_HH:mm')}.${fileExt}`;

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new File(byteArrays, fileName, { type: contentType });
}

export function imageToFile(ImgSrc: string) {
  const block = ImgSrc.split(';');
  const contentType = block[0].split(':')[1];
  const realData = block[1].split(',')[1];

  return b64toFile(realData, contentType);
}

export const cutFileName = (fileName: string) =>
  fileName.replace(/^(.{15})(.+?)(.{7})$/, (m, p1, p2, p3) => [p1, '...', p3].join(''));
