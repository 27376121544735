import React from 'react';
import { Card, Row } from 'antd';

interface IComponentProps {
  title: string | JSX.Element;
  content?: string | JSX.Element;
  children: React.ReactNode;
}

export const DashboardInfoCard: React.FC<IComponentProps> = props => {
  const { title, content, children } = props;

  return (
    <div className="dashboard-card">
      <Row align="middle" className="dashboard-card__title">
        {title}
      </Row>
      <Card className="dashboard-card__content" bordered={false}>
        {content || children}
      </Card>
    </div>
  );
};
