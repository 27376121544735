import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UIErrorHandler } from 'common/components/UIErrorHandler';
import { Header } from 'common/components/Header/Header';
import { ECommonRoutes } from 'common/models/routes.model';
import { PrivateLayout } from 'common/components/Layouts/PrivateLayout';
import { BackLayout } from 'common/components/Layouts/BackLayout';
import { PublicLayout } from 'common/components/Layouts/PublicLayout';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EmailConfirmation } from 'entities/Auth/components/EmailConfirmation';
import { CreatePassword } from 'entities/Auth/components/CreatePassword';
import { SignUpConfirmPage } from 'entities/Auth/components/SignUpConfirmPage';
import { AuthError } from 'entities/Auth/components/AuthError';
import { EUserRoles, EUserStatus } from 'entities/User/User.models';

type AllProps = RouteComponentProps & WithTranslation & IAuthConnectedProps & IUsersConnectedProps;

class App extends React.Component<AllProps> {
  constructor(props: AllProps) {
    super(props);
    this.props.initAuthModel();
  }

  render() {
    const { userCurrent, authModel } = this.props;
    const { data: authData } = authModel;
    const { data: userData } = userCurrent;
    const authorized = authData?.access;
    const needOnboarding =
      (userData?.role === EUserRoles.Borrower && userData?.status === EUserStatus.Onboarding) ||
      (userData?.role === EUserRoles.Investor &&
        (userData?.status === EUserStatus.Onboarding || userData?.status === EUserStatus.Unconfirmed));
    const side = !authorized || (authorized && needOnboarding) ? 'outside' : 'inside';

    return (
      <UIErrorHandler>
        <BackLayout side={side}>
          <Header />
          <Switch>
            <Route path={ECommonRoutes.UserConfirm} component={SignUpConfirmPage} />
            <Route path={ECommonRoutes.AuthError} component={AuthError} exact />
            <Route path={ECommonRoutes.CreatePass} component={CreatePassword} />
            <Route path={ECommonRoutes.EmailConfirm} component={EmailConfirmation} exact />
            <Route path={ECommonRoutes.SignUp} key={ECommonRoutes.SignUp} component={PublicLayout} exact />,
            <Route path={ECommonRoutes.LogIn} key={ECommonRoutes.LogIn} component={PublicLayout} exact />
            <Route path={ECommonRoutes.RestorePass} component={PublicLayout} exact />
            <Route path="*" component={PrivateLayout} />
          </Switch>
        </BackLayout>
      </UIErrorHandler>
    );
  }
}

export default communicationAuth.injector(communicationUser.injector(withRouter(withTranslation()(App))));
