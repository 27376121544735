import React from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import i18n from 'i18n';
import { EDateTimeFormats } from 'common/consts';
import { ReactComponent as Coins } from 'app/assets/icons/Coins.svg';
import { ReactComponent as Hammer } from 'app/assets/icons/Hammer.svg';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { AmountCard } from 'entities/LoanApp/components/AmountCard';

type AllType = ILoanAppConnectedProps;

const DefaultInfoComponent: React.FC<AllType> = props => {
  const { loanAppModel, loanAppRepaymentDetails } = props;
  const { data: repaymentData } = loanAppRepaymentDetails;
  const { data: appModel } = loanAppModel;

  return (
    <div className="default-info">
      <Trans
        i18nKey="dateOfTheDefaulting"
        values={{ date: moment(appModel?.closedAt).format(EDateTimeFormats.FullDayShortMonthFullYear) }}
      >
        Date of the defaulting:
        <span className="ff-bold">{moment(appModel?.closedAt).format(EDateTimeFormats.FullDayShortMonthFullYear)}</span>
      </Trans>

      <div className="default-info__amount-info">
        <AmountCard
          icon={<Coins />}
          title={i18n.t<string>('loanProps.repaymentAmount')}
          amount={repaymentData?.repaymentAmount}
        />

        <AmountCard icon={<Hammer />} title={i18n.t<string>('loanProps.fineAmount')} amount={repaymentData?.penaltiesTotal} />
      </div>
    </div>
  );
};

export const DefaultInfo = communicationLoanApp.injector(DefaultInfoComponent);
