import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { DataCard } from 'common/components/Card/DataCard';
import PerformanceImg from 'app/assets/images/Performance.svg';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { communicationUpswot, IUpswotConnectedProps } from 'entities/Upswot/Upswot.communication';

type AllProps = IUsersConnectedProps & IUpswotConnectedProps;

function PerformanceComponent(props: AllProps) {
  const { userCurrent, upswotModel } = props;
  const { loading, data: upswotData } = upswotModel;
  const { id: userId, profile } = userCurrent.data || {};
  const [mode, setMode] = useState<undefined | 'create' | 'view'>();
  const { t } = useTranslation();

  useEffect(() => {
    const { getUpswotModel } = props;

    userId &&
      getUpswotModel({
        userId,
        onSuccess: () => {
          setMode('view');
        },
        onFail: () => {
          setMode('create');
        }
      });
  }, [userId]);

  if (mode === 'create') {
    return (
      <div className="application-info application-info_with-bg">
        <Row gutter={32}>
          <Col xs={{ span: 24, order: 2 }} lg={{ span: 14, order: 1 }} xl={13}>
            {profile?.firstName && (
              <div className="fs-xxxlg">
                {t('borrowerLoans.transparencyCreate.header1')} {profile.firstName}
              </div>
            )}
            <DataCard
              mainClassName="borderless"
              header={t('borrowerLoans.transparencyCreate.header2')}
              description={t('borrowerLoans.transparencyCreate.description')}
            />

            <div className="mt-12">
              <Button
                className="form-submit-button"
                loading={loading}
                onClick={() => {
                  const { addUpswotModel } = props;

                  userId &&
                    addUpswotModel({
                      userId,
                      onSuccess: () => {
                        setMode('view');
                      }
                    });
                }}
                disabled
              >
                {t('borrowerLoans.transparencyCreate.comingSoon')}
              </Button>
            </div>
          </Col>

          <Col xs={{ span: 24, order: 1 }} lg={{ span: 10, order: 2 }} xl={11} className="info-image-container">
            <img className="application-info__image" src={PerformanceImg} alt="Performance" />
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <LayoutBasic className="pt-10">
      {loading && <LoadingSpinner />}

      {mode === 'view' && upswotData?.url && (
        <iframe className="b-none iframe-h-full" src={upswotData.url} width="100%" title="Performance dashboard from upswot" />
      )}
    </LayoutBasic>
  );
}

export const Performance = communicationUser.injector(communicationUpswot.injector(PerformanceComponent));
