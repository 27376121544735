export const phoneCodes = [
  { code: 93, country: 'Afghanistan' },
  { code: 355, country: 'Albania' },
  { code: 213, country: 'Algeria' },
  { code: 684, country: 'American Samoa' },
  { code: 376, country: 'Andorra' },
  { code: 244, country: 'Angola' },
  { code: 268, country: 'Antigua / Swaziland' },
  { code: 54, country: 'Argentina' },
  { code: 374, country: 'Armenia' },
  { code: 297, country: 'Aruba' },
  { code: 247, country: 'Ascension Island' },
  { code: 61, country: 'Australia' },
  { code: 672, country: 'Australian External Territories' },
  { code: 43, country: 'Austria' },
  { code: 994, country: 'Azerbaijan' },
  { code: 242, country: 'Bahamas / Congo' },
  { code: 973, country: 'Bahrain' },
  { code: 880, country: 'Bangladesh' },
  { code: 375, country: 'Belarus' },
  { code: 32, country: 'Belgium' },
  { code: 501, country: 'Belize' },
  { code: 229, country: 'Benin' },
  { code: 975, country: 'Bhutan' },
  { code: 284, country: 'British Virgin Islands' },
  { code: 591, country: 'Bolivia' },
  { code: 387, country: 'Bosnia and Hercegovina' },
  { code: 267, country: 'Botswana' },
  { code: 55, country: 'Brazil' },
  { code: 673, country: 'Brunei Darussalm' },
  { code: 359, country: 'Bulgaria' },
  { code: 226, country: 'Burkina Faso' },
  { code: 257, country: 'Burundi' },
  { code: 237, country: 'Cameroon' },
  { code: 1, country: 'USA / Canada / Caribbean Nations' },
  { code: 238, country: 'CapeVerde Islands / Cape Verdi' },
  { code: 345, country: 'Cayman Islands' },
  { code: 236, country: 'Central African Republic' },
  { code: 235, country: 'Chad' },
  { code: 56, country: 'Chile' },
  { code: 86, country: "China (People's Republic)" },
  { code: 886, country: 'China-Taiwan / Taiwan' },
  { code: 57, country: 'Colombia' },
  { code: 269, country: 'Comoros and Mayotte / Mayolte' },
  { code: 682, country: 'Cook Islands' },
  { code: 506, country: 'Costa Rica' },
  { code: 385, country: 'Croatia' },
  { code: 53, country: 'Cuba' },
  { code: 357, country: 'Cyprus' },
  { code: 420, country: 'Czech Republic' },
  { code: 45, country: 'Denmark' },
  { code: 246, country: 'Diego Garcia / Barbados' },
  { code: 767, country: 'Dominca' },
  { code: 809, country: 'Dominican Republic / Bermuda / Anguilla' },
  { code: 253, country: 'Djibouti' },
  { code: 593, country: 'Ecuador' },
  { code: 20, country: 'Egypt' },
  { code: 503, country: 'El Salvador' },
  { code: 240, country: 'Equatorial Guinea' },
  { code: 291, country: 'Eritrea' },
  { code: 372, country: 'Estonia' },
  { code: 251, country: 'Ethiopia' },
  { code: 500, country: 'Falkland Islands' },
  { code: 298, country: 'Faroe (Faeroe) Islands (Denmark)' },
  { code: 679, country: 'Fiji' },
  { code: 358, country: 'Finland' },
  { code: 33, country: 'France / Monaco' },
  { code: 594, country: 'French Guiana' },
  { code: 241, country: 'Gabon (Gabonese Republic)' },
  { code: 220, country: 'Gambia' },
  { code: 995, country: 'Georgia' },
  { code: 49, country: 'Germany' },
  { code: 233, country: 'Ghana' },
  { code: 350, country: 'Gibraltar' },
  { code: 30, country: 'Greece' },
  { code: 299, country: 'Greenland' },
  { code: 473, country: 'Grenada / Carricou / Montserrat' },
  { code: 671, country: 'Guam' },
  { code: 502, country: 'Guatemala' },
  { code: 224, country: 'Guinea' },
  { code: 245, country: 'Guinea-Bissau' },
  { code: 592, country: 'Guyana' },
  { code: 509, country: 'Haiti' },
  { code: 504, country: 'Honduras' },
  { code: 852, country: 'Hong Kong' },
  { code: 36, country: 'Hungary' },
  { code: 354, country: 'Iceland' },
  { code: 91, country: 'India' },
  { code: 62, country: 'Indonesia' },
  { code: 98, country: 'Iran' },
  { code: 964, country: 'Iraq' },
  { code: 353, country: 'Ireland (Irish Republic; Eire)' },
  { code: 972, country: 'Israel' },
  { code: 39, country: 'Italy / Vatican City' },
  { code: 225, country: "Ivory Coast (La Cote d'Ivoire)" },
  { code: 876, country: 'Jamaica' },
  { code: 81, country: 'Japan' },
  { code: 962, country: 'Jordan' },
  { code: 254, country: 'Kenya' },
  { code: 855, country: 'Khmer Republic (Cambodia/Kampuchea)' },
  { code: 686, country: 'Kiribati Republic (Gilbert Islands)' },
  { code: 82, country: 'South Korea' },
  { code: 850, country: 'North Korea' },
  { code: 965, country: 'Kuwait' },
  { code: 996, country: 'Kyrgyz Republic' },
  { code: 371, country: 'Latvia' },
  { code: 856, country: 'Laos' },
  { code: 961, country: 'Lebanon' },
  { code: 266, country: 'Lesotho' },
  { code: 231, country: 'Liberia' },
  { code: 370, country: 'Lithuania' },
  { code: 218, country: 'Libya' },
  { code: 423, country: 'Liechtenstein' },
  { code: 352, country: 'Luxembourg' },
  { code: 853, country: 'Macao' },
  { code: 389, country: 'Macedonia' },
  { code: 261, country: 'Madagascar' },
  { code: 265, country: 'Malawi' },
  { code: 60, country: 'Malaysia' },
  { code: 960, country: 'Maldives' },
  { code: 223, country: 'Mali' },
  { code: 356, country: 'Malta' },
  { code: 692, country: 'Marshall Islands' },
  { code: 596, country: 'Martinique (French Antilles)' },
  { code: 222, country: 'Mauritania' },
  { code: 230, country: 'Mauritius' },
  { code: 52, country: 'Mexico' },
  { code: 691, country: 'Micronesia (F.S. of Polynesia)' },
  { code: 373, country: 'Moldova' },
  { code: 976, country: 'Mongolia' },
  { code: 212, country: 'Morocco' },
  { code: 258, country: 'Mozambique' },
  { code: 95, country: 'Myanmar (former Burma)' },
  { code: 264, country: 'Namibia (former South-West Africa)' },
  { code: 674, country: 'Nauru' },
  { code: 977, country: 'Nepal' },
  { code: 31, country: 'Netherlands' },
  { code: 599, country: 'Netherlands Antilles' },
  { code: 687, country: 'New Caledonia' },
  { code: 64, country: 'New Zealand' },
  { code: 505, country: 'Nicaragua' },
  { code: 227, country: 'Niger' },
  { code: 234, country: 'Nigeria' },
  { code: 683, country: 'Niue' },
  { code: 1670, country: 'North Mariana Islands (Saipan)' },
  { code: 47, country: 'Norway' },
  { code: 968, country: 'Oman' },
  { code: 92, country: 'Pakistan' },
  { code: 680, country: 'Palau' },
  { code: 507, country: 'Panama' },
  { code: 675, country: 'Papua New Guinea' },
  { code: 595, country: 'Paraguay' },
  { code: 51, country: 'Peru' },
  { code: 63, country: 'Philippines' },
  { code: 48, country: 'Poland' },
  { code: 351, country: 'Portugal (includes Azores)' },
  { code: 1787, country: 'Puerto Rico' },
  { code: 974, country: 'Qatar' },
  { code: 262, country: 'Reunion (France)' },
  { code: 40, country: 'Romania' },
  { code: 7, country: 'Russia' },
  { code: 250, country: 'Rwanda (Rwandese Republic)' },
  { code: 670, country: 'Saipan' },
  { code: 378, country: 'San Marino' },
  { code: 239, country: 'Sao Tome and Principe' },
  { code: 966, country: 'Saudi Arabia' },
  { code: 221, country: 'Senegal' },
  { code: 381, country: 'Serbia and Montenegro / Yugoslavia' },
  { code: 248, country: 'Seychelles' },
  { code: 232, country: 'Sierra Leone' },
  { code: 65, country: 'Singapore' },
  { code: 421, country: 'Slovakia' },
  { code: 386, country: 'Slovenia' },
  { code: 677, country: 'Solomon Islands' },
  { code: 252, country: 'Somalia' },
  { code: 27, country: 'South Africa' },
  { code: 34, country: 'Spain' },
  { code: 94, country: 'Sri Lanka' },
  { code: 290, country: 'St. Helena' },
  { code: 869, country: 'St. Kitts / Nevis' },
  { code: 508, country: 'Saint-Pierre and Miquelon (France)' },
  { code: 249, country: 'Sudan' },
  { code: 597, country: 'Suriname' },
  { code: 46, country: 'Sweden' },
  { code: 41, country: 'Switzerland' },
  { code: 963, country: 'Syrian Arab Republic (Syria)' },
  { code: 689, country: 'Tahiti (French Polynesia)' },
  { code: 255, country: 'Tanzania (includes Zanzibar)' },
  { code: 66, country: 'Thailand' },
  { code: 228, country: 'Togo (Togolese Republic)' },
  { code: 690, country: 'Tokelau' },
  { code: 676, country: 'Tonga' },
  { code: 1868, country: 'Trinidad and Tobago' },
  { code: 216, country: 'Tunisia' },
  { code: 90, country: 'Turkey' },
  { code: 993, country: 'Turkmenistan' },
  { code: 688, country: 'Tuvalu (Ellice Islands)' },
  { code: 256, country: 'Uganda' },
  { code: 380, country: 'Ukraine' },
  { code: 971, country: 'United Arab Emirates' },
  { code: 44, country: 'United Kingdom' },
  { code: 598, country: 'Uruguay' },
  { code: 678, country: 'Vanuatu (New Hebrides)' },
  { code: 58, country: 'Venezuela' },
  { code: 84, country: 'Viet Nam' },
  { code: 1340, country: 'Virgin Islands' },
  { code: 681, country: 'Wallis and Futuna' },
  { code: 685, country: 'Western Samoa' },
  { code: 967, country: 'Yemen Arab Republic (North Yemen)' },
  { code: 243, country: 'Zaire' },
  { code: 260, country: 'Zambia' },
  { code: 263, country: 'Zimbabwe' }
];
