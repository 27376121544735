import { actionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { EErrorStatus } from 'common/models/errors.model';
import { RequestLoadingHelper } from 'common/helpers/RequestLoading.helper';
import { upswotTransport } from 'entities/Upswot/Upswot.transport';
import { IAddUpswotModel, IGetUpswotModel, ITransparencyModel, IUpswotModel } from 'entities/Upswot/Upswot.models';

const namespace = 'upswot';

export interface IUpswotConnectedProps {
  upswotModel: StoreBranch<IUpswotModel>;
  getUpswotModel(params: IGetUpswotModel): void;
  addUpswotModel(params: IAddUpswotModel): void;

  upswotTransparency: StoreBranch<ITransparencyModel>;
  getUpswotTransparency(userId: string): void;
  clearUpswotTransparency(): void;
}

const upswotModelApiProvider = [
  new APIProvider(actionsTypes.get, upswotTransport.getByUser, {
    postSuccessHook: (response, payload) => {
      payload?.onSuccess && payload.onSuccess();
    },
    postFailHook: (response, payload) => {
      if (response?.status === EErrorStatus.NotFound) {
        payload?.onFail && payload.onFail();
      }
    }
  }),
  new APIProvider(actionsTypes.add, upswotTransport.add, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    postSuccessHook: (response, payload) => {
      payload?.onSuccess && payload.onSuccess();
    }
  })
];

const transparencyApiProvider = [new APIProvider(actionsTypes.get, upswotTransport.getTransparency)];

const branches = [new Branch('model', upswotModelApiProvider), new Branch('transparency', transparencyApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationUpswot = buildCommunication<IUpswotConnectedProps>(strategy);
