import React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import { ECommonRoutes, ESpecialistRoutes } from 'common/models/routes.model';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EUserRoles } from 'entities/User/User.models';
import { ELoanListTabs } from 'entities/LoanApp/LoanApp.const';

interface IComponentProps {
  children: React.ReactNode;
}

type AllProps = IAuthConnectedProps & IUsersConnectedProps & RouteComponentProps & IComponentProps;

class SpecialistLayoutComponent extends React.Component<AllProps> {
  render() {
    const { authModel, userCurrent, children, history } = this.props;
    const { loading: authLoading } = authModel;
    const { loading: userLoading } = userCurrent;
    const userRole = userCurrent.data?.role;
    const loading = authLoading || userLoading;
    const { location } = history;

    const path =
      location.pathname !== ECommonRoutes.Main
        ? location
        : { pathname: ESpecialistRoutes.Loans, search: `?tab=${ELoanListTabs.New}` };

    if (userRole === EUserRoles.Specialist && !loading) {
      return children ? children : <Redirect to={path} />;
    }
    return null;
  }
}

const containers = compose(withRouter, communicationAuth.injector, communicationUser.injector);

export const SpecialistLayout = containers(SpecialistLayoutComponent);
