import { actionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { dashboardTransport } from 'entities/Dashboard/Dashboard.transport';
import { IDashboardModel } from 'entities/Dashboard/Dashboard.model';

const namespace = 'dashboard';

export interface IDashboardConnectedProps {
  dashboardModel: StoreBranch<IDashboardModel>;
  addDashboardModel(userId: string): void;
}

const dashboardModelApiProvider = [new APIProvider(actionsTypes.add, dashboardTransport.addDashboardModel)];

const branches = [new Branch('model', dashboardModelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationDashboard = buildCommunication<IDashboardConnectedProps>(strategy);
