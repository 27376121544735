import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';

export const LoadingLoanAppPage = () => {
  return (
    <LayoutBasic className="pt-10 pb-10 white-layout">
      <Row justify="center">
        <Col span={20}>
          <div className="bordered-card p-10 mb-5">
            <Skeleton active paragraph={{ rows: 2 }} />
          </div>
          <div className="bordered-card p-10 mb-5">
            <Skeleton active paragraph={{ rows: 6 }} />
          </div>
          <div className="bordered-card p-10 mb-5">
            <Skeleton active paragraph={{ rows: 4 }} />
          </div>
        </Col>
      </Row>
    </LayoutBasic>
  );
};
