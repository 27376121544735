import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';

export const LoadingInvestPageForInvestor = () => {
  return (
    <LayoutBasic className="pt-10 pb-10 white-layout">
      <Row gutter={24} justify="center">
        <Col xl={24}>
          <Skeleton active paragraph={{ rows: 0 }} className="bordered-card p-5 mb-6" />
        </Col>
        <Col md={24} xl={16}>
          <Skeleton active paragraph={{ rows: 8 }} className="bordered-card p-5 mb-6" />
        </Col>
        <Col md={24} xl={8}>
          <Skeleton active paragraph={{ rows: 12 }} className="bordered-card p-5 mb-6" />
        </Col>
      </Row>
    </LayoutBasic>
  );
};
