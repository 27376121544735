import {
  actionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from '@redux-saga/core/effects';
import { RequestLoadingHelper } from 'common/helpers/RequestLoading.helper';
import { loanDocumentsTransport } from 'entities/LoanDocuments/LoanDocuments.transport';
import {
  ILoanDocRequest,
  ILoanDocRequestAddParams,
  ILoanDocRequestUpdateParams
} from 'entities/LoanDocuments/LoanDocuments.models';

const namespace = 'loanDocuments';

export interface ILoanDocumentsConnectedProps {
  loanDocumentsModel: StoreBranch<ILoanDocRequest>;
  addLoanDocumentsModel(params: ILoanDocRequestAddParams): void;
  updateLoanDocumentsModel(params: ILoanDocRequestUpdateParams): void;

  loanDocumentsCollection: StoreBranch<ILoanDocRequest[]>;
  getLoanDocumentsCollection(loanId: string): void;
}

const modelApiProvider = [
  new APIProvider(actionsTypes.add, loanDocumentsTransport.addDocumentRequest, {
    postSuccessHook: function*(response: ILoanDocRequest, payload: ILoanDocRequestAddParams) {
      yield put({ type: getStartType('loanApp', 'model', actionsTypes.get), payload: { id: payload.loanApplicationId } });
    }
  }),
  new APIProvider(actionsTypes.update, loanDocumentsTransport.updateDocumentRequest, {
    preRequestDataMapper: RequestLoadingHelper.setOldData,
    postSuccessHook: function*(response: ILoanDocRequest, payload: ILoanDocRequestUpdateParams) {
      yield put({ type: getStartType('loanApp', 'model', actionsTypes.get), payload: { id: payload.loanId } });
    }
  })
];

const collectionApiProvider = [new APIProvider(actionsTypes.get, loanDocumentsTransport.getDocumentRequest)];

const branches = [new Branch('model', modelApiProvider), new Branch('collection', collectionApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationLoanDocuments = buildCommunication<ILoanDocumentsConnectedProps>(strategy);
