import React from 'react';

interface IComponentProps {
  header?: string | JSX.Element;
  className?: string;
  description?: string | JSX.Element;
}

export const DataCardBase: React.FC<IComponentProps> = props => {
  const { header, description, className = '' } = props;

  return (
    <div className={`data-card-base ${className}`}>
      {header && <div className="data-card-base__header">{header}</div>}
      {description && <div className="data-card-base__description">{description}</div>}
    </div>
  );
};
