import React from 'react';
import i18n from 'i18n';
import { Button } from 'antd';
import { Description } from 'common/components/Description/Description';
import { DescriptionItem } from 'common/components/Description/DescriptionItem';
import { Popup } from 'common/components/Popup';
import { getLoanDetails } from 'entities/LoanApp/LoanApp.helpers';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { LoanAppInfoByBorrower } from 'entities/Onboarding/components/LoanAppInfoByBorrower';

interface IComponentState {
  edit: boolean;
}

interface IComponentProps {
  currency?: string;
  editable?: boolean;
  header?: string;
  metricsForInvestor?: boolean;
}

type AllProps = IUsersConnectedProps & ILoanAppConnectedProps & IComponentProps;

class LoanDetailsComponent extends React.Component<AllProps, IComponentState> {
  state = { edit: false };

  render() {
    const { edit } = this.state;
    const { loanAppModel, editable, userCurrent, header, metricsForInvestor } = this.props;
    const { data: loanApp } = loanAppModel;
    const currency = loanApp?.currency;
    const role = userCurrent.data?.role;

    if (!loanApp) {
      return null;
    }

    const loanDetailsItems = role && getLoanDetails(loanApp, role, metricsForInvestor);

    return (
      <>
        <Description
          header={header ? header : i18n.t('loanDetails')}
          footer={
            editable ? (
              <Button className="submit-button" type="primary" onClick={this.toggleEdit}>
                {i18n.t<string>('editLoanInfoButton')}
              </Button>
            ) : (
              undefined
            )
          }
        >
          {loanDetailsItems &&
            loanDetailsItems.map(
              ({ title, value, formatter }, index) =>
                !!value && <DescriptionItem key={index} title={title} endValue={formatter(value, currency)} />
            )}
        </Description>

        {editable && (
          <Popup
            title={i18n.t<string>('editLoanInfoButton')}
            visible={edit}
            onCancel={this.toggleEdit}
            className="loan-edit-popup"
          >
            <LoanAppInfoByBorrower edit onSubmit={this.toggleEdit} />
          </Popup>
        )}
      </>
    );
  }

  toggleEdit = () => {
    this.setState(prevState => ({
      edit: !prevState.edit
    }));
  };
}

export const LoanDetails = communicationUser.injector(communicationLoanApp.injector(LoanDetailsComponent));
