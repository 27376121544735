import React from 'react';
import { useTranslation } from 'react-i18next';
import { Description } from 'common/components/Description/Description';
import { DescriptionItem } from 'common/components/Description/DescriptionItem';
import { RiskClass } from 'common/components/RiskClass';
import { getScores } from 'entities/LoanApp/LoanApp.helpers';
import { EScores } from 'entities/LoanApp/LoanApp.const';

interface IComponentProps {
  ownRisk?: string;
  crif?: string;
}

export const RiskOverviewBlock: React.FC<IComponentProps> = props => {
  const { crif = '--', ownRisk = '--' } = props;
  const { t } = useTranslation();

  return (
    <Description header={t('loanRiskOverview.header')}>
      <DescriptionItem
        title={t('loanRiskOverview.class')}
        endValue={<RiskClass className={`mr-0 risk-class risk-class--${ownRisk || '--'}`}>{ownRisk || '--'}</RiskClass>}
      />
      <DescriptionItem title={t('loanRiskOverview.crif')} endValue={crif} />
      <DescriptionItem title={t('loanRiskOverview.def')} endValue={getScores(EScores.Probability, ownRisk)} />
      <DescriptionItem title={t('loanRiskOverview.rate')} endValue={getScores(EScores.Rate, ownRisk)} />
      <DescriptionItem title={t('loanRiskOverview.return')} endValue={getScores(EScores.Target, ownRisk)} />
    </Description>
  );
};
