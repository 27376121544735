export const ratingMarks = {
  0: 'A',
  25: 'B',
  50: 'C',
  75: 'D',
  100: 'E'
};

export const interestRateFilterMax = 16;

export const durationFilterValues = [
  { name: 'All', value: '' },
  { name: '6-24', value: '6,24' },
  { name: '25-36', value: '25,36' },
  { name: '37-48', value: '37,48' },
  { name: '49-60', value: '49,60' }
];

export const interestRateFilterValues = [
  { name: 'All', value: '' },
  { name: '0-6', value: '0,6' },
  { name: '6-8', value: '6,8' },
  { name: '8-10', value: '8,10' },
  { name: '10-15', value: '10,15' }
];
