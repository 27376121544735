import React from 'react';
import { Row } from 'antd';
import { getMarkupForSumWithAccountCurrency } from 'entities/LoanApp/LoanApp.helpers';

interface IComponentProps {
  percent: string;
  sum?: number;
  tooltip?: string;
}

export const PortfolioHealthItem: React.FC<IComponentProps> = props => {
  const { percent, sum, tooltip } = props;
  return (
    <div className="portfolio-health__item">
      <Row justify="space-between" className="portfolio-health__item_value">
        <span>{percent} %</span>
        <span>{getMarkupForSumWithAccountCurrency(sum)}</span>
      </Row>
      {tooltip && <div className="portfolio-health__item_tooltip">{tooltip}</div>}
    </div>
  );
};
