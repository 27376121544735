import React from 'react';
import i18n from 'i18n';
import { Button, Col, Form, Row, FormInstance } from 'antd';
import { mapBEErrorsToFields } from 'common/helpers/errors.helper';
import { EErrorStatus } from 'common/models/errors.model';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { CompanyDetailsForm } from 'entities/Onboarding/components/CompanyDetailsForm';
import { IndustryField } from 'entities/Onboarding/components/IndustryField';
import { EOnboardingSteps } from 'entities/Onboarding/OnboardingSteps.const';

interface IComponentProps {
  edit?: boolean;
  onSubmit?: () => void;
}

type AllProps = IOnboardingConnectedProps & IComponentProps;
class BusinessInfoByInvestorComponent extends React.Component<AllProps> {
  formRef = React.createRef<FormInstance>();

  componentDidUpdate() {
    const { onboardingModel } = this.props;
    const { errors } = onboardingModel;
    // @ts-ignore
    const status = errors?.status;
    // @ts-ignore
    const data = errors?.data.errors;

    if (status === EErrorStatus.Validation) {
      mapBEErrorsToFields(this.formRef.current!, data);
    }
  }

  render() {
    const { onboardingModel } = this.props;
    const { loading: companyLoading } = onboardingModel;

    return (
      <Form ref={this.formRef} layout="vertical" onFinish={this.onSubmit} hideRequiredMark={true}>
        <div className="onboarding-stepper__block">
          <h3 className="onboarding-stepper__block__title">{i18n.t<string>('step1PanelHeader.borrower')}</h3>
          {/* @ts-ignore */}
          <CompanyDetailsForm disabled={companyLoading} />
        </div>

        <Row>
          <Col xs={24} md={12}>
            <IndustryField disabled={companyLoading} />
          </Col>
        </Row>

        <Form.Item className="mb-0">
          <Button className="form-submit-button" type="primary" htmlType="submit" loading={companyLoading}>
            {i18n.t<string>('form.submit')}
          </Button>
        </Form.Item>
      </Form>
    );
  }

  onSubmit = () => {
    const { addCompanyOnboardingModel, skipOnboardingModel, setOnboardingStepsModel } = this.props;
    const { validateFields } = this.formRef.current!;

    validateFields().then(values => {
      // TODO: Provide currency as a separate field.
      const { externalId, ...restData } = values;

      addCompanyOnboardingModel({
        externalId: externalId.key,
        ...restData,
        onSuccess: () => {
          skipOnboardingModel({
            step: EOnboardingSteps.LoanStep,
            onSuccess: () => {
              setOnboardingStepsModel({ step: EOnboardingSteps.KYCStep });
            }
          });
        }
      });
    });
  };
}

export const BusinessInfoByInvestor = communicationOnboarding.injector(BusinessInfoByInvestorComponent);
