import axios from 'axios';
import { objectToQuery } from 'common/helpers/request.helper';
import { IModelRequestParams, IRequestSuccess } from 'common/models/request.model';
import {
  ISyncDocuSign,
  IGetLoanAppCollectionParams,
  ILoanAppCollection,
  ILoanAppConfirmInvestModel,
  ILoanAppCreateModel,
  ILoanAppInvestRequestModel,
  ILoanAppInvestResponseModel,
  ILoanAppModel,
  ILoanAppSign,
  ILoanAppUpdateModel,
  ILoanStatModel,
  IRepaymentDetailsModel,
  ILoanApplicationDeclineParams
} from 'entities/LoanApp/LoanApp.models';
import { IUserCollection } from 'entities/User/User.models';

const basePath = '/loan-applications';
const docusignPath = '/docusign';

export const loanAppTransport = {
  addLoanApp: (params: ILoanAppCreateModel): Promise<ILoanAppModel> => axios.post(`${basePath}`, params).then(r => r.data),
  updateLoanApp: (params: ILoanAppUpdateModel): Promise<ILoanAppModel> =>
    axios.put(`${basePath}/${params.id}`, params).then(r => r.data),
  getLoanAppModel: ({ id, ...rest }: IModelRequestParams): Promise<ILoanAppModel> =>
    axios.get(`${basePath}/${id}${objectToQuery({ ...rest })}`).then(r => r.data),
  defaultLoanAppModel: (loanId: string): Promise<void> => axios.put(`${basePath}/${loanId}/default`).then(r => r.data),
  declineLoanAppModel: ({ id, comment }: ILoanApplicationDeclineParams): Promise<void> =>
    axios.put(`${basePath}/${id}/decline`, { comment }).then(r => r.data),
  getLoanAppCollection: (params: IGetLoanAppCollectionParams): Promise<ILoanAppCollection> =>
    axios.get(`${basePath}${objectToQuery(params)}`).then(r => r.data),
  syncDocuSign: (
    params: IRequestSuccess & {
      loanId: string;
    }
  ): Promise<ISyncDocuSign> => axios.post(`${docusignPath}/${params.loanId}/force-update`).then(r => r.data),
  addLoanAppSign: (id: string): Promise<ILoanAppSign> => axios.post(`${basePath}/${id}/send-and-sign`).then(r => r.data),
  getLoanAppSign: (id: string): Promise<ILoanAppSign> => axios.get(`${basePath}/${id}/envelope-view-url`).then(r => r.data),
  investLoanApp: (params: ILoanAppInvestRequestModel): Promise<ILoanAppInvestResponseModel> =>
    axios.post(`${basePath}/${params.id}/invest`, params).then(r => r.data),
  confirmInvestLoanApp: ({ id, ...rest }: ILoanAppConfirmInvestModel): Promise<ILoanAppInvestResponseModel> =>
    axios.post(`${basePath}/${id}/invest-confirm${objectToQuery({ ...rest })}`).then(r => r.data),
  getLoanAppSigners: (loanId: string): Promise<IUserCollection> => axios.get(`${basePath}/${loanId}/signers`).then(r => r.data),
  getLoanAppInvestors: (loanId: string): Promise<IUserCollection> =>
    axios.get(`${basePath}/${loanId}/investors`).then(r => r.data),
  getStat: (id: string): Promise<ILoanStatModel> => axios.get(`${basePath}/statistic/${id}`).then(r => r.data),
  getLoanAppRepaymentDetails: (loanId: string): Promise<IRepaymentDetailsModel> =>
    axios.get(`${basePath}/${loanId}/repayment-details`).then(r => r.data),
  addLoanAppRepaymentDetails: (loanId: string): Promise<any> =>
    axios.post(`${basePath}/${loanId}/early-repayment`).then(r => r.data)
};
