import { actionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';
import { companiesTransport } from 'entities/Companies/Companies.transport';
import { IAddNotesParams } from 'entities/Companies/Companies.models';
import { ICompanyModel } from 'entities/Onboarding/OnboardingSteps.models';

const namespace = 'companies';

export interface ICompaniesConnectedProps {
  companiesNotes: StoreBranch<ICompanyModel>;
  addCompaniesNotes(params: IAddNotesParams): void;
}

const companiesNotesApiProvider = [new APIProvider(actionsTypes.add, companiesTransport.addNotes)];

const branches = [new Branch('notes', companiesNotesApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationCompanies = buildCommunication<ICompaniesConnectedProps>(strategy);
