import React from 'react';
import i18n from 'i18n';
import { Button, Checkbox, Col, Form, FormInstance, Row } from 'antd';
import { FormSelect } from 'common/components/Form/FormSelect';
import { mapBEErrorsToFields } from 'common/helpers/errors.helper';
import { EErrorStatus } from 'common/models/errors.model';
import { MIDFUNDER_EMAIL } from 'common/config';
import { ECompanyCountries } from 'common/consts';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import ImgBusinessInfo from 'app/assets/images/BusinessInfoByBorrower.svg';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { IBusinessInfoInitialValues, ICompanyCreateParams, ICompanyModel } from 'entities/Onboarding/OnboardingSteps.models';
import { CompanyDetailsForm } from 'entities/Onboarding/components/CompanyDetailsForm';
import {
  aboutBusinessOptions,
  annualProfitsOptions,
  companiesStates,
  EOnboardingSteps,
  monthlyRevenueOptions
} from 'entities/Onboarding/OnboardingSteps.const';
import { IndustryField } from 'entities/Onboarding/components/IndustryField';

type AllProps = IOnboardingConnectedProps;

interface IComponentState {
  showState: boolean;
  otherOptionSelected: boolean;
  otherIndustryOptionSelected: boolean;
  initialValues: IBusinessInfoInitialValues | undefined;
}

class BusinessInfoByBorrowerComponent extends React.Component<AllProps, IComponentState> {
  state = {
    showState: false,
    otherOptionSelected: false,
    otherIndustryOptionSelected: false,
    initialValues: undefined
  };
  formRef = React.createRef<FormInstance>();

  componentDidUpdate() {
    const { onboardingModel } = this.props;
    const { errors } = onboardingModel;
    // @ts-ignore
    const status = errors?.status;
    // @ts-ignore
    const data = errors?.data.errors;

    if (status === EErrorStatus.Validation) {
      mapBEErrorsToFields(this.formRef.current!, data);
    }
  }

  componentDidMount() {
    const { getOnboardingCompanyCollection } = this.props;

    getOnboardingCompanyCollection().then(response => {
      const data = response?.data[0];

      if (data) {
        const initialValues = this.getInitialValues(data);

        if (initialValues.registrationCountry === ECompanyCountries.CH) {
          // @ts-ignore TODO: fix typing on BE, shouldn't be website in required param
          this.setState({ showState: true, initialValues });
        } else {
          // @ts-ignore
          this.setState({ initialValues });
        }
      }
    });
  }

  render() {
    const { onboardingModel, onboardingCompanyCollection } = this.props;
    const { showState, otherOptionSelected, otherIndustryOptionSelected, initialValues } = this.state;
    const { loading: companyLoading } = onboardingModel;
    const { loading: companyCollectionLoading } = onboardingCompanyCollection;
    const otherOption = 'Other';

    if (companyLoading || companyCollectionLoading) {
      return <LoadingSpinner />;
    }

    return (
      <Form
        ref={this.formRef}
        hideRequiredMark={true}
        layout="vertical"
        onFinish={this.onSubmit}
        onFinishFailed={this.onFinishFailed}
        initialValues={initialValues}
      >
        <div className="onboarding-stepper__block pb-7">
          <h3 className="onboarding-stepper__block__title">{i18n.t<string>('step1PanelHeader.borrower')}</h3>
          <CompanyDetailsForm
            disabled={companyLoading}
            form={this.formRef.current!}
            changeCompanyCountry={companyCountry => {
              companyCountry === otherOption
                ? this.setState({ otherOptionSelected: true })
                : this.setState({ showState: companyCountry === ECompanyCountries.CH, otherOptionSelected: false });
            }}
            otherOptionSelected={otherOptionSelected}
            initialValues={initialValues}
          />
        </div>

        {!otherOptionSelected ? (
          <>
            <div className="onboarding-stepper__block">
              <h3 className="onboarding-stepper__block__title">{i18n.t<string>('step1FewQuestion')}</h3>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={i18n.t<string>('step1MonthlyRevenue')}
                    name="monthlyRevenue"
                    rules={[
                      {
                        required: true,
                        message: i18n.t<string>('pleaseSelectOption')
                      }
                    ]}
                  >
                    <FormSelect placeholder="selectPlaceholder" data={monthlyRevenueOptions} disabled={companyLoading} />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  <IndustryField
                    disabled={companyLoading}
                    onChange={value => {
                      value === otherOption &&
                        !otherIndustryOptionSelected &&
                        this.setState({ otherIndustryOptionSelected: true });
                      value !== otherOption &&
                        otherIndustryOptionSelected &&
                        this.setState({ otherIndustryOptionSelected: false });
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} md={12}>
                  <Form.Item
                    label={i18n.t<string>('step1AnnualProfits')}
                    name="annualProfits"
                    rules={[
                      {
                        required: true,
                        message: i18n.t<string>('pleaseSelectOption')
                      }
                    ]}
                  >
                    <FormSelect placeholder="selectPlaceholder" data={annualProfitsOptions} disabled={companyLoading} />
                  </Form.Item>
                </Col>
                {showState && (
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={i18n.t<string>('step1StateOfBusiness')}
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: i18n.t<string>('pleaseSelectOption')
                        }
                      ]}
                    >
                      <FormSelect placeholder={i18n.t('selectPlaceholder')} data={companiesStates} disabled={companyLoading} />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </div>

            {!otherIndustryOptionSelected ? (
              <div className="onboarding-stepper__block">
                <h3 className="onboarding-stepper__block__title">{i18n.t<string>('step1AboutBusiness')}</h3>
                <Form.Item name="additionalInfo">
                  <Checkbox.Group disabled={companyLoading}>
                    <Row>
                      {aboutBusinessOptions.map((item, index) => (
                        <Col xs={24} lg={10} xl={8} className="mb-3" key={index}>
                          <Checkbox value={item} className="form-checkbox" form-checkbox>
                            {i18n.t<string>(item)}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </div>
            ) : (
              <p className="onboarding-stepper__block__title">
                <span>{i18n.t<string>('step1OtherIndustryDescription.0')}</span>
                <a href={`mailto:${MIDFUNDER_EMAIL}`}>{i18n.t<string>('step1OtherIndustryDescription.1')}</a>
                <span>{i18n.t<string>('step1OtherIndustryDescription.2')}</span>
              </p>
            )}

            <Form.Item className="mb-0">
              <Button
                className="form-submit-button"
                type="primary"
                htmlType="submit"
                loading={companyLoading}
                disabled={otherIndustryOptionSelected}
              >
                {i18n.t<string>('form.submit')}
              </Button>
            </Form.Item>
          </>
        ) : (
          <div className="other-company">
            <p className="onboarding-stepper__block__title p-0">
              <span>{i18n.t<string>('step1OtherCompanyDescription.0')}</span>
              <span className="ff-regular">{i18n.t<string>('step1OtherCompanyDescription.1')}</span>
              <span>{i18n.t<string>('step1OtherCompanyDescription.2')}</span>
              <span className="ff-regular">{i18n.t<string>('step1OtherCompanyDescription.3')}</span>
              <span>{i18n.t<string>('step1OtherCompanyDescription.4')}</span>
            </p>
            <p className="onboarding-stepper__block__title p-0">
              <span>{i18n.t<string>('step1OtherCompanyDescription.5')}</span>
              <a href={`mailto:${MIDFUNDER_EMAIL}`}>{i18n.t<string>('step1OtherCompanyDescription.6')}</a>
              <span>{i18n.t<string>('step1OtherCompanyDescription.7')}</span>
            </p>
            <p className="onboarding-stepper__block__title p-0">{i18n.t<string>('step1OtherCompanyDescription.8')}</p>
            <p className="onboarding-stepper__block__title p-0">{i18n.t<string>('step1OtherCompanyDescription.9')}</p>

            <div className="other-company__image-wrapper">
              <img className="other-company__image" src={ImgBusinessInfo} alt="Business info" />
            </div>
          </div>
        )}
      </Form>
    );
  }

  getInitialValues = (data: ICompanyModel) => ({
    // @ts-ignore
    registrationCountry: data.registrationCountry,
    currency: data.currency,
    externalId: {
      disabled: undefined,
      key: data.externalId,
      label: data.name,
      value: Number(data.externalId)
    },
    monthlyRevenue: data.questionary?.monthlyRevenue,
    industryId: data.industryId,
    annualProfits: data.questionary?.annualProfits,
    state: data.questionary?.state,
    additionalInfo: Object.keys(data.questionary?.additionalInfo || {})
  });

  isValuesChanged = (currentData: ICompanyCreateParams, prevData?: IBusinessInfoInitialValues) => {
    if (!prevData) {
      return true;
    }

    const convertedPrevData = {
      externalId: prevData.externalId.key,
      industryId: prevData.industryId,
      registrationCountry: prevData.registrationCountry,
      currency: prevData.currency,
      questionary: {
        monthlyRevenue: prevData.monthlyRevenue,
        annualProfits: prevData.annualProfits,
        state: prevData.state,
        additionalInfo: Object.fromEntries(prevData.additionalInfo.map(item => [item, true]))
      }
    };

    return JSON.stringify(convertedPrevData) !== JSON.stringify(currentData);
  };

  onSubmit = () => {
    const { addCompanyOnboardingModel, setOnboardingStepsModel } = this.props;
    const { initialValues } = this.state;
    const { validateFields } = this.formRef.current!;

    validateFields().then((values: any) => {
      const { externalId, industryId, registrationCountry, currency, ...restData } = values;

      restData.additionalInfo = restData.additionalInfo
        ?.sort((a: string, b: string) => a.localeCompare(b))
        .reduce((object: any, item: any) => {
          object[item] = true;
          return object;
        }, {});

      // @ts-ignore TODO: fix typing on BE, shouldn't be website in required param
      const params: ICompanyCreateParams = {
        externalId: externalId.key,
        industryId,
        registrationCountry,
        currency,
        questionary: {
          ...restData
        }
      };

      if (this.isValuesChanged(params, initialValues)) {
        addCompanyOnboardingModel({
          ...params,
          onSuccess: () => {
            setOnboardingStepsModel({ step: EOnboardingSteps.LoanStep });
          }
        });
      } else {
        setOnboardingStepsModel({ step: EOnboardingSteps.LoanStep });
      }
    });
  };

  onFinishFailed = ({ errorFields }: any) => {
    this.formRef.current!.scrollToField(errorFields[0].name);
  };
}

export const BusinessInfoByBorrower = communicationOnboarding.injector(BusinessInfoByBorrowerComponent);
