import {
  EDecoratorNames,
  EDocTypes,
  EKycStatus,
  EKycSteps,
  kycBorrowerSkipSteps,
  KYCConfigForBorrower
} from 'entities/KYCProvider/KYC.const';
import {
  IKYCAssignDataItem,
  IKYCFormValues,
  IKYCStatus,
  IPersonalInformationModel,
  IShareholder,
  IUploadFileModel,
  TShareholderSlot,
  TStepType
} from 'entities/KYCProvider/KYCProvider.models';
import { IOnboardingStepsModel } from 'entities/Onboarding/OnboardingSteps.models';
import { EUserRoles } from 'entities/User/User.models';

export const prepareSimpleDataToAssignFiles = (step: EKycSteps, type: EDocTypes, fileIds: string[]) => {
  return {
    items: [{ step, type, fileIds }]
  };
};

export const getShareholdersData = (step: EKycSteps, shareholders: IShareholder[]) => {
  return {
    items: shareholders.map((item, index) => ({
      step,
      type: item.document.type,
      fileIds: item.document.fileIds,
      meta: {
        shareholder: {
          id: `slot${index}` as TShareholderSlot,
          firstName: item.firstName,
          lastName: item.lastName
        }
      }
    }))
  };
};

export const getPersonalInfoData = (step: EKycSteps, data: IPersonalInformationModel) => {
  return {
    items: [
      {
        step,
        type: EKycSteps.PersonalInformation as TStepType,
        meta: {
          personalInformation: data
        }
      }
    ]
  };
};

export const checkKYCSkipped = (skippedSteps?: string[]): boolean => {
  if (!skippedSteps?.length) {
    return false;
  }

  return skippedSteps.length === kycBorrowerSkipSteps.length;
};

export const goToKYCStep = (
  navigation: 'next' | 'back',
  currentStep: EKycSteps,
  setOnboardingStepsModel: (params: IOnboardingStepsModel) => void
) => {
  const steps = KYCConfigForBorrower.map(step => step.stepType);

  // @ts-ignore
  // eslint-disable-next-line
  navigation === 'next' ? setOnboardingStepsModel({ step: EBorrowerOnboardingSteps.KYCStep, kycStep: steps[steps.indexOf(currentStep) + 1] }) : setOnboardingStepsModel({ step: EBorrowerOnboardingSteps.KYCStep, kycStep: steps[steps.indexOf(currentStep) - 1] });
};

export const getKYCData = (role: EUserRoles, docType: EDocTypes, values: IKYCFormValues) => {
  // @ts-ignore
  // eslint-disable-next-line
  const filteredValues: IKYCFormValues = Object.fromEntries(Object.entries(values).filter(([key, value]) => value !== undefined));

  const items: IKYCAssignDataItem[] = [
    {
      step: EKycSteps.PersonalInformation,
      type: EKycSteps.PersonalInformation,
      meta: {
        personalInformation: {
          firstName: filteredValues.firstName,
          lastName: filteredValues.lastName,
          middleName: filteredValues.middleName,
          phone: filteredValues.phone,
          birthday: filteredValues.birthday,
          countryNationality: filteredValues.countryNationality,
          address: filteredValues.address,
          city: filteredValues.city,
          country: filteredValues.country,
          zipCode: filteredValues.zipCode,
          companyDescription: filteredValues.companyDescription
        }
      }
    }
  ];

  for (const key in filteredValues) {
    switch (key) {
      case EDecoratorNames.IdentityCardSideA: {
        items.push({
          step: EKycSteps.IdPrimary,
          type: docType,
          fileIds: Object.values({
            identityCardSideA: filteredValues.identityCardSideA,
            identityCardSideB: filteredValues.identityCardSideB
          }).map(item => item?.id) as string[]
        });
        break;
      }
      case EDecoratorNames.Passport: {
        items.push({
          step: EKycSteps.IdPrimary,
          type: docType,
          fileIds: [filteredValues[key]?.id] as string[]
        });
        break;
      }
      case EDocTypes.UBO: {
        items.push({
          step: EKycSteps.UBO,
          type: EDocTypes.UBO,
          fileIds: filteredValues[key]?.map(item => item.id)
        });
        break;
      }
      case EDocTypes.KBIS: {
        items.push({
          step: EKycSteps.KBIS,
          type: EDocTypes.KBIS,
          fileIds: filteredValues[key]?.map((item: IUploadFileModel) => item.id)
        });
        break;
      }
      case EDocTypes.ArticlesOfAssociation: {
        items.push({
          step: EKycSteps.ArticlesOfAssociation,
          type: EDocTypes.ArticlesOfAssociation,
          fileIds: filteredValues[key]?.map((item: IUploadFileModel) => item.id)
        });
        break;
      }
      case EDocTypes.FundManagementRegulations: {
        items.push({
          step: EKycSteps.FundManagementRegulations,
          type: EKycSteps.FundManagementRegulations,
          fileIds: filteredValues[key]?.map((item: IUploadFileModel) => item.id)
        });
        break;
      }
      case EDocTypes.AdditionalDocuments: {
        items.push({
          step: EKycSteps.AdditionalDocuments,
          type: EKycSteps.AdditionalDocuments,
          fileIds: filteredValues[key]?.map((item: IUploadFileModel) => item.id)
        });
        break;
      }
      default: {
        break;
      }
    }
  }

  if (role === EUserRoles.Borrower) {
    values.shareholders?.forEach((shareholder, index) => {
      items.push({
        step: EKycSteps.Shareholders,
        type: shareholder.document.type,
        fileIds: shareholder.document.fileIds,
        meta: {
          shareholder: {
            id: `slot${index}` as TShareholderSlot,
            firstName: shareholder.firstName,
            lastName: shareholder.lastName
          }
        }
      });
    });
  }

  return items;
};

export const setDisable = (data: IKYCStatus | null, step: EKycSteps) => {
  return (
    data?.status === EKycStatus.Declined ||
    data?.status === EKycStatus.WaitingForApproval ||
    // @ts-ignore
    (data?.status === EKycStatus.AvailableForEditing && data?.steps[step]?.status === EKycStatus.Approved)
  );
};
