import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';

export const LoadingApplicationInfo = () => {
  return (
    <LayoutBasic className="pt-7 pb-7 white-layout">
      <Row justify="center">
        <Col xl={{ span: 8, order: 2 }} lg={10} md={12} sm={16} xs={24}>
          <Skeleton active avatar={{ shape: 'square', size: 400 }} paragraph={false} title={false} className="mb-6" />
        </Col>
        <Col xl={{ span: 16, order: 1 }} md={24}>
          <Skeleton active paragraph={{ rows: 8 }} className="bordered-card p-5 mb-6" />
        </Col>
      </Row>
      <Row justify="start" gutter={24}>
        <Col xl={4} md={24}>
          <Skeleton active paragraph={{ rows: 1 }} className="bordered-card p-5 mb-6" />
        </Col>
        <Col xl={4} md={24}>
          <Skeleton active paragraph={{ rows: 1 }} className="bordered-card p-5 mb-6" />
        </Col>
        <Col xl={4} md={24}>
          <Skeleton active paragraph={{ rows: 1 }} className="bordered-card p-5 mb-6" />
        </Col>
        <Col xl={6}></Col>
      </Row>
    </LayoutBasic>
  );
};
