import React from 'react';
import { Row } from 'antd';
import i18n from 'i18n';
import numeral from 'numeral';
import { RiskClass } from 'common/components/RiskClass';
import { ENumberFormats } from 'common/consts';
import { LoanInfoCard } from 'common/components/LoanInfoCard';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { EPaymentTypes } from 'entities/LoanApp/LoanApp.const';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';

type AllProps = ILoanAppConnectedProps;

class ActiveLoanInfoComponent extends React.Component<AllProps> {
  render() {
    const { loanAppModel, loanAppPaymentSchedule } = this.props;
    const { data: loanAppData, loading: loanAppLoading } = loanAppModel;
    const { data: paymentScheduleData, loading: paymentScheduleLoading } = loanAppPaymentSchedule;
    const loading = loanAppLoading || paymentScheduleLoading;

    if (loading || !loanAppData || !paymentScheduleData) {
      return null;
    }

    const { loanAmount, ownRiskClass } = loanAppData;
    const repaidAmount = paymentScheduleData.data?.reduce(
      (acc, item) => (item.type === EPaymentTypes.Debt && item.isPaid ? acc + numeral(item.monthlyInstallments).value() : acc),
      0
    );

    return (
      <Row align="stretch" gutter={20} className="mb-6">
        <LoanInfoCard colSize={6}>
          <Row justify="center">
            <RiskClass className={`main-title risk-class--${ownRiskClass || '--'}`} width="64px" height="41px">
              {ownRiskClass || '--'}
            </RiskClass>
          </Row>
          <div className="tooltip color-gray">{i18n.t<string>('loanProps.ownRiskClass')}</div>
        </LoanInfoCard>
        <LoanInfoCard colSize={9}>
          <div>
            <span className="mr-2 color-gray">
              <AccountCurrency />
            </span>
            <span className="color-black">{numeral(repaidAmount).format(ENumberFormats.Real)}</span>
          </div>
          <div className="tooltip color-gray">{i18n.t<string>('loanProps.repaidAmount')}</div>
        </LoanInfoCard>
        <LoanInfoCard colSize={9}>
          <div>
            <span className="mr-2 color-gray">
              <AccountCurrency />
            </span>
            <span className="color-black">{numeral(loanAmount).format(ENumberFormats.Real)}</span>
          </div>
          <div className="tooltip color-gray">{i18n.t<string>('loanProps.loanAmount')}</div>
        </LoanInfoCard>
      </Row>
    );
  }
}

export const ActiveLoanInfo = communicationLoanApp.injector(ActiveLoanInfoComponent);
