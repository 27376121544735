import React from 'react';
import i18n from 'i18n';
import { Col, Row, Skeleton } from 'antd';
import { IZefixModel } from 'entities/Onboarding/OnboardingSteps.models';

interface IComponentProps {
  data?: IZefixModel | null;
  loading: boolean;
  onboarding: boolean;
}

export const ZefixData: React.FC<IComponentProps> = props => {
  const { data, loading } = props;
  const { legalForm, uid, address, ehraid } = data || {};

  const getFirmAddress = () => {
    return address ? Object.values(address).join(', ') : '';
  };

  if (loading) {
    return <Skeleton active title={false} paragraph={{ rows: 3 }} />;
  }

  if (!ehraid) {
    return <div>--</div>;
  }

  return (
    <div>
      {!!ehraid && (
        <div className="company-details">
          <Row justify="start" className="company-details__title">
            <Col md={4} xs={24}>
              {i18n.t<string>('step1Address')}
            </Col>
            <span className="company-details__value">{getFirmAddress()}</span>
          </Row>
          <Row justify="start" className="company-details__title">
            <Col md={4} xs={24}>
              {i18n.t<string>('step1LegalForm')}
            </Col>
            <span className="company-details__value">{legalForm}</span>
          </Row>
          <Row justify="start" className="company-details__title">
            <Col md={4} xs={24}>
              {i18n.t<string>('step1UID')}
            </Col>
            <span className="company-details__value">{uid}</span>
          </Row>
        </div>
      )}
    </div>
  );
};
