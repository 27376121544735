import React from 'react';
import i18n from 'i18n';
import { Button, message, Row, Spin, Upload } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { UploadFile } from 'antd/es/upload/interface';
import { DeleteOutlined } from '@ant-design/icons';
import { checkFileSize } from 'common/helpers/fileLoader.helper';
import { allDocsExts } from 'common/consts';
import UploadIcon from 'app/assets/icons/UploadIcon.svg';
import { EFileStatus } from 'entities/KYCProvider/KYC.const';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { IUploadFileModel } from 'entities/KYCProvider/KYCProvider.models';

interface IAntdFormControl {
  onChange?: (value?: IUploadFileModel[]) => void;
  value?: any;
}

interface IComponentProps {
  loadingRequest: boolean;
  setLoading?: (value: boolean) => void;
}

interface IComponentState {
  uploading: boolean;
  files: IUploadFileModel[];
}

type AllProps = IComponentProps & IAntdFormControl & IAuthConnectedProps;

class MultipleFileUploaderCardComponent extends React.Component<AllProps, IComponentState> {
  state = { uploading: false, files: [] as IUploadFileModel[] };

  render() {
    const { authModel, loadingRequest } = this.props;
    const { files, uploading } = this.state;
    const { data } = authModel;
    const token = data?.access.token;

    return (
      <div className={files.length ? 'bg-white p-7 br-default' : ''}>
        <Upload
          type="drag"
          name="file"
          multiple={true}
          action="/api/files/upload"
          headers={{ authorization: `Bearer ${token}` }}
          accept={allDocsExts.exts}
          showUploadList={false}
          onChange={this.handleChange}
          // @ts-ignore
          beforeUpload={checkFileSize}
          disabled={uploading || loadingRequest}
          className={`form-upload form-upload_small ${files.length ? 'mb-6' : ''}`}
        >
          <Row align="middle" justify="center" className={loadingRequest ? 'o-6' : ''}>
            {uploading ? (
              <Spin size="small" />
            ) : (
              <>
                <img src={UploadIcon} alt="Upload icon" className="mr-4" />
                <span>{i18n.t<string>('KYCStepper.uploaderTooltipShort')}</span>
              </>
            )}
          </Row>
        </Upload>
        {!!files.length && (
          <>
            {files.map(item => (
              <Row className="loaded-items__item" key={item.id} align="middle">
                <div className="loaded-items__item_name pb-1">{item.name}</div>
                <div>
                  <Button type="link" size="small" className="color-primary" onClick={() => this.onRemove(item)}>
                    <DeleteOutlined />
                  </Button>
                </div>
              </Row>
            ))}

            <Button type="link" className="color-primary pl-0 ff-bold" onClick={this.onRemoveAll}>
              {i18n.t<string>('KYCStepper.removeAll')}
            </Button>
          </>
        )}
      </div>
    );
  }

  handleChange = (info: UploadChangeParam<UploadFile<{ id: string }>>) => {
    const { files } = this.state;
    const { onChange, setLoading } = this.props;
    const { file, fileList } = info;
    const { status, response } = file;
    const id = response?.id;

    if (fileList.some(item => item.status === EFileStatus.Uploading)) {
      this.setState({ uploading: true });
      if (setLoading) {
        setLoading(true);
      }
    }

    if (file.status === EFileStatus.Error) {
      this.setState({
        uploading: false
      });
      if (setLoading) {
        setLoading(false);
      }
      message.error(i18n.t<string>('errors:anyUiError'));
    }

    if (status === EFileStatus.Done && id) {
      this.setState({ files: [...files, { name: file.name, id }] });

      if (onChange) {
        onChange([...files, { name: file.name, id }]);
      }
    }

    if (!fileList.some(item => item.status === EFileStatus.Uploading)) {
      this.setState({ uploading: false });
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  onRemove = (file: IUploadFileModel) => {
    const { files } = this.state;
    const { onChange } = this.props;
    const index = files.indexOf(file);
    const newFiles = files.slice();

    newFiles.splice(index, 1);
    this.setState({ files: newFiles });

    if (onChange) {
      onChange(newFiles);
    }
  };

  onRemoveAll = () => {
    this.setState({ files: [] as IUploadFileModel[] });
  };
}

export const MultipleFileUploaderCard = communicationAuth.injector(MultipleFileUploaderCardComponent);
