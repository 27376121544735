import React, { PropsWithChildren } from 'react';
import { Col } from 'antd';

interface IComponentProps {
  colSize: number;
}

export const LoanInfoCard: React.FC<PropsWithChildren<IComponentProps>> = props => {
  const { children, colSize } = props;
  return (
    <Col span={colSize}>
      <div className="bordered-card loan-info-card">{children}</div>
    </Col>
  );
};
