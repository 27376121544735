import React from 'react';
import i18n from 'i18n';
import { RouteComponentProps, withRouter } from 'react-router';
import { Pagination, Spin, Table } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnType } from 'antd/lib/table/interface';
import { ESpecialistRoutes } from 'common/models/routes.model';
import { getFormattedPagination } from 'common/helpers/table.helper';
import { IGetLoanAppCollectionParams, ILoanAppCollection, ILoanAppModel } from 'entities/LoanApp/LoanApp.models';
import {
  ELoanListTabs,
  loansTableColumnsClientInfo,
  loansTableColumnsFull,
  loansTableColumnsInvestments,
  loansTableColumnsShort
} from 'entities/LoanApp/LoanApp.const';

interface IComponentProps {
  data: ILoanAppCollection | null;
  reloadCollection: (params: IGetLoanAppCollectionParams) => void;
  filter?: IGetLoanAppCollectionParams;
  loading?: boolean;
  tabKey: string;
  className?: string;
  columns?: ColumnType<ILoanAppModel>[];
}

type AllProps = RouteComponentProps & IComponentProps;

class LoansTableRestComponent extends React.Component<AllProps> {
  render() {
    const { data, loading, tabKey, className = '', columns } = this.props;
    const pageNumber = data?.page || 1;
    const total = data?.total;
    const loansData = data?.data || [];

    const showEmptyText = !loansData.length;
    const emptyContent = loading ? <Spin /> : <p>{i18n.t<string>('noLoansRecords')}</p>;
    const resultEmptyContent = showEmptyText ? emptyContent : undefined;

    return (
      <>
        <Table
          rowKey="id"
          dataSource={loansData}
          columns={columns || this.getCollectionOfColumns(tabKey)}
          className={className}
          pagination={false}
          onChange={this.onTableChange}
          loading={showEmptyText ? undefined : loading}
          locale={{ emptyText: resultEmptyContent }}
          onRow={record => ({
            onClick: () => {
              this.onRow(record);
            }
          })}
        />
        {!!total && (
          <Pagination size="small" defaultCurrent={1} current={pageNumber} total={total} onChange={this.onPageChange} />
        )}
      </>
    );
  }

  onTableChange = (
    pagination: TablePaginationConfig,
    filters: Partial<Record<keyof ILoanAppModel, string[]>>,
    sorter: SorterResult<ILoanAppModel> | SorterResult<ILoanAppModel>[]
  ) => {
    const { reloadCollection, filter } = this.props;

    if (!(sorter instanceof Array) && sorter.field) {
      const field = sorter.column?.key || sorter.field;
      let params;

      if (sorter.order) {
        params = { orderDirection: sorter.order === 'descend' ? 'DESC' : 'ASC', orderField: field.toString() };
      } else {
        params = { orderDirection: undefined, orderField: undefined };
      }

      // @ts-ignore
      reloadCollection({ ...filter, ...params });
    }
  };

  onPageChange = (page: number, pageSize: number) => {
    const { filter, reloadCollection } = this.props;

    reloadCollection({ ...filter, ...getFormattedPagination(page, pageSize) });
  };

  onRow = (record: ILoanAppModel) => {
    const { history, tabKey } = this.props;
    history.push({ pathname: `${ESpecialistRoutes.Loans}/${record.id}`, state: { from: tabKey } });
  };

  getCollectionOfColumns = (tabKey: string) => {
    switch (tabKey) {
      case ELoanListTabs.New:
      case ELoanListTabs.RateRequest:
      case ELoanListTabs.InfoRequested:
        return loansTableColumnsShort;
      case ELoanListTabs.Investments:
        return loansTableColumnsInvestments;
      case ELoanListTabs.ClientInfo:
        return loansTableColumnsClientInfo;
      case ELoanListTabs.Marketplace:
      case ELoanListTabs.Bookmarks:
      default:
        return loansTableColumnsFull;
    }
  };
}

export const LoansTableRest = withRouter(LoansTableRestComponent);
