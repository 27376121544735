import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import i18n from 'i18n';
import { MIDFUNDER_EMAIL } from 'common/config';
import ImgAnalysis from 'app/assets/images/ApplicationInfo/Analysis.svg';
import { ILoanRequirements } from 'entities/Onboarding/OnboardingSteps.models';

interface IComponentProps {
  loanRequirements: ILoanRequirements;
  submit?: () => void;
}

export const LoanAppInfoForbidCreate: React.FC<IComponentProps> = ({ loanRequirements, submit }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="fs-xxlg ff-semiBold mb-7">{t('loansForbidCreate.header')}</div>
      {!loanRequirements.isCompanyAgeEligible && !loanRequirements.isIndustryEligible && (
        <div className="fs-sm ff-semiBold mb-11">
          {t('loansForbidCreate.ageAndIndustryAreNotEligibleDescription.0')}
          <a href={`mailto:${MIDFUNDER_EMAIL}`}>{t('loansForbidCreate.ageAndIndustryAreNotEligibleDescription.1')}</a>
          {t('loansForbidCreate.ageAndIndustryAreNotEligibleDescription.2')}
        </div>
      )}

      {loanRequirements.isCompanyAgeEligible && !loanRequirements.isIndustryEligible && (
        <div className="fs-sm ff-semiBold mb-11">
          {t('loansForbidCreate.industryIsNotEligibleDescription.0')}
          <a href={`mailto:${MIDFUNDER_EMAIL}`}>{t('loansForbidCreate.industryIsNotEligibleDescription.1')}</a>
          {t('loansForbidCreate.industryIsNotEligibleDescription.2')}
        </div>
      )}

      {!loanRequirements.isCompanyAgeEligible && loanRequirements.isIndustryEligible && (
        <div className="fs-sm ff-semiBold mb-11 loan-app-info-forbid-create__description">
          <p>{t('loansForbidCreate.ageIsNotEligibleDescription.0')}</p>
          <p>{t('loansForbidCreate.ageIsNotEligibleDescription.1')}</p>
          <p>{t('loansForbidCreate.ageIsNotEligibleDescription.2')}</p>
        </div>
      )}

      <div className="mb-12">
        <img className="application-info__image" src={ImgAnalysis} alt="Analysis" style={{ maxWidth: '450px' }} />
      </div>

      {submit && (
        <Button type="primary" size="large" className="form-submit-button" onClick={submit}>
          {i18n.t<string>('continue')}
        </Button>
      )}
    </>
  );
};
