import React from 'react';
import i18n from 'i18n';
import { FormCard } from 'common/components/Form/FormCard';
import { LoadingSpinner } from 'common/components/LoadingSpinner';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { EOnboardingSteps } from 'entities/Onboarding/OnboardingSteps.const';
import { BusinessInfoByInvestor } from 'entities/Onboarding/components/BusinessInfoByInvestor';
import { EUserTypes } from 'entities/User/User.models';
import { KYCStepper } from 'entities/KYCProvider/components/KYCStepper';
import { getOnboardingStep } from 'entities/Onboarding/OnboardingSteps.helper';

interface IComponentProps {
  onChangeInvestorType: (val: EUserTypes) => void;
  investorType: EUserTypes;
}

type AllProps = IComponentProps & IOnboardingConnectedProps & IUsersConnectedProps;

class StepperForInvestorComponent extends React.Component<AllProps> {
  componentDidMount(): void {
    const { getOnboardingStepsModel } = this.props;

    getOnboardingStepsModel();
  }

  render() {
    const { onboardingModel, onboardingStepsModel } = this.props;
    const step = getOnboardingStep(onboardingStepsModel.data, onboardingModel.data);

    if (onboardingModel.loading || onboardingStepsModel.loading) {
      return <LoadingSpinner />;
    }

    switch (step) {
      case EOnboardingSteps.CompanyStep:
        return (
          <FormCard title={i18n.t('step2Header.investor')}>
            <BusinessInfoByInvestor />
          </FormCard>
        );
      case EOnboardingSteps.KYCStep:
      default:
        return (
          <FormCard>
            <KYCStepper />
          </FormCard>
        );
    }
  }
}

export const StepperForInvestor = communicationUser.injector(communicationOnboarding.injector(StepperForInvestorComponent));
