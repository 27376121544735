import axios from 'axios';
import { ICollectionWithIdRequestParams } from 'common/models/request.model';
import { objectToQuery } from 'common/helpers/request.helper';
import { ILoanAppAuditCollection } from 'entities/LoanAppAudit/LoanAppAudit.models';

const basePath = '/loan-application-audit';

export const loanAppAuditTransport = {
  getLoanAppAuditCollection: ({ id, limit = 50, ...rest }: ICollectionWithIdRequestParams): Promise<ILoanAppAuditCollection> =>
    axios.get(`${basePath}/${id}${objectToQuery({ limit, ...rest })}`).then(r => r.data)
};
