import React, { RefObject } from 'react';
import { FormInstance } from 'antd';
import IdentityDocuments from 'entities/KYCProvider/components/IdentityDocuments';
import { EDocTypes, EKycSteps } from 'entities/KYCProvider/KYC.const';
import { communicationKyc, IKycConnectedProps } from 'entities/KYCProvider/KYCProvider.communication';
import { IKYCStatus } from 'entities/KYCProvider/KYCProvider.models';
import { IFileModel } from 'entities/LoanApp/LoanApp.models';
import { setDisable } from 'entities/KYCProvider/helpers/KYCProvider.helper';

interface IComponentState {
  docType: EDocTypes | undefined;
}

interface IComponentProps {
  form: RefObject<FormInstance<any>>;
  options: EDocTypes[];
  setDocType: (docType: EDocTypes) => void;
  required?: boolean;
}

type AllProps = IComponentProps & IKycConnectedProps;

class CurrentIDStep extends React.Component<AllProps, IComponentState> {
  state = {
    docType: undefined
  };

  componentDidMount() {
    const { options, setDocType, kycStatus } = this.props;
    const { data } = kycStatus;
    const initialDocTypeType = data?.steps.idPrimary?.data?.fileType;

    if (initialDocTypeType) {
      // @ts-ignore
      this.setState({ docType: initialDocTypeType });
      // @ts-ignore
      setDocType(initialDocTypeType);
    } else {
      this.setState({ docType: options[0] });
      setDocType(options[0]);
    }
  }

  render() {
    const { form, options, required, kycStatus } = this.props;
    const { docType } = this.state;
    const { data } = kycStatus;
    const initialValues = this.getInitialValues(data);
    const disabled = setDisable(data, EKycSteps.IdPrimary);

    return (
      <IdentityDocuments
        form={form}
        options={options}
        docType={docType}
        changeDocType={this.changeDocType}
        disabled={disabled}
        initialValues={initialValues}
        required={required}
      />
    );
  }

  getInitialValues = (data: IKYCStatus | null) => {
    const { docType } = this.state;
    const initialDocTypeType = data?.steps.idPrimary?.data?.fileType;
    const initialValues = data?.steps.idPrimary?.data?.files?.map((file: IFileModel) => ({ id: file.id, name: file.name }));

    return docType === initialDocTypeType ? initialValues : undefined;
  };

  changeDocType = (docType: EDocTypes) => {
    const { setDocType } = this.props;

    this.setState({ docType });
    setDocType(docType);
  };
}

export default communicationKyc.injector(CurrentIDStep);
