import React from 'react';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';

interface IComponentProps {
  icon: JSX.Element;
  title: string;
  amount?: number;
}

export const AmountCard: React.FC<IComponentProps> = ({ icon, title, amount }) => (
  <div className="d-flex d-flex_align_center amount-card">
    {icon}
    <span>{title}:</span>
    <span>
      {amount} {''}
      <AccountCurrency />
    </span>
  </div>
);
