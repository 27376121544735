import React from 'react';
import { Button } from 'antd';
import i18n from 'i18n';
import { DataCard } from 'common/components/Card/DataCard';

export class AdditionalInfoRequestComponent extends React.Component {
  render() {
    return (
      <DataCard
        mainClassName="borderless"
        header={i18n.t('loanInfoStatusAddition')}
        description={i18n.t('loanInfoStatusAdditionDescription')}
        footer={
          <div className="attachment__button">
            <Button className="submit-button" type="primary">
              {i18n.t<string>('upload')}
            </Button>
          </div>
        }
      />
    );
  }
}

export const AdditionalInfoRequest = AdditionalInfoRequestComponent;
