import { Col, Row, Skeleton } from 'antd';
import React from 'react';
import { LayoutBasic } from 'common/components/Layouts/LayoutBasic';

export const LoadingPageForInvestor = () => {
  return (
    <LayoutBasic className="pt-10 pb-10 white-layout">
      <Skeleton active paragraph={{ rows: 3 }} className="bordered-card p-5 mb-6" />
      <Row gutter={24} justify="center">
        <Col md={24} xl={16}>
          <Col span={12}>
            <Skeleton active paragraph={{ rows: 10 }} className="bordered-card p-5 mb-6" />
          </Col>
          <Col span={12}>
            <Skeleton active paragraph={{ rows: 10 }} className="bordered-card p-5 mb-6" />
          </Col>
        </Col>
        <Col span={8}></Col>
      </Row>
    </LayoutBasic>
  );
};
