import { actionsTypes, APIProvider, BaseStrategy, Branch, buildCommunication, StoreBranch } from '@axmit/redux-communications';

const namespace = 'currency';

export interface ICurrencyConnectedProps {
  currencyModel: StoreBranch<string>;
  addCurrencyModel(currency: string): void;
}

const currencyModelApiProvider = [new APIProvider(actionsTypes.add, data => data)];

const branches = [new Branch('model', currencyModelApiProvider)];

const strategy = new BaseStrategy({
  namespace,
  // @ts-ignore
  branches
});

export const communicationCurrency = buildCommunication<ICurrencyConnectedProps>(strategy);
