import React, { SyntheticEvent } from 'react';
import i18n from 'i18n';
import { Row } from 'antd';
import { cutFileName } from 'common/helpers/fileLoader.helper';
import GreenMark from 'app/assets/icons/GreenMark.svg';
import CrossInRound from 'app/assets/icons/CrossInRound.svg';
import UploadIcon from 'app/assets/icons/UploadIcon.svg';

interface IComponentProps {
  fileName: string;
  removeFile: (event: SyntheticEvent) => void;
}

export const UploadField = (props: IComponentProps) => {
  const { fileName, removeFile } = props;
  return (
    <Row justify="space-between" align="middle" className="pl-4">
      <Row align="middle" style={{ flexFlow: 'nowrap' }}>
        <img src={fileName ? GreenMark : UploadIcon} alt="Green mark" className="mr-4" />
        <div className="simple-text color-grayDarkest">
          {fileName ? cutFileName(fileName) : i18n.t<string>('withdrawalTab.selectDocumentToUpload')}
        </div>
      </Row>
      {fileName && <img src={CrossInRound} alt="Remove file button" className="mr-4" onClick={removeFile} />}
    </Row>
  );
};
