import React from 'react';
import i18n from 'i18n';
import { Popup } from 'common/components/Popup';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { LoanAppInfoByBorrower } from 'entities/Onboarding/components/LoanAppInfoByBorrower';

interface IComponentState {
  edit: boolean;
}

type AllProps = IUsersConnectedProps & ILoanAppConnectedProps;

class LoanInfoEditComponent extends React.Component<AllProps, IComponentState> {
  state = { edit: false };

  render() {
    const { edit } = this.state;
    const { loanAppModel } = this.props;
    const { data: loanApp } = loanAppModel;

    if (!loanApp) {
      return null;
    }

    return (
      <>
        <div className="application-info__change-conditions">
          <p onClick={this.toggleEdit}>{i18n.t<string>('loanInfoChangeConditions')}</p>
        </div>
        <Popup title={i18n.t<string>('editLoanInfoButton')} visible={edit} onCancel={this.toggleEdit}>
          <LoanAppInfoByBorrower edit onSubmit={this.toggleEdit} />
        </Popup>
      </>
    );
  }

  toggleEdit = () => {
    this.setState(prevState => ({
      edit: !prevState.edit
    }));
  };
}

export const LoanInfoEdit = communicationUser.injector(communicationLoanApp.injector(LoanInfoEditComponent));
