import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AmountHelper } from 'common/helpers/amount.helper';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';

interface IComponentProps {
  companyId: string;
  ownerCurrency: string;
}

type AllProps = IComponentProps & ILoanAppConnectedProps;

function CompanyDetailsDescriptionStat(props: AllProps) {
  const { loanAppStat, ownerCurrency } = props;
  const { data: statistic } = loanAppStat;
  const { t } = useTranslation();

  useEffect(() => {
    const { getLoanAppStat, companyId } = props;

    getLoanAppStat(companyId);
  }, []);

  if (!statistic) {
    return null;
  }

  return (
    <div className="d-flex gap_md mb-3 mt-6">
      <div className="stat-card d-flex__item">
        <div className="stat-card__header">{t('companyDetails.stats.active')}</div>
        <div className="stat-card__content">{getContent(statistic.active.count, statistic.active.sum, ownerCurrency)}</div>
      </div>

      <div className="stat-card d-flex__item">
        <div className="stat-card__header">{t('companyDetails.stats.app')}</div>
        <div className="stat-card__content">
          {getContent(statistic.applications.count, statistic.applications.sum, ownerCurrency)}
        </div>
      </div>
    </div>
  );
}

function getContent(count: number, sum: number, ownerCurrency: string): JSX.Element {
  const currency = sum ? ` (${AmountHelper.getCurrency(sum, ownerCurrency)})` : '';

  return (
    <>
      <span className="ff-semiBold">{count}</span>
      {currency}
    </>
  );
}

export default communicationLoanApp.injector(CompanyDetailsDescriptionStat);
