import React from 'react';
import { EOnboardingStepStatus, IOnboardingStepperItem } from 'entities/Onboarding/OnboardingSteps.models';
import { OnboardingProgressBarItem } from 'entities/Onboarding/components/OnboardingProgressBarItem';
import { communicationOnboarding, IOnboardingConnectedProps } from 'entities/Onboarding/OnboardingSteps.communication';
import { communicationKyc, IKycConnectedProps } from 'entities/KYCProvider/KYCProvider.communication';
import { EKycStatus } from 'entities/KYCProvider/KYC.const';

interface IComponentProps {
  steps?: IOnboardingStepperItem<string>[];
}

type AllProps = IOnboardingConnectedProps & IKycConnectedProps & IComponentProps;

class OnboardingProgressBarComponent extends React.Component<AllProps> {
  render() {
    const { kycStatus, kycSubmit, onboardingStepsModel, steps, setOnboardingStepsModel } = this.props;
    const { data } = onboardingStepsModel;
    const { data: kycStatusData } = kycStatus;
    const { loading: kycSubmitLoading } = kycSubmit;

    return (
      <div className="onboarding-progress-bar">
        <div className="onboarding-progress-bar__track" />
        {steps?.map((item, index) => {
          const itemStatus = this.getStatusForPoint(item.step, index);

          return (
            <OnboardingProgressBarItem
              key={item.step}
              item={item}
              itemStatus={itemStatus}
              currentStep={data?.step}
              kycStatus={kycStatusData?.status || EKycStatus.Pending}
              kycSubmitLoading={kycSubmitLoading}
              setOnboardingStepsModel={setOnboardingStepsModel}
            />
          );
        })}
      </div>
    );
  }

  getStatusForPoint = (step: string, index: number): 'approved' | 'current' | '' => {
    const { onboardingModel, steps } = this.props;
    const { data: onboarding } = onboardingModel;

    if (!onboarding) {
      return '';
    }

    // @ts-ignore
    const newStep = onboarding[step] as EOnboardingStepStatus;
    // @ts-ignore
    const prevStep = index !== 0 ? (onboarding[steps[index - 1].step] as EOnboardingStepStatus) : undefined;

    if (newStep === EOnboardingStepStatus.Completed || newStep === EOnboardingStepStatus.Skipped) {
      return 'approved';
    }

    if (newStep === EOnboardingStepStatus.InProgress) {
      return 'current';
    }

    if (index === 0 && newStep === EOnboardingStepStatus.New) {
      return 'current';
    }

    if (index !== 0 && (prevStep === EOnboardingStepStatus.Completed || prevStep === EOnboardingStepStatus.Skipped)) {
      return 'current';
    }

    return '';
  };
}

export const OnboardingProgressBar = communicationOnboarding.injector(communicationKyc.injector(OnboardingProgressBarComponent));
