import React from 'react';
import { Col, Row } from 'antd';
import i18n from 'i18n';
import { Link } from 'react-router-dom';
import { InfoSection } from 'common/components/InfoSection/InfoSection';
import { InfoSectionItem } from 'common/components/InfoSection/InfoSectionItem';
import { ESpecialistRoutes } from 'common/models/routes.model';
import { CompanyDetailsDescriptionNotes } from 'common/components/CompanyDetailsDescriptionNotes';
import CompanyDetailsDescriptionStat from 'common/components/CompanyDetailsDescriptionStat';
import { defaultCurrency } from 'common/consts';
import { ICompanyModel } from 'entities/Onboarding/OnboardingSteps.models';
import { communicationCurrency, ICurrencyConnectedProps } from 'entities/Currency/Currency.communication';
import { EUserRoles } from 'entities/User/User.models';
import { UserUpswotLink } from 'entities/Upswot/components/UserUpswotLink';

interface IComponentProps {
  company: ICompanyModel;
  companyClickable?: boolean;
  userRole?: EUserRoles.Investor | EUserRoles.Specialist;
  splitData?: boolean;
  reloadCompany: () => void;
  userId?: string;
}

interface ICompanyDescriptionItem {
  label: string;
  value: string | JSX.Element;
}

type AllProps = IComponentProps & ICurrencyConnectedProps;

const CompanyDetailsDescriptionComponent: React.FC<AllProps> = props => {
  const { company, userRole, splitData, reloadCompany, userId } = props;
  const isManager = userRole === EUserRoles.Specialist;

  const companyDescription = getCompanyDescription();

  if (splitData) {
    const arrLength = companyDescription.length % 2 === 0 ? companyDescription.length / 2 : companyDescription.length / 2 + 1;
    const firstPart = companyDescription.slice(0, arrLength);
    const secondPart = companyDescription.slice(arrLength);

    return (
      <InfoSection header={i18n.t('companyDetails.header')}>
        {isManager && (
          <CompanyDetailsDescriptionStat companyId={company.id} ownerCurrency={company.currency || defaultCurrency} />
        )}

        <Row gutter={140}>
          <Col xs={24} lg={12}>
            {firstPart.map(({ label, value }, index) => (
              <InfoSectionItem key={index} title={label} value={value} />
            ))}
          </Col>
          <Col xs={24} lg={12}>
            {secondPart.map(({ label, value }, index) => (
              <InfoSectionItem key={index} title={label} value={value} />
            ))}
          </Col>
        </Row>
      </InfoSection>
    );
  }
  return (
    <InfoSection header={i18n.t('companyDetails.header')}>
      <Row>
        <Col xs={24}>
          {isManager && (
            <CompanyDetailsDescriptionStat companyId={company.id} ownerCurrency={company.currency || defaultCurrency} />
          )}

          {companyDescription.map(({ label, value }, index) => (
            <InfoSectionItem key={index} title={label} value={value} />
          ))}

          {isManager && userId && <UserUpswotLink userId={userId} />}

          <CompanyDetailsDescriptionNotes
            editable={userRole === EUserRoles.Specialist}
            companyId={company.id}
            notes={company.notes}
            reloadCompany={reloadCompany}
          />
        </Col>
      </Row>
    </InfoSection>
  );

  function getCompanyDescription() {
    const { name, address, legalForm, questionary, uid, industry, userId, currency } = company;
    const { companyClickable } = props;

    const descriptionItems: ICompanyDescriptionItem[] = [];
    const UID = { label: 'UID', value: uid };

    if (questionary) {
      const { monthlyRevenue, additionalInfo, state, annualProfits } = questionary;

      const whatCompanyDo = {
        label: i18n.t('companyDetails.whatCompanyDo'),
        value: additionalInfo
          ? Object.keys(additionalInfo)
              .map(item => i18n.t(item))
              .join(', ')
          : '- -'
      };

      if (userRole !== EUserRoles.Investor) {
        descriptionItems.push(
          {
            label: i18n.t('companyDetails.name'),
            value: companyClickable ? (
              <Link target="_blank" to={userId ? `${ESpecialistRoutes.Users}${userId}` : ESpecialistRoutes.Clients}>
                {name}
              </Link>
            ) : (
              name
            )
          },
          { label: i18n.t('companyDetails.address'), value: address },
          { label: i18n.t('companyDetails.legalForm'), value: legalForm },
          { label: i18n.t('companyDetails.industry'), value: i18n.t(industry?.name || '') },
          UID,
          {
            label: i18n.t('companyDetails.monthlyRevenue'),
            value: `${monthlyRevenue} ${currency}`
          }
        );

        state && descriptionItems.push({ label: i18n.t('companyDetails.stateOfYourBusiness'), value: state });

        descriptionItems.push(
          { label: i18n.t('companyDetails.annualProfits'), value: `${annualProfits} ${currency}` },
          whatCompanyDo
        );
      } else {
        descriptionItems.push(
          { label: i18n.t('companyDetails.legalForm'), value: legalForm },
          { label: i18n.t('companyDetails.industry'), value: i18n.t(industry?.name || '') },
          {
            label: i18n.t('companyDetails.monthlyRevenue'),
            value: `${monthlyRevenue} ${currency}`
          },
          { label: i18n.t('companyDetails.operatingYears'), value: '-' },
          whatCompanyDo
        );
      }
    } else {
      descriptionItems.push(UID);
    }
    return descriptionItems;
  }
};

export const CompanyDetailsDescription = communicationCurrency.injector(CompanyDetailsDescriptionComponent);
