import React from 'react';
import i18n from 'i18n';
import { Attachment } from 'common/components/Attachment';
import { Description } from 'common/components/Description/Description';
import { IFileModel } from 'entities/LoanApp/LoanApp.models';
import { IUserFileModel } from 'entities/User/User.models';
import { docNames } from 'entities/KYCProvider/KYC.const';

interface IComponentProps {
  files: IUserFileModel[];
}

export const AttachedDocs: React.FC<IComponentProps> = props => {
  const { files } = props;
  const attachmentsMap = new Map<string, IFileModel[]>();

  files.forEach((item: any) => {
    const { step, file } = item;
    const docData = attachmentsMap.get(step);

    if (file) {
      if (docData) {
        docData.push(file);
        attachmentsMap.set(step, docData);
      } else {
        attachmentsMap.set(step, [file]);
      }
    }
  });
  const attachments: JSX.Element[] = [];

  attachmentsMap.forEach((value: IFileModel[], key: string) =>
    attachments.push(<Attachment key={key} title={docNames[key] || key} files={value} />)
  );

  return (
    <Description classNameBody="pt-7" header={i18n.t('attachedDocuments')}>
      {attachments}
    </Description>
  );
};
