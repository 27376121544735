import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Button, Progress, Row } from 'antd';
import i18n from 'i18n';
import { InfoBlock } from 'common/components/InfoSection/InfoBlock';
import { ECommonRoutes } from 'common/models/routes.model';
import ProgressIcon from 'app/assets/icons/ProgresIcon.svg';
import { RepaymentsTable } from 'entities/LoanApp/components/RepaymentsTable';
import { ELoanAppStatus, EPaymentTypes } from 'entities/LoanApp/LoanApp.const';
import { EWalletTabs } from 'entities/Wallet/Wallet.const';
import { getNextPaymentData } from 'entities/LoanApp/LoanApp.helpers';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';
import { IPaymentScheduleCollection } from 'entities/PaymentSchedules/PaymentSchedules.models';
import { EarlyRepaymentModal } from 'entities/LoanApp/components/EarlyRepaymentModal';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';

interface IComponentState {
  repay: boolean;
}

interface IComponentProps {
  loanId: string;
  status: string;
  repaymentAmount?: number;
  userRole?: string;
  paymentScheduleData?: IPaymentScheduleCollection | null;
}

type AllProps = IUsersConnectedProps & IComponentProps & RouteComponentProps<{ id: string }> & ILoanAppConnectedProps;

class LoanInfoActiveComponent extends React.Component<AllProps, IComponentState> {
  state = { repay: false };

  componentDidMount() {
    const { loanId, getLoanAppRepaymentDetails } = this.props;

    getLoanAppRepaymentDetails(loanId);
  }

  render() {
    const { status, userRole, paymentScheduleData, userBalance, addLoanAppRepaymentDetails, match } = this.props;
    const { repay } = this.state;
    const paymentData = paymentScheduleData?.data;
    const loanId = match.params.id;
    const balance = userBalance.data?.balance;

    return (
      <>
        <div className="active-info-block">
          <div className="d-flex d-flex_align_center d-flex_justify_between">
            {this.getDebtPayment() < 0 && (
              <InfoBlock
                title={i18n.t('depositAmount')}
                infoValue={{ partOne: -this.getDebtPayment().toFixed(2), partTwo: <AccountCurrency /> }}
              />
            )}
            {status !== ELoanAppStatus.Completed && (
              <Button className="form-submit-button mb-7" onClick={this.toggleRepay}>
                {i18n.t<string>('loanInfoEarlyRepayment.earlyRepayment')}
              </Button>
            )}
          </div>
          {status !== ELoanAppStatus.Completed && (
            <div className="fs-xs">
              <Trans i18nKey="depositOptions">
                You can make a deposit using a <Link to="#">credit card</Link> or make a wire bank transfer on the
                <Link to={`${ECommonRoutes.Wallet}?tab=${EWalletTabs.Deposit}`}> wallet</Link> page
              </Trans>
            </div>
          )}
        </div>
        <p className="active-info-block__title">{i18n.t<string>('loanInfoYourLoanRepaymentProgress')}</p>
        <Progress
          className="active-info-block__progress-bar"
          percent={this.progressValue()}
          status="exception"
          strokeColor="#794dd3"
          showInfo={false}
          strokeLinecap="square"
        />
        <Row align="top" justify="space-between">
          <p className="active-info-block__subtitle">{i18n.t<string>('loanInfoRepaymentProgressDescription.0')}</p>
          <img src={ProgressIcon} alt="progress" />
        </Row>

        <RepaymentsTable userRole={userRole} scheduleData={paymentData} />

        <EarlyRepaymentModal
          repay={repay}
          balance={balance}
          confirm={() => addLoanAppRepaymentDetails(loanId)}
          cancel={this.toggleRepay}
        />
      </>
    );
  }

  progressValue = () => {
    const { paymentScheduleData, repaymentAmount } = this.props;
    const paymentData = paymentScheduleData?.data;

    let progress = 0;
    if (paymentData && repaymentAmount) {
      const paidAmount = paymentData
        ?.filter(item => item.isPaid)
        ?.reduce((acc, it) => (it.totalPayment ? acc + it.totalPayment : acc), 0);

      progress = (paidAmount * 100) / repaymentAmount;
      return progress;
    }
    return progress;
  };

  getDebtPayment = () => {
    const { paymentScheduleData, userBalance } = this.props;
    const paymentData = paymentScheduleData?.data;
    const balance = userBalance.data?.balance;

    let debtPayment: number = 0;

    // TODO Replace to helper
    const penaltyAmount = paymentData
      ?.filter(item => item.type === EPaymentTypes.Penalty && !item.isPaid)
      ?.reduce((acc, it) => (it.totalPayment ? acc + it.totalPayment : acc), 0);

    const nextPaymentData = getNextPaymentData(paymentData);

    if (balance !== undefined && penaltyAmount !== undefined && nextPaymentData?.totalPayment) {
      debtPayment = balance - penaltyAmount - nextPaymentData?.totalPayment;
    }

    return debtPayment;
  };

  toggleRepay = () => {
    this.setState(prevState => ({
      repay: !prevState.repay
    }));
  };
}

export const LoanInfoActive = communicationUser.injector(communicationLoanApp.injector(withRouter(LoanInfoActiveComponent)));
