import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Pagination, Table } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { SorterResult } from 'antd/es/table/interface';
import { EAdminRoutes, ESpecialistRoutes } from 'common/models/routes.model';
import { getFormattedPagination } from 'common/helpers/table.helper';
import { EUserRoles, IGetUserCollectionParams, IUserCollection, IUserModel } from 'entities/User/User.models';
import { borrowerColumns, investorsColumns, managersColumns } from 'entities/User/User.const';

interface IComponentProps {
  data: IUserCollection;
  applyParams: (params: IGetUserCollectionParams) => void;
  loading?: boolean;
  role?: string;
}

type AllProps = RouteComponentProps & IComponentProps;

class UsersTableComponent extends React.Component<AllProps> {
  render() {
    const { data = { page: 0, total: 0, data: undefined }, loading, role } = this.props;
    const { page, total, data: usersData } = data;
    const onRowMethod = role === EUserRoles.Specialist ? this.onRow : undefined;

    return (
      <>
        <Table
          rowKey="id"
          dataSource={usersData}
          columns={this.getColumns()}
          className="custom-table user-table"
          pagination={false}
          onChange={this.onTableChange}
          rowClassName={record => (record.profile ? 'custom-table__row_clickable' : '')}
          loading={loading}
          onRow={record => ({
            onClick: () => {
              if (onRowMethod) {
                onRowMethod(record);
              }
            }
          })}
        />
        <Pagination size="small" defaultCurrent={1} current={page} total={total} onChange={this.onPageChange} />
      </>
    );
  }

  onTableChange = (
    pagination: TablePaginationConfig,
    filters: Partial<Record<keyof IUserModel, string[]>>,
    sorter: SorterResult<IUserModel> | SorterResult<IUserModel>[]
  ) => {
    const { applyParams } = this.props;

    if (!(sorter instanceof Array) && sorter.field) {
      const field = sorter.column?.key || sorter.field;

      if (sorter.order) {
        applyParams({ orderDirection: sorter.order === 'descend' ? 'DESC' : 'ASC', orderField: field.toString() });
      } else {
        applyParams({ orderDirection: undefined, orderField: undefined });
      }
    }
  };

  onPageChange = (page: number, pageSize: number) => {
    const { applyParams } = this.props;

    applyParams(getFormattedPagination(page, pageSize));
  };

  onRow = (record: IUserModel) => {
    if (!record.profile) {
      return;
    }

    const { history } = this.props;
    history.push(`${ESpecialistRoutes.Users}${record.id}`);
  };

  getColumns = (): ColumnProps<IUserModel>[] => {
    const { path } = this.props.match;

    switch (path) {
      case ESpecialistRoutes.Investors:
        return investorsColumns;
      case ESpecialistRoutes.Clients:
        return borrowerColumns;
      case EAdminRoutes.Managers:
        return managersColumns;
      default:
        return [];
    }
  };
}

export const UsersTable = withRouter(UsersTableComponent);
