import React, { useEffect } from 'react';
import i18n from 'i18n';
import { Description } from 'common/components/Description/Description';
import { Attachment } from 'common/components/Attachment';
import { InfoSection } from 'common/components/InfoSection/InfoSection';
import { communicationLoanDocuments, ILoanDocumentsConnectedProps } from 'entities/LoanDocuments/LoanDocuments.communication';

interface IComponentProps {
  loanId: string;
  cardView?: boolean;
}

type AllProps = ILoanDocumentsConnectedProps & IComponentProps;

const AdditionalDocsComponent: React.FC<AllProps> = props => {
  const { loanDocumentsCollection, loanId, cardView } = props;
  const { data: documents } = loanDocumentsCollection;

  useEffect(() => {
    const { getLoanDocumentsCollection } = props;

    getLoanDocumentsCollection(loanId);
  }, [loanId]);

  if (!documents || !documents.length) {
    return null;
  }

  const content = documents.map(({ documents: docs }) =>
    docs.map(({ id: docId, name, files }) => (
      <Attachment title={name.toString()} files={files.map(file => ({ ...file, name: file.name.toString() }))} key={docId} />
    ))
  );

  return cardView ? (
    <Description classNameBody="pt-7" header={i18n.t('requestedDocuments')}>
      {content}
    </Description>
  ) : (
    <InfoSection header={i18n.t('requestedDocuments')}>{content}</InfoSection>
  );
};

export const AdditionalDocs = communicationLoanDocuments.injector(AdditionalDocsComponent);
