import React from 'react';
import { Row, Spin } from 'antd';

export const LoadingSpinner: React.FC = () => {
  return (
    <Row justify="center">
      <Spin size="large" className="m-11" />
    </Row>
  );
};
