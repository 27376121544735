import React from 'react';
import i18n from 'i18n';
import { getPersonalInfo } from 'common/helpers/common.helper';
import { DescriptionItem } from 'common/components/Description/DescriptionItem';
import { Description } from 'common/components/Description/Description';
import { IUserProfile } from 'entities/User/User.models';

interface IComponentProps {
  profile: IUserProfile;
  email?: string;
  currency?: string;
  footer?: JSX.Element;
}

export const PersonalDetails: React.FC<IComponentProps> = props => {
  const { profile, footer, email, currency } = props;
  const personalDetails = getPersonalInfo(profile, email, currency);

  return (
    <Description header={i18n.t('profileDetails')}>
      {personalDetails.map(item => item.value && <DescriptionItem key={item.title} title={item.title} endValue={item.value} />)}
      {footer && <div className="mt-4">{footer}</div>}
    </Description>
  );
};
