import React from 'react';
import { useParams } from 'react-router';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { BorrowerLoan } from 'entities/LoanApp/components/BorrowerLoan';

type AllProps = ILoanAppConnectedProps;

const BorrowerLoanPageComponent: React.FC<AllProps> = () => {
  const { id } = useParams<{ id: string }>();

  return id ? <BorrowerLoan loanId={id} /> : null;
};

export const BorrowerLoanPage = communicationLoanApp.injector(BorrowerLoanPageComponent);
