import React from 'react';
import { Col, Dropdown, Menu, Row } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import i18n from 'i18n';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { languages } from 'common/models/languages.model';
import { ECommonRoutes } from 'common/models/routes.model';
import { Avatar } from 'common/components/Avatar';
import { adminMenu, borrowerMenu, investorMenu, specialistMenu } from 'common/components/Header/Header.const';
import { MenuWithItems } from 'common/components/Header/MenuWithItems';
import { BalanceBlock } from 'common/components/Header/BalanceBlock';
import { getLandingDomainName } from 'common/helpers/domainName.helper';
import LightLogo from 'app/assets/icons/LightLogo.png';
import DarkLogo from 'app/assets/icons/DarkLogo.png';
import WhiteCross from 'app/assets/icons/WhiteCross.svg';
import { EUserRoles, EUserStatus } from 'entities/User/User.models';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { EWalletTabs } from 'entities/Wallet/Wallet.const';

interface IComponentState {
  langTitle: string;
}

type AllProps = IAuthConnectedProps & IUsersConnectedProps & RouteComponentProps;

class HeaderComponent extends React.Component<AllProps, IComponentState> {
  state = {
    langTitle: ''
  };

  langMenuItems = (
    <Menu
      className="header__dropdown"
      items={languages.map(({ value, short, key }) => ({
        label: value,
        key,
        onClick: () => this.changeLang(short, key)
      }))}
    />
  );

  componentDidMount(): void {
    const langTitle = this.defineLang();
    this.setState({ langTitle });
  }

  render() {
    const { langTitle } = this.state;
    const { authModel, userCurrent, location } = this.props;
    const { data: authData } = authModel;
    const { data: userData } = userCurrent;

    const profile = userData?.profile;
    const userId = userData?.id;
    const role = userData?.role;
    const status = userData?.status;
    const authorized = authData?.access;

    const notForMaintainers = role !== EUserRoles.Specialist && role !== EUserRoles.Admin;
    const confirmedUser =
      status === EUserStatus.Confirmed || (role === EUserRoles.Borrower && status === EUserStatus.Unconfirmed);
    const url = getLandingDomainName();

    const profileMenuItems = (
      <Menu className="header__dropdown">
        {notForMaintainers && profile && confirmedUser && (
          <Menu.Item onClick={this.redirectToWallet} key="myWallet">
            {i18n.t<string>('headerMyWallet')}
          </Menu.Item>
        )}
        {profile && confirmedUser && (
          <Menu.Item onClick={this.redirectToProfile} key="profile">
            {i18n.t<string>('headerProfile')}
          </Menu.Item>
        )}
        <Menu.Item onClick={this.logOut} className="mt-5" key="logout">
          {i18n.t<string>('headerLogOutButton')}
        </Menu.Item>
      </Menu>
    );

    return (
      <Row
        justify="space-between"
        align="middle"
        className={`header header--${authorized ? 'authorized' : ''} header--${
          location.pathname === ECommonRoutes.Onboarding ? 'onboarding' : ''
        }`}
      >
        <Col xl={2} lg={2} md={2} xs={2}>
          <Link to={ECommonRoutes.Main}>
            <img src={authorized ? DarkLogo : LightLogo} alt="Main logo" />
          </Link>
        </Col>
        <Col xl={15} lg={{ span: 14, order: 2 }} md={2} xs={{ span: 2, order: 3 }}>
          {this.getMenu()}
        </Col>

        <Col xl={7} lg={{ span: 8, order: 3 }} md={20} xs={{ span: 20, order: 2 }}>
          <Row justify="end" align="middle" gutter={16}>
            <Col lg={12} md={7}>
              {notForMaintainers && profile && <BalanceBlock userId={userId} />}
            </Col>
            <Col lg={4} md={3}>
              {/* TODO: Remove "header__lang" class after implementing translations */}
              <Dropdown trigger={['click']} overlay={this.langMenuItems} placement="bottom" className="pointer header__lang">
                <Row align="middle" justify="end" className="fs-xs color-white">
                  {langTitle}
                  <DownOutlined className="ml-2" />
                </Row>
              </Dropdown>
            </Col>
            <Col lg={5} md={3}>
              {authorized ? (
                // @ts-ignore
                <Dropdown trigger={['click']} overlay={profileMenuItems} placement="bottom" className="pointer">
                  <Row align="middle">
                    <Avatar profile={profile} />
                    <DownOutlined className="ml-2 color-white" />
                  </Row>
                </Dropdown>
              ) : (
                <Row justify="end" align="middle">
                  <a href={url}>
                    <img src={WhiteCross} alt="Cross button" />
                  </a>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  getMenu = () => {
    const { userCurrent, history } = this.props;
    const role = userCurrent.data?.role;
    const status = userCurrent.data?.status;
    const needOnboarding = status === EUserStatus.Onboarding;
    const unconfirmed = status === EUserStatus.Unconfirmed;
    // const isBlocked = status === EUserStatus.Blocked;
    const isBlocked = false;
    const { pathname } = history.location;

    switch (role) {
      case EUserRoles.Specialist:
        return <MenuWithItems items={specialistMenu} path={pathname} history={history} />;
      case EUserRoles.Admin:
        return <MenuWithItems items={adminMenu} path={pathname} history={history} />;
      case EUserRoles.Investor:
        return isBlocked || needOnboarding || unconfirmed ? (
          <div></div>
        ) : (
          <MenuWithItems items={investorMenu} path={pathname} history={history} />
        );
      case EUserRoles.Borrower:
        return isBlocked || needOnboarding ? (
          <div></div>
        ) : (
          <MenuWithItems items={borrowerMenu} path={pathname} history={history} />
        );
      default:
        return [];
    }
  };

  defineLang = () => {
    const definiteLang = i18n.language.slice(0, 2);
    const isLangExist = languages.find(item => item.short === definiteLang);
    /* Language will always be found because the i18n provides the default language (De) if definite language doesn't exist
    in whitelist from config. But we need this check because linter doesn't know about it.*/
    return isLangExist ? isLangExist.key : '';
  };

  changeLang = (lang: string, langTitle: string) => {
    i18n.changeLanguage(lang).then();
    this.setState({ langTitle });
  };

  logIn = () => {
    const { history } = this.props;

    history.push(ECommonRoutes.LogIn);
  };

  logOut = () => {
    const { deleteAuthModel, clearUserCurrent, history } = this.props;

    history.push(ECommonRoutes.LogIn);
    deleteAuthModel();
    clearUserCurrent();
  };

  redirectToProfile = () => {
    const { history } = this.props;

    history.push(ECommonRoutes.Profile);
  };

  redirectToWallet = () => {
    const { history } = this.props;

    history.push({ pathname: ECommonRoutes.Wallet, search: `?tab=${EWalletTabs.Deposit}` });
  };
}

export const Header = communicationUser.injector(communicationAuth.injector(withRouter(HeaderComponent)));
