import {
  actionsTypes,
  APIProvider,
  BaseStrategy,
  Branch,
  buildCommunication,
  getStartType,
  StoreBranch
} from '@axmit/redux-communications';
import { put } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { message } from 'antd';
import i18n from 'i18n';
import { ECommonRoutes } from 'common/models/routes.model';
import { IModelRequestParams } from 'common/models/request.model';
import { showPostFailErrors } from 'common/helpers/errors.helper';
import { EErrorStatus } from 'common/models/errors.model';
import { RequestLoadingHelper } from 'common/helpers/RequestLoading.helper';
import {
  IBalanceModel,
  IConfirmationCode,
  IDepositInfoModel,
  IGetUserCollectionParams,
  INewManagerRequestModel,
  INewManagerResponseModel,
  INewPasswordModel,
  IResendRequestModel,
  IRestorePasswordModel,
  IUpdateUserModel,
  IUserCollection,
  IUserModel,
  IUserSignUpModel
} from 'entities/User/User.models';
import { IAuthModel } from 'entities/Auth/Auth.models';
import { userTransport } from 'entities/User/User.transport';

const namespace = 'user';

export interface IUserStoreProps {
  model: StoreBranch<IUserModel>;
  current: StoreBranch<IUserModel>;
}

export interface IUsersConnectedProps {
  userModel: StoreBranch<IUserModel, IAuthModel>;
  userCollection: StoreBranch<IUserCollection>;
  userCurrent: StoreBranch<IUserModel, string, any>;
  userManager: StoreBranch<INewManagerResponseModel, INewManagerRequestModel, any>;
  userRegistration: StoreBranch<IUserModel, IUserSignUpModel, any>;
  userBalance: StoreBranch<IBalanceModel>;
  userDepositInfo: StoreBranch<IDepositInfoModel>;
  addUserRegistration(params: IUserSignUpModel): void;
  confirmUserRegistration(params: IConfirmationCode): void;
  resendUserRegistration(params: IResendRequestModel): void;

  userPasswordRestore: StoreBranch<IRestorePasswordModel, IRestorePasswordModel, any>;
  addUserPasswordRestore(params: IRestorePasswordModel): void;
  updateUserPasswordRestore(params: INewPasswordModel): void;

  getUserCurrent(id: string): void;
  clearUserCurrent(): void;
  updateUserCurrent(params: IUpdateUserModel): void;
  setUserCurrent(params: IUserModel): void;

  getUserModel(params: IModelRequestParams): void;
  clearUserModel(): void;
  blockUserModel(userId: string): void;
  unblockUserModel(userId: string): void;

  getUserCollection(params: IGetUserCollectionParams): Promise<IUserCollection>;

  getUserBalance(id: string): void;
  getUserDepositInfo(id: string): void;
  addUserManager(params: INewManagerRequestModel): void;
}

const modelApiProvider = [
  new APIProvider(actionsTypes.get, userTransport.getUserModel),
  new APIProvider('block', userTransport.block, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  }),
  new APIProvider('unblock', userTransport.unblock, {
    preRequestDataMapper: RequestLoadingHelper.setOldData
  })
];
const collectionApiProvider = [
  new APIProvider(actionsTypes.get, userTransport.getUserCollection, {
    preRequestDataMapper: function(
      response: IUserCollection | null,
      payload: string,
      branchState: StoreBranch<IUserCollection, string>
    ) {
      return branchState.data;
    },
    postFailHook: function(response: any) {
      const status = response?.status;

      if (status === EErrorStatus.Forbidden) {
        message.error(i18n.t<string>('errors:forbiddenResource'));
      }
    }
  })
];

const currentApiProvider = [
  new APIProvider(actionsTypes.get, userTransport.getUserCurrent, {
    preRequestDataMapper: function(response: IUserModel | null, payload: string, branchState: StoreBranch<IUserModel, string>) {
      return branchState.data;
    },
    postSuccessHook: function*(response: IUserModel) {
      // @ts-ignore Need FIX BE
      const currency = response?.currency;

      if (!currency) {
        return;
      }

      yield put({ type: getStartType('currency', 'model', actionsTypes.add), payload: currency });
    }
  }),
  new APIProvider('set', data => data),
  new APIProvider(actionsTypes.update, userTransport.updateUserCurrent, {
    preRequestDataMapper: function(
      response: IUserModel | null,
      payload: IUpdateUserModel,
      branchState: StoreBranch<IUserModel, IUpdateUserModel>
    ) {
      return branchState.data;
    }
  })
];

const registrationApiProvider = [
  new APIProvider(actionsTypes.add, userTransport.addRegistration, {
    postSuccessHook: function*() {
      yield put(push(ECommonRoutes.EmailConfirm));
    },
    postFailHook: showPostFailErrors
  }),
  new APIProvider('confirm', userTransport.confirmRegistration, {
    postSuccessHook: function*() {
      message.success(`${i18n.t('accountConfirmed')}`);
      yield put(push(ECommonRoutes.LogIn));
    },
    postFailHook: function*() {
      yield put(push(ECommonRoutes.Main));
    }
  }),
  new APIProvider('resend', userTransport.resendRegistration, {
    postSuccessHook: function*() {
      yield put(push(ECommonRoutes.EmailConfirm));
    }
  })
];

const passwordRestoreApiProvider = [
  new APIProvider(actionsTypes.add, userTransport.restorePassword, {
    postSuccessHook: function*() {
      yield put(push(ECommonRoutes.EmailConfirm));
    }
  }),
  new APIProvider(actionsTypes.update, userTransport.updatePassword, {
    postSuccessHook: function*() {
      yield put(push(ECommonRoutes.LogIn));
      message.success(`${i18n.t('passwordUpdateSuccess')}`);
    },
    postFailHook: showPostFailErrors
  })
];

const balanceApiProvider = [new APIProvider(actionsTypes.get, userTransport.getBalance)];

const depositInfoApiProvider = [new APIProvider(actionsTypes.get, userTransport.getDepositInfo)];

const managerApiProvider = [
  new APIProvider(actionsTypes.add, userTransport.addManager, {
    postSuccessHook: function() {
      message.success(i18n.t<string>('managerSuccessfullyAdded'));
    },
    postFailHook: function(response: any) {
      const status = response?.status;

      if (status === EErrorStatus.Conflict) {
        message.error(i18n.t<string>('errors:unique.email'));
      }
    },
    clearParams: true
  })
];

const branches = [
  new Branch('model', modelApiProvider),
  new Branch('collection', collectionApiProvider, new StoreBranch({})),
  new Branch('current', currentApiProvider),
  new Branch('registration', registrationApiProvider),
  new Branch('passwordRestore', passwordRestoreApiProvider),
  new Branch('balance', balanceApiProvider),
  new Branch('depositInfo', depositInfoApiProvider),
  new Branch('manager', managerApiProvider)
];

const strategy = new BaseStrategy({
  namespace,
  branches
});

export const communicationUser = buildCommunication<IUsersConnectedProps>(strategy);
