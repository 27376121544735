import React from 'react';
import i18n from 'i18n';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Card, Col, Row, message } from 'antd';
import Radio from 'antd/lib/radio';
import { RadioChangeEvent } from 'antd/lib/radio/interface';
import numeral from 'numeral';
import { ENumberFormats, queriesForRequests } from 'common/consts';
import { ESpecialistRoutes } from 'common/models/routes.model';
import { IModelRequestParams } from 'common/models/request.model';
import { RestyledTopNavBar } from 'common/components/RestyledTopNavBar';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { SignAndInvestWidget } from 'entities/User/components/Investor/SignAndInvestWidget';
import { EInvestOptions } from 'entities/User/components/Investor/Investor.const';
import { EInvestConfirmParams, EInvestPaymentStatus, ELoanAppStatus } from 'entities/LoanApp/LoanApp.const';
import { LoadingInvestPageForInvestor } from 'entities/User/components/Investor/LoadingInvestPageForInvestor';
import { AccountCurrency } from 'entities/Currency/components/AccountCurrency';
import { SyncWithDocuSign } from 'entities/LoanApp/components/SyncWithDocuSign';

interface IComponentState {
  investOption: string;
}

type AllProps = ILoanAppConnectedProps & RouteComponentProps<{ id: string }>;

class InvestPageComponent extends React.Component<AllProps, IComponentState> {
  state = {
    investOption: EInvestOptions.Whole
  };

  componentDidMount() {
    const { getLoanAppModel, match, location } = this.props;
    const { id } = match.params;

    const payload: IModelRequestParams = { id };

    const paymentParams = new URLSearchParams(location.search);
    const event = paymentParams.get('event');

    if (event === queriesForRequests.docuSignComplete) {
      payload.event = event;
    }

    getLoanAppModel(payload);

    this.confirmInvestOrShowError();
  }

  render() {
    const { investOption } = this.state;
    const { loanAppModel, loanAppSigners, loanAppInvestors, loanAppInvestConfirm, match, getLoanAppSigners } = this.props;
    const { id } = match.params;
    const { data: loanAppData, loading: loanAppLoading } = loanAppModel;
    const { data: signersCollection } = loanAppSigners;
    const { data: investorsCollection } = loanAppInvestors;
    const { loading: confirmLoading } = loanAppInvestConfirm;
    const { name, loanAmount, status } = loanAppData || {};

    if (status && status !== ELoanAppStatus.LookingForInvestor) {
      return <Redirect to={`${ESpecialistRoutes.Loans}/${id}`} />;
    }

    return (
      <div className="content-layout">
        {loanAppLoading || !loanAppData || !signersCollection || !investorsCollection || confirmLoading ? (
          <LoadingInvestPageForInvestor />
        ) : (
          <>
            <RestyledTopNavBar entityId={id} entityName={name || ''} />
            <Row gutter={[25, 25]} justify="center">
              <Col md={24} xl={16}>
                <p className="investment-page-card-title">Investment details</p>
                <Card title={i18n.t<string>('howMuchToInvest')} bordered={false} className="shadow-card">
                  <Radio.Group defaultValue={investOption} onChange={this.onRadioChange}>
                    <div className={this.getClass(EInvestOptions.Whole)}>
                      <Radio value={EInvestOptions.Whole} className="form-radio mb-4">
                        <span className="mr-2 shadow-card__whole-title">{i18n.t<string>('wholeAmount.title')}</span>
                        <span className="ff-bold">{numeral(loanAmount).format(ENumberFormats.Real)}</span>
                        <span className="shadow-card__whole-title">
                          {' '}
                          <AccountCurrency />
                        </span>
                      </Radio>
                      <div className="shadow-card__radio-text">{i18n.t<string>('wholeAmount.text')}</div>
                    </div>
                    <div className={this.getClass(EInvestOptions.Partial)}>
                      <Radio value={EInvestOptions.Partial} className="form-radio mb-4" disabled>
                        <span className="mr-2 shadow-card__partial-title">{i18n.t<string>('partialAmount.title')}</span>
                        <span className="ff-bold">2,000.00-{numeral(loanAmount).format(ENumberFormats.Real)}</span>
                        <span className="shadow-card__partial-title">
                          {' '}
                          <AccountCurrency />
                        </span>
                      </Radio>
                      <div className="shadow-card__radio-text">{i18n.t<string>('partialAmount.text')}</div>
                    </div>
                  </Radio.Group>
                </Card>
              </Col>
              <Col md={24} xl={8}>
                <p className="investment-page-card-title">Investment summary</p>
                <SignAndInvestWidget />
              </Col>
            </Row>
          </>
        )}

        <SyncWithDocuSign
          signersCollection={signersCollection}
          onSuccess={() => {
            getLoanAppSigners(id);
          }}
        />
      </div>
    );
  }

  onRadioChange = (e: RadioChangeEvent) => {
    this.setState({ investOption: e.target.value });
  };

  getClass = (value: EInvestOptions) => {
    const { investOption } = this.state;
    const defaultClass = 'radio-layout';

    return value === investOption ? defaultClass + ' active' : defaultClass;
  };

  confirmInvestOrShowError = () => {
    const { addLoanAppInvestConfirm, match } = this.props;
    const { id } = match.params;

    const paymentParams = new URLSearchParams(window.location.search);
    const status = paymentParams.get(EInvestConfirmParams.Status);
    const reference = paymentParams.get(EInvestConfirmParams.Reference);

    switch (status) {
      case EInvestPaymentStatus.Ok:
        if (reference) {
          addLoanAppInvestConfirm({ id, reference });
        }
        break;
      case EInvestPaymentStatus.Cancelled:
        message.error(i18n.t<string>('errors:operationsCancelledByUser'));
        break;
      case EInvestPaymentStatus.Error:
        message.error(i18n.t<string>('errors:operationsCancelledByBank'));
        break;
      default:
        break;
    }
  };
}

export const InvestPage = communicationLoanApp.injector(withRouter(InvestPageComponent));
