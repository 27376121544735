import React from 'react';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ECommonRoutes } from 'common/models/routes.model';

interface IComponentProps {
  className?: string;
}

export const FormFooter: React.FC<IComponentProps> = props => {
  const { className } = props;
  return (
    <div className={`${className} form-footer`}>
      <div>{i18n.t<string>('areYouNotRegistered')}</div>
      <div>
        <Trans i18nKey="registerAsAnotherRole">
          Now
          <Link to={`${ECommonRoutes.SignUp}?role=investor`}> become an investor </Link>
          or
          <Link to={`${ECommonRoutes.SignUp}?role=borrower`}> register as a borrower </Link>
        </Trans>
      </div>
    </div>
  );
};
