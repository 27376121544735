import React from 'react';
import { IUserProfile } from 'entities/User/User.models';

interface IComponentProps {
  profile?: IUserProfile;
  className?: string;
}

export const Avatar: React.FC<IComponentProps> = props => {
  const { profile, className = 'text-avatar' } = props;
  let label = 'N/A';

  if (profile) {
    const { firstName, lastName } = profile;

    label = firstName.charAt(0) + lastName.charAt(0);
  }

  return <div className={className}>{label}</div>;
};
