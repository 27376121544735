import React from 'react';
import i18n from 'i18n';
import { Tabs } from 'antd';
import { COLLECTION_LIMIT_DEFAULT } from 'common/consts';
import { ELoanListTabs, investorLoanListTabs } from 'entities/LoanApp/LoanApp.const';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';
import { IGetLoanAppCollectionParams } from 'entities/LoanApp/LoanApp.models';
import { LoansTableRest } from 'entities/LoanApp/components/LoansTableRest';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';

interface IComponentState {
  defaultActiveKey: string;
  filter: IGetLoanAppCollectionParams;
}

type AllProps = ILoanAppConnectedProps & IUsersConnectedProps;

class InvestmentsComponent extends React.Component<AllProps, IComponentState> {
  state: IComponentState = {
    defaultActiveKey: ELoanListTabs.Active,
    filter: { limit: COLLECTION_LIMIT_DEFAULT, offset: 0 }
  };

  componentDidMount(): void {
    const { defaultActiveKey } = this.state;
    this.onSwitchTab(defaultActiveKey);
  }

  render() {
    const { filter } = this.state;
    const { loanAppCollection, getLoanAppCollection } = this.props;
    const { loading } = loanAppCollection;
    const loanList = loanAppCollection.data;

    return (
      <Tabs defaultActiveKey={ELoanListTabs.Active} onChange={this.onSwitchTab} className="custom-tabs investor-tabs">
        {investorLoanListTabs.map(item => (
          <Tabs.TabPane tab={i18n.t<string>(item.tab)} key={item.key}>
            {loanList && (
              <div className="content-layout">
                <LoansTableRest
                  className="custom-table"
                  data={loanList}
                  loading={loading}
                  reloadCollection={params => {
                    this.setState({ filter: params });
                    getLoanAppCollection(params);
                  }}
                  filter={filter}
                  tabKey={ELoanListTabs.Investments}
                />
              </div>
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
    );
  }

  onSwitchTab = (activeKey: string) => {
    const { getLoanAppCollection, userCurrent } = this.props;
    const { data: user } = userCurrent;

    if (!user) {
      return;
    }

    const { filter } = this.state;
    const newStatus = activeKey === ELoanListTabs.Active ? 'active' : 'completed';

    this.setState({ filter: { ...filter, status: newStatus, investorId: user.id } });
    getLoanAppCollection({ ...filter, status: newStatus, investorId: user.id });
  };
}

export const Investments = communicationUser.injector(communicationLoanApp.injector(InvestmentsComponent));
