import React from 'react';
import i18n from 'i18n';
import moment from 'moment';
import { Description } from 'common/components/Description/Description';
import { DescriptionItem } from 'common/components/Description/DescriptionItem';
import { EDateTimeFormats } from 'common/consts';
import { getFormattedSumWithAccountCurrency } from 'entities/LoanApp/LoanApp.helpers';
import { communicationLoanApp, ILoanAppConnectedProps } from 'entities/LoanApp/LoanApp.communication';

interface IComponentProps {
  currency?: string;
}

type AllProps = ILoanAppConnectedProps & IComponentProps;

const DefaultDetailsComponent: React.FC<AllProps> = props => {
  const { loanAppModel, loanAppRepaymentDetails, currency } = props;
  const { data: loanApp } = loanAppModel;
  const { data: repaymentDetailsData } = loanAppRepaymentDetails;

  return (
    <Description header={i18n.t('defaultInfo')}>
      <>
        <DescriptionItem
          title={i18n.t<string>('loanProps.loanIsDefaulted')}
          endValue={moment(loanApp?.closedAt).format(EDateTimeFormats.DateWithSeparator)}
        />
        <DescriptionItem
          title={i18n.t<string>('loanProps.fineAmount')}
          endValue={getFormattedSumWithAccountCurrency(repaymentDetailsData?.penaltiesTotal, undefined, currency)}
        />
        <DescriptionItem
          title={i18n.t<string>('loanProps.paidAmount')}
          endValue={getFormattedSumWithAccountCurrency(repaymentDetailsData?.paidTotal, undefined, currency)}
        />
      </>
    </Description>
  );
};

export const DefaultDetails = communicationLoanApp.injector(DefaultDetailsComponent);
