import React from 'react';

interface IComponentProps {
  label: string;
  oldValue?: string | number;
  newValue?: string | number;
  className?: string;
}

export const LoanHistoryMessage: React.FC<IComponentProps> = props => {
  const { label, oldValue, newValue, className = 'history-item__message' } = props;
  return (
    <div className={className}>
      <span className="secondary-label">{`${label}: `}</span>
      {oldValue ? (
        <>
          {oldValue} <span className="secondary-label">→</span>{' '}
        </>
      ) : (
        ''
      )}
      {newValue}
    </div>
  );
};
