import { all, put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import i18n from 'i18n';
import { push } from 'connected-react-router';
import { EErrorStatus } from 'common/models/errors.model';
import { ECommonRoutes } from 'common/models/routes.model';
import { clearAuth, communicationAuth } from 'entities/Auth/Auth.communication';
import { communicationUser } from 'entities/User/User.communication';
import { communicationLoanApp } from 'entities/LoanApp/LoanApp.communication';
import { communicationOnboarding } from 'entities/Onboarding/OnboardingSteps.communication';
import { communicationTransactions } from 'entities/Transactions/Transactions.communication';
import { communicationLoanAppBookmarks } from 'entities/Bookmarks/Bookmarks.communication';
import { communicationLoanAppAudit } from 'entities/LoanAppAudit/LoanAppAudit.communication';
import { communicationPayments } from 'entities/Wallet/Wallet.communication';
import { communicationDashboard } from 'entities/Dashboard/Dashboard.communications';
import { communicationKyc } from 'entities/KYCProvider/KYCProvider.communication';
import { communicationCurrency } from 'entities/Currency/Currency.communication';
import { communicationCompanies } from 'entities/Companies/Companies.communication';
import { communicationLoanDocuments } from 'entities/LoanDocuments/LoanDocuments.communication';
import { communicationUpswot } from 'entities/Upswot/Upswot.communication';

function* errorWatcher() {
  yield takeEvery('*', function* logger(action: any) {
    if (action.type.match('FAIL')) {
      const status = action.payload && action.payload.status;

      switch (status) {
        case EErrorStatus.BadRequest:
          message.error(i18n.t<string>('errors:badRequest'));
          break;
        case EErrorStatus.Unauthorized:
          message.error(i18n.t<string>('errors:unauthorized'));
          yield clearAuth();
          yield put(push(ECommonRoutes.LogIn));
          break;
        case EErrorStatus.InternalServerError:
          message.error(i18n.t<string>('errors:internalServerError'));
          break;
        default:
          break;
      }
    }
  });
}

export default function* rootSaga(): any {
  yield all([
    errorWatcher(),
    ...communicationAuth.sagas,
    ...communicationUser.sagas,
    ...communicationLoanApp.sagas,
    ...communicationOnboarding.sagas,
    ...communicationTransactions.sagas,
    ...communicationLoanAppBookmarks.sagas,
    ...communicationLoanAppAudit.sagas,
    ...communicationPayments.sagas,
    ...communicationDashboard.sagas,
    ...communicationCurrency.sagas,
    ...communicationCompanies.sagas,
    ...communicationLoanDocuments.sagas,
    ...communicationUpswot.sagas,
    ...communicationKyc.sagas
  ]);
}
