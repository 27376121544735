import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoCard } from 'common/components/Card/InfoCard';
import { MIDFUNDER_EMAIL } from 'common/config';
import Doc from 'app/assets/images/Doc.svg';
import { communicationUser, IUsersConnectedProps } from 'entities/User/User.communication';
import { communicationKyc, IKycConnectedProps } from 'entities/KYCProvider/KYCProvider.communication';
import { EKycStatus } from 'entities/KYCProvider/KYC.const';
import { AddKYCCardRightSlot } from 'entities/LoanApp/components/AddKYCCardRightSlot';
import { EUserRoles } from 'entities/User/User.models';

type AllProps = IUsersConnectedProps & IKycConnectedProps;

export const AddKYCCardComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const { kycStatus, userCurrent } = props;
  const { data } = kycStatus;
  const { data: userCurrentData } = userCurrent;
  const { status } = data || {};
  const isRejected = status === EKycStatus.Declined;

  useEffect(() => {
    const { getKycStatus, userCurrent } = props;
    const { data } = userCurrent;

    if (data?.role === EUserRoles.Borrower && data.id) {
      getKycStatus(data.id);
    }
  }, []);

  const getStatusLabel = (value: EKycStatus | undefined): string => {
    if (!value) {
      return '';
    }

    return t(`borrowerLoans.KYC.status.${value}`, 'unknown');
  };

  const statusLabel = getStatusLabel(status as EKycStatus);

  return (
    <InfoCard
      header={userCurrentData?.role === EUserRoles.Borrower ? t('borrowerLoans.KYC.header') : t('KYCStepper.bannerForInvestor')}
      subheader={`${t('borrowerLoans.KYC.description', { status: statusLabel })} ${
        isRejected ? t('borrowerLoans.KYC.rejectionMessage', { email: MIDFUNDER_EMAIL }) : ''
      }`}
      image={Doc}
      classNameSubHead={isRejected ? 'color-red' : undefined}
      imageAlt="KYC doc"
      rightSlot={<AddKYCCardRightSlot role={userCurrentData?.role} status={status as EKycStatus} />}
      className={userCurrentData?.role === EUserRoles.Investor ? 'info-card--investor' : ''}
    />
  );
};

export const AddKYCCard = communicationUser.injector(communicationKyc.injector(AddKYCCardComponent));
